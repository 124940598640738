const GetLandShpaeImage = (shape: string) => {
  switch (shape) {
    case "정방":
      return {
        text: "정사각형 모양의 토지로서 양변의 길이 비율이 1:1.1 내외인 토지",
        img: "https://back.transfarmer.kr/img/temp/1715043993996.png",
      };
    case "가로장방":
      return {
        text: "장방형의 토지로 넓은 면이 도로에 접하거나 도로를 향하고 있는 토지",
        img: "https://back.transfarmer.kr/img/temp/1715043878631.png",
      };
    case "세로장방":
      return {
        text: "장방형의 토지로 좁은 면이 도로에 접하거나 도로를 향하고 있는 토지",
        img: "https://back.transfarmer.kr/img/temp/1715043952675.png",
      };
    case "사다리":
      return {
        text: "사다리꼴(변형사다리형을 포함)모양의 토지",
        img: "https://back.transfarmer.kr/img/temp/1715043929536.png",
      };
    case "삼각":
      return {
        text: "삼각형의 토지로 그 한 면이 도로에 접하거나 도로를 향하고 있는 토지",
        img: "https://back.transfarmer.kr/img/temp/1715043942348.png",
      };
    case "역삼각":
      return {
        text: "삼각형의 토지(역사다리형을 포함)로 꼭지점 부분이 도로에 접하거나 도로를 향하고 있는 토지",
        img: "https://back.transfarmer.kr/img/temp/1715043942348.png",
      };
    case "부정":
      return {
        text: "다각형 또는 부정형의 토지",
        img: "https://back.transfarmer.kr/img/temp/1715043917755.png",
      };
    case "자루":
      return {
        text: "출입구가 자루처럼 좁게 생긴 토지",
        img: "https://back.transfarmer.kr/img/temp/1715043980806.png",
      };
    default:
      return {
        text: "",
        img: "",
      };
  }
};
export default GetLandShpaeImage;
