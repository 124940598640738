import { Topnav } from '../../component/mobile/Topnav';
import menuBtn from './../../asset/images/community/menuBtn.svg';
import share from './../../asset/images/community/share.svg';
import commentImg from './../../asset/images/community/speechBubble.svg';
import like from './../../asset/images/community/heartIcon.svg';
import sample from './../../asset/images/community/sample.svg';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { BottomNav } from '../../component/mobile/BottomNav';
import { useDispatch, useSelector } from 'react-redux';
import '../../asset/styles/SaleInfo.css';
import Spinner from '../../component/browser/Spinner';
import BoardService from '../../service/board/Board';
import NewComment from './NewComment';

interface DetailData {
  articleIdx: string;
  board: string;
  commentAmount: number | null;
  content: string;
  likeAmount: number | null;
  success: boolean;
  title: string;
  updateTime: string | null;
  views: number;
  writeTime: string;
  like: string;
}
interface CommentData {
  articleIdx: string;
  commentAmount: number | null;
  commentIdx: string;
  content: string;
  createdDate: string;
  email: string;
  likeAmount: number | null;
  like: string;
}
interface commentDataArray extends Array<CommentData> {}
// interface communityDataArray extends Array<DetailData> {}
const CommunityDetail = () => {
  const navigate = useNavigate();
  const email = useSelector((state: any) => {
    return state.user.userId;
  });
  const { state } = useLocation();
  const [detailData, setDetailData] = useState<DetailData>();
  const [commentData, setCommentData] = useState<commentDataArray>([]);
  const ref = useRef(null);
  const ref2 = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(5);
  const [menuType, setMenuType] = useState('');
  const [commentIdx, setCommentIdx] = useState('');
  const params = useParams();
  const customStyles = {
    content: {
      top: '0%',
      left: '0%',
      right: '0%',
      transform: 'translate(0%, 135%)',
    },
  };

  const getCommunityDetail = async () => {
    try {
      const result = await BoardService.GetBoardDetail(state.idx);
      setDetailData(result);
    } catch (error) {
      console.log(error);
    }
  };

  const getCommentApi = async () => {
    const key = 'article';
    const value = state.idx;
    let offset = 0;
    try {
      const result = await BoardService.GetComment(key, value, offset, limit);
      setCommentData(result.rows);
      sessionStorage.setItem('offset', '5');
      sessionStorage.setItem('totalCount', result.totalCount);
    } catch (error) {
      console.log(error);
    }
  };

  const getCommentScrollApi = async () => {
    const key = 'article';
    const value = state.idx;
    let offset = Number(sessionStorage.getItem('offset'));
    let totalCount = Number(sessionStorage.getItem('totalCount'));
    if (offset < totalCount) {
      setLoading(true);
      try {
        const result = await BoardService.GetComment(key, value, offset, limit);
        setCommentData((prev) => [...prev, ...result.rows]);
        setLoading(false);
        sessionStorage.setItem('offset', (offset + limit).toString());
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  const DeleteBoardApi = async () => {
    const articleIdx = state.idx;
    if (window.confirm('삭제하시겠습니까?')) {
      try {
        const result = await BoardService.DeleteBoard(articleIdx);
        alert('삭제하였습니다.');
        navigate('/community', { replace: true });
      } catch (error) {
        console.log(error);
      }
    } else {
      alert('취소하였습니다.');
    }
  };
  const DeleteCommentApi = async () => {
    try {
      await BoardService.DeleteComment(commentIdx);
    } catch (error) {
      console.log(error);
    }
  };
  const LikeApi = async (type: string, indexNum: string) => {
    const data = {
      type: type,
    };
    try {
      const result = await BoardService.Like(indexNum, data);
      if (result.success) {
        if (type === 'A') {
          getCommunityDetail();
        } else {
          getCommentApi();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const DislikeApi = async (type: string, indexNum: string) => {
    const data = { type: type };
    try {
      const result = await BoardService.Dislike(indexNum, data);
      if (result.success) {
        if (type === 'A') {
          getCommunityDetail();
        } else {
          getCommentApi();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const ReportApi = async (idx: string) => {
    const data = {
      type: menuType,
    };
    try {
      const result = await BoardService.Report(idx, data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    sessionStorage.clear();
    getCommunityDetail();
    getCommentApi();
  }, []);
  useEffect(() => {
    window.addEventListener(
      'scroll',
      () => {
        handleScroll();
      },
      {
        capture: true,
      },
    );
    return () => {
      window.removeEventListener('scroll', () => handleScroll());
    };
  }, []);

  useEffect(() => {}, []);
  const handleScroll = () => {
    if (ref2.current) {
      if (
        Math.round(
          (ref2.current as any).offsetHeight + (ref2.current as any).scrollTop,
        ) === (ref2.current as any).scrollHeight
      ) {
        getCommentScrollApi();
      }
    }
  };
  return (
    <div
      className="flex-column w-100er overflow-auto"
      style={{ paddingBottom: '140px', height: window.innerHeight }}
      ref={ref2}
    >
      {loading && <Spinner />}
      <ToastContainer
        position="bottom-center"
        hideProgressBar={false}
        limit={1}
        autoClose={1000}
      />
      <Modal
        className="fade-in-box"
        style={customStyles}
        isOpen={open}
        onRequestClose={() => {
          setOpen(false);
        }}
      >
        {email === state.email ? (
          <div
            className="flex-column flex-center w-100per"
            style={{ paddingBottom: '265px' }}
          >
            <div
              className=" w-100per flex-center padding-10-0"
              onClick={() => {
                {
                  menuType === 'A' ? DeleteBoardApi() : DeleteCommentApi();
                }
              }}
            >
              <div className="font-bold">삭제하기</div>
            </div>
            <div
              className=" w-100per flex-center padding-10-0"
              onClick={() => {
                {
                  menuType === 'A'
                    ? navigate(`/community/modify/${state.idx}`)
                    : navigate(`/community/commentmodify/${commentIdx}`);
                }
              }}
            >
              <div className="font-bold">수정하기</div>
            </div>
          </div>
        ) : (
          <div
            className="flex-column flex-center w-100per"
            style={{ paddingBottom: '265px' }}
          >
            <div
              className=" w-100per flex-center padding-10-0"
              onClick={() => {
                ReportApi(detailData?.articleIdx as string);
              }}
            >
              <div className="font-bold">글 신고하기</div>
            </div>
            <div className=" w-100per flex-center padding-10-0">
              <div className="font-bold">사용자 차단하기</div>
            </div>
          </div>
        )}
      </Modal>
      <Topnav text="커뮤니티" />
      <div className="flex-column w-100per padding-0-20 gap-20">
        <div className="flex-row space-between center-y mt-20">
          <div className="flex-row center-y gap-10">
            <img src={sample} />
            <div className="flex-column">
              <div className="font-normal">강원도</div>
              <div className="font-normal">{state.email}</div>
              <div className="font-normal">
                {detailData && detailData.writeTime}
              </div>
            </div>
          </div>
          <img
            src={menuBtn}
            onClick={() => {
              setOpen(true);
              setMenuType('A');
            }}
          />
        </div>

        <div className="flex-column gap-10 padding-b-20 borderbottom-1px-e8ebe6">
          <div className="font-bold">{detailData && detailData.title}</div>
          <div className="font-normal">{detailData && detailData.content}</div>
        </div>
        <div className="flex-row space-around center-y padding-b-20 borderbottom-1px-e8ebe6">
          <div
            className="flex-row gap-10 center-y"
            onClick={() => {
              {
                detailData && detailData.like === 'Y'
                  ? DislikeApi('A', state.idx)
                  : LikeApi('A', state.idx);
              }
            }}
          >
            {detailData && detailData.like === 'Y' ? (
              <div className="font-normal" style={{ fontSize: '18px' }}>
                ♥
              </div>
            ) : (
              <div className="font-normal" style={{ fontSize: '18px' }}>
                ♡
              </div>
            )}
            <div className="font-normal" style={{ whiteSpace: 'nowrap' }}>
              좋아요
            </div>
          </div>

          <div className="flex-row gap-10 center-y">
            <img src={share} />
            <div className="font-normal" style={{ whiteSpace: 'nowrap' }}>
              공유하기
            </div>
          </div>
        </div>
        {commentData &&
          commentData.map((item) => {
            return (
              <div className="flex-column gap-20">
                <div className="flex-row space-between">
                  <div className="flex-row gap-10 center-y">
                    <img src={sample} />
                    <div className="flex-column">
                      <div className="font-normal">{item.email}</div>

                      <div className="font-normal">{item.createdDate}</div>
                    </div>
                  </div>
                  <img
                    src={menuBtn}
                    onClick={() => {
                      setOpen(true);
                      setMenuType('C');
                      setCommentIdx(item.commentIdx);
                    }}
                  />
                </div>
                <div className="flex-column  gap-10">
                  <div
                    className="font-normal"
                    style={{
                      fontWeight: '500',
                      maxWidth: '100%',
                      wordBreak: 'break-all',
                    }}
                  >
                    {item.content}
                  </div>
                  <div className="flex-row gap-20">
                    <div
                      className="flex-row center-y"
                      onClick={() => {
                        {
                          item.like === 'Y'
                            ? DislikeApi('C', item.commentIdx)
                            : LikeApi('C', item.commentIdx);
                        }
                      }}
                    >
                      {item.like === 'Y' ? (
                        <div
                          className="font-normal"
                          style={{ fontSize: '18px' }}
                        >
                          ♥
                        </div>
                      ) : (
                        <div
                          className="font-normal"
                          style={{ fontSize: '18px' }}
                        >
                          ♡
                        </div>
                      )}
                      <div
                        className="font-normal"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        좋아요
                      </div>
                    </div>
                    <div
                      className="flex-row center-y"
                      onClick={() => {
                        navigate(
                          `/community/detail/${params.id}/${item.commentIdx}`,
                        );
                      }}
                    >
                      <img src={commentImg} />
                      <div
                        className="font-normal"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        댓글쓰기
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <NewComment
        ref={ref}
        onSuccess={() => {
          getCommentApi();
        }}
        setComment={setComment}
        data={{
          content: comment,
          articleIdx: state.idx,
        }}
      ></NewComment>
      <BottomNav />
    </div>
  );
};

export default CommunityDetail;
