import { Navigate, useNavigate } from 'react-router-dom';

export const FindIdPopUp = ({ setOnPopUp, type }: any) => {
  console.log(type);
  const navigate = useNavigate();
  return (
    <div
      className="flex-column fixed h-100vh z-9999 flex-center"
      style={{
        backgroundColor: '#00000060',
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
      }}
    >
      <div className="w-312 bg-FFFFFF padding-24-16-16-16 border-radius-16 gap-12 flex-center flex-column">
        <img
          src={
            type === 'error'
              ? 'asset/images/warn/WarnWithGrayCircle.svg'
              : '/asset/images/check/Check24GreenCircle2.svg'
          }
          className="w-56 h-56"
        />
        <div className="flex-column gap-24 flex-center w-100per">
          <div className="flex-column gap-8 flex-center">
            <div className="mobile-body-1-bold color-dark-primary text-center">
              {type === 'error'
                ? '비밀번호 찾기는 이메일로 가입한 경우에만 가능합니다.'
                : '비밀번호 변경 완료'}
            </div>
            {type === 'error' ? (
              <div className="mobile-body-2-medium color-disabled text-center">
                가입하신 소셜 계정으로 로그인 진행해 보세요.
              </div>
            ) : (
              <div className="mobile-body-2-medium color-disabled text-center">
                비밀번호 변경이 완료되었습니다. <br /> 새로운 비밀번호로
                로그인해 주세요.
              </div>
            )}
          </div>
          {type === 'error' ? (
            <div className="w-100per flex-row gap-8">
              <div
                className="w-50per padding-y-12 bg-blue-gray-100 border-radius-8 flex-center pointer"
                onClick={() => {
                  navigate('/');
                }}
              >
                <div className="mobile-body-2-medium color-disabled">
                  홈으로
                </div>
              </div>
              <div
                className="w-50per padding-y-12 bg-primary-500 border-radius-8 flex-center pointer"
                onClick={() => {
                  navigate('/login');
                }}
              >
                <div className="mobile-body-2-medium color-FFFFFF">로그인</div>
              </div>
            </div>
          ) : (
            <div className="w-100per bg-primary-500 padding-y-12 border-radius-8 flex-center pointer">
              <div
                className="mobile-body-2-medium color-FFFFFF"
                onClick={() => {
                  setOnPopUp(false);
                  navigate('/login');
                }}
              >
                로그인 화면으로
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
