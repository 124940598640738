import { useEffect } from 'react';
import PrivacyTerms from './terms/Privacy';
import ServiceTerms from './terms/Service';
import LocationTerms from './terms/Location';
import InfoTerms from './terms/Info';

export const TermsSns = ({ setTermsOpen, termsOpen, type, setType }: any) => {
  useEffect(() => {
    // 모달이 열릴 때 스크롤 막기
    if (termsOpen) {
      document.body.style.overflow = 'hidden';
    }

    // 모달이 닫힐 때 스크롤 해제
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [termsOpen]);
  return (
    <div
      className="flex-column fixed h-100per z-9999 browser-flex-center w-100per flex-center"
      style={{
        backgroundColor: '#00000060',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <div
        className="bg-FFFFFF  border-radius-16 gap-12 w-312"
        style={{ overflowY: 'auto', margin: '30px 0' }}
      >
        {type === 'p' ? (
          <PrivacyTerms SNS setTermsOpen={setTermsOpen} />
        ) : type === 's' ? (
          <ServiceTerms SNS setTermsOpen={setTermsOpen} />
        ) : type === 'l' ? (
          <LocationTerms SNS setTermsOpen={setTermsOpen} />
        ) : type === 'n' ? (
          <InfoTerms SNS setTermsOpen={setTermsOpen} />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
