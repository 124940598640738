import { useEffect, useState } from 'react';
import { handleKeyDown, isMobile } from '../function/function';
import cookie from 'react-cookies';
import { useDispatch, useSelector } from 'react-redux';
import { LoginId } from '../../store/user2Slice';
import { LoginService } from '../../service/service';
import { GetUserInfo, useAuth } from '../../utilities/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import Button, { Button2 } from '../mobile/Button';
import { SignUpType } from '../../store/signUpInfoSlice';
import {
  BrowserFacebook,
  BrowserGoogle,
  BrowserKakao,
  BrowserNaver,
} from '../../asset/styles/browser/login/Login';
import { set } from 'lodash';
import { RedirectHandler } from '../../controller/Redirect';

export const LoginPopUp2 = ({ address, setOnLoginPopup }: any) => {
  const location = useLocation();

  const navigate = useNavigate();
  const [pwd, setPwd] = useState('');
  const [id, setId] = useState(cookie.load('rememberId') || '');
  const [rememberChecked, setRememberChecked] = useState(false);
  const dispatch = useDispatch();
  const { onLogin } = useAuth();
  const state = useSelector((state: any) => {
    return state.user2;
  });
  const state2 = useSelector((state: any) => {
    return state.type;
  });
  // console.log(location.state);
  const LoginAPI = async () => {
    const data = {
      userId: id,
      password: pwd,
    };
    try {
      const result = await LoginService.Login(data);

      if (rememberChecked) {
        cookie.save('rememberId', state.userId, { path: '/' });
      } else {
        cookie.remove('rememberId');
      }
      // if (state2.type === "findland") {
      //   if (result.data.success) {
      //     onLogin(result.data.accessToken);
      //     // 추후에 변경
      //     GetUserInfo();
      //   }
      // } else if (state2.type === "aifarm") {
      //   if (result.data.success) {
      //     onLogin(result.data.accessToken);
      //     // 추후에 변경
      //     GetUserInfo();
      //   }
      // }
      if (result.data.success) {
        if (isMobile(window.innerWidth)) {
          localStorage.setItem('accessToken', result.data.accessToken);
          GetUserInfo('search');
          navigate(`${location.state.pathname}?area=${location.state.address}`);
        } else {
          // onLogin(result.data.accessToken);
          GetUserInfo('search');
          localStorage.setItem('accessToken', result.data.accessToken);
          // 추후에 변경

          setOnLoginPopup(false);
        }
      }
    } catch (error: any) {
      if (error.response.data.message === 'This userId dose not exist') {
        alert('아이디를 확인해주세요.');
      }
      if (error.response.data.message === 'Password mismatch') {
        alert('비밀번호를 확인해주세요.');
      }
    }
  };

  const SetPathName = () => {
    if (!isMobile(window.innerWidth)) {
      sessionStorage.setItem(
        'pathname',
        location.pathname.slice(-1) === '/'
          ? location.pathname.slice(0, -1)
          : location.pathname,
      );
      sessionStorage.setItem('pathArea', address);
    } else {
      sessionStorage.setItem('pathname', location.state.pathname);
      sessionStorage.setItem('pathArea', location.state.address);
    }
  };

  useEffect(() => {
    if (cookie.load('rememberId')) {
      setId(cookie.load('rememberId'));
      setRememberChecked(true);
    }
  }, []);
  return (
    <div
      className="z-20 center-x center-y "
      style={{
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        padding: !isMobile(window.innerWidth) ? '24px' : '',
        position: !isMobile(window.innerWidth) ? 'fixed' : 'relative',
        background: '#00000025',
      }}
    >
      <div
        className={`bg-FFFFFF w-100per flex-column browser-w-360`}
        style={{
          borderRadius: isMobile(window.innerWidth) ? '' : '16px',
        }}
      >
        <div className="flex-row space-between center-y padding-9-16 border-b-171E26">
          {isMobile(window.innerWidth) ? (
            <img
              src="/asset/images/arrow/ArrowBack24.svg"
              onClick={() => {
                navigate(-1);
              }}
            />
          ) : (
            <div className="w-33per"></div>
          )}
          <div className="w-33per m-t2-18-b color-dark-primary text-center">
            로그인
          </div>
          <div
            className="end-x"
            style={{ width: isMobile(window.innerWidth) ? '' : '33%' }}
          >
            <img
              src="/asset/images/close/Close24.svg "
              className="pointer "
              onClick={() => {
                isMobile(window.innerWidth)
                  ? navigate('/')
                  : setOnLoginPopup(false);
              }}
            />
          </div>
        </div>
        <div className="w-100per padding-32-16-0-16 flex-column gap-80">
          <div className="flex-column gap-32">
            <div className=" snsjoin flex-column flex-center gap-24 w-100per">
              <div className="w-100per font-medium f-size-14 flex-center">
                SNS 계정으로 로그인하기
              </div>
              <div className="flex-row flex-center gap-16 w-100per">
                <BrowserKakao
                  className=" hover"
                  onClick={() => {
                    dispatch(SignUpType('K'));
                    RedirectHandler('Kakao');
                    SetPathName();
                  }}
                />
                <BrowserNaver
                  className=" hover"
                  onClick={() => {
                    dispatch(SignUpType('N'));
                    RedirectHandler('Naver');
                    SetPathName();
                  }}
                />
                <BrowserFacebook
                  className=" hover"
                  onClick={() => {
                    dispatch(SignUpType('F'));
                    RedirectHandler('Facebook');
                    SetPathName();
                  }}
                />
                <BrowserGoogle
                  className=" hover"
                  onClick={() => {
                    dispatch(SignUpType('G'));
                    RedirectHandler('Google');
                    SetPathName();
                  }}
                />
              </div>
            </div>
            <div className="w-100per flex-row flex-center gap-8">
              <div
                style={{
                  height: '1px',
                  flex: '1',
                  backgroundColor: 'rgba(23, 30, 38, 0.10)',
                }}
              ></div>
              <div
                style={{ color: 'rgba(23, 30, 38, 0.40)', fontSize: '12px' }}
              >
                또는
              </div>
              <div
                style={{
                  height: '1px',
                  flex: '1',
                  backgroundColor: 'rgba(23, 30, 38, 0.10)',
                }}
              ></div>
            </div>
            <div className="w-100per flex-column gap-24">
              <div className="w-100per font-medium f-size-14 flex-center">
                이메일로 로그인하기
              </div>
              <div className="flex-column gap-16">
                <div className="flex-column gap-8">
                  <input
                    value={id}
                    onKeyDown={handleKeyDown}
                    className="flex-row w-100per h-50 border-radius-10 padding-x-14 border-1-e6e6e6 font-medium f-size-13"
                    placeholder="아이디 입력 (이메일)"
                    onChange={(e: any) => {
                      setId(e.target.value);
                    }}
                    onBlur={(e: any) => {
                      dispatch(LoginId(e.target.value));
                    }}
                  />
                  <input
                    value={pwd}
                    className="flex-row w-100per h-50 border-radius-10 padding-x-14 border-1-e6e6e6 font-medium f-size-13"
                    placeholder="비밀번호 입력"
                    type="password"
                    onKeyDown={(event) => {
                      handleKeyDown(event);
                      if (event.key === 'Enter') {
                        LoginAPI();
                      }
                    }}
                    onChange={(e: any) => {
                      setPwd(e.target.value);
                    }}
                    onBlur={(e: any) => setPwd(e.target.value)}
                  />
                </div>
                <Button
                  on
                  className=" hover"
                  onClick={() => {
                    LoginAPI();
                  }}
                  description="로그인"
                />
              </div>
              <div className="flex-row space-between w-100per">
                <div
                  className="flex-row gap-8 center-y pointer"
                  onClick={() => {
                    setRememberChecked(!rememberChecked);
                  }}
                >
                  <img
                    className="w-18 h-18"
                    src={
                      rememberChecked
                        ? '/asset/images/check/Check24GreenCircle.svg'
                        : '/asset/images/check/checkWithGrayCircle.svg'
                    }
                  />
                  <div className="font-medium f-size-14 line-h-20 color-dark-primary">
                    아이디 저장
                  </div>
                </div>
                <div
                  className="font-regular f-size-13 line-h-0 color-000000 pointer"
                  onClick={() => {
                    navigate(`/find`, { state: { tab: 0 } });
                  }}
                >
                  아이디•비밀번호 찾기
                </div>
              </div>
            </div>
          </div>
          {/* 회원가입 버튼 */}
          <div className="w-100per mb-40">
            <div
              className="joinbubble"
              style={{
                position: 'relative',
              }}
            >
              <div className="w-100per flex-row gap-10 flex-center  color-FFFFFF  bg-000000 z-10">
                <img src="/asset/images/banner/banner_money.svg" alt="" />
                <span>
                  지금 가입하면
                  <span className="free"> 10,000P 지급!</span>
                </span>
              </div>
            </div>
            <Button2
              on
              className=" hover"
              onClick={() => {
                dispatch(SignUpType('C'));
                navigate('/signup');
              }}
              description="회원가입"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
