import back from './../../asset/images/report/goback.svg';
interface PropsType {
  text: string;
}
const ReportTitle = ({ text }: PropsType) => {
  return (
    <div className="flex-column padding-x-16 gap-6">
      <div className="color-text-primary font-bold f-size-20 line-h-29">
        {text}
      </div>
      <div className="flex-row space-between w-100per">
        <div className="font-medium f-size-12 line-h-14 color-text-secondary">
          {/* {area[0].area} {area.length > 1 ? `외 ${area.length - 1}곳` : ""} */}
        </div>
      </div>
    </div>
  );
};

export default ReportTitle;
