import { useEffect } from 'react';
import { transNum } from '../../../component/function/function';
import InfoTitle from './InfoTitle';

const ReportInfo = ({
  data,
  type,
  list,
  auctionType,
}: {
  data: any;
  type: any;
  list: any;
  auctionType?: any;
}) => {
  const typeObj = {
    A: { material: '경매물건', color: '#EDF6FF', color2: '#0C64CE' },
    P: { material: '공매물건', color: '#FFE9E9', color2: '#D84836' },
    S: { material: '지자체 물건', color: '#FFFAE9', color2: '#E78B3E' },
  };

  return (
    <div className="flex-column">
      {!list.length ? (
        <InfoTitle
          address={data ? data.address : ''}
          type={type}
          auctionType={auctionType}
          keyword={data ? data.keyword : ''}
        />
      ) : (
        ''
      )}
      {type !== 'S' ? (
        <div className="padding-16 gap-8 flex-column">
          <div className="flex-row gap-8">
            <div className="font-medium f-size-12 line-h-14 color-text-tertiary">
              {data && data.auctionNumber && data.auctionNumber.split(' ')[1]}
            </div>
            <div className="font-medium f-size-12 line-h-14 color-text-tertiary">
              {data && data.court}
            </div>
          </div>
          <div className="flex-column">
            <div
              className="flex-row"
              style={{ borderBlock: '1px solid #171e2610' }}
            >
              <div
                className="bg-light-gray flex-row center-y padding-x-16"
                style={{ flex: 0.5 }}
              >
                <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                  입찰일
                </div>
              </div>
              <div style={{ flex: 2 }}>
                <div className="flex-row gap-8 center-y  padding-10-16">
                  <div className="font-medium f-size-14 line-h-20 color-text-primary">
                    {data &&
                      data.bidDate &&
                      data.bidDate.slice(0, 10).split('-').join('.')}
                  </div>
                  <div className="flex-row">
                    <div className="padding-2-8 border-radius-20 bg-primary-100">
                      <div className="font-medium f-size-14 line-h-20 color-primary-500">
                        {Math.ceil(
                          (new Date(data && data.bidDate).getTime() -
                            new Date().getTime()) /
                            (1000 * 3600 * 24),
                        ) >= 0
                          ? `마감 (D-${Math.ceil(
                              (new Date(data && data.bidDate).getTime() -
                                new Date().getTime()) /
                                (1000 * 3600 * 24),
                            )})`
                          : `마감`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="flex-row"
              style={{ borderBlock: '1px solid #171e2610' }}
            >
              <div
                className="bg-light-gray flex-row center-y padding-x-16"
                style={{ flex: 0.5 }}
              >
                <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                  감정가
                </div>
              </div>
              <div style={{ flex: 2 }}>
                <div className="flex-row gap-8 center-y  padding-10-16">
                  <div className="font-medium f-size-14 line-h-20 color-text-primary">
                    {transNum(data && data.appraisedValue)}원 (
                    {data &&
                      data.bidDate &&
                      data.bidDate.slice(2, 10).split('-').join('.')}{' '}
                    기준)
                  </div>
                </div>
              </div>
            </div>
            <div
              className="flex-row"
              style={{ borderBlock: '1px solid #171e2610' }}
            >
              <div
                className="bg-light-gray flex-row center-y padding-x-16"
                style={{ flex: 0.5 }}
              >
                <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                  최저가
                </div>
              </div>
              <div style={{ flex: 2 }}>
                <div className="flex-row gap-8 center-y  padding-10-16">
                  <div className="font-medium f-size-14 line-h-20 color-error">
                    {transNum(data && data.lowestPrice)}원
                  </div>
                </div>
              </div>
            </div>
            <div
              className="flex-row"
              style={{ borderBlock: '1px solid #171e2610' }}
            >
              <div
                className="bg-light-gray flex-row center-y padding-x-16"
                style={{ flex: 0.5 }}
              >
                <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                  입찰보증금
                </div>
              </div>
              <div style={{ flex: 2 }}>
                <div className="flex-row gap-8 center-y  padding-10-16">
                  <div className="font-medium f-size-14 line-h-20 color-text-primary">
                    최저 {transNum(data && data.lowestPrice / 10)}원 (낙찰가의
                    10%)
                  </div>
                </div>
              </div>
            </div>
            <div
              className="flex-row"
              style={{ borderBlock: '1px solid #171e2610' }}
            >
              <div
                className="bg-light-gray flex-row center-y padding-x-16"
                style={{ flex: 0.5 }}
              >
                <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                  유찰횟수
                </div>
              </div>
              <div style={{ flex: 2 }}>
                <div className="flex-row gap-8 center-y  padding-10-16">
                  <div className="font-medium f-size-14 line-h-20 color-text-primary">
                    {data && data.bidCount}회
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ReportInfo;
