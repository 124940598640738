import { createSlice } from '@reduxjs/toolkit';

const inquiryInitialState = {
  inquiryType: '',
  toggle: false,
};

const inquirySlice = createSlice({
  name: 'inquiry',
  initialState: inquiryInitialState,
  reducers: {
    ChangeInquiryWriteType(state, action) {
      state.inquiryType = action.payload;
    },
    OnToggle(state, action) {
      state.toggle = action.payload;
    },
  },
});

export const { ChangeInquiryWriteType, OnToggle } = inquirySlice.actions;
export default inquirySlice.reducer;
