import { useEffect, useState } from 'react';
import { isMobile, transNum } from '../../../../component/function/function';
import {
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  BarChart,
  Bar,
  Cell,
  ResponsiveContainer,
  LabelList,
  Legend,
} from 'recharts';
import { RowSectionTitle } from '../../../../component/report/Row';
import { TextSectionTitle } from '../../../../component/report/Text';
import { BoxGreen2 } from '../../../../component/report/Box';

import './ReportDetail.css';
import PriceService from '../../../../service/price/priceApi';
import DoughnutChart3 from '../../../../component/mobile/DoughnutChart3';
import useDidMountEffect from '../../../../hooks/UseDidMountEffect';

interface ReportDetailProps {
  data: any;
  type?: string;
  address: string;

  lastPyeongRegion: any;
  pyeongRegionlist: any;
  mypage?: boolean;
  des?: string;
  divRef?: any;
  divRef2?: any;
}

interface ChartDataItem {
  name: string;
  uv: number;
  vl: string;
}

const ReportDetail8 = ({
  data,
  type,
  address,

  lastPyeongRegion,
  pyeongRegionlist,
  mypage,
  des,
}: ReportDetailProps) => {
  const [areaType, setAreaType] = useState('P');

  const [selectedRegion, setSelectedRegion] = useState('emd');
  const [selectedTab, setSelectedTab] = useState(0);
  const [activeIndex, setActiveIndex] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState(0);
  // const [chartData, setChartData] = useState<ChartDataItem[]>([
  //   { name: '해당 농지', uv: 30000, vl: '' },
  //   { name: '읍면동', uv: 30000, vl: '' },
  //   { name: '시군구', uv: 30000, vl: '' },
  //   { name: '시도', uv: 30000, vl: '' },
  // ]);
  const addressParts = address && address.split(' ').slice(0, 3).reverse();

  const priceType = type || 'L';

  const handleTabClick = (idx: number) => {
    setSelectedTab(idx);
    setSelectedRegion(['emd', 'sig', 'ctp'][idx]);

    const selectedRegionIndex = barData.findIndex(
      (item) => item.name === address[idx],
    );
    setActiveIndex(idx + 1);
  };

  const handleClick = (data: any, index: number) => {
    if (index === 0) {
      return;
    }
    setSelectedTab(index - 1);
    setSelectedRegion(['emd', 'sig', 'ctp'][index - 1]);

    const selectedRegionIndex = barData.findIndex(
      (item) => item.name === address[index - 1],
    );

    setActiveIndex(index);
    // console.log(data, index);
    // if (index !== 0) {
    //   console.log(data, index);
    //   setActiveIndex(index);
    //   const tabIndex = address.findIndex((el) => el === data.name);
    //   console.log(tabIndex);
    //   setSelectedTab(tabIndex);
    //   setSelectedRegion(['emd', 'sig', 'ctp'][tabIndex]);
    // }
  };

  const barData = [
    {
      name: priceType === 'H' ? '단독주택 추정가' : '농지 추정가',
      uv: (mypage ? des === '가격분석 - 농지' : priceType === 'L')
        ? areaType === 'P'
          ? data.estimatedPricePerPyeong
          : data.estimatedPricePerMeter
        : areaType === 'P'
          ? data.perPyeongEstimatedHousingPrice
          : data.PerMeterEstimatedHousingPrice,
      vl: `${
        priceType === 'L'
          ? areaType === 'P'
            ? transNum(data.estimatedPricePerPyeong, true, true)
            : transNum(data.estimatedPricePerMeter, true, true)
          : areaType === 'P'
            ? transNum(data.perPyeongEstimatedHousingPrice, true, true)
            : transNum(data.PerMeterEstimatedHousingPrice, true, true)
      }원`,
    },
    {
      name: addressParts && addressParts[0],
      uv:
        areaType === 'P'
          ? lastPyeongRegion?.emd?.last1year?.pricePerPyeong
          : lastPyeongRegion?.emd?.last1year?.price,
      vl:
        lastPyeongRegion?.emd?.last1year?.pricePerPyeong !== null
          ? `${
              areaType === 'P'
                ? transNum(
                    lastPyeongRegion?.emd?.last1year?.pricePerPyeong,
                    true,
                    true,
                  )
                : transNum(lastPyeongRegion?.emd?.last1year?.price, true, true)
            }원`
          : '실거래 없음',
    },
    {
      name: addressParts && addressParts[1],
      uv:
        areaType === 'P'
          ? lastPyeongRegion?.sig?.last1year?.pricePerPyeong
          : lastPyeongRegion?.sig?.last1year?.price,
      vl:
        lastPyeongRegion?.sig?.last1year?.pricePerPyeong !== null
          ? `${
              areaType === 'P'
                ? transNum(
                    lastPyeongRegion?.sig?.last1year?.pricePerPyeong,
                    true,
                    true,
                  )
                : transNum(lastPyeongRegion?.sig?.last1year?.price, true, true)
            }원`
          : '실거래 없음',
    },
    {
      name: addressParts && addressParts[2],
      uv:
        areaType === 'P'
          ? lastPyeongRegion?.ctp?.last1year?.pricePerPyeong
          : lastPyeongRegion?.ctp?.last1year?.price,
      vl:
        lastPyeongRegion?.ctp?.last1year?.pricePerPyeong !== null
          ? `${
              areaType === 'P'
                ? transNum(
                    lastPyeongRegion?.ctp?.last1year?.pricePerPyeong,
                    true,
                    true,
                  )
                : transNum(lastPyeongRegion?.ctp?.last1year?.price, true, true)
            }원`
          : '실거래 없음',
    },
  ];

  const { emdList, sigList, ctpList }: any = pyeongRegionlist || {};

  //라인 차트 데이터  만들기

  const formatDataForLineChart = (list: any) => {
    return (list ? list : []).map((item: any) => {
      return {
        month: !item.month ? '' : item.month,
        price: item.price === 'No data' ? 0 : item.price,
        pricePerPyeong: !item.pricePerPyeong ? 0 : item.pricePerPyeong,
        count: !item.count ? 0 : item.count,
        prepare1month: !item.prepare1month ? 0 : item.prepare1month,
        prepare1monthPerPyeong: !item.prepare1monthPerPyeong
          ? 0
          : item.prepare1monthPerPyeong,
        prepare1monthRate: !item.prepare1monthRate ? 0 : item.prepare1monthRate,
        prepare1year: !item.prepare1year ? 0 : item.prepare1year,
        prepare1yearPerPyeong: !item.prepare1yearPerPyeong
          ? 0
          : item.prepare1yearPerPyeong,
        prepare1yearRate: !item.prepare1yearRate ? 0 : item.prepare1yearRate,
        prepareMax: !item.prepareMax ? 0 : item.prepareMax,
        prepareMaxPerPyeong: !item.prepareMaxPerPyeong
          ? 0
          : item.prepareMaxPerPyeong,
        prepareMaxRate: !item.prepareMaxRate ? 0 : item.prepareMaxRate,
        up: !item.up ? 0 : item.up,
        upRate: !item.upRate ? 0 : item.upRate,
        down: !item.down ? 0 : item.down,
        downRate: !item.downRate ? 0 : item.downRate,
        etc: !item.etc ? 0 : item.etc,
        etcRate: !item.etcRate ? 0 : item.etcRate,
      };
    });
  };

  const formattedEmdList = formatDataForLineChart(emdList);
  const formattedSigList = formatDataForLineChart(sigList);
  const formattedCtpList = formatDataForLineChart(ctpList);

  const lineChartDataArray = {
    emd: formatDataForLineChart(emdList),
    sig: formatDataForLineChart(sigList),
    ctp: formatDataForLineChart(ctpList),
  };

  const CustomYAxisTick = ({ x, y, payload }: any) => (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={5} textAnchor="end" fill="#666">
        {payload.value.toLocaleString()}
      </text>
    </g>
  );
  useEffect(() => {
    console.log(
      'wefwef',
      emdList,
      formatDataForLineChart(emdList),
      formattedEmdList,
      lineChartDataArray,
    );
  }, [selectedRegion]);
  const handlePrevData = () => {
    setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextData = () => {
    setSelectedIndex((prevIndex) =>
      Math.min(
        prevIndex + 1,
        lineChartDataArray[selectedRegion as keyof typeof lineChartDataArray]
          .length - 1,
      ),
    );
  };

  const selectedData =
    lineChartDataArray[selectedRegion as keyof typeof lineChartDataArray][
      selectedIndex
    ];

  const dougunutData = [
    {
      text: '상승 거래',
      rate: selectedData?.upRate || 0,
      color: '#EC4F4F',
      count: selectedData?.up || 0,
    },
    {
      text: '하락 거래',
      rate: selectedData?.downRate || 0,
      color: '#4B47FC',
      count: selectedData?.down || 0,
    },
    {
      text: '보합 거래',
      rate: selectedData?.etcRate || 0,
      color: '#A3A6A9',
      count: selectedData?.etc || 0,
    },
  ];

  useDidMountEffect(() => {
    let idx: any = 0;
    lineChartDataArray[selectedRegion as keyof typeof lineChartDataArray]
      // ex) 공덕면을 눌렀더가 김제시를 눌렀다가 다시 공덕면을 눌렀을 때 그래프의 tooltip이 배열에 맞게가 아니라 0번째 원소로 계속 보이는 현상 수정
      // .sort((b: any, a: any) => {
      //   return (
      //     Number(a.month.split('-').join('')) -
      //     Number(b.month.split('-').join(''))
      //   );
      // })
      .map((el: any, i: number) => {
        if (Number(el.count) > 0 && i === 0) {
          idx = 100;
        } else if (idx) {
          return;
        } else if (Number(el.count) > 0) {
          idx = i;
        }
      });
    setSelectedIndex(idx === 100 ? 0 : idx);
  }, [pyeongRegionlist, selectedTab]);

  const combinedData: any = [];
  const months = Object.keys(lineChartDataArray.ctp); // Assuming all arrays have same length and order

  months.forEach((month) => {
    combinedData.push({
      month: lineChartDataArray.ctp[month].month,
      ctpPrice: lineChartDataArray.ctp[month].pricePerPyeong,
      emdPrice: lineChartDataArray.emd[month].pricePerPyeong,
      sigPrice: lineChartDataArray.sig[month].pricePerPyeong,
    });
  });
  combinedData.sort((a: any, b: any) => {
    // 문자열로 비교
    if (a.month < b.month) return -1;
    if (a.month > b.month) return 1;
    return 0;
  });
  const Title = ({ title }: any) => (
    <div className="padding-8-16 ">
      <div
        className="flex-center border-radius-8 padding-4-8 gap-10 flex-row border-1E9B6B"
        style={{ width: '164px' }}
      >
        <div className="m-b1-16-b color-primary-500 no-wrap">{title}</div>
      </div>
    </div>
  );

  return (
    <div className="padding-b-24 browser-bg-FFFFFF">
      {/* 지역 탭 */}
      <div className="flex-column">
        <div className="pyeongregion-title-box">
          <RowSectionTitle>
            <TextSectionTitle>
              {priceType === 'L' ? '지역별 토지단가' : '지역별 단독주택단가'}
              {areaType === 'P' ? '(평)' : '(m²)'}
            </TextSectionTitle>
          </RowSectionTitle>

          <div className="padding-24-16-8">
            <div
              className="switch-p-m hover bg-FFFFFF padding-2-8 cneter-x center-y flex-row border-radius-100 border-1-e2e2e2 font-medium f-size-12 line-h-20 color-text-tertiary"
              onClick={() => setAreaType((prev) => (prev !== 'P' ? 'P' : 'M'))}
            >
              <img src="/asset/images/arrow/Refresh20.svg" alt="" />
              {areaType === 'P' ? 'm²' : '평'}
            </div>
          </div>
        </div>
        <div className="of-x-auto relative flex-row">
          <div className="flex-row padding-12 gap-8">
            <div className="border-radius-100 flex-row gap-5">
              {addressParts &&
                addressParts.map((el: any, idx: any) => (
                  <div
                    key={idx}
                    className={`padding-6-16 border-radius-100 hover no-wrap ${
                      selectedTab === idx ? 'active-tab' : ''
                    }`}
                    style={{
                      border: '1px solid #F0F0F0',
                      color: selectedTab === idx ? '#fff' : '#171e268f',
                      backgroundColor: selectedTab === idx ? '#1A2129' : '#fff',
                    }}
                    onClick={() => {
                      handleTabClick(idx);
                    }}
                  >
                    {el}
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* 최신 평당가 박스*/}

        <div className="padding-8-16 gap-8 flex-row">
          <BoxGreen2>
            <div className="font-bold f-size-14 line-h-20 color-text-secondary">
              최근 1년
            </div>
            <div
              className="font-bold f-size-13 line-h-24 "
              style={{ color: '#1E9B6B' }}
            >
              {lastPyeongRegion[selectedRegion]?.last1year.pricePerPyeong !==
                null &&
              lastPyeongRegion[selectedRegion]?.last1year.pricePerPyeong !==
                0 ? (
                <>
                  <p>
                    {priceType === 'L' ? '토지단가' : '단독주택단가'}
                    {areaType === 'P' ? '(평) ' : '(m²) '}
                    {areaType === 'P' ? (
                      <>
                        {transNum(
                          lastPyeongRegion[selectedRegion]?.last1year
                            .pricePerPyeong,
                          true,
                          true,
                        )}
                        원
                      </>
                    ) : (
                      <>
                        {transNum(
                          lastPyeongRegion[selectedRegion]?.last1year.price,
                          true,
                          true,
                        )}
                        원
                      </>
                    )}
                  </p>
                  <p>
                    거래량{' '}
                    {Number(
                      lastPyeongRegion[selectedRegion]?.last1year.count,
                    ).toLocaleString()}
                    건
                  </p>
                </>
              ) : (
                <p>사례 없음</p>
              )}
            </div>
          </BoxGreen2>
          <BoxGreen2>
            <div className="font-bold f-size-14 line-h-20 color-text-secondary">
              최근 3개월
            </div>
            <div
              className="font-bold f-size-13 line-h-24 "
              style={{ color: '#1E9B6B' }}
            >
              {lastPyeongRegion[selectedRegion]?.last1month.pricePerPyeong !==
                null &&
              lastPyeongRegion[selectedRegion]?.last1month.pricePerPyeong !==
                0 ? (
                <>
                  <p>
                    {priceType === 'L' ? '토지단가' : '단독주택단가'}
                    {areaType === 'P' ? '(평) ' : '(m²) '}
                    {areaType === 'P' ? (
                      <>
                        {transNum(
                          lastPyeongRegion[selectedRegion]?.last1month
                            .pricePerPyeong,
                          true,
                          true,
                        )}
                        원
                      </>
                    ) : (
                      <>
                        {transNum(
                          lastPyeongRegion[selectedRegion]?.last1month.price,
                          true,
                          true,
                        )}
                        원
                      </>
                    )}
                  </p>
                  <p>
                    거래량{' '}
                    {Number(
                      lastPyeongRegion[selectedRegion]?.last1month.count,
                    ).toLocaleString()}
                    건
                  </p>
                </>
              ) : (
                <p>사례 없음</p>
              )}
            </div>
          </BoxGreen2>
        </div>
      </div>
      {/* 막대그래프*/}
      <div className={` flex-column`}>
        {' '}
        <div className="flex-column w-50per">
          <div className="tota tota1" style={{ padding: '16px 16px 0px 16px' }}>
            <div className="frame-2609336">
              <div className="lastPyeongRegion-title">
                {selectedTab === 0
                  ? address.split(' ').slice(0, 3).join(' ')
                  : selectedTab === 1
                    ? address.split(' ').slice(0, 2).join(' ')
                    : selectedTab === 2
                      ? address.split(' ').slice(0, 1).join(' ')
                      : '---'}
              </div>
            </div>
            <div className="_3-km">
              최근 1년 평균 실거래가({areaType === 'P' ? '평' : 'm²'})
            </div>
          </div>
          <div className="frame-2609304">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                margin={{ top: 40 }}
                width={700}
                height={3000}
                data={barData}
              >
                <XAxis
                  dataKey="name"
                  tickLine={false}
                  style={{ fontSize: '12px' }}
                />
                <Bar
                  dataKey="uv"
                  fill="#09CE7D"
                  onClick={handleClick}
                  radius={[10, 10, 0, 0]}
                >
                  <LabelList
                    dataKey="vl"
                    offset={12}
                    position="top"
                    fill="#000"
                    fontSize="13"
                  />
                  {barData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      cursor="pointer"
                      fill={
                        entry.name === '농지 추정가' ||
                        entry.name === '단독주택 추정가'
                          ? '#219C6D'
                          : index === activeIndex
                            ? '#09CE7D'
                            : '#EAF8F2'
                      }
                      onClick={() => index !== 0 && handleClick(entry, index)}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
          {/* 라인그래프 */}
          <div className="padding-24-16 flex-column gap-16">
            <LineChart
              className="flex-medium f-size-11 center-x "
              width={
                isMobile(window.innerWidth) ? window.innerWidth - 32 : 360 - 16
              }
              height={181}
              // data={lineChartDataArray[
              //   selectedRegion as keyof typeof lineChartDataArray
              // ].reverse()}
              data={
                lineChartDataArray[
                  selectedRegion as keyof typeof lineChartDataArray
                ]
              }
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              onClick={(nextState, event) => {
                const clickedLabel = nextState?.activeLabel;
                const chartData =
                  lineChartDataArray[
                    selectedRegion as keyof typeof lineChartDataArray
                  ];
                const selectedIndex =
                  chartData.length -
                  1 -
                  chartData.findIndex(
                    (item: any) => item.month === clickedLabel,
                  );

                if (selectedIndex !== -1) {
                  setSelectedIndex(selectedIndex);
                }
              }}
            >
              {areaType === 'P' ? (
                <Line
                  type="monotone"
                  dataKey="pricePerPyeong"
                  stroke="#53df89"
                />
              ) : (
                <Line type="monotone" dataKey="price" stroke="#53df89" />
              )}
              <XAxis dataKey="month" />
              <YAxis tick={<CustomYAxisTick />} />
              <Tooltip
                content={({ payload, label, active }) => {
                  if (active && payload && payload.length) {
                    console.log('payloda', payload, label);
                    const dataItem = payload[0].payload;
                    const priceKey =
                      areaType === 'P' ? 'pricePerPyeong' : 'price';
                    const priceUnit = areaType === 'P' ? '평' : 'm²';
                    return (
                      <div className="custom-tooltip">
                        <p>{`${label}`}</p>
                        <p>{`거래량: ${dataItem.count}건`}</p>
                        <p
                          style={{ color: '#53df89' }}
                        >{`토지단가(${priceUnit}): ${transNum(
                          dataItem[priceKey],
                          true,
                          true,
                        )}원`}</p>
                      </div>
                    );
                  }
                  return null;
                }}
              />
            </LineChart>
          </div>
        </div>
        <div className="flex-column w-100per">
          <div className="calander-switch w-100per">
            <div className="padding-24-16-8">
              <div
                className="switch-p-m hover bg-FFFFFF padding-2-8 cneter-x center-y flex-row border-radius-100 border-1-e2e2e2 font-medium f-size-12 line-h-20 color-text-tertiary"
                onClick={() =>
                  setAreaType((prev) => (prev !== 'P' ? 'P' : 'M'))
                }
              >
                <img src="/asset/images/arrow/Refresh20.svg" alt="" />
                {areaType === 'P' ? 'm²' : '평'}
              </div>
            </div>
          </div>
          {/* 도넛그래프 */}
          <div className="padding-16 flex-column gap-16">
            <div className="frame-2609340">
              <div className="frame-2609338">
                <div className="monthly">
                  <div className="frame-2608953">
                    <img
                      src="/asset/images/arrow/arrow_left_calendar_black.svg"
                      alt="왼쪽화살표"
                      onClick={handlePrevData}
                    />
                    <div className="frame-26089532">
                      <div className="_2023-122">{selectedData?.month}</div>
                    </div>
                    <img
                      src="/asset/images/arrow/arrow_right_calendar_black.svg"
                      alt="오른쪽화살표"
                      onClick={handleNextData}
                    />
                  </div>
                </div>
                {selectedData?.pricePerPyeong === 0 ? (
                  <div className="no-data-message">
                    <img
                      src="/asset/images/warn/WarnWithGrayCircle.svg"
                      alt=""
                    />
                    <p>실거래가 없습니다.</p>
                  </div>
                ) : (
                  <div className="frame-2608355">
                    <div className="frame-2609341">
                      <div className="list">
                        <div className="text">
                          {priceType === 'L'
                            ? '평균 토지단가'
                            : '평균 단독주택단가'}
                          {areaType === 'P' ? '(평)' : '(m²)'}
                        </div>
                        <div className="text2">
                          {Math.round(
                            areaType === 'P'
                              ? selectedData?.pricePerPyeong
                              : selectedData?.price,
                          ).toLocaleString()}
                          원{areaType === 'P' ? '/평' : '/m²'}
                        </div>
                      </div>
                      <div className="list">
                        <div className="text">
                          전월 대비{areaType === 'P' ? '(평)' : '(m²)'}
                        </div>
                        <div
                          className="text3"
                          style={{
                            color:
                              selectedData?.prepare1monthPerPyeong === 0
                                ? '#171e26'
                                : selectedData?.prepare1monthPerPyeong > 0
                                  ? '#EC4F4F'
                                  : '#4B47FC',
                          }}
                        >
                          {Math.abs(
                            Math.round(
                              areaType === 'P'
                                ? selectedData?.prepare1monthPerPyeong
                                : selectedData?.prepare1month,
                            ),
                          ).toLocaleString()}
                          {selectedData?.prepare1monthPerPyeong > 0
                            ? '원 상승 '
                            : selectedData?.prepare1monthPerPyeong < 0
                              ? '원 하락 '
                              : '원 '}
                          {`(${selectedData?.prepare1monthRate > 0 ? '+' : ''}${
                            Math.round(selectedData?.prepare1monthRate * 10) /
                            10
                          }%)`}
                        </div>
                      </div>
                      <div className="list">
                        <div className="text">
                          전년 동기 대비{areaType === 'P' ? '(평)' : '(m²)'}
                        </div>
                        <div
                          className="text4"
                          style={{
                            color:
                              selectedData?.prepare1yearPerPyeong === 0
                                ? '#171e26'
                                : selectedData?.prepare1yearPerPyeong > 0
                                  ? '#EC4F4F'
                                  : '#4B47FC',
                          }}
                        >
                          {Math.abs(
                            Math.round(
                              areaType === 'P'
                                ? selectedData?.prepare1yearPerPyeong
                                : selectedData?.prepare1year,
                            ),
                          ).toLocaleString()}
                          {selectedData?.prepare1yearPerPyeong > 0
                            ? '원 상승 '
                            : selectedData?.prepare1yearPerPyeong < 0
                              ? '원 하락 '
                              : '원 '}
                          {`(${selectedData?.prepare1yearRate > 0 ? '+' : ''}${
                            Math.round(selectedData?.prepare1yearRate * 10) / 10
                          }%)`}
                        </div>
                      </div>
                      <div className="list">
                        <div className="text">
                          최고가 대비{areaType === 'P' ? '(평)' : '(m²)'}
                        </div>
                        <div
                          className="text4"
                          style={{
                            color:
                              selectedData?.prepareMaxPerPyeong === 0
                                ? '#171e26'
                                : selectedData?.prepareMaxPerPyeong > 0
                                  ? '#EC4F4F'
                                  : '#4B47FC',
                          }}
                        >
                          {/* {Math.abs(
                          Math.round(selectedData?.prepareMaxPerPyeong)
                        ).toLocaleString()} */}
                          {Math.abs(
                            Math.round(
                              areaType === 'P'
                                ? selectedData?.prepareMaxPerPyeong
                                : selectedData?.prepareMax,
                            ),
                          ).toLocaleString()}
                          {selectedData?.prepareMaxPerPyeong > 0
                            ? '원 상승 '
                            : selectedData?.prepareMaxPerPyeong < 0
                              ? '원 하락 '
                              : '원 '}
                          {`(${selectedData?.prepareMaxRate > 0 ? '+' : ''}${
                            Math.round(selectedData?.prepareMaxRate * 10) / 10
                          }%)`}
                        </div>
                      </div>
                    </div>
                    <div className="flex-row center-y space-between w-100per">
                      <div className="center-y" style={{ height: '132px' }}>
                        <DoughnutChart3 list={dougunutData} />
                      </div>
                      <div
                        className="flex-column gap-8"
                        style={{ width: '180px' }}
                      >
                        <div className="flex-row space-between f-size-14 font-medium color-text-primary">
                          <span>총 거래량</span>
                          <span>{selectedData?.count}건</span>
                        </div>
                        {dougunutData.map((el, index) => (
                          <div className="flex-row space-between" key={index}>
                            <div className="flex-row center-y gap-8">
                              <div
                                style={{
                                  width: '8px',
                                  height: '8px',
                                  borderRadius: '100%',
                                  background: el.color,
                                }}
                              ></div>
                              <div
                                className="font-medium f-size-14 line-h-20 color-text-tertiary"
                                style={{ width: '55px' }}
                              >
                                {el.text}
                              </div>
                            </div>
                            <div
                              className="font-medium f-size-14 line-h-20 color-text-primary"
                              style={{ textAlign: 'right' }}
                            >
                              {el.count}건 ({el.rate.toFixed(1)}%)
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReportDetail8;
