import { useEffect, useState } from 'react';
import {
  RowSectionTitle,
  RowTableGreenBold,
  RowTableValue,
} from '../../../../component/report/Row';
import { TextSectionTitle } from '../../../../component/report/Text';
import { transNum } from '../../../../component/function/function';
import { Tooltip2 } from '../../../../component/common/tooltip';
import { BoxGreen1 } from '../../../../component/report/Box';

interface data {
  SGA: number;
  avgFarmEquipmentCost: number;
  avgLaborCost: number;
  avgLandRentalCost: number;
  avgMaterialCost: number;
  avgOrchardCost: number;
  avgSGA: number;
  avgWaterHeatingCost: number;
  farmEquipmentCost: number;
  laborCost: number;
  landRentalCost: number;
  materialCost: number;
  orchardCost: number;
  waterHeatingCost: number;
}

const ReportDetail4 = ({ data }: { data: any }) => {
  const [onToolTip2, setOnToolTip2] = useState(false);

  return (
    <div className="padding-b-24 relative">
      <RowSectionTitle>
        <TextSectionTitle>4. 예상 매출</TextSectionTitle>
      </RowSectionTitle>
      <div className="flex-column">
        <div className="padding-16-16-0">
          <RowTableGreenBold
            title={`매출액(${data.crops}기작)`}
            value={`${transNum(data.yearSales)}원`}
          />
        </div>
        <div className="padding-8-16-16 gap-8 flex-column end-y">
          <RowTableValue
            title={`생산량(${data.crops}기작)`}
            value={`${data.quantity && data.quantity.toLocaleString()}kg`}
          />
          <RowTableValue
            warn
            onClick={() => setOnToolTip2(true)}
            title="판매단가"
            value={`${transNum(data.unitPrice)}원`}
          />

          {/* <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
              ※ 지역내 산지 도매상 평균 판매단가를 적용함
            </div> */}
          <div className="absolute">
            <Tooltip2
              left="-337"
              bottom="-60"
              width
              on={onToolTip2}
              off={() => setOnToolTip2(false)}
              text="지역내 산지 도매상 평균 판매단가를 적용함"
            />
          </div>
        </div>

        {/* <div className="padding-16-16-0">
            <div className="font-bold f-size-16 line-h-24 color-text-secondary">
              최근 10년, 사과의 지역 평균 판매단가
            </div>
          </div> */}
        <div className="padding-8-16 gap-10">
          <BoxGreen1>
            <div className="font-bold f-size-14 line-h-20 color-text-secondary">
              AI 예상 매출액은 {transNum(data.yearSales)}원으로
            </div>
            <div
              className="font-bold f-size-16 line-h-24 color-primary-500"
              style={{
                color:
                  data.yearSales < data.avgYearSales ? '#d62554' : '#1E9B6B',
              }}
            >
              지역 평균 {transNum(data.avgYearSales)}원
              {data.yearSales > data.avgYearSales
                ? `보다 ${Math.round(data.differenceRate * 10) / 10}% 높습니다.`
                : data.yearSales < data.avgYearSales
                  ? `보다 ${
                      Math.round(data.differenceRate * -1 * 10) / 10
                    }% 낮습니다.`
                  : '과 같습니다.'}
            </div>
          </BoxGreen1>
        </div>
      </div>
    </div>
  );
};

export default ReportDetail4;
