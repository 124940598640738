import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MapService from '../../../service/map/Map';
import { isMobile } from '../../../component/function/function';
import { colorChannel } from '@mui/system';
import GuideBanner from '../../../component/banner/GuideBanner';
import LandService from '../../../service/land/landApi';
import { set } from 'lodash';
interface IMapContainerData {
  setCurrentMarker: React.Dispatch<React.SetStateAction<any>>;
  setFixMarker: React.Dispatch<React.SetStateAction<any>>;
  setMarkerArray: React.Dispatch<React.SetStateAction<Array<any>>>;
  setAreaList: React.Dispatch<React.SetStateAction<Array<any>>>;
  setPanTo: React.Dispatch<React.SetStateAction<any>>;
  setPanToAndMarker: React.Dispatch<React.SetStateAction<any>>;
  setIsSearch: React.Dispatch<React.SetStateAction<any>>;
  setStickPolygon: React.Dispatch<React.SetStateAction<any>>;
  setDrawCircle: React.Dispatch<React.SetStateAction<any>>;
  hash: string;
  location: string;
  setZoomIn: any;
  setZoomIn2: any;
  setOnInputFocus?: any;
  setMapClick?: any;
  temp?: boolean;
  mapClick?: boolean;
  address?: string;
  kmTab?: number;
  setKmTab?: any;
  priceReport?: boolean;
}

const MapContainer = (props: IMapContainerData) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [xpos, setXpos] = useState('');
  const [ypos, setYpos] = useState('');
  const [onBanner, setOnBanner] = useState(true);
  const location = useLocation();
  const obj = {
    strokeWeight: 3, // 선의 두께입니다
    strokeColor: '#09ce7d', // 선의 색깔입니다
    strokeOpacity: 0.8, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
    strokeStyle: 'solid', // 선의 스타일입니다
    fillColor: '#09ce7d', // 채우기 색깔입니다
    fillOpacity: 0.25, // 채우기 불투명도 입니다
  };

  const navigate = useNavigate();

  let circleArray: any = [];
  let circleArray2: any = [];
  let circleArray3: any = [];
  let circleArray4: any = [];

  const [mapTypeChanger, setMapTypeChanger]: any = useState();
  const [overlay, setOverlay] = useState(false);
  let customOverlay: any; // customOverlay 변수를 정의합니다.
  let customOverlay2: any;
  let customOverlay3: any;
  const [addOverLay, setAddOverlay]: any = useState();
  const [mapTypeTitle, setMapTypeTitle] = useState('roadmap');
  const panToFunc = async () => {
    try {
      const result = await LandService.coordinate(props.address || '');

      setXpos(result.coordinate.xpos);
      setYpos(result.coordinate.ypos);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    window.kakao?.maps.load(() => {
      let locPosition = new window.kakao.maps.LatLng(
        37.50437802028816,
        127.04996591045683,
      );
      if (navigator.geolocation) {
        // GeoLocation을 이용해서 접속 위치를 얻어옵니다
        navigator.geolocation.getCurrentPosition(function (position) {
          var lat = position.coords.latitude, // 위도
            lon = position.coords.longitude; // 경도

          map.setCenter(new window.kakao.maps.LatLng(lat, lon));
        });
      }

      const container = document.getElementById('map'); //지도를 담을 영역의 DOM 레퍼런스
      const options = {
        //지도를 생성할 때 필요한 기본 옵션
        center: locPosition, //지도의 중심좌표.
        level: 6, //지도의 레벨(확대, 축소 정도)
      };
      const map = new window.kakao.maps.Map(container, options); //지도 생성 및 객체 리턴

      const handleMapClick = (mouseEvent: any) => {
        const latLng = mouseEvent.latLng;
      };

      const addOverlay = (overlay: boolean) => {
        if (overlay) {
          map.removeOverlayMapTypeId(window.kakao.maps.MapTypeId.USE_DISTRICT);
        } else {
          map.addOverlayMapTypeId(window.kakao.maps.MapTypeId.USE_DISTRICT);
        }
        setOverlay(!overlay);
      };

      function zoomIn() {
        // if (location.pathname === '/price/map') {
        //   map.setLevel(6);
        // } else {
        //   map.setLevel(1);
        // }
        map.setLevel(1);
      }
      props.setZoomIn(() => () => zoomIn());
      function setMapType(maptype: any) {
        if (maptype === 'roadmap') {
          map.setMapTypeId(window.kakao.maps.MapTypeId.ROADMAP);
        } else {
          map.setMapTypeId(window.kakao.maps.MapTypeId.HYBRID);
        }
        setMapTypeTitle(maptype);
      }
      if (true) {
        addOverlay(true);
        setMapType('');
      } else {
        addOverlay(false);
        setMapType('roadmap');
      }
      setAddOverlay(() => (overlay: any) => addOverlay(overlay));
      setMapTypeChanger(() => (maptype: any) => setMapType(maptype));

      const geocoder = new window.kakao.maps.services.Geocoder();

      const searchMarker = new window.kakao.maps.Marker({
        position: map.getCenter(),
      });

      var imageSrc =
          'https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/marker_red.png', // 마커이미지의 주소입니다
        imageSize = new window.kakao.maps.Size(64, 69), // 마커이미지의 크기입니다
        imageOption = { offset: new window.kakao.maps.Point(27, 69) }; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.

      // 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
      var markerImage = new window.kakao.maps.MarkerImage(
          imageSrc,
          imageSize,
          imageOption,
        ),
        markerPosition = new window.kakao.maps.LatLng(37.54699, 127.09598); // 마커가 표시될 위치입니다

      // 마커를 생성합니다

      var marker = new window.kakao.maps.Marker({
        position: markerPosition,
        image: markerImage, // 마커이미지 설정
      });
      window.kakao.maps.event.addListener(map, 'click', handleMapClick);
      window.kakao.maps.event.addListener(
        map,
        'click',
        // InfoWindow를 전역 변수로 선언하여 중복 생성을 방지합니다.

        function (mouseEvent: any) {
          searchDetailAddrFromCoords(
            mouseEvent.latLng,
            function (result: any, status: any) {
              if (status === window.kakao.maps.services.Status.OK) {
                props.setIsSearch(false);
                searchMarker.setMap(map);
                searchMarker.setPosition(mouseEvent.latLng);
                window.kakao.maps.event.addListener(
                  searchMarker,
                  'click',
                  () => {
                    searchMarker.setMap(null);
                  },
                );
                props.setZoomIn2(false);
                const marker = new window.kakao.maps.Marker({
                  position: mouseEvent.latLng,
                });
                if (
                  location.pathname !== '/auction/map'
                  //  &&
                  // !(
                  //   location.pathname === '/price/map' &&
                  //   (sessionStorage.getItem('priceReport') === 'open' ||
                  //     props.priceReport)
                  // ) &&
                  // !props.priceReport
                ) {
                  navigate(`?area=${result[0].address.address_name}`);
                }

                props.setCurrentMarker({
                  marker: marker,
                  latLng: mouseEvent.latLng,
                });
              }
            },
          );
        },
      );
      props.setDrawCircle(() => {
        return (latLng: any) => {
          drawCircle(latLng);
        };
      });

      let drawnOverlays: any[] = []; // 각 클릭마다 생성된 overlay를 추적하기 위한 배열
      let drawnOverlays2: any[] = []; // 각 클릭마다 생성된 overlay를 추적하기 위한 배열
      async function drawCircle(latLng: any) {
        props.setIsSearch(false);
        searchMarker.setMap(map);
        latLng = new window.kakao.maps.LatLng(latLng.Ma, latLng.La);

        const circleOptions = {
          strokeWeight: 1,
          strokeColor: '#FFFFFF',
          strokeOpacity: 1,
          strokeStyle: 'solid',
        };

        const circleInfo = [
          {
            radius: 300,
            fillOpacity: 0.25,
          },
          {
            radius: 1000,
            fillOpacity: sessionStorage.getItem('kmTab') === '1' ? 0.25 : 0,
          },
          {
            radius: 2000,
            fillOpacity: sessionStorage.getItem('kmTab') === '2' ? 0.25 : 0,
          },
          {
            radius: 3000,
            fillOpacity: sessionStorage.getItem('kmTab') === '3' ? 0.25 : 0,
          },
        ];

        const circleArrayRef = [
          circleArray,
          circleArray2,
          circleArray3,
          circleArray4,
        ];

        // if (location.pathname === '/price/map') {
        //   // Remove existing overlays
        //   drawnOverlays.forEach((overlay: any) => {
        //     if (overlay) overlay.setMap(null);
        //   });

        //   circleInfo.forEach((info, index) => {
        //     const circle = new window.kakao.maps.Circle({
        //       center: latLng,
        //       radius: info.radius,
        //       ...circleOptions,
        //       fillOpacity: info.fillOpacity,
        //       fillColor: info.fillOpacity !== 0 ? '#FFFFFF' : null,
        //     });

        //     if (circleArrayRef[index].length > 0) {
        //       circleArrayRef[index].push(circle);
        //       circleArrayRef[index][0].setMap(null);
        //       circleArrayRef[index].shift();
        //       circleArrayRef[index][0].setMap(map);
        //     } else {
        //       circleArrayRef[index].push(circle);
        //       circle.setMap(map);
        //     }
        //   });

        //   searchMarker.setPosition(latLng);

        //   const topLatLngArray = [300, 1000, 2000, 3000].map((radius) => {
        //     const centerLatLng = latLng;
        //     return new window.kakao.maps.LatLng(
        //       centerLatLng.getLat() +
        //         (radius / 1000 / 6371) * (180 / Math.PI) +
        //         0.0002,
        //       centerLatLng.getLng()
        //     );
        //   });

        //   // Update or create overlays
        //   drawnOverlays = topLatLngArray.map((topLatLng, index) => {
        //     let overlay = new window.kakao.maps.CustomOverlay({ zIndex: 1 });

        //     const contentNode = document.createElement('div');
        //     contentNode.addEventListener('mouseover', function (event) {
        //       // 오버레이 내의 요소 중 마우스 오버된 요소인지 확인
        //       if (
        //         sessionStorage.getItem('priceReport') === 'open' &&
        //         index !== 0
        //       ) {
        //         if (event.target === this) {
        //           props.setKmTab(index);

        //           sessionStorage.setItem('kmTab', index.toString());
        //           // 추가 동작 수행
        //         }
        //       }
        //     });

        //     contentNode.addEventListener('mouseout', function (event) {
        //       // 오버레이 내의 요소 중 마우스가 벗어난 요소인지 확인
        //       if (event.target === this) {
        //         props.setKmTab(10000);

        //         sessionStorage.removeItem('kmTab');
        //         // 추가 동작 수행
        //       }
        //     });
        //     contentNode.innerHTML = `<div style="position:relative; z-index: -1; background-color: #FFFFFF; border-radius: 999px; padding: 4px 8px;      font-family: Pretendard;
        //     font-size: 12px;
        //     font-weight: 500;
        //     color: #2A3746;
        //     line-height: 14px;
        //     text-align: left;">${circleInfo[index].radius}m 이내</div>`;

        //     overlay.setContent(contentNode);
        //     overlay.setPosition(topLatLng);
        //     overlay.setMap(map);

        //     return overlay;
        //   });

        //   drawnOverlays2.forEach((overlay) => {
        //     overlay.setMap(null);
        //   });

        //   // sessionstorage의 kmtab 값과 일치하는 경우에만 overlay를 그립니다.
        //   drawnOverlays2 = topLatLngArray.map((topLatLng, index) => {
        //     let overlay = new window.kakao.maps.CustomOverlay({ zIndex: 1 });

        //     const contentNode = document.createElement('div');
        //     contentNode.innerHTML = `
        //     <div style="
        //         position: relative;
        //         background-color: #09CE7D;
        //         border-radius: 8px;
        //         padding: 8px;
        //         display: flex;
        //         flex-direction: column;
        //         align-items: center;
        //     ">
        //         <div style="
        //             font-family: Pretendard;
        //             font-size: 12px;
        //             font-weight: 500;
        //             color: #FFFFFF;
        //             line-height: 14px;
        //             text-align: left;
        //         ">추정가</div>
        //         <div style="
        //             font-family: Pretendard;
        //             font-size: 14px;
        //             color: #FFFFFF;
        //             font-weight: 700;
        //             line-height: 20px;
        //             text-align: left;
        //         ">${
        //           sessionStorage.getItem('kmPrice') === '0'
        //             ? '추정가 없음'
        //             : sessionStorage.getItem('kmPrice') + '원'
        //         }</div>
        //         <div style="
        //             position: absolute;
        //             bottom: -10px;
        //             left: 50%;
        //             transform: translateX(-50%);
        //             border-style: solid;
        //             border-width: 10px 10px 0;
        //             border-color: #09CE7D transparent transparent;
        //         "></div>
        //     </div>
        // `;

        //     if (sessionStorage.getItem('kmTab') === index.toString()) {
        //       overlay.setContent(contentNode);
        //       overlay.setPosition(topLatLng);
        //       overlay.setMap(map);
        //     }

        //     return overlay;
        //   });
        // }
      }
      function searchDetailAddrFromCoords(coords: any, callback: any) {
        // 좌표로 법정동 상세 주소 정보를 요청합니다
        geocoder.coord2Address(coords.getLng(), coords.getLat(), callback);
      }
      const fixMarker = (
        marker: any,
        query: any,
        areaList: any,
        info: object,
      ) => {
        let length = 0;
        // if (areaList[0]) {
        //   var moveLatLon = new window.kakao.maps.LatLng(
        //     areaList[0].latLng.Ma,
        //     areaList[0].latLng.La
        //   );
        //   const clickLine = new window.kakao.maps.Polyline({
        //     map: map, // 선을 표시할 지도입니다
        //     path: [moveLatLon, marker.latLng], // 선을 구성하는 좌표 배열입니다 클릭한 위치를 넣어줍니다
        //     strokeOpacity: 0, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
        //   });

        //   length = clickLine.getLength();
        // }
        if (true) {
          // length < 2000 (합필)
          // searchMarker.setMap(null);
          if (marker) {
            // marker.marker.setMap(map);
            // window.kakao.maps.event.addListener(marker.marker, "click", () => {
            //   marker.marker.setMap(null);
            //   props.setMarkerArray((prev) => {
            //     prev.splice(prev.length - 1, 1);
            //     return [...prev];
            //   });
            //   props.setAreaList((prev) => {
            //     prev.splice(prev.length - 1, 1);
            //     return [...prev];
            //   });
            // });
            // props.setMarkerArray((prev) => [...prev, marker.marker]);
            props.setAreaList((prev) => [
              ...prev,
              { area: query, latLng: marker.latLng, info },
            ]);
          }
        } else {
          alert('최초 선택지로부터 반경 2km를 초과하면 선택이 불가합니다.');
        }
      };

      props.setFixMarker(() => {
        return (marker: any, query: any, areaList: any, info: object) => {
          fixMarker(marker, query, areaList, info);
        };
      });

      async function panTo(latLng: any) {
        searchMarker.setMap(null);

        var moveLatLon = new window.kakao.maps.LatLng(latLng.Ma, latLng.La);

        await map.panTo(moveLatLon);
        markerFunc();
      }
      props.setPanTo(() => {
        return (latLng: any) => {
          panTo(latLng);
        };
      });

      async function panToAndMarker(latLng: any, isSearch: boolean) {
        if (isSearch) {
          var moveLatLon = new window.kakao.maps.LatLng(latLng.Ma, latLng.La);

          map.panTo(moveLatLon);
          searchMarker.setMap(map);
          searchMarker.setPosition(moveLatLon);
          await window.kakao.maps.event.addListener(
            searchMarker,
            'click',
            () => {
              searchMarker.setMap(null);
            },
          );
          const marker = new window.kakao.maps.Marker({
            position: moveLatLon,
          });
          props.setCurrentMarker({
            marker: marker,
            latLng: moveLatLon,
          });
          markerFunc();
        }
        props.setIsSearch(true);
      }
      props.setPanToAndMarker(() => {
        return (latLng: any, isSearch: boolean) => {
          panToAndMarker(latLng, isSearch);
        };
      });

      if (sessionStorage.areaList) {
        if (props.hash === '#7') {
          const areaList = JSON.parse(sessionStorage.areaList);
          areaList.map((el: any, i: number) => {
            var moveLatLon = new window.kakao.maps.LatLng(
              el.latLng.Ma,
              el.latLng.La,
            );

            searchDetailAddrFromCoords(
              moveLatLon,
              function (result: any, status: any) {
                if (status === window.kakao.maps.services.Status.OK) {
                  const marker = new window.kakao.maps.Marker({
                    position: moveLatLon,
                  });
                  marker.setMap(map);
                  window.kakao.maps.event.addListener(marker, 'click', () => {
                    marker.setMap(null);

                    props.setMarkerArray((prev) => {
                      prev.splice(i, 1);
                      return [...prev];
                    });
                    props.setAreaList((prev) => {
                      prev.splice(i - 1, 1);
                      return [...prev];
                    });
                  });
                  props.setMarkerArray((prev) => {
                    return [...prev, marker];
                  });
                }
              },
            );
          });
        }
      }
      let resetRar: any = [];
      let markerRar: any = [];

      const markerFunc = async function () {
        // if (locationHook.pathname !== "/house/map") {
        //   return;
        // }
        // return;
        resetRar.map((el: any) => {
          el.setMap(null);
        });
        markerRar.map((el: any) => {
          el.setMap(null);
        });
        var bounds = map.getBounds();

        // 영역정보의 남서쪽 정보를 얻어옵니다
        var swLatlng = bounds.getSouthWest();
        var neLatlng = bounds.getNorthEast();

        let level = map.getLevel();
        let data: any = [];

        markerRar = [];
        if (level <= 1) {
          try {
            let list = await getMaplist();
            list = JSON.parse(list.data).map((el: any) => {
              return {
                address: el.address,
                // rectangle: y,
                id: el.id,
                pnuCode: el.pnucode,
                point: [el.lat, el.lng],
              };
            });
            data = list;
          } catch (err) {
            console.log(err);
          }
        } else if (level > 1 && level <= 3) {
          try {
            let list = await getMaplist();
            list = JSON.parse(list.data).map((el: any) => {
              return {
                amount: el.amount || 0,
                point: [el.avg.lat, el.avg.lng],
              };
            });
            data = list;
          } catch (err) {
            console.log(err);
          }
        } else {
          if (level > 8) {
            level = 'ctp_rvn';
          } else if (level > 6) {
            level = 'sig';
          } else if (level > 3) {
            level = 'emd';
          }
          try {
            let list = await getMaplist();
            list = JSON.parse(list.data).map((el: any) => {
              return {
                name: el.name,
                // rectangle: y,
                id: el.id,
                amount: el.legaldong || 0,
                point: el.point
                  .split('POINT')[1]
                  .slice(1, -1)
                  .split(' ')
                  .map((el: any) => Number(el)),
              };
            });
            data = list;
          } catch (err) {
            console.log(err);
          }
        }
        // const filteredCtp = data.filter(({ point }: any) => {
        //   if (
        //     point[0] >= swLatlng.La &&
        //     point[0] <= neLatlng.La &&
        //     point[1] >= swLatlng.Ma &&
        //     point[1] <= neLatlng.Ma
        //   ) {
        //     return true;
        //   }
        //   return false;
        // });
        let array;
        if (typeof level === 'string') {
          array = data.map((el: any) => {
            return {
              content: el.name,
              latlng: new window.kakao.maps.LatLng(el.point[1], el.point[0]),
              // rectangle: el.rectangle,
              id: el.id,
              amount: el.amount,
            };
          });
        } else if (level <= 3 && level > 1) {
          array = data.map((el: any) => {
            return {
              content: el.amount,
              latlng: new window.kakao.maps.LatLng(el.point[1], el.point[0]),
            };
          });
        } else {
          array = data.map((el: any) => {
            return {
              content: el.address,
              latlng: new window.kakao.maps.LatLng(el.point[1], el.point[0]),
              // rectangle: el.rectangle,
              id: el.id,
              pnuCode: el.pnuCode,
            };
          });
        }
        let rar: any = [];
        for (var i = 0; i < array.length; i++) {
          // 마커를 생성합니다
          let a = array[i];
          let num = i;

          var content = document.createElement('div');
          if (typeof level === 'string') {
            var child1 = document.createElement('div');
            var child12 = document.createElement('div');
            var child2 = document.createElement('div');
            var child22 = document.createElement('div');
            child1.className =
              'padding-6 bg-primary-400 border-radius-100 hover-marker1 border-1-09CE7D min-w-35';
            child12.className =
              'font-bold f-size-12 line-h-16 hover-marker2 text-center';
            child12.innerText = Number(a.amount).toLocaleString() || '0';
            child1.append(child12);
            content.append(child1);
            child2.className = 'padding-6-10';
            child22.className =
              'font-bold f-size-12 line-h-16 color-text-primary bg-FFFFFF';
            child22.innerText = a.content;
            child2.append(child22);
            content.append(child2);
            content.className =
              'flex-row border-1-09CE7D border-radius-27 bg-white hover-marker pointer';
          } else if (level <= 3 && level > 1) {
            var child1 = document.createElement('div');
            var child12 = document.createElement('div');

            child1.className =
              'padding-6 bg-primary-400 border-radius-100per hover-marker1 border-2-09CE7D w-55 h-55 center-x center-y';
            child12.className =
              'font-bold f-size-16 line-h-26 hover-marker4 text-center color-FFFFFF';
            child12.innerText = a.content || 0;
            child1.append(child12);
            content.append(child1);

            content.className =
              'flex-row border-1-09CE7D border-radius-27 bg-white hover-marker pointer';
          } else {
            var child = document.createElement('img');
            child.src = '/asset/images/marker/marker.png';
            child.className = 'pointer green-marker';

            var child1 = document.createElement('div');
            var child12 = document.createElement('div');
            var child2 = document.createElement('div');
            var child22 = document.createElement('div');
            var childX = document.createElement('div');

            childX.className =
              'flex-row border-1-09CE7D border-radius-27 bg-white pointer absolute hover-marker3 z-10 bottom-m-16 mt-92';
            child1.className =
              'padding-6 bg-primary-400 border-radius-100 hover-marker3 border-1-09CE7D min-w-35 mb-20';
            child12.className =
              'font-bold f-size-12 line-h-16 hover-marker3 text-center';
            child12.innerText = ' ';
            child1.append(child12);
            child2.className = 'padding-6-10 ';
            child22.className =
              'font-bold f-size-12 line-h-16 color-text-primary bg-FFFFFF';
            child22.innerText = a.content;
            child2.append(child22);
            childX.append(child2);
            content.append(child);
            content.append(childX);
            content.className =
              'green-marker relative center-x center-y flex-column padding-b-32';
          }

          var marker = new window.kakao.maps.CustomOverlay({
            map: map, // 마커를 표시할 지도
            position: array[i].latlng, // 마커의 위치
            content: content,
          });

          markerRar.push(marker);

          if (level < 2) {
            content.addEventListener('mousedown', (e: any) => {
              mouseDownEventHandler(e);
              navigate('?address=' + a.content);
            });
          } else {
            content.addEventListener('mousedown', (e: any) => {
              mouseDownEventHandler(e);
              var level = map.getLevel();
              map.setLevel(level - 1);
              map.setCenter(a.latlng);
            });
          }
          let over = false;
          content.addEventListener('mouseover', async () => {
            let polygon: any;
            markerRar[num].setZIndex(10);
            if (level !== 3 && level !== 2 && !markerRar[num].poly) {
              over = true;
              let tbObj;
              let value = a.id;
              if (level <= 1) {
                tbObj = 'tbaddress';
              } else if (level > 1 && level <= 3) {
              } else if (level === 'emd') {
                tbObj = 'emd';
              } else if (level === 'sig') {
                tbObj = 'sig';
              } else {
                tbObj = 'ctp_rvn';
              }

              const res = await MapService.getPolygon(
                value,
                tbObj as 'tbaddress',
              );

              let x = JSON.parse(res.data)
                .rectangle.split('MULTIPOLYGON')[1]
                .split('(')
                .map((el: any) => {
                  if (el.length > 0) {
                    return el
                      .split(')')
                      .map((el: any) => {
                        if (el.length > 0 && el !== ',') {
                          return JSON.stringify(
                            el.split(',').map((el: any) => {
                              return el.split(' ').map((el: any) => Number(el));
                            }),
                          ).slice(1, -1);
                        } else {
                          return '';
                        }
                      })
                      .join(']');
                  } else {
                    return el;
                  }
                })
                .join('[')
                .split('][')
                .join('],[');
              let y = JSON.parse(x);
              var polygonPath: any;

              polygonPath = y;
              const one = polygonPath.map((el: any) => {
                if (Array.isArray(el[0])) {
                  return el.map((el: any) => {
                    if (Array.isArray(el[0])) {
                      return el.map((el: any) => {
                        return new window.kakao.maps.LatLng(el[1], el[0]);
                      });
                    }
                    return new window.kakao.maps.LatLng(el[1], el[0]);
                  });
                }
                return new window.kakao.maps.LatLng(el[1], el[0]);
              });
              if (markerRar[num]) {
                markerRar[num].poly = one;
              }
              one.map((el: any) => {
                polygon = new window.kakao.maps.Polygon({
                  path: el, // 그려질 다각형의 좌표 배열입니다
                  ...obj,
                });
                polygon.setMap(map);
                rar.push(polygon);
                resetRar.push(polygon);
              });
            } else if (!!markerRar[num].poly && !over) {
              over = true;
              rar.map((el: any) => {
                el.setMap(null);
              });
              rar = [];
              markerRar[num].poly.map((el: any) => {
                polygon = new window.kakao.maps.Polygon({
                  path: el, // 그려질 다각형의 좌표 배열입니다
                  ...obj,
                });
                polygon.setMap(map);
                rar.push(polygon);
                resetRar.push(polygon);
              });
            }
          });
          content.addEventListener('mouseout', () => {
            over = false;
            markerRar[num].setZIndex(0);
            rar.map((el: any) => {
              el.setMap(null);
            });
            rar = [];
          });
        }

        async function getMaplist() {
          return await MapService.getList(
            swLatlng.La,
            neLatlng.La,
            swLatlng.Ma,
            neLatlng.Ma,
            level,
            props.location.slice(1, 6),
          );
        }

        function mouseDownEventHandler(e: any) {
          window.kakao.maps.event.preventMap();
          e.stopPropagation();
          if (e.preventDefault) {
            e.preventDefault();
          } else {
            e.returnValue = false;
          }
        }
      };
      window.kakao.maps.event.addListener(map, 'dragend', markerFunc);
      window.kakao.maps.event.addListener(map, 'zoom_changed', markerFunc);
      markerFunc();

      let third: any = [];

      const stickPolygon = async (address: string) => {
        try {
          third.map((el: any) => {
            el.setMap(null);
          });
          let polygon: any;
          const res2 = await MapService.getPolygon2(address);
          let x = JSON.parse(res2.data)
            .rectangle.split('MULTIPOLYGON')[1]
            .split('(')
            .map((el: any) => {
              if (el.length > 0) {
                return el
                  .split(')')
                  .map((el: any) => {
                    if (el.length > 0 && el !== ',') {
                      return JSON.stringify(
                        el.split(',').map((el: any) => {
                          return el.split(' ').map((el: any) => Number(el));
                        }),
                      ).slice(1, -1);
                    } else {
                      return '';
                    }
                  })
                  .join(']');
              } else {
                return el;
              }
            })
            .join('[')
            .split('][')
            .join('],[');
          let y = JSON.parse(x);
          var polygonPath: any;
          polygonPath = y;
          const second = polygonPath.map((el: any) => {
            if (Array.isArray(el[0])) {
              return el.map((el: any) => {
                if (Array.isArray(el[0])) {
                  return el.map((el: any) => {
                    return new window.kakao.maps.LatLng(el[1], el[0]);
                  });
                }
                return new window.kakao.maps.LatLng(el[1], el[0]);
              });
            }
            return new window.kakao.maps.LatLng(el[1], el[0]);
          });
          if (marker) {
            marker.poly = second;
          }
          second.map((el: any) => {
            polygon = new window.kakao.maps.Polygon({
              path: el, // 그려질 다각형의 좌표 배열입니다
              ...obj,
            });
            polygon.setMap(map);
            // 다각형에 마우스오버 이벤트가 발생했을 때 변경할 채우기 옵션입니다
            third.push(polygon);
            var mouseoverOption = {
              fillColor: '#1ca56c', // 채우기 색깔입니다
            };

            // 다각형에 마우스아웃 이벤트가 발생했을 때 변경할 채우기 옵션입니다
            var mouseoutOption = {
              fillColor: '#09ce7d', // 채우기 색깔입니다
            };

            // 다각형에 마우스오버 이벤트를 등록합니다
            window.kakao.maps.event.addListener(
              polygon,
              'mouseover',
              function () {
                // 다각형의 채우기 옵션을 변경합니다
                polygon.setOptions(mouseoverOption);
              },
            );

            window.kakao.maps.event.addListener(
              polygon,
              'mouseout',
              function () {
                // 다각형의 채우기 옵션을 변경합니다
                polygon.setOptions(mouseoutOption);
              },
            );
          });
        } catch (err) {
          console.log(err);
        }
      };

      props.setStickPolygon(() => {
        return (address: any) => {
          stickPolygon(address);
        };
      });
    });
  }, [props.location]);
  // useEffect(() => {
  //   panToFunc();
  // }, [props.address]);

  return (
    <div
      onClick={() => {
        if (!props.priceReport) {
          props.setMapClick(true);
          props.setOnInputFocus(false);
        }
      }}
      className="w-100per  z-1"
      id="map"
      style={{
        width: isMobile(window.innerWidth) ? '100%' : '100%',
        height:
          // window.innerWidth < 1024 ? `${window.innerHeight - 52}px` : "100vh",
          window.innerWidth < 1024 ? `calc(100vh - 54px)` : ``,
      }}
    >
      {(location.pathname === '/price/map' ||
        location.pathname === '/annuity/map' ||
        location.pathname === '/findland/map' ||
        location.pathname === '/aiFarming/map' ||
        location.pathname === '/house/map') &&
        // !props.mapClick &&
        isMobile(window.innerWidth) &&
        onBanner && <GuideBanner temp={props.temp} setOnBanner={setOnBanner} />}
      <div className="flex-row">
        <div className="custom_typecontrol radius_border flex-row">
          <span
            id="btnRoadmap"
            className={`${mapTypeTitle === 'roadmap' ? 'selected_btn' : 'btn'}`}
            onClick={() => mapTypeChanger('roadmap')}
          >
            지도
          </span>
          <span
            id="btnSkyview"
            className={`${mapTypeTitle === 'skyview' ? 'selected_btn' : 'btn'}`}
            onClick={() => mapTypeChanger('skyview')}
          >
            스카이뷰
          </span>
        </div>
        <div className="custom_typecontrol2 radius_border flex-row">
          <span
            id="btnRoadmap"
            className={`${overlay ? 'selected_btn' : ''}`}
            onClick={() => addOverLay(overlay)}
          >
            지적편집도
          </span>
        </div>
        {isMobile(window.innerWidth) && !props.priceReport && (
          <img
            onClick={() => {
              navigate('/');
            }}
            src="/asset/images/icon/map_home_floating.svg"
            style={{
              position: 'fixed',
              bottom: '80px',
              right: '0px',
              zIndex: '9999',
            }}
          />
        )}
      </div>
    </div>
  );
};

export default MapContainer;
