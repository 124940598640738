import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { SetPopUpOn } from '../../../store/onOffSlice';
import { useEffect } from 'react';
import { clickAnnuityMenu } from '../../../hackler';
const Main1 = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Box = ({
    title,
    content,
    src,
    url,
    type,
    tab,
    className,
    N,
    left,
    url2,
  }: any) => (
    <div
      className={
        'padding-16 gap-8 bg-FFFFFF border-radius-8 flex-1 hover space-between h-152' +
        className
      }
      onClick={() => {
        navigate(`${url}`);
        sessionStorage.setItem('tab', tab);
        (tab === 'price' || tab === 'annuity') && dispatch(SetPopUpOn(true));
        if (tab === 'annuity') {
          clickAnnuityMenu();
        }
        if (url2) {
          window.location.href = url2;
        }
      }}
    >
      <div className="flex-column">
        <div
          className={`font-bold  line-h-24 color-text-primary w-1024-f-size-18 `}
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            gap: '4px',
            alignItems: 'center',
          }}
        >
          {title}
          {N && (
            <div
              className="flex-center bg-increase"
              style={{ width: '16px', height: '16px', borderRadius: '4px' }}
            >
              <div className="font-bold f-size-16 color-FFFFFF">N</div>
            </div>
          )}
        </div>
        <div
          className={`font-medium f-size-14 line-h-20 color-text-secondary`}
          style={{ minHeight: '40px' }}
        >
          {content}
        </div>
      </div>
      <div className="flex-row end-x end-y">
        <img src={src} style={{ height: '48px' }} alt="" />
      </div>
    </div>
  );
  const BoxBrowser = ({
    title,
    content,
    src,
    url,
    tab,
    N,
    left,
    url2,
  }: any) => (
    <div
      className="padding-16 flex-column bg-FFFFFF border-radius-8 flex-1 hover space-between h-162"
      onClick={() => {
        navigate(`${url}`);
        sessionStorage.setItem('tab', tab);
        sessionStorage.setItem('temp', 'temp');
        (tab === 'price' || tab === 'annuity') && dispatch(SetPopUpOn(true));
        if (tab === 'annuity') {
          clickAnnuityMenu();
        }
        if (url2) {
          window.location.href = url2;
        }
      }}
    >
      <div>
        <div
          className="font-bold f-size-18 line-h-30 color-text-primary "
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            gap: '4px',
            alignItems: 'center',
          }}
        >
          <span>{title}</span>
          {N && (
            <div
              className="flex-center bg-increase"
              style={{
                width: '16px',
                height: '16px',
                borderRadius: '4px',
              }}
            >
              <div className="font-bold f-size-12 color-FFFFFF">N</div>
            </div>
          )}
        </div>

        <div className="font-medium f-size-16 line-h-26 color-text-secondary">
          {content}
        </div>
      </div>
      <div className="flex-row end-x">
        <img src={src} style={{ height: '48px' }} alt="" />
      </div>
    </div>
  );
  return (
    <div
      className="flex  browser-center-x"
      style={{
        background: '#F0F0F0',
      }}
    >
      <div className="padding-32-16 tablet-padding-32-40 browser-padding-80-60 gap-24 browser-gap-40 browser-max-w-1320 w-100per tablet-flex-column flex-row browser-1920-gap-104">
        <div className="font-bold f-size-20 line-h-29 color-text-primary browser-f-size-32 browser-line-h-46 color-text-primary tablet ">
          농지 검색에서 수익 창출까지 <br />
          AI 가이드로 농촌생활 똑똑하게!
        </div>
        <div className="font-bold f-size-20 line-h-29 color-text-primary browser-f-size-32 browser-line-h-46 color-text-primary browser flex-column no-wrap">
          <span className="font-medium f-size-20 line-h-33 color-text-primary">
            농지 검색에서 수익 창출까지
          </span>
          AI 가이드로 <br /> 농촌생활 똑똑하게!
        </div>
        <div className="flex-column gap-8 tablet w-100per">
          <div className="flex-row gap-8 w-100per">
            <Box
              className="  tablet-new-flex-row "
              title="가격 분석"
              content="AI가 추정하는 농지/전원주택 가격"
              src="/asset/images/main/price.svg"
              url="/price/map"
              type="1"
              tab="price"
            />
            <Box
              className="  tablet-new-flex-row  "
              title="토지 활용"
              content="토지의 활용 가능성 AI 진단하기"
              src="/asset/images/main/landUse.svg"
              url="/landUse/map"
              type="1"
              tab="landUse"
              N
              left="60"
            />
            {/* <Box
              className="  tablet-new-flex-row  "
              title="매물 분석"
              content="농지/전원주택의 매물, 경매, 공매 정보"
              src="/asset/images/main/auction.svg"
              url="/auction/map"
              type="2"
              tab="findland"
              N
              left="60"
            /> */}
            <Box
              className=" tablet-flex none "
              title="농지연금 분석"
              content="가입 조건부터 수익 분석까지"
              src="/asset/images/main/pension.svg"
              url="/annuity/map"
              type="1"
              tab="annuity"
            />
          </div>
          <div className="flex-row gap-8 mobile-flex">
            <Box
              className="  tablet-new-flex-row"
              title="농지연금 분석"
              content="가입 조건부터 수익 분석까지"
              src="/asset/images/main/pension.svg"
              url="/annuity/map"
              type="1"
              tab="annuity"
            />
            <Box
              className="  tablet-new-flex-row "
              title="농지 찾기"
              content="귀농 희망자 맞춤 농지"
              src="/asset/images/main/find.svg"
              url="/findland/map"
              type="2"
              tab="findland"
            />
          </div>
          <div className="flex-row gap-8">
            <Box
              className="  tablet-flex none "
              title="농지 찾기"
              content="귀농 희망자 맞춤 농지"
              src="/asset/images/main/find.svg"
              url="/findland/map"
              type="2"
              tab="findland"
            />
            <Box
              className="tablet-new-flex-row "
              title="내 농장 진단"
              content="농부 성적표"
              src="/asset/images/main/operate.svg"
              url="/aiFarming/map"
              type="2"
              tab="aifarm"
            />
            <Box
              className="tablet-new-flex-row "
              title="매물 거래소"
              content="토지/주택 매물&경공매 정보"
              src="/asset/images/main/exchange.svg"
              url=""
              url2="https://realty.transfarmer.co.kr/"
              type="2"
              tab=""
              N
              left="67"
            />
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              gap: '8px',
            }}
          >
            <div
              className="padding-16 flex-row gap-8 bg-FFFFFF border-radius-8 flex-1 hover space-between"
              onClick={() => {
                navigate(`/hidden/policy`);
                sessionStorage.setItem('tab', 'policy');
              }}
            >
              <div className="relative">
                <div
                  className={`font-bold line-h-24 color-text-primary ${`f-size-16`}`}
                >
                  숨은 정책 지원금
                </div>
                <div
                  className={`font-medium f-size-14
        
         line-h-20 color-text-secondary`}
                >
                  농촌·농업의 지역별/사업별 정책 찾기
                </div>
              </div>
              <div className="flex-row end-x">
                <img
                  src="/asset/images/main/policy.svg"
                  style={{ height: '48px' }}
                  alt=""
                />
              </div>
            </div>

            <div
              className="padding-16 flex-row gap-8 bg-FFFFFF border-radius-8 flex-1 hover space-between"
              onClick={() => {
                navigate(`/house/map`);
                sessionStorage.setItem('tab', 'house');
              }}
            >
              <div className="relative">
                <div
                  className={`font-bold line-h-24 color-text-primary ${`f-size-16`}`}
                >
                  내 집 짓기
                </div>
                <div
                  className={`font-medium f-size-14
        
         line-h-20 color-text-secondary`}
                >
                  귀촌 희망자 맞춤 주택
                </div>
              </div>
              <div className="flex-row end-x">
                <img
                  src="/asset/images/main/house.svg"
                  style={{ height: '48px' }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex-column gap-16 browser">
          <div className="flex-row gap-16">
            <BoxBrowser
              title="가격 분석"
              content="AI가 추정하는 농지와 전원주택 가격 보기"
              src="/asset/images/main/price.svg"
              url="/price/map"
              tab="price"
            />
            <BoxBrowser
              title="토지 활용"
              content="토지의 활용 가능성 AI 진단하기"
              src="/asset/images/main/landUse.svg"
              url="/landUse/map"
              tab="landUse"
              N
              left="67"
            />
            {/* <BoxBrowser
              title="매물 분석"
              content="농지/전원주택의 매물, 경매, 공매 정보"
              src="/asset/images/main/auction.svg"
              url="/auction/map"
              tab="auction"
              N
              left="67"
            /> */}
            <BoxBrowser
              title="농지연금 분석"
              content="농지, 나이에 따라 달라지는 예상 수령액 보기"
              src="/asset/images/main/pension.svg"
              url="/annuity/map"
              tab="annuity"
            />
          </div>
          <div className="flex-row gap-16">
            <BoxBrowser
              title="농지 찾기"
              content="궁금한 농지의 작물별 예상 수익보기"
              src="/asset/images/main/find.svg"
              url="/findland/map"
              tab="findland"
            />
            <BoxBrowser
              title="내 농장 진단"
              content="내 작물 정보 입력하고 농사 성적표 확인하기"
              src="/asset/images/main/operate.svg"
              url="/aiFarming/map"
              tab="aifarm"
            />
            <BoxBrowser
              title="매물 거래소"
              content="토지/주택 매물&경공매 정보"
              src="/asset/images/main/exchange.svg"
              url=""
              tab=""
              N
              left="82"
              url2="https://realty.transfarmer.co.kr/"
            />
          </div>
          <div style={{ width: '100%', display: 'flex', gap: '16px' }}>
            <div
              className="padding-16 flex-row gap-60 bg-FFFFFF border-radius-8 flex-1 hover space-between"
              onClick={() => {
                navigate(`/hidden/policy`);
                sessionStorage.setItem('tab', 'policy');
                sessionStorage.setItem('temp', 'temp');
              }}
            >
              <div className="relative">
                <div className="font-bold f-size-18 line-h-30 color-text-primary">
                  숨은 정책 지원금
                </div>
                <div className="font-medium f-size-16 line-h-26 color-text-secondary">
                  농촌·농업의 지역별/사업별 정책 찾기
                </div>
              </div>
              <div className="flex-row end-x">
                <img
                  src="/asset/images/main/policy.svg"
                  style={{ height: '48px' }}
                  alt=""
                />
              </div>
            </div>

            <div
              className="padding-16 flex-row bg-FFFFFF border-radius-8 flex-1 hover space-between"
              onClick={() => {
                navigate(`/house/map`);
                sessionStorage.setItem('tab', 'house');
                sessionStorage.setItem('temp', 'temp');
              }}
            >
              <div className="relative">
                <div className="font-bold f-size-18 line-h-30 color-text-primary">
                  내 집 짓기
                </div>
                <div className="font-medium f-size-16 line-h-26 color-text-secondary">
                  원하는 땅에 집 설계도 선택하고 견적 확인하기
                </div>
              </div>
              <div className="flex-row end-x">
                <img
                  src="/asset/images/main/house.svg"
                  style={{ height: '48px' }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main1;
