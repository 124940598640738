import fruit1 from './../../../../asset/images/findLand/fruit1.png';
import fruit2 from './../../../../asset/images/findLand/fruit2.png';
import fruit3 from './../../../../asset/images/findLand/fruit3.png';
import search from './../../../../asset/images/notice/searchIcon.svg';
import loadingImg from './../../../../asset/images/spinner/loading.svg';
import { useState, useEffect, MouseEvent, useRef } from 'react';
import arrowDown from './../../../../asset/images/arrow/arrowDown.svg';
import arrowUp from './../../../../asset/images/arrow/arrowTopGray.svg';
import { CropInfo } from '../common';
import { Title } from './Content';
import { CropSelecter } from '../component';
interface PropsType {
  facility: string;
  crop: string;
  recommendCrop: CropInfo[];
  setCrop: any;
  anotherCrop: CropInfo[];
  setIndex: any;
  index: number;
  spinner: any;
}
const CropSelect = ({
  crop,
  setCrop,
  recommendCrop,
  facility,
  anotherCrop,
  setIndex,
  index,
  spinner,
}: PropsType) => {
  const dummy = [
    '사과',
    '방울토마토',
    '방울토마토',
    '사과',
    '사과',
    '사과',
    '사과',
    '방울토마토',
    '방울토마토',
    '방울토마토',
  ];
  const containerRef = useRef<HTMLDivElement>(null);
  const [dragging, setDragging] = useState(false);
  const [more, setMore] = useState(false);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [clickPoint, setClickPoint] = useState(0);
  const temp = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setIndex(-1);
    setCrop(null);
  }, [anotherCrop, recommendCrop]);
  const handleMouseDownEvent = (e: MouseEvent<HTMLDivElement>) => {
    // 마우스 클릭하면
    setDragging(true);
    if (containerRef.current) {
      setClickPoint(e.pageX); // clickPoint는 처음 클릭한 지점
      setScrollLeft(containerRef.current.scrollLeft); // 스크롤움직인 거리, 처음은 0
    }
  };

  const handleMouseMoveEvent = (e: MouseEvent<HTMLDivElement>) => {
    if (!dragging) return; // onMouseDownEvent에서 dragging=true가 아니면 작동하지 않음

    e.preventDefault();

    if (containerRef.current) {
      // clickPoint는 onMouseDown에서 처음 클릭한 위치가 할당된다.
      // walk = 마우스를 끌고간 최종 위치 - 처음 마우스를 클릭한 위치
      // 오른쪽에서 왼쪽으로 끌면 음수이다.
      const walk = e.pageX - clickPoint;

      // walk가 음수, 즉 오른쪽에서 왼쪽으로 끌었을때 scrollLeft - walk는 양수가 되면서
      // containerRef의 scrollLeft가 증가하면서 스크롤이 오른쪽으로 움직인다.
      containerRef.current.scrollLeft = scrollLeft - walk;
    }
  };
  const [onCropSelecter, setOnCropSelecter] = useState(false);
  return (
    <div className="flex-column w-100per report_result_box bg-white gap-8 padding-b-16">
      {onCropSelecter ? (
        <CropSelecter
          value={crop}
          onChange={(e: any, i: any) => {
            setIndex(i + recommendCrop.length);
            setCrop(e.name);
            setOnCropSelecter(false);
          }}
          off={() => setOnCropSelecter(false)}
          cropList={anotherCrop || []}
        />
      ) : (
        ''
      )}
      <Title text="2. 작물" />
      {facility === '' ? (
        <div className="flex-column w-100per gap-10  padding-8-16-16-16">
          <div className="font-bold f-size-14 line-h-20 color-text-primary">
            AI 추천 작물
          </div>
          <div className="flex-column flex-center w-100per h-120 bg-blue-gray-100 border-none border-radius-8">
            <div className="font-bold f-size-14 line-h-20 color-text-secondary text-center">
              재배 환경을 입력하면 <br />
              AI가 토양 및 기후 데이터를 분석하여
              <br />
              적합 작물을 추천해드려요
            </div>
          </div>
        </div>
      ) : (
        <div className="flex-column gap-20">
          <div className="flex-column">
            {spinner ? (
              <div className="padding-x-16">
                <div
                  className="flex-column flex-center w-100per h-120 bg-blue-gray-100 border-dotted-171E2610 border-radius-8 "
                  style={{ borderWidth: '5px' }}
                >
                  <div className="flex-row gap-10 spinner-container">
                    <img src={loadingImg} className="spinner-dot" />
                    <img src={loadingImg} className="spinner-dot" />
                    <img src={loadingImg} className="spinner-dot" />
                    <img src={loadingImg} className="spinner-dot" />
                  </div>
                  <div className="font-bold f-size-14 line-h-20 color-text-secondary  spinner-container">
                    토양/기후 등에 적합한 작물을 찾고 있어요
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex-column gap-8 padding-x-16">
                <div className="font-bold f-size-14 line-h-20 color-text-primary">
                  AI 추천 작물
                </div>
                <div
                  className="of-x-auto flex-row gap-10 "
                  ref={containerRef}
                  onMouseDown={handleMouseDownEvent}
                  onMouseLeave={() => setDragging(false)}
                  onMouseUp={() => setDragging(false)}
                  onMouseMove={handleMouseMoveEvent}
                >
                  {recommendCrop.length > 0 ? (
                    recommendCrop.map((item: any, i: number) => {
                      return (
                        // <div
                        //   className={`flex-column center-y padding-5-10-10-10 border-radius-6 w-90 gap-10 pointer ${
                        //     crop === item.name
                        //       ? `border-1E9B6B`
                        //       : `border-171E26`
                        //   }`}
                        //   onClick={() => {
                        //     setIndex(i);
                        //     setCrop(item.name);
                        //   }}
                        // >
                        //   <img
                        //     src={`https://back.transfarmer.kr/img/${item.img}`}
                        //     style={{ width: "70px" }}
                        //   />
                        //   <div className="font-medium f-size-14 line-h-20 color-text-secondary ">
                        //     {item.name}
                        //   </div>
                        // </div>
                        <div
                          className="flex-column gap-8 center-y hover center-y"
                          onClick={() => {
                            setIndex(i);
                            setCrop(item.name);
                          }}
                          style={{ width: '100px' }}
                        >
                          <div
                            className="relative"
                            style={{
                              width: '80px',
                              height: '80px',
                            }}
                          >
                            <div
                              className=" border-radius-100per of-hidden center-x center-y"
                              style={{
                                width: '80px',
                                height: '80px',
                                border:
                                  crop === item.name
                                    ? '2px solid #1E9B6B'
                                    : '1px solid #171e2610',
                              }}
                            >
                              <img
                                src={`https://back.transfarmer.kr/img/${item.img}`}
                                alt=""
                                style={{ width: '70px', height: '70px' }}
                              />
                            </div>
                            {crop === item.name ? (
                              <img
                                src="/asset/images/check/Check24GreenCircle.svg"
                                alt=""
                                style={{
                                  position: 'absolute',
                                  left: 0,
                                  bottom: 0,
                                }}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                          <div
                            className="font-medium f-size-14 line-h-20 text-center"
                            style={{
                              color: crop === item.name ? '#1e9b6b' : '#000000',
                              wordBreak: 'keep-all',
                            }}
                          >
                            {item.name}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="flex-center padding-24-16 bg-blue-gray-100 border-radius-8">
                      <div className="font-medium f-size-14 line-h-20 color-text-tertiary text-center">
                        해당 재배 환경에 적합한 추천 작물이 없습니다.
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {!spinner && facility !== '스마트팜' && (
            <div className="flex-column padding-16 gap-16">
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                추천 외 작물 선택
              </div>
              {(!crop || index < recommendCrop.length) && (
                <div
                  className="gap-8 center-y flex-column w-100per bg-primary-100 border-radius-8 padding-y-24 hover"
                  onClick={() => setOnCropSelecter(true)}
                >
                  <img src="/asset/images/plus/plus38.svg" alt="" />
                  <div className="font-bold f-size-14 line-h-20 color-primary-600">
                    원하는 작물을 선택해보세요
                  </div>
                </div>
              )}
              {crop &&
                index - recommendCrop.length >= 0 &&
                anotherCrop[index - recommendCrop.length] &&
                (anotherCrop[index - recommendCrop.length] as any).img && (
                  <div className="flex-row start-y">
                    <div
                      className="flex-column gap-8 center-y hover center-y"
                      style={{ width: '100px' }}
                    >
                      <div
                        className="relative"
                        style={{
                          width: '80px',
                          height: '80px',
                        }}
                      >
                        <div
                          className=" border-radius-100per of-hidden center-x center-y"
                          style={{
                            width: '80px',
                            height: '80px',
                            border: '2px solid #1E9B6B',
                          }}
                        >
                          <img
                            src={`https://back.transfarmer.kr/img/${
                              (anotherCrop[index - recommendCrop.length] as any)
                                .img
                            }`}
                            alt=""
                            style={{ width: '70px', height: '70px' }}
                          />
                        </div>

                        <img
                          src="/asset/images/check/Check24GreenCircle.svg"
                          alt=""
                          style={{
                            position: 'absolute',
                            left: 0,
                            bottom: 0,
                          }}
                        />
                      </div>
                      <div
                        className="font-medium f-size-14 line-h-20 text-center"
                        style={{
                          color: '#1e9b6b',
                          wordBreak: 'keep-all',
                        }}
                      >
                        {crop}
                      </div>
                    </div>
                    <img
                      src="/asset/images/close/Close28Ring.svg"
                      alt=""
                      className="hover"
                      onClick={() => {
                        setIndex(0);
                        setCrop(null);
                      }}
                    />
                  </div>
                )}
              {/* <div
                ref={temp}
                className={`flex-row gap-8 flex-wrap max-h-90 overflow-hidden ${
                  more ? `max-h-524` : ``
                }`}
              >
                {(anotherCrop || []).length > 0 &&
                  anotherCrop.map((item: any, i: number) => {
                    let tempIndex = i + recommendCrop.length;
                    return (
                      <div
                        className={`flex-column border-166F4D-2px center-y padding-5-10-10-10 border-radius-6 gap-10 no-wrap flex-center pointer ${
                          crop === item.name ? `bg-primary-600` : `bg-white`
                        }`}
                        onClick={() => {
                          setIndex(i + recommendCrop.length);
                          setCrop(item.name);
                        }}
                      >
                        <div
                          className={`font-medium f-size-14 line-h-20 ${
                            crop === item.name
                              ? `color-white`
                              : `color-primary-600`
                          }`}
                        >
                          {item.name}
                        </div>
                      </div>
                    );
                  })}
              </div> */}

              {/* <div className="w-100per flex-center">
                <div
                  className="bg-light-gray-200 border-radius-100 flex-center w-24 h-24 pointer"
                  onClick={() => {
                    setMore(!more);
                  }}
                >
                  <img src={more ? arrowUp : arrowDown} />
                </div>
              </div> */}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CropSelect;
