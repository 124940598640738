import { useLocation, useParams } from 'react-router-dom';
import { TopNav2 } from '../../component/mobile/Topnav';
import ReportInfo from './components/Info';
import ReportDetail5 from './components/detail/ReportDetail5';
import ReportDetail6 from './components/detail/ReportDetail6';
import ReportDetail7 from './components/detail/ReportDetail7';

import ReportDetail9 from './components/detail/ReportDetail9';
import ReportDetail10 from './components/detail/ReportDetail10';
import Summary1 from './components/summary/Summary1';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { isMobile } from '../../component/function/function';
import PriceService from '../../service/price/priceApi';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingDot } from '../../store/loadingSlice';
import { SaveBtn } from '../../component/common/saveBtn';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Temp from './components/Temp';

import ReportDetail8 from './components/detail/ReportDetail8';
import ReportService from '../../service/report/reportApi';
import { SaveReportPopUp } from '../../component/common/saveReportPopUp';
import { SaveReportPopUp2 } from '../../component/common/saveReportPopUp2';
import { set } from 'lodash';
const PriceReportMain = ({
  reportIdx,
  setClose,
  info,
  setKmTab,
  kmTab,
  setDrawCircle,
  address,
  address2, //mypage 보고서에서 쓸 address
  mypage,
  des,
  setCloseSearchPointDetail,
  setOnPointHistory,
  search,
}: any) => {
  const [on, setOn] = useState(false);
  const viewerRef = useRef(null);
  const [loading, setLoading] = useState({
    origin: false,
    price: false,
  });
  const [savePopUp, setSavePopUp] = useState(false); //보고서 저장 완료 팝업
  const [savePopUp2, setSavePopUp2] = useState(false); //보고서 저장 안하고 나갈 때 팝업

  const [html, setHtml] = useState('');
  const [desc, setDesc] = useState();
  const [realDeal, setRealDeal] = useState([
    {
      address: '',
      distance: 0,
      landtradedate: '',
      landtradeprice: '',
      pnucode: '',
      pyeongprice: 0,
    },
  ]);

  const [auction, setAuction] = useState([
    {
      address: '',
      distance: 0,
      auctiondate: '',
      auctionprice: '',
      pnucode: '',
      pyeongprice: 0,
    },
  ]);
  const counter = useRef(0);
  const [reportStorage, setReportStorage] = useState(new Map());
  const state = useSelector((state: any) => {
    return state.type;
  });
  console.log('address', address);
  const priceReport = async () => {
    const data = {
      type:
        state.priceType === 'land'
          ? 'L'
          : state.priceType === 'house'
            ? 'H'
            : '',
      address: isMobile(window.innerWidth)
        ? locationHook.state.address
        : address,
    };
    try {
      dispatch(LoadingDot('S'));
      const result = await ReportService.priceReport(data);
      dispatch(LoadingDot('N'));
      setSavePopUp(true);
    } catch (error) {
      console.log(error);
      dispatch(LoadingDot('N'));
    }
  };

  const [pAuction, setPAuction] = useState([
    {
      address: '',
      distance: 0,
      pauctiondate: '',
      pauctionprice: '',
      pnucode: '',
      pyeongprice: 0,
    },
  ]);
  const [zMoelAuction, setZMoelAuction] = useState([
    {
      address: '',
      distance: 0,
      auctiondate: '',
      auctionprice: '',
      pnucode: '',
    },
  ]);
  const [sales, setSales] = useState([
    {
      address: '',
      area: 0,
      distance: 0,
      pnuCode: '',
      price: 0,
      regTime: '',

      pyeongprice: 0,
    },
  ]);

  const [zModelData, setZmodelData] = useState({
    auction: {},
    estimatedPrice: {
      distanceOver1YearOver1: 0,
      distanceOver1YearUnder1: 0,
      distanceUnder1YearOver1: 0,
      distanceYearUnder1: 134595.36860815302,
    },
  });
  const [zModelGraph, setZmodelGraph] = useState([
    {
      type: '',
      address: '',
      distance: 0,
      landtradedate: '',
      landtradeprice: '',
      pnucode: '',
      pyeongprice: 0,
      auctiondate: '',
      regTime: '',
      pauctiondate: '',
      price: '',
      pauctionprice: '',
      auctionprice: '',
    },
  ]);
  const [estimatedPrice, setEstimatedPrice] = useState({
    list_1000: [{ avgPrice: 0 }],

    list_2000: [{ avgPrice: 0 }],

    list_3000: [{ avgPrice: 0 }],
  });
  const [lastPyeongRegion, setLastPyeongRegion] = useState<any>('');
  const [pyeongRegionlist, setPyeongRegionlist] = useState<any>({});

  const [originData, setOriginData] = useState({
    category: '',
    origin: '',
    createdTime: '',
  });
  const [totalData, setTotalData] = useState({
    area: 0,
    address: '',
    perMeterEstimatedPrice: 0,
    perPyeongEstimatedPrice: 0,
    estimatedPrice: 572786688,
    category: '답',
    landTradePrice: 461700000,
    landTradeDate: '20210421',
    officialPriceList: {
      '2014': 141000,
      '2015': 145300,
      '2016': 147200,
      '2017': 152000,
      '2018': 140500,
      '2019': 146500,
      '2020': 152500,
      '2021': 160700,
      '2022': 182700,
      '2023': 168400,
    },
    difference: 2.0055106888361047,
    surroundings: [
      {
        address: '경기도 안성시 가사동 60-5',
        landTradePrice: 899934,
        landTradeDate: '20210421',
        distance: 1.2919436717325758,
      },
      {
        address: '경기도 안성시 가사동 60-6',
        landTradePrice: 847961,
        landTradeDate: '20180604',
        distance: 25.54371889347502,
      },
      {
        address: '경기도 안성시 가사동 57-4',
        landTradePrice: 931032,
        landTradeDate: '20210320',
        distance: 108.7136306926981,
      },
    ],
    surroundingsAvgPrice: 1198272.4150725163,
    CAGR: 0.019927306710443293,
  });
  const [totalData2, setTotalData2] = useState({
    area: 0,
    address: '',
    perMeterEstimatedPrice: 337728,
    perPyeongEstimatedPrice: 1116461.2224,
    estimatedPrice: 572786688,
    category: '답',
    houseTradePrice: 461700000,
    houseTradeDate: '20210421',
    officialPriceList: {
      '2014': 141000,
      '2015': 145300,
      '2016': 147200,
      '2017': 152000,
      '2018': 140500,
      '2019': 146500,
      '2020': 152500,
      '2021': 160700,
      '2022': 182700,
      '2023': 168400,
    },
    difference: 2.0055106888361047,
    surroundings: [
      {
        address: '경기도 안성시 가사동 60-5',
        houseTradePrice: 899934,
        houseTradeDate: '20210421',
        distance: 1.2919436717325758,
      },
      {
        address: '경기도 안성시 가사동 60-6',
        houseTradePrice: 847961,
        houseTradeDate: '20180604',
        distance: 25.54371889347502,
      },
      {
        address: '경기도 안성시 가사동 57-4',
        houseTradePrice: 931032,
        houseTradeDate: '20210320',
        distance: 108.7136306926981,
      },
    ],
    surroundingsAvgPrice: 1198272.4150725163,
    CAGR: 0.019927306710443293,
  });
  const [auctionData, setAuctionData] = useState({
    address: '',
    auctionList: [],
    alast1: { radius1: '', radius2: '', radius3: '', avg: '' },
    alast3: { radius1: '', radius2: '', radius3: '', avg: '' },
    alast6: { radius1: '', radius2: '', radius3: '', avg: '' },
    alast12: { radius1: '', radius2: '', radius3: '', avg: '' },
  });
  const [landData, setLandData] = useState({
    list: [],
    last1: { radius1: '', radius2: '', radius3: '', avg: '' },
    last3: { radius1: '', radius2: '', radius3: '', avg: '' },
    last6: { radius1: '', radius2: '', radius3: '', avg: '' },
    last12: { radius1: '', radius2: '', radius3: '', avg: '' },
  });
  const [landLineChartData, setLandLineChartData] = useState<any>([]);
  const [auctionLineChartData, setAuctionLineChartData] = useState<any>([]);

  const locationHook = useLocation();

  const [origin, setOrigin] = useState();
  const dispatch = useDispatch();

  let param: any;
  const param2 = useParams();

  if (isMobile(window.innerWidth)) {
    param = { reportIdx: param2.address };
  } else {
    param = { reportIdx: reportIdx };
  }

  const state2 = useSelector((state2: any) => {
    return state2.type;
  });

  const getData = async () => {
    try {
      dispatch(LoadingDot('W'));
      const result = await PriceService.getData(
        isMobile(window.innerWidth) ? locationHook.state.reportIdx : reportIdx,
      );
      setDesc(result.result.report_desc);
      setOriginData(result.result.origin);
      setLastPyeongRegion(result.result.pyeongRegion.last);
      setPyeongRegionlist(result.result.pyeongRegion.list);

      setRealDeal(result.result.surrounding.realDealPrice);
      setAuction(result.result.surrounding.auction);
      setPAuction(result.result.surrounding.pauction);
      setSales(result.result.surrounding.sales);
      setZmodelData(result.result.surrounding.zModelData);
      setZmodelGraph(result.result.surrounding.zModelData.graph);
      setEstimatedPrice(result.result.surrounding.estimatedPrice);
      setAuctionData(result.result.trend.auctionData);
      setLandData(result.result.trend.landData);
      setLandLineChartData(result.result.trend.landData.list.reverse());
      setAuctionLineChartData(
        result.result.trend.auctionData.auctionList.reverse(),
      );
      dispatch(LoadingDot('N'));
    } catch (error) {
      dispatch(LoadingDot('N'));
      console.log(error);
    }
  };

  // async function loadData() {
  //   // Use the Fetch Api to pull the data https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
  //   const headers = new Headers();

  //   const dataRequest = new Request(
  //     "https://demodata.mescius.io/northwind/api/v1/Customers",
  //     {
  //       headers: headers,
  //     }
  //   );

  //   const response = await fetch(dataRequest);
  //   const data = await response.json();
  //   return data;
  // }
  // async function loadReport() {
  //   // load report definition from the file
  //   const reportResponse = await fetch(
  //     "/activereportsjs/demos/resource/reports/CustomersTable.rdlx-json"
  //   );
  //   const report = await reportResponse.json();
  //   return report;
  // }
  // useEffect(() => {
  //   async function openReport() {
  //     const data = await loadData();
  //     const report = await loadReport();
  //     report.DataSources[0].ConnectionProperties.ConnectString =
  //       "jsondata=" + JSON.stringify(data);
  //     if (viewerRef.current) {
  //       viewerRef.current.Viewer.open(report);
  //     }
  //   }
  //   openReport();
  // }, []);
  // useEffect(() => {
  //   getData();
  //   if (mypage) {
  //     getPrice(address2);
  //   }
  // }, []);
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (loading.origin || loading.price) {
      dispatch(LoadingDot('W'));
    } else {
      dispatch(LoadingDot('N'));
    }
  }, [loading]);
  const divRef = useRef<HTMLDivElement>(null);
  const divRef2 = useRef<HTMLDivElement>(null);
  const divRef3 = useRef<HTMLDivElement>(null);
  const divRef4 = useRef<HTMLDivElement>(null);
  const divRef5 = useRef<HTMLDivElement>(null);
  const divRef6 = useRef<HTMLDivElement>(null);
  const divRef7 = useRef<HTMLDivElement>(null);

  const convertElementToImage = async (element: any) => {
    if (element) {
      let canvas = await html2canvas(element, { scale: 2, useCORS: true });
      return canvas.toDataURL('image/jpeg');
    }
    return null;
  };

  const convertHTMLToImages = async () => {
    const elements = [
      divRef.current,
      divRef2.current,
      divRef3.current,
      divRef4.current,
    ];
    const imageDataArray = await Promise.all(
      elements.map(async (element) => {
        return await convertElementToImage(element);
      }),
    );
    return imageDataArray.filter((imageData) => imageData !== null);
  };

  const handleButtonClick = async () => {
    let imgDataArray = await convertHTMLToImages();

    if (imgDataArray.length > 0) {
      let doc = new jsPDF();
      imgDataArray.forEach((imgData: any, index) => {
        // doc.addImage(imgData, "PNG", 0, 0, 210, 297);
        doc.addImage(imgData, 'JPEG', 0, 0, 210, 297);
        if (index < imgDataArray.length - 1) {
          doc.addPage();
        }
      });
      doc.save('a4.pdf');
    }
  };
  // useEffect(() => {
  //   const handleBeforeUnload = (event: any) => {
  //     event.preventDefault(); // 이벤트 기본 동작 막기
  //     event.returnValue = ''; // Chrome에서 필요한 작업
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     console.log('awefawef');
  //     setSavePopUp2(true); // 팝업 열기
  //     alert('변경사항이 저장되지 않습니다.');
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  return (
    <div
      className={`w-100per  browser-h-100per browser-bg-FFFFFF ${
        isMobile(window.innerWidth) && `of-scroll`
      }`}
      style={
        isMobile(window.innerWidth)
          ? { height: window.innerHeight, zIndex: 100 }
          : { overflow: 'hidden', overflowY: 'auto' }
      }
    >
      {savePopUp && <SaveReportPopUp setSavePopUp={setSavePopUp} />}
      {/* {savePopUp2 && (
        <SaveReportPopUp2
          setSavePopUp2={setSavePopUp2}
          closeParentComponent={() => {
            // 부모 컴포넌트 닫기
            setClose(true);
            // 추가적으로 부모 컴포넌트의 다른 동작을 수행할 수 있습니다.
            // 예: 부모 컴포넌트를 완전히 언마운트하는 로직 등
          }}
        />
      )} */}
      <div style={{ overflowY: 'hidden' }} ref={viewerRef}>
        <div
          className=""
          style={{
            height: isMobile(window.innerWidth)
              ? window.innerHeight - 48
              : '100%',
            overflowX: 'hidden',
          }}
        >
          <div ref={divRef} style={{ height: '' }}>
            {(locationHook.pathname.slice(-1) === '/'
              ? locationHook.pathname.slice(0, -1)
              : locationHook.pathname) === '/mypage/point' ? (
              <TopNav2
                browser
                text="가격 분석"
                closeBack
                close
                setClose={setClose}
                report
                mypage
              />
            ) : (locationHook.pathname.slice(-1) === '/'
                ? locationHook.pathname.slice(0, -1)
                : locationHook.pathname) === '/mypage/map' ? (
              ''
            ) : (
              <TopNav2
                search
                browser
                text="가격 분석"
                closeBack
                close
                setClose={setClose}
              />
            )}
            {search && (
              <div className="flex-row space-between padding-12-16 center-y">
                <img
                  src="/asset/images/arrow/arrowBack.svg"
                  alt="arrow"
                  className="pointer"
                  onClick={() => {
                    setCloseSearchPointDetail(false);
                  }}
                />
                <div className="m-b1-16-b color-dark-primary">가격 분석</div>
                <img
                  src="/asset/images/close/CloseBlack24.svg"
                  className="pointer"
                  alt="close"
                  onClick={() => {
                    setCloseSearchPointDetail(false);
                    setOnPointHistory(false);
                  }}
                />
              </div>
            )}
            {desc && <ReportInfo data={desc} mypage={mypage} des={des} />}

            <div style={{ height: '16px', background: '#F7F8F9' }} />

            {desc && <Summary1 mypage={mypage} des={des} data={desc} />}

            <div style={{ height: '16px', background: '#F7F8F9' }} />

            {desc && <ReportDetail5 data={desc} mypage={mypage} />}
          </div>
          <div style={{ height: '16px', background: '#F7F8F9' }} />
          <div>
            <div>
              <ReportDetail6
                des={des}
                divRef={divRef2}
                mypage={mypage && true}
                realDeal={realDeal}
                auction={auction}
                pAuction={pAuction}
                zMoelAuction={zMoelAuction}
                zModelData={zModelData}
                zModelGraph={zModelGraph}
                estimatedPrice={estimatedPrice}
                sales={sales}
                setKmTab={setKmTab}
                kmTab={kmTab}
                setDrawCircle={setDrawCircle}
                address={
                  isMobile(window.innerWidth)
                    ? locationHook.state.address
                    : address
                }
              />
            </div>
            <div style={{ height: '16px', background: '#F7F8F9' }} />
            <div ref={divRef3}>
              <ReportDetail7
                auctionLineChartData={auctionLineChartData}
                landLineChartData={landLineChartData}
                landData={landData}
                auctionData={auctionData}
                mypage={mypage && true}
                address={
                  isMobile(window.innerWidth)
                    ? locationHook.state.address
                    : decodeURI(locationHook.search.split('=')[1])
                }
              />
            </div>
            <div style={{ height: '16px', background: '#F7F8F9' }} />
            {desc && (
              <div ref={divRef4}>
                <ReportDetail8
                  mypage={mypage}
                  pyeongRegionlist={pyeongRegionlist}
                  lastPyeongRegion={lastPyeongRegion}
                  data={desc}
                  type={state2.priceType === 'land' ? 'L' : 'H'}
                  des={mypage && des}
                  address={
                    mypage
                      ? address2
                      : isMobile(window.innerWidth)
                        ? locationHook.state.address
                        : decodeURI(locationHook.search.split('=')[1])
                  }
                />
              </div>
            )}
            {!mypage && (
              <div style={{ height: '16px', background: '#F7F8F9' }} />
            )}
          </div>

          {/* <ReportDetail9
            data={state2.priceType === 'land' ? totalData : totalData2}
          />
          <div style={{ height: '16px', background: '#F7F8F9' }} /> */}

          {!mypage && <ReportDetail10 originData={originData} />}

          {!mypage && (
            <div className="w-100per flex-center padding-b-32">
              <SaveBtn
                onClick={() => {
                  priceReport();
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PriceReportMain;
