import { useEffect, useRef, useState } from 'react';
import { TopNav2, TopNavNewBrowser } from '../../component/mobile/Topnav';
import { isMobile, transNum } from '../../component/function/function';
import Banner from '../../component/AIIntroduction/Footer';
import {
  FilterModal,
  FilterModal2,
  FilterModal3,
} from './components/FilterModal';
import PolicyService from '../../service/policy/Policy';
import FooterBrowser from '../../component/browser/Footer';
import TopBanner from '../../component/banner/TopBanner';
import useDidMountEffect from '../../hooks/UseDidMountEffect';
import { Helmet } from 'react-helmet';
import { clickGoToDetail } from '../../hackler';
import { useLocation } from 'react-router-dom';

const HiddenPolicy = ({ reportIdx, setClose }: any) => {
  const [tab, setTab] = useState(1);
  const [temp, setTemp] = useState(false);
  const [filter, setFilter]: any = useState([]);
  const [region, setRegion]: any = useState([]);
  const [onModal, setOnModal] = useState(false);
  const [onModal2, setOnModal2] = useState(false);
  const [onModal3, setOnModal3] = useState(false);
  const [subsidyIdx, setSubsidyIdx] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageList, setPageList] = useState([1, '...']);
  const [openSort, setOpenSort] = useState(false);
  const [openSort1, setOpenSort1] = useState(false);
  const [openSort3, setOpenSort3] = useState(false);
  const [openSort4, setOpenSort4] = useState(false);
  const [sort, setSort] = useState('마감일 가까운순');
  const [right, setRight] = useState(true);
  const [spread1, setSpread1] = useState(true);
  const [spread2, setSpread2] = useState(true);
  const [year, setYear] = useState('2025');

  const containerRef = useRef<any>(null);

  const [search, setSearch] = useState('');
  const [type, setType] = useState('');
  const [bCategory, setBCategory] = useState('');
  const [eCategory, setECategory] = useState('');
  const [termType, setTermType] = useState('진행중+시행예정');
  const [tooltip1, setTooltip1] = useState(true);

  const [allCount, setAllCount] = useState({
    businessCount: '4940',
    educationCount: '3441',
    totalCount: '8381',
  });
  const [totalCount, setTotalCount] = useState(0);
  const [itemList, setItemList] = useState([
    {
      agency: '',
      amount: '',
      category: '',
      expireIn: 0,
      likeCount: 0,
      sido: '',
      subsidyIdx: 8294,
      title: '',
      type: '',
      view: 0,
    },
  ]);

  const [detail, setDetail] = useState({
    agency: '',
    amount: '',
    category: '',
    description: '',
    endDate: '',
    startDate: '',
    subsidyIdx: 0,
    target: '',
    title: '',
  });

  const sortObj = {
    '최근 등록일순': 'SD',
    '마감일 가까운순': 'EA',
    '지원금액 높은순': 'AD',
    '조회수 많은순': 'VD',
    '좋아요 많은순': 'LD',
  };

  const termObj = {
    진행중: 'C',
    시행예정: 'N',
    '진행중+시행예정': 'M',
    마감: 'P',
    전체: '',
  };

  //   type
  // B: 사업, E: 교육 없으면 전체

  // bCategory
  // 사업 필터(,구분) 없으면 전체

  // eCategory
  // 교육 필터(,구분) 없으면 전체

  // termType
  // P: 마감, C: 진행중, N: 시행전 없으면 전체

  // region
  // 지역 필터(,구분) 없으면 전체 (현재 지역이 없어서 '' 보내야함)

  // sort *
  // VD: 조회수DESC,VA: 조회수ASC,SD: 시작일DESC,SA: 시작일ASC,ED: 마감일DESC,EA: 마감일ASC,LD: 좋아요DESC,LA: 좋아요ASC

  // limit
  // offset

  const generatePagination = () => {
    const maxVisiblePages = 5; // 표시할 최대 페이지 수
    const pagination = [];

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pagination.push(i);
      }
    } else {
      if (currentPage <= Math.ceil(maxVisiblePages / 2) + 1) {
        for (let i = 1; i <= maxVisiblePages; i++) {
          pagination.push(i);
        }
        pagination.push('...');
        pagination.push(totalPages);
      } else if (
        currentPage >=
        totalPages - Math.floor(maxVisiblePages / 2) - 1
      ) {
        pagination.push(1);
        pagination.push('...');
        for (let i = totalPages - (maxVisiblePages - 1); i <= totalPages; i++) {
          pagination.push(i);
        }
      } else {
        pagination.push(1);
        pagination.push('...');
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pagination.push(i);
        }
        pagination.push('...');
        pagination.push(totalPages);
      }
    }

    setPageList(pagination);
  };

  const getList = async () => {
    try {
      const res = await PolicyService.list(
        tab === 1 ? '' : tab === 2 ? 'E' : 'B',
        bCategory,
        eCategory,
        termObj[termType as '마감'],
        region,
        // "",
        search,
        sortObj[sort as '마감일 가까운순'],
        limit,
        limit * (currentPage - 1),
        year,
      );
      setItemList(res.list);
      setAllCount(res.countList[0]);
      if (tab === 1) {
        setTotalCount(Number(res.countList[0].totalCount) || 0);
        setTotalPages(
          Math.ceil((Number(res.countList[0].totalCount) || 0) / limit),
        );
      } else if (tab === 2) {
        setTotalCount(Number(res.countList[0].educationCount) || 0);
        setTotalPages(
          Math.ceil((Number(res.countList[0].educationCount) || 0) / limit),
        );
      } else {
        setTotalCount(Number(res.countList[0].businessCount) || 0);
        setTotalPages(
          Math.ceil((Number(res.countList[0].businessCount) || 0) / limit),
        );
      }
      window.scrollTo(0, 0);
    } catch (err) {
      console.log(err);
    }
  };

  const getDetail = async () => {
    try {
      const res = await PolicyService.detail(subsidyIdx);
      setDetail(res);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getList();
    setFilter(
      [...eCategory.split(','), ...bCategory.split(',')].filter(
        (el) => el !== '',
      ),
    );
  }, [currentPage, eCategory, bCategory, termType, region, sort, limit, temp]);

  useEffect(() => {
    if (currentPage === 1) {
      getList();
      setFilter(
        [...eCategory.split(','), ...bCategory.split(',')].filter(
          (el) => el !== '',
        ),
      );
    } else {
      setCurrentPage(1);
    }
  }, [tab, eCategory, bCategory, termType, region, sort, limit, temp]);

  useEffect(() => {
    generatePagination();
  }, [currentPage, totalPages]);

  useEffect(() => {
    getDetail();
  }, [subsidyIdx]);

  const scroll = () => {
    if (containerRef.current) {
      containerRef.current.style.scrollBehavior = 'smooth';
      if (right) {
        containerRef.current.scrollLeft += window.innerWidth;
        if (
          containerRef.current.scrollLeft + window.innerWidth <=
          containerRef.current.scrollWidth - containerRef.current.clientWidth
        ) {
          setRight(true);
        } else {
          setRight(false);
        }
      } else {
        containerRef.current.scrollLeft = 0;
        setRight(true);
      }
    }
  };

  useDidMountEffect(() => {
    if (year === '2025' && termType !== '진행중+시행예정') {
      setTermType('진행중+시행예정');
    } else if (termType !== '마감') {
      setTermType('마감');
    } else {
      getList();
    }
  }, [year]);

  return (
    <div className="flex-column center-y">
      <TopBanner />
      <Helmet>
        <title>트랜스파머 - 숨은 정책 지원금</title>
      </Helmet>
      <TopNavNewBrowser />
      <TopNav2 text="숨은 정책 지원금" hamburger closeBack />
      <FilterModal
        open={onModal}
        setOpen={setOnModal}
        tab={tab}
        bCategory={bCategory}
        setBCategory={setBCategory}
        eCategory={eCategory}
        setECategory={setECategory}
        termType={termType}
        setTermType={setTermType}
        totalCount={totalCount}
      />
      <FilterModal2 open={onModal2} setOpen={setOnModal2} detail={detail} />
      <FilterModal3
        open={onModal3}
        setOpen={setOnModal3}
        year={year}
        setYear={setYear}
      />
      <div
        className="padding-y-4 flex-row gap-8 flex-row center-y w-100per center-x hover tablet"
        style={{ borderBottom: '1px solid #171e2610' }}
        onClick={() => setOnModal3(true)}
      >
        <div
          className="font-bold f-size-18 line-h-26"
          style={{ color: '#171e2640' }}
        >
          {year}
        </div>
        <div
          className="flex-row center-x center-y"
          style={{
            padding: '3px',
            borderRadius: '999px',
            background: '#171e2640',
            width: '18px',
            height: '18px',
          }}
        >
          <img src="/asset/images/arrow/ArrowBottom12White.svg" alt="" />
        </div>
      </div>
      <div className="flex-column padding-32-16 tablet-padding-y-32 gap-40 border-b-1-171E2610 browser-max-w-1200  tablet-max-w-904 tablet-max-w-688 w-100per">
        <div className="flex-row gap-16 end-y browser">
          <div
            className="font-bold f-size-32 line-h-46 color-text-primary "
            style={{ marginTop: '48px' }}
          >
            숨은 정책 지원금
          </div>
          <div
            className="relative none tablet-flex"
            style={{ height: 'fit-content' }}
            onBlur={() => setOpenSort4(false)}
            tabIndex={0}
          >
            <div
              className="flex-row padding-8-16 border-1-171E2610 border-radius-8 space-between center-y hover relative"
              style={{ width: '128px' }}
              onClick={() => {
                setOpenSort4((prev) => !prev);
              }}
            >
              <div className="font-medium f-size-14 line-h-20 color-text-primary">
                {year}
              </div>
              <img
                src="/asset/images/arrow/ArrowBottom16.svg"
                style={{ width: '16px', height: '16px' }}
                alt=""
              />
            </div>
            {openSort4 ? (
              <div
                className="flex-column border-1-171E2610 border-radius-8 absolute bg-FFFFFF"
                style={{ width: '128px', right: 0, top: 45, zIndex: 20 }}
              >
                {['2025', '2024', '2023', '2022', '2021', '2020'].map((el) => (
                  <div
                    className="flex-row padding-8-16 center-y hidden_policy_dropdown_item hover "
                    style={{ width: '100%' }}
                    onClick={() => {
                      setYear(el);
                      setOpenSort4(false);
                    }}
                  >
                    <div
                      className={
                        'font-medium f-size-14 line-h-20 ' +
                        (el === year ? 'color-primary-400' : '')
                      }
                    >
                      {el}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="flex-column gap-16">
          <div className="flex-row space-between center-y">
            <div className="flex-row gap-10 w-768-gap-16 w-1024-gap-32">
              {[
                {
                  title: '전체',
                  amount: Number(allCount.totalCount).toLocaleString(),
                  value: 1,
                },
                {
                  title: '교육',
                  amount: Number(allCount.educationCount).toLocaleString(),
                  value: 2,
                },
                {
                  title: '사업',
                  amount: Number(allCount.businessCount).toLocaleString(),
                  value: 3,
                },
              ].map((el, idx) => (
                <div
                  key={idx}
                  className="font-bold f-size-18 line-h-26 hover text-center"
                  onClick={() => setTab(el.value)}
                  style={{ color: el.value === tab ? '#171E26' : '#171E2666' }}
                >
                  {el.title} {el.amount}
                </div>
              ))}
            </div>
            <div
              className={
                'tablet-wide-none relative border-radius-100 hover flex-row gap-5 tablet-padding-6-16 ' +
                (filter.length === 0 ? 'padding-6 ' : 'padding-6-16')
              }
              onClick={() => setOnModal(true)}
              style={{
                border: '1px solid #000000',
              }}
            >
              {tooltip1 ? (
                <div
                  className="padding-8 border-radius-8 absolute ballon55 hover"
                  style={{ background: '#3d5066', right: 0, top: -33 }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setTooltip1(false);
                  }}
                >
                  <div className="font-bold f-size-12 line-h-14 color-FFFFFF nowrap">
                    원하는 정책 지원 종류를 선택해 볼 수 있어요!
                  </div>
                </div>
              ) : (
                ''
              )}
              <img
                src="/asset/images/filter/Filter2.svg"
                style={{ width: '24px', height: '24px' }}
                alt=""
              />
              {filter.length ? (
                <div className="font-medium f-size-16 line-h-24 no-wrap color-text-primary">
                  {filter.length}
                </div>
              ) : (
                <div className="font-medium f-size-16 line-h-24 no-wrap color-text-primary none tablet-flex">
                  필터
                </div>
              )}
            </div>
          </div>
          <div
            className="hidden-policy-filter-tab-box"
            style={{ position: 'relative' }}
          >
            <div
              className="hidden-policy-filter-tab"
              ref={containerRef}
              style={{
                display: 'flex',
                flexDirection: 'row',
                overflowX: 'auto',
                padding: '8px 0',
              }}
            >
              <div
                className="flex-row gap-8"
                style={{ paddingRight: '50px', width: 'fit-content' }}
              >
                <div
                  className="padding-6-16 border-radius-100 hover"
                  style={{
                    border: '1px solid #F0F0F0',
                    background: region.length === 0 ? '#171e26' : '',
                  }}
                  onClick={() => setRegion([])}
                >
                  <div
                    className={
                      'font-medium f-size-16 line-h-24 color-FFFFFF no-wrap ' +
                      (region.length === 0
                        ? 'color-FFFFFF'
                        : 'color-text-tertiary')
                    }
                  >
                    전국
                  </div>
                </div>
                {[
                  '경기',
                  '강원',
                  '충북',
                  '충남',
                  '전북',
                  '전남',
                  '경북',
                  '경남',
                  '부산',
                  '대구',
                  '세종',
                  '광주',
                  '서울',
                  '울산',
                  '대전',
                  '제주',
                ].map((el, idx) => (
                  <div
                    key={idx}
                    className="padding-6-16 border-radius-100 hover"
                    style={{
                      border: '1px solid #F0F0F0',
                      background: region.includes(el) ? '#171e26' : '',
                    }}
                    onClick={() => {
                      if (region.includes(el)) {
                        const idx = region.indexOf(el);
                        setRegion((prev: any) => {
                          prev.splice(idx, 1);
                          return [...prev];
                        });
                      } else {
                        setRegion((prev: any) => [...prev, el]);
                      }
                    }}
                  >
                    <div
                      className={
                        'font-medium f-size-16 line-h-24 no-wrap ' +
                        (region.includes(el)
                          ? 'color-FFFFFF'
                          : 'color-text-tertiary')
                      }
                    >
                      {el}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {isMobile(window.innerWidth) ? (
              <div
                className="flex-row end-x center-y hover"
                onClick={() => {}}
                style={{
                  position: 'absolute',
                  width: '52px',
                  right: '0',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  zIndex: 1,
                  background: `linear-gradient(
                to right,
                rgba(255, 255, 255, 0.103) 0%,
                rgba(255, 255, 255, 0.795) 20%,
                rgb(255, 255, 255) 100%,
                #ffffff 100%
              )`,
                }}
              >
                <div
                  style={{
                    border: '1px solid #171e26',
                    borderRadius: '100px',
                    padding: '6px',
                    background: '#FFFFFF',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={scroll}
                >
                  <img
                    src="/asset/images/arrow/ArrowRight24.svg"
                    style={{
                      width: '24px',
                      height: '24px',
                      transform: right ? '' : 'rotate(180deg)',
                    }}
                    alt=""
                  />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="flex-row gap-40 browser-1920-gap-80 w-1024-mt-24">
          <div
            style={{ width: '208px' }}
            className="browser-display-1023 flex-column gap-24 relative"
          >
            {tooltip1 ? (
              <div
                className="padding-8 border-radius-8 absolute ballon56 hover"
                style={{ background: '#3d5066', left: 0, top: -37 }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setTooltip1(false);
                }}
              >
                <div className="font-bold f-size-12 line-h-14 color-FFFFFF nowrap">
                  원하는 정책 지원 종류를 선택해 볼 수 있어요!
                </div>
              </div>
            ) : (
              ''
            )}
            <div
              className="flex-row space-between w-100per center-y"
              style={{ width: '208px' }}
            >
              <div className="font-bold f-size-20 line-h-33 color-text-primary">
                필터
              </div>
              <div
                className="bg-primary-100 padding-8-16 gap-4 flex-row border-radius-8 hover"
                onClick={() => {
                  setECategory('');
                  setBCategory('');
                  setTermType('진행중+시행예정');
                }}
              >
                <img src="/asset/images/arrow/Refresh24Green.svg" alt="" />
                <div className="font-medium f-size-16 line-h-24 color-primary-400">
                  초기화
                </div>
              </div>
            </div>
            <div
              className={
                'padding-24-16 border-radius-16 bg-light-gray-100 flex-column ' +
                (spread1 ? ' gap-40' : '')
              }
            >
              {tab !== 3 ? (
                <div className="flex-column gap-16">
                  <div className="flex-row space-between center-y">
                    <div className="font-bold f-size-16 line-h-26 color-text-secondary">
                      지원 교육 종류
                    </div>
                    <img
                      src="/asset/images/arrow/ArrowTop24.svg"
                      alt=""
                      style={{ transform: spread1 ? '' : 'rotate(180deg)' }}
                      className="hover"
                      onClick={() => setSpread1((prev) => !prev)}
                    />
                  </div>
                  <div
                    className="flex-row gap-16 transition"
                    style={{
                      flexWrap: 'wrap',
                      maxHeight: spread1 ? '100%' : 0,
                      overflow: 'hidden',
                    }}
                  >
                    {[
                      '후계농업경영인',
                      '청년',
                      '귀농귀촌',
                      '전문가',
                      '컨설팅',
                      '마케팅',
                      '인증',
                      '식품',
                      '기타',
                    ].map((el, idx) => (
                      <div
                        key={idx}
                        className="flex-row center-y gap-8 w-100per hover"
                        onClick={
                          eCategory.includes(el)
                            ? () => {
                                if (eCategory.includes(',' + el)) {
                                  setECategory((prev: any) =>
                                    prev.split(',' + el).join(''),
                                  );
                                } else if (eCategory.includes(el + ',')) {
                                  setECategory((prev: any) =>
                                    prev.split(el + ',').join(''),
                                  );
                                } else {
                                  setECategory('');
                                }
                              }
                            : () => {
                                setECategory((prev: any) =>
                                  prev === '' ? el : prev + ',' + el,
                                );
                              }
                        }
                      >
                        {eCategory.includes(el) ? (
                          <img
                            src="/asset/images/check/Check24Green.svg"
                            alt=""
                          />
                        ) : (
                          <div
                            style={{
                              width: '24px',
                              height: '24px',
                              border: '1px solid #171e2610',
                              borderRadius: '4px',
                              background: '#FFFFFF',
                            }}
                          />
                        )}
                        <div
                          className={
                            'font-medium f-size-14 line-h-20 nowrap' +
                            (eCategory.includes(el)
                              ? ' color-primary-400'
                              : ' color-text-tertiary')
                          }
                        >
                          {el}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                ''
              )}
              {tab !== 2 ? (
                <div className="flex-column gap-16">
                  <div className="flex-row space-between center-y">
                    <div className="font-bold f-size-16 line-h-26 color-text-secondary">
                      지원 사업 종류
                    </div>
                    <img
                      src="/asset/images/arrow/ArrowTop24.svg"
                      alt=""
                      className="hover"
                      style={{ transform: spread2 ? '' : 'rotate(180deg)' }}
                      onClick={() => setSpread2((prev) => !prev)}
                    />
                  </div>
                  <div
                    className="flex-row gap-16 transition"
                    style={{
                      flexWrap: 'wrap',
                      maxHeight: spread2 ? '100%' : 0,
                      overflow: 'hidden',
                    }}
                  >
                    {[
                      '농지',
                      '주택/민박',
                      '귀농',
                      '귀촌',
                      '체험/체류',
                      '농시설/농기계',
                      '종자/육묘',
                      '인력',
                      '친환경/에너지',
                      '생산',
                      '가공/판매/유통',
                      '재해/방역/방제',
                      '컨설팅',
                      '청년/창업',
                      '복지',
                    ].map((el, idx) => (
                      <div
                        key={idx}
                        className="flex-row center-y gap-8 w-100per hover"
                        onClick={
                          bCategory.includes(el)
                            ? () => {
                                if (bCategory.includes(',' + el)) {
                                  setBCategory((prev: any) =>
                                    prev.split(',' + el).join(''),
                                  );
                                } else if (eCategory.includes(el + ',')) {
                                  setBCategory((prev: any) =>
                                    prev.split(el + ',').join(''),
                                  );
                                } else {
                                  setBCategory('');
                                }
                              }
                            : () => {
                                setBCategory((prev: any) =>
                                  prev === '' ? el : prev + ',' + el,
                                );
                              }
                        }
                      >
                        {bCategory.includes(el) ? (
                          <img
                            src="/asset/images/check/Check24Green.svg"
                            alt=""
                          />
                        ) : (
                          <div
                            style={{
                              width: '24px',
                              height: '24px',
                              border: '1px solid #171e2610',
                              borderRadius: '4px',
                              background: '#FFFFFF',
                            }}
                          />
                        )}
                        <div
                          className={
                            'font-medium f-size-14 line-h-20 nowrap ' +
                            (bCategory.includes(el)
                              ? ' color-primary-400'
                              : ' color-text-tertiary')
                          }
                        >
                          {el}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
            <div
              className="browser"
              style={{
                marginTop: '16px',
                padding: '40px 0',
                borderBlock: '1px solid #171e2610',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <div className="font-medium f-size-14 line-h-20 color-text-secondary w-100per">
                원하시는 키워드가 있으신가요?
              </div>
              <div className="flex-row gap-8 w-100per">
                <div className="border-1-171E2610 border-radius-8 padding-8 center-x center-y w-100per">
                  <input
                    type="text"
                    value={search}
                    onChange={(e: any) => setSearch(e.target.value)}
                    className="border-none w-100per"
                    placeholder="지역, 교육명, 사업명 등"
                  />
                </div>
                <div
                  onClick={() => {
                    setCurrentPage(1);
                    setECategory('');
                    setBCategory('');
                    setTermType('진행중+시행예정');
                    setSort('마감일 가까운순');
                    setTab(1);
                    setTemp(!temp);
                  }}
                  className="padding-8-12 border-radius-8 bg-light-gray-100 hover"
                >
                  <div className="font-medium f-size-12 line-h-16 color-text-secondary no-wrap">
                    검색
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-column gap-24 w-100per">
            <div
              className="flex-row space-between center-y relative gap-32"
              style={{ flexWrap: 'wrap' }}
            >
              <div className="font-bold f-size-20 line-h-29 text-color-primary flex-row w-1024-f-size-24 w-1024-line-h-34">
                {totalCount.toLocaleString()}건
                <span className="none tablet-flex">의 정책 지원</span>
              </div>
              <div className="flex-row gap-8 browser-gap-16">
                <div
                  className="relative none tablet-flex"
                  onBlur={() => setOpenSort1(false)}
                  tabIndex={0}
                >
                  <div
                    className="flex-row padding-8-16 border-1-171E2610 border-radius-8 space-between center-y hover relative"
                    style={{ width: '146px' }}
                    onClick={() => {
                      setOpenSort1((prev) => !prev);
                    }}
                  >
                    <div className="font-medium f-size-14 line-h-20 color-text-primary">
                      {termType}
                    </div>
                    <img
                      src="/asset/images/arrow/ArrowBottom16.svg"
                      style={{ width: '16px', height: '16px' }}
                      alt=""
                    />
                  </div>
                  {openSort1 ? (
                    <div
                      className="flex-column border-1-171E2610 border-radius-8 absolute bg-FFFFFF"
                      style={{ width: '146px', right: 0, top: 45 }}
                    >
                      {[
                        '진행중',
                        '시행예정',
                        '진행중+시행예정',
                        '마감',
                        '전체',
                      ].map((el) => (
                        <div
                          className="flex-row padding-8-16 center-y hidden_policy_dropdown_item hover "
                          style={{ width: '100%' }}
                          onClick={() => {
                            setTermType(el);
                            setOpenSort1(false);
                          }}
                        >
                          <div
                            className={
                              'font-medium f-size-14 line-h-20 ' +
                              (el === termType ? 'color-primary-400' : '')
                            }
                          >
                            {el}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div
                  className="relative"
                  onBlur={() => setOpenSort(false)}
                  tabIndex={0}
                >
                  <div
                    className="flex-row padding-8-16 border-1-171E2610 border-radius-8 space-between center-y hover relative"
                    style={{ width: '146px' }}
                    onClick={() => {
                      setOpenSort((prev) => !prev);
                    }}
                  >
                    <div className="font-medium f-size-14 line-h-20 color-text-primary">
                      {sort}
                    </div>
                    <img
                      src="/asset/images/arrow/ArrowBottom16.svg"
                      style={{ width: '16px', height: '16px' }}
                      alt=""
                    />
                  </div>
                  {openSort ? (
                    <div
                      className="flex-column border-1-171E2610 border-radius-8 absolute bg-FFFFFF"
                      style={{ width: '146px', right: 0, top: 45 }}
                    >
                      {[
                        '최근 등록일순',
                        '마감일 가까운순',
                        '지원금액 높은순',
                        '조회수 많은순',
                        '좋아요 많은순',
                      ].map((el) => (
                        <div
                          className="flex-row padding-8-16 center-y hidden_policy_dropdown_item hover"
                          style={{ width: '100%' }}
                          onClick={() => {
                            setSort(el);
                            setOpenSort(false);
                          }}
                        >
                          <div
                            className={
                              'font-medium f-size-14 line-h-20 ' +
                              (el === sort ? 'color-primary-400' : '')
                            }
                          >
                            {el}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div
                  className="relative none tablet-flex"
                  onBlur={() => setOpenSort3(false)}
                  tabIndex={0}
                >
                  <div
                    className="flex-row padding-8-16 border-1-171E2610 border-radius-8 space-between center-y hover relative"
                    style={{ width: '146px' }}
                    onClick={() => {
                      setOpenSort3((prev) => !prev);
                    }}
                  >
                    <div className="font-medium f-size-14 line-h-20 color-text-primary">
                      {limit}개씩 보기
                    </div>
                    <img
                      src="/asset/images/arrow/ArrowBottom16.svg"
                      style={{ width: '16px', height: '16px' }}
                      alt=""
                    />
                  </div>
                  {openSort3 ? (
                    <div
                      className="flex-column border-1-171E2610 border-radius-8 absolute bg-FFFFFF"
                      style={{ width: '146px', right: 0, top: 45 }}
                    >
                      {['10개씩 보기', '20개씩 보기', '30개씩 보기'].map(
                        (el) => (
                          <div
                            className="flex-row padding-8-16 center-y hidden_policy_dropdown_item hover "
                            style={{ width: '100%' }}
                            onClick={() => {
                              setLimit(Number(el.slice(0, 2)));
                              setOpenSort3(false);
                            }}
                          >
                            <div
                              className={
                                'font-medium f-size-14 line-h-20 ' +
                                (Number(el.slice(0, 2)) === limit
                                  ? 'color-primary-400'
                                  : '')
                              }
                            >
                              {el}
                            </div>
                          </div>
                        ),
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <div className="flex-row gap-16 flex-wrap browser-1920-gap-32">
              {itemList.map((el) => (
                <Item data={el} setOpen={setOnModal2} setIdx={setSubsidyIdx} />
              ))}
            </div>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageList={pageList}
              totalPages={totalPages}
            />
          </div>
        </div>
        <div className="keyword-search browser2">
          <div className="font-medium f-size-14 line-h-20 color-text-secondary no-wrap">
            원하시는 키워드가 있으신가요?
          </div>
          <div className="flex-row gap-8 w-100per">
            <div className="border-1-171E2610 border-radius-8 padding-8 center-x center-y w-100per">
              <input
                type="text"
                value={search}
                onChange={(e: any) => setSearch(e.target.value)}
                className="border-none w-100per"
                placeholder="지역, 교육명, 사업명 등"
              />
            </div>
            <div
              className="padding-8-12 border-radius-8 bg-light-gray-100 hover"
              onClick={() => {
                setCurrentPage(1);
                setECategory('');
                setBCategory('');
                setTermType('진행중+시행예정');
                setSort('마감일 가까운순');
                setTab(1);
              }}
            >
              <div className="font-medium f-size-12 line-h-16 color-text-secondary no-wrap">
                검색
              </div>
            </div>
          </div>
        </div>
      </div>
      <Banner />
      <FooterBrowser />
    </div>
  );
};

export default HiddenPolicy;
const Item = ({
  data,
  setIdx,
  setOpen,
}: {
  data: {
    agency: string;
    amount: string;
    category: string;
    expireIn: number;
    likeCount: number;
    sido: string;
    subsidyIdx: number;
    title: string;
    type: string;
    view: number;
  };
  setIdx: any;
  setOpen: any;
}) => {
  const locationHook = useLocation();
  return (
    <div
      className="browser-1920-h-320 space-between flex-column gap-26 w-100per tablet-max-w-336 tablet-max-w-312 policy-item browser-1920-w-440 padding-16-24 tablet-padding-24-32"
      onClick={() => {
        clickGoToDetail(locationHook.pathname.split('/')[1]);
        setIdx(data.subsidyIdx);
        setOpen(true);
      }}
      style={{
        borderRadius: '16px',
      }}
    >
      <div className="flex-column gap-16">
        <div className="flex-column gap-8">
          <div className="flex-row end-x" style={{ height: '24px' }}>
            {/* <img src="/asset/images/mark/BookMark.svg" alt="" /> */}
          </div>
          <div className="flex-row gap-16">
            <div className="flex-row gap-4 center-y tablet-gap-16">
              <img src="/asset/images/policy/Calender.svg" alt="" />
              <div className="font-medium f-size-14 line-h-20 color-text-secondary">
                {data.expireIn < 0 ? '마감' : `마감일 D-${data.expireIn}`}
              </div>
            </div>
            {data.amount ? (
              <div className="flex-row gap-4 center-y">
                <img src="/asset/images/policy/Money.svg" alt="" />
                <div
                  className="font-medium f-size-14 line-h-20 color-text-secondary"
                  style={{ wordBreak: 'keep-all' }}
                >
                  지원금 {data.amount}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="font-bold f-size-16 line-h-24 color-text-primary tablet-policy-title">
          {data.title}
        </div>
      </div>
      <div>
        <div className="flex-column gap-10">
          <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
            {data.agency}
          </div>
          <div className="flex-row gap-8">
            {data.sido ? (
              <div className="padding-4-8 flex-row gap-10 bg-blue-gray-100 border-radius-6">
                <div className="font-medium f-size-12 line-h-16 color-text-tertiary">
                  {data.sido}
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="padding-4-8 flex-row gap-10 bg-blue-gray-100 border-radius-6">
              <div className="font-medium f-size-12 line-h-16 color-text-tertiary">
                {data.category}
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row gap-8 end-x">
          <div className="flex-row gap-4">
            <img src="/asset/images/mark/View12.svg" alt="" />
            <div className="font-medium f-size-12 line-h-16 color-171E2640">
              {data.view}
            </div>
          </div>
          <div className="flex-row gap-4">
            <img src="/asset/images/mark/BookMark12.svg" alt="" />
            <div className="font-medium f-size-12 line-h-16 color-171E2640">
              {data.likeCount}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Pagination = ({
  currentPage,
  setCurrentPage,
  pageList,
  totalPages,
}: {
  currentPage: number;
  setCurrentPage: any;
  pageList: (string | number)[];
  totalPages: number;
}) => {
  return (
    <div className="flex-row w-100per center-x gap-24 center-y">
      {currentPage > 1 ? (
        <div
          className="bg-light-gray-200 border-radius-999 center-x center-y hover"
          style={{ width: '32px', height: '32px' }}
          onClick={() => setCurrentPage((prev: any) => prev - 1)}
        >
          <img src="/asset/images/arrow/ArrowLeftPage.svg" alt="" />
        </div>
      ) : (
        <div
          className="bg-light-gray-200 border-radius-999 center-x center-y hover"
          style={{
            width: '32px',
            height: '32px',
            opacity: '30%',
          }}
        >
          <img src="/asset/images/arrow/ArrowLeftPage.svg" alt="" />
        </div>
      )}
      {pageList.map((el, key) => (
        <div
          key={key}
          className={
            'font-bold f-size-16 line-h-26 ' +
            (typeof el === 'number' ? 'hover ' : '') +
            (el === currentPage
              ? ' color-text-primary'
              : ' color-text-tertiary')
          }
          onClick={() => {
            if (typeof el === 'number') setCurrentPage(el);
          }}
        >
          {el}
        </div>
      ))}

      {totalPages > currentPage ? (
        <div
          className="bg-light-gray-200 border-radius-999 center-x center-y hover"
          style={{
            width: '32px',
            height: '32px',
            transform: 'rotate(180deg)',
          }}
          onClick={() => setCurrentPage((prev: any) => prev + 1)}
        >
          <img src="/asset/images/arrow/ArrowLeftPage.svg" alt="" />
        </div>
      ) : (
        <div
          className="bg-light-gray-200 border-radius-999 center-x center-y hover"
          style={{
            width: '32px',
            height: '32px',
            transform: 'rotate(180deg)',
            opacity: '30%',
          }}
        >
          <img src="/asset/images/arrow/ArrowLeftPage.svg" alt="" />
        </div>
      )}
    </div>
  );
};
