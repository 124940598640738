import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  kakaoCode: '',
  naverCode: '',
  facebookCode: '',
  googleCode: '',
  kakaoUrl: '',
  naverUrl: '',
  facebookUrl: '',
  googleUrl: '',
};

const loginSlice = createSlice({
  name: 'code',
  initialState: initialState,
  reducers: {
    KakaoCode(state, action) {
      state.kakaoCode = action.payload;
    },
    NaverCode(state, action) {
      state.naverCode = action.payload;
    },
    FacebookCode(state, action) {
      state.facebookCode = action.payload;
    },
    GoogleCode(state, action) {
      state.googleCode = action.payload;
    },
    KakaoRedirectUrl(state, action) {
      state.kakaoUrl = action.payload;
    },
    NaverRedirectUrl(state, action) {
      state.naverUrl = action.payload;
    },
    FacebookRedirecturl(state, action) {
      state.facebookUrl = action.payload;
    },
    GoogleRedirectUrl(state, action) {
      state.googleUrl = action.payload;
    },
  },
});

export const {
  KakaoCode,
  NaverCode,
  FacebookCode,
  GoogleCode,
  KakaoRedirectUrl,
  NaverRedirectUrl,
  FacebookRedirecturl,
  GoogleRedirectUrl,
} = loginSlice.actions;
export default loginSlice.reducer;
