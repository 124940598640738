import Request from '../../@api/request';

// import { request } from "../service"
// const baseURL = "http://localhost:3333/";
// const baseURL = "https://back.transfarmer.kr/";
const baseURL = `${process.env.REACT_APP_HOST}`;
export const request = new Request(baseURL);

export default class PensionService {
  static value = async (address: string) => {
    const result = await request.get('pension/value?address=' + address);
    return result.data;
  };

  static save = async (
    type: any,
    pnuCode: any,
    age: any,
    career: any,
    isFarm: any,
    isHave2: any,
    isNear: any,
    isNear2: any,
    isRight: any,
    isIllegal: any,
    isShare: any,
    farmValue: any,
  ) => {
    const data = {
      type,
      pnuCode,
      age,
      career,
      isFarm,
      isHave2,
      isNear,
      isNear2,
      isRight,
      isIllegal,
      isShare,
      farmValue,
    };
    const result = await request.post('pension', data);
    return result;
  };

  static load = async (reportIdx: number) => {
    const result = await request.get('pension/' + reportIdx);
    return result.data;
  };
  //tmpAge, fixedTypePeriod, managementTypePeriod, purpose, tmpAuctionPrice
  static patchTmpAge = async (tmpAge: number, reportIdx: number) => {
    const result = await request.patch('pension/' + reportIdx, { tmpAge });
    return result.data;
  };
  static patchFixedTypePeriod = async (
    fixedTypePeriod: number,
    reportIdx: number,
  ) => {
    const result = await request.patch('pension/' + reportIdx, {
      fixedTypePeriod,
    });
    return result.data;
  };
  static patchManagementTypePeriod = async (
    managementTypePeriod: number,
    reportIdx: number,
  ) => {
    const result = await request.patch('pension/' + reportIdx, {
      managementTypePeriod,
    });
    return result.data;
  };
  static patchPurpose = async (purpose: string, reportIdx: number) => {
    const result = await request.patch('pension/' + reportIdx, { purpose });
    return result.data;
  };
  static patchTmpAuctionPrice = async (
    tmpAuctionPrice: any,
    reportIdx: number,
  ) => {
    const result = await request.patch('pension/' + reportIdx, {
      tmpAuctionPrice,
    });
    return result.data;
  };
  //보고서 수정 저장
  static saveReport = async (data: any) => {
    const result = await request.patch('/pension', data);
    return result.data;
  };
}
