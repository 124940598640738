export const Cover = ({ address }: any) => {
  return (
    <div className="flex-column w-100per h-100per space-between padding-24">
      <div className="flex-row w-100per space-between">
        <img
          style={{ width: '300px', height: '80px' }}
          src="/asset/images/logo/pdf_logo2.png"
        />
        <div className="flex-column gap-16 end-x">
          <div className="m-b2-14-m text-end">Report for VIP</div>
          <div className="padding-16 flex-column bg-light-gray-100 border-171E26">
            <div className="m-b2-14-m">의뢰번호 TF000001</div>
            <div className="m-b2-14-m">
              본 보고서를 수령하신 후 <br />
              해당 보고서에 대한 Transfarmer 전문가의 <br /> 브리핑을 30분 간
              받아 보실 수 있습니다.
            </div>
          </div>
        </div>
      </div>
      <div className="flex-column gap-16">
        <div className="w-h1-48-b">{address} </div>
        <div className="w-h1-48-b">가치 분석 심층 컨설팅 보고서</div>
      </div>
      <div className="flex-column gap-16">
        <div className="flex-column gap-8">
          <div className="w-t1-32-b">Transfarmer Pricing Analysis Report</div>
          <div className="w-t2-24-m">2024-05-24</div>
        </div>
        <div className="m-b2-14-m color-text-tertiary">
          'Transfarmer의 가격 분석 컨설팅 보고서'는 부동산 및 경영 전문가가
          작성한 참고용으로, <br />
          향후 경제상황이나 부동산 정책 등 제반 가정 변화에 따라 그 내용이나
          결과가 달라질 수 있음에 유의 바랍니다.
        </div>
      </div>
    </div>
  );
};
