import axios from 'axios';
import { useEffect, useState } from 'react';
import Spinner from '../../component/browser/Spinner';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GoogleCode } from '../../store/codeSlice';
import { LoginId } from '../../store/user2Slice';
import GetActService from '../../service/act/Act';
import { GetUserInfo, useAuth } from '../../utilities/auth';
import { SignUpType } from '../../store/signUpInfoSlice';

const GoogleRedirect = () => {
  const { onLogin } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const googleCode = useSelector((state: any) => {
    {
      return state.code.googleCode;
    }
  });
  const redirectUrl = useSelector((state: any) => {
    {
      return state.code.googleUrl;
    }
  });

  const GoogleGetCode = () => {
    const code = new URLSearchParams(window.location.search).get('code')!;
    dispatch(GoogleCode(encodeURI(code)));
  };

  const GoogleGetAct = async () => {
    const provider = 'google';
    const data = {
      redirectUrl: sessionStorage.getItem('url'),
    };
    try {
      const result = await GetActService.getAct(
        googleCode,
        provider,
        data as any,
      );
      if (result.data.accessToken === undefined) {
        dispatch(SignUpType('G'));
        navigate('/signUpSNS');
        sessionStorage.setItem('email', result.data.email);
        sessionStorage.setItem('name', result.data.name);
        sessionStorage.setItem('phone', result.data.phoneNumber);
      } else {
        onLogin(result.data.accessToken);
        GetUserInfo();
      }
    } catch (error) {
      navigate('/');
    }
  };
  useEffect(() => {
    GoogleGetCode();
  }, []);

  useEffect(() => {
    if (googleCode) {
      GoogleGetAct();
    }
  }, [googleCode]);

  return (
    <div>
      <Spinner />
    </div>
  );
};

export default GoogleRedirect;
