import { useEffect, useState } from 'react';

import {
  RowSectionTitle,
  RowTableValue,
} from '../../../../../component/report/Row';
import { TextSectionTitle } from '../../../../../component/report/Text';
import { isMobile, transNum } from '../../../../../component/function/function';
import { Area, AreaChart, Tooltip, XAxis, YAxis } from 'recharts';
import { Tooltip2 } from '../../../../../component/common/tooltip';
import { ButtonSelectorBox } from '../../../../../component/report/Button';
import { BoxGreen1, BoxTable2 } from '../../../../../component/report/Box';
import SignController from '../../../../../controller/sign/SignController';

interface data {
  loanList: {
    loanName: string;
    loanCost: number;
    loanDate: string;
    loanRate: number;
    redemptionPeriod: number;
    retentionPeriod: number;
    loanType: string;
  }[];
  loanPlan: any[];
  recentRepayment: {
    info: any[];
  };
  totalInterest: number;
  totalRepayment: number;
}
interface Repayment {
  interest: number;
}

const ReportDetail7 = ({ data }: { data: data }) => {
  const [name, setName] = useState('');
  const currentDate = new Date();
  const currentYear: number = currentDate.getFullYear();
  const currentMonth: number = currentDate.getMonth() + 1;

  let remainSum = 0;
  if (
    data.recentRepayment &&
    data.recentRepayment.info &&
    data.recentRepayment.info.length > 0
  ) {
    remainSum = data.recentRepayment.info[0].remainSum || 0;
  }
  useEffect(() => {
    SignController.getName(setName);
  }, []);

  const [tab, setTab] = useState(1);
  const [onToolTip, setOnToolTip] = useState(false);
  const obj = {
    P: '원금',
    T: '원리금',
    B: '만기',
  };
  let tbList =
    data.recentRepayment &&
    data.recentRepayment.info.map((el, i) => {
      return [
        i + 1,
        `${el.year}-${el.month}`,
        `${transNum(el.total)}원`,
        `${transNum(el.principal)}원`,
        `${transNum(el.interest)}원`,
        `${transNum(el.principalSum)}원`,
        `${transNum(el.remainSum)}원`,
      ];
    });
  let tbList2 =
    data.loanPlan &&
    data.loanPlan.map((el, i) => {
      return [
        i + 1,
        `${el.year}-${el.month}`,
        `${transNum(el.total)}원`,
        `${transNum(el.principal)}원`,
        `${transNum(el.interest)}원`,
        `${transNum(el.principalSum)}원`,
        `${transNum(el.remainSum)}원`,
      ];
    });
  const table = {
    th: [
      '회차',
      '상환일정',
      '월상환금',
      '납입원금',
      '이자',
      '납입원금누계',
      '잔금',
    ],
    tb: tbList,
  };
  const table2 = {
    th: [
      '회차',
      '상환일정',
      '월상환금',
      '납입원금',
      '이자',
      '납입원금누계',
      '잔금',
    ],
    tb: tbList2,
  };

  const btnArr = [
    { children: '대출현황', selected: 1 },
    '',
    { children: '최근 상환계획', selected: 2 },
    '',
    { children: '전체 상환계획', selected: 3 },
  ];

  const currentYearMonth = `${currentYear}-${currentMonth}`;

  // 현재가 몇회차인지 찾는 함수
  const findCurrentPeriod = (loanPlan: any) => {
    let currentPeriod = -1;

    for (let i = 0; i < loanPlan.length; i++) {
      const periodYearMonth = `${loanPlan[i].year}-${loanPlan[i].month}`;
      if (periodYearMonth === currentYearMonth) {
        currentPeriod = i + 1;
        break;
      }
    }

    return currentPeriod;
  };

  const currentPeriod = findCurrentPeriod(data.loanPlan);
  console.log(`현재 ${currentYearMonth}은(는) ${currentPeriod}회차입니다.`);

  // 현재 회차까지의 이자를 모두 더하는 함수
  const calculateTotalInterestUntil = (
    loanPlan: any,
    currentPeriod: number,
  ): number => {
    let totalInterest = 0;

    for (let i = 0; i < currentPeriod; i++) {
      totalInterest += Number(loanPlan[i].interest);
    }

    return totalInterest;
  };
  const totalInterestUntil = calculateTotalInterestUntil(
    data.loanPlan,
    currentPeriod,
  );

  // 다음 회차부터 남은 잔여 이자를 구하는 함수
  const calculateRemainingInterestFromNextPeriod = (
    loanPlan: any[],
    currentPeriod: number,
  ) => {
    let remainingInterest = 0;

    for (let i = currentPeriod; i < loanPlan.length; i++) {
      const currentLoan = loanPlan[i];
      if (
        currentLoan &&
        typeof currentLoan.interest === 'number' &&
        !isNaN(currentLoan.interest)
      ) {
        remainingInterest += currentLoan.interest;
      }
    }

    return remainingInterest;
  };

  const remainingInterestFromNextPeriod =
    calculateRemainingInterestFromNextPeriod(data.loanPlan, currentPeriod);

  return (
    <div className="padding-b-24 relative">
      <RowSectionTitle>
        <TextSectionTitle warn onClick={() => setOnToolTip(true)}>
          7. 대출 진단
        </TextSectionTitle>
      </RowSectionTitle>
      <div style={{ position: 'absolute', zIndex: '1' }}>
        <Tooltip2
          left="15"
          bottom="5"
          on={onToolTip}
          off={() => setOnToolTip(false)}
          text="대출 이자율은 고정금리에 한해 분석합니다."
        />
      </div>
      <ButtonSelectorBox tab={tab} setTab={setTab} btnArr={btnArr} />
      {tab === 1 ? (
        <div className="flex-column">
          {data.loanList.map((el, i) => (
            <div className="flex-column">
              <div className="padding-16-16-8">
                <div className="font-bold f-size-16 line-h-24 color-text-secondary">
                  대출 {i + 1}
                </div>
              </div>
              <div className="padding-8-16 flex-column gap-8">
                <RowTableValue title="대출명" value={el.loanName} />
                <RowTableValue title="대출실행일" value={el.loanDate} />
                <RowTableValue
                  title="대출금액"
                  value={`${transNum(el.loanCost)}원`}
                />
                <RowTableValue
                  title="연이자율"
                  value={el.loanRate * 100 + '%'}
                />
                <RowTableValue
                  title="대출기간"
                  value={
                    Number(el.redemptionPeriod) +
                    Number(el.retentionPeriod) +
                    '년'
                  }
                />
                <RowTableValue
                  title="거치기간"
                  value={el.retentionPeriod + '년'}
                />
                <RowTableValue
                  title="상환방식"
                  value={obj[el.loanType as 'P']}
                />
              </div>
              <div className="padding-8-16 gap-10">
                <BoxGreen1>
                  <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                    {name}님의 대출 원금은 {transNum(el.loanCost)}원,
                    <br /> 이자 합계는 {transNum(data.totalInterest)}원으로
                    계산됩니다.
                  </div>
                </BoxGreen1>
              </div>
              <div className="padding-8-16 gap-10">
                <BoxGreen1>
                  <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                    대출 상환 원리금 합계는 총 {transNum(data.totalRepayment)}
                    원입니다.
                  </div>
                </BoxGreen1>
              </div>
            </div>
          ))}
        </div>
      ) : (
        ''
      )}
      {tab === 2 ? (
        <div className="flex-column">
          <div className="flex-column">
            <div className="padding-16-16-8">
              <div className="font-bold f-size-16 line-h-24 color-text-secondary">
                최근 6개월 상환계획
              </div>
            </div>
            <div className="padding-8-16-0">
              <div className="font-medium f-size-16 line-h-24 color-text-secondary">
                원금 균등 상환
              </div>
            </div>
            <div className="padding-16 relative of-x-auto">
              <BoxTable2 table={table} />
            </div>

            <div className="padding-8-16 gap-10">
              <BoxGreen1>
                <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                  오늘자 기준 {name}님의 대출 잔여금은 {transNum(remainSum)}
                  원이며, <br />
                  잔여 이자는 {transNum(remainingInterestFromNextPeriod)}
                  원입니다.
                </div>
              </BoxGreen1>
            </div>

            <div className="padding-8-16 gap-10">
              <BoxGreen1>
                <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                  오늘자 기준 대출 상환 원리금 합계는 <br />총{' '}
                  {transNum(remainSum + remainingInterestFromNextPeriod)}
                  원입니다.
                </div>
              </BoxGreen1>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {tab === 3 ? (
        <div className="flex-column">
          <div className="padding-8-16-0">
            <div className="font-medium f-size-16 line-h-24 color-text-secondary">
              {obj[data.loanList[0].loanType as 'P']}
            </div>
          </div>
          <div className="flex- row padding-12-16 gap-8 end-x">
            <div className="flex-row gap-4 center-y">
              <div
                className="border-radius-100per"
                style={{ width: '6px', height: '6px', background: '#09CE7D' }}
              />
              <div className="font-medium f-size-11 line-h-12 color-primary-400">
                이자
              </div>
            </div>
            <div className="flex-row gap-4 center-y">
              <div
                className="border-radius-100per"
                style={{ width: '6px', height: '6px', background: '#869CB6' }}
              />
              <div className="font-medium f-size-11 line-h-12 color-blue-gray-500">
                납입원금
              </div>
            </div>
          </div>
          <div className="w-100per center-x">
            <div
              className="of-x-auto"
              style={{
                width: isMobile(window.innerWidth)
                  ? window.innerWidth - 32
                  : 365 - 32,
              }}
            >
              <AreaChart
                className="flex-medium f-size-11 center-x"
                width={
                  isMobile(window.innerWidth)
                    ? window.innerWidth - 32
                    : 365 - 32
                }
                height={181}
                data={data.loanPlan.map((el) => {
                  return { ...el, day: `${el.year}-${el.month}` };
                })}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <XAxis dataKey="day" />
                <YAxis />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="interest"
                  name="이자"
                  fill="#09CE7D"
                />
                <Area
                  type="monotone"
                  dataKey="principal"
                  name="납입원금"
                  fill="#869CB6"
                />
              </AreaChart>
            </div>
          </div>
          <div
            className="padding-16 relative of-x-auto of-y-auto"
            style={{ height: '284px' }}
          >
            <BoxTable2 table={table2} />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ReportDetail7;
