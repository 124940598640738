import { Content1, Footer, ReportTitle } from '../component';

export const AskPrice = () => {
  const content = [
    '현재 온라인 상 농지 매물(12,000건) 중 본 건 주변의 매물 분석 결과,',
    '매도 호가는 XXX,XXX원/평에서 XXX,XXX원/평으로 분석되며, ',
  ];
  return (
    <div className="flex-column w-100per space-between page">
      <div className="flex-column  w-100per">
        <ReportTitle
          address="전북특별자치도 김제시 순동 445-19"
          category="매도 호가 분석"
        />
        <div className="flex-column padding-24 gap-12">
          <Content1 content={content} />
        </div>
      </div>
      <Footer number={10} />
    </div>
  );
};
