import Request from '../../@api/request';
import { useAuth } from '../../utilities/auth';

// const baseURL = "http://localhost:3333/";
// const baseURL = "https://back.transfarmer.kr/";
const baseURL = `${process.env.REACT_APP_HOST}`;
interface SignUpData {
  loginType: string;
  email: any;
  name: any;
  birthDate?: string;
  gender?: string;
  category: string;
  agriculturalNum?: number;
  phone_1st: any;
  phone_2nd: any;
  password?: string;
  isNotification?: string;
}
interface SignUpSNSData {
  birthDate: string;
  gender: string;
  name: string;
  category: string;
  agriculturalNum?: number;
}
interface CheckSMSData {
  userId: string;
  code: string;
  phone: string;
}
interface SendSMS {
  userId: string;
  phone: string;
}
interface Withdrawal {
  reason?: (string | null)[];
  authCode: string;
}

export const request = new Request(baseURL);
export default class SignUpService {
  // static SignUpSNS = async (data: SignUpSNSData) => {
  //   return await request.post("users/sign", data);
  // };
  static SignUp = async (data: SignUpData) => {
    return await request.post('users/sign', data);
  };
  static SendSms = async (data: SendSMS) => {
    return await request.post('users/sign/sms-verification/send', data);
  };
  static CheckSMS = async (data: CheckSMSData) => {
    return await request.post('users/sign/sms-verification/check', data);
  };
  static IdEqualCheck = async (data: string) => {
    const userId = data;
    return await request.post('users/sign/check', { userId });
  };
  static Logout = async () => {
    await request.delete('users/sign/logout');
  };
  static FindId = async (name: string, phone: string) => {
    const result = await request.get(
      `users/sign/find/id?name=${name}&phone=${phone}`,
    );
    return result;
  };
  static ChangePwd = async (data: any) => {
    const result = await request.patch(`users/sign/update/password`, data);
    return result;
  };
  static CheckUser = async (email: string, name: string, phone: string) => {
    const result = await request.get(
      `users/sign/update/password/check?email=${email}&name=${name}&phone=${phone}`,
    );
    return result;
  };

  static Withdrawal = async (data: Withdrawal) => {
    const result = await request.put('users/sign/withdrawal', data);
    return result;
  };
  static Authentication = async (data: any) => {
    const result = await request.post(
      '/users/sign/withdrawal/authentication',
      data,
    );
    return result.data;
  };
}
