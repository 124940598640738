import { Link, useNavigate } from 'react-router-dom';
import { isMobile } from '../../../component/function/function';
import { useEffect, useState } from 'react';

const ConsultingPopUp = ({
  setConsultingPopUp,
  consultingPopUp,
  handleDoNotShowToday,
}: any) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (consultingPopUp) {
      // document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Cleanup function to reset overflow when component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [consultingPopUp]);
  if (window.navigator.userAgent === 'ReactSnap') {
    return <></>;
  }
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: '#00000060',
        top: 0,
        left: 0,
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      }}
      onClick={() => setConsultingPopUp(false)}
    >
      <div
        className="flex-column browser-border-radius-8 w-100per border-radius-t-l-8 border-radius-t-r-8 "
        style={{
          backgroundColor: 'white',
          // top: isMobile(window.innerWidth) ? '15%' : '25%',
          // right: isMobile(window.innerWidth) ? '5%' : '0%',
          top: isMobile(window.innerWidth) ? '11%' : '20%',
          width: '312px',
          height: '550px',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Link
          to="https://transfarmer.notion.site/e3eb7cc33250494c96a32ec1c0d4b759"
          style={{ height: '510px' }}
          className="pointer  border-radius-t-l-8 border-radius-t-r-8 "
          onClick={() => {
            setConsultingPopUp(false);
          }}
        >
          <img
            src="/asset/images/banner/main-banner.gif"
            alt=""
            style={{ width: '100%', height: '100%' }}
          />
        </Link>

        <div className="flex-row space-between w-100per center-y flex-1 padding-8-16">
          <div
            className="flex-row pointer center-y"
            onClick={() => {
              handleDoNotShowToday();
            }}
          >
            <img
              src={'/asset/images/check/Check24Gray2.svg'}
              style={{ color: '#171E268F' }}
              alt=""
            />
            <div className={`m-b2-14-m ${`color-text-tertiary`}`}>
              오늘 하루 보지 않기
            </div>
          </div>
          <div
            className="flex-row pointer center-y"
            onClick={() => {
              setConsultingPopUp(false);
            }}
          >
            <img src="/asset/images/close/CloseGray24.svg" alt="" />
            <div className="m-b2-14-m color-text-tertiary">닫기</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsultingPopUp;
