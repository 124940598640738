import { useState, useEffect } from 'react';
import {
  AIFarmingMapSideBarCon,
  AreaSearchCon,
  AreaSearchContent,
  AreaSearchContentBox,
  AreaSearchContentLocation,
} from '../../../../asset/styles/mobile/aiFraming/AIFarmingMap';
import { Font } from '../../../../asset/styles/browser/font/font';
import { useNavigate, useLocation } from 'react-router-dom';
import LandService from '../../../../service/land/landApi';
import { useSelector } from 'react-redux';
interface ListType {
  address: string;
  gugun: string;
  lat: null;
  lon: null;
  isFarm: string;
  jibunAddr?: string;
  detail?: string;
}
interface IAreaSearchData {
  searchList: Array<{
    address: string;
    jibunAddr?: string;
    detail?: string;
    service: string;
  }>;
  searchState: {
    onSearchList: boolean;
    setOnSearchList: React.Dispatch<React.SetStateAction<boolean>>;
  };
  searchValue: string;
  setSearchList: any;
  onInputFocus: boolean;
}

const AreaSearch = (props: IAreaSearchData) => {
  const navigate = useNavigate();
  const [hover, setHover] = useState(0);
  const locationHook = useLocation();
  const state2 = useSelector((state: any) => {
    return state.type;
  });

  const searchApi = async () => {
    try {
      let result: { searchList: Array<ListType> | Array<never> } | any;
      if (locationHook.pathname.startsWith('/auction/map')) {
        result = await LandService.search2(props.searchValue);
      } else {
        if (
          locationHook.pathname.startsWith('/house/map') ||
          state2.priceType === 'house'
        ) {
          result = await LandService.searchRoad1st(props.searchValue);
        } else {
          result = await LandService.search(props.searchValue);
        }
      }
      props.setSearchList(result.searchList);
    } catch (error) {
      console.log(error);
    }
  };
  const deleteSearchAll = async () => {
    try {
      const result = await LandService.deleteSearchListAll();
      searchApi();
      console.log('deleteAll', result);
    } catch (error) {
      console.log(error);
    }
  };
  const deleteSearch = async (address: string, service: string) => {
    const data = {
      address: address,
      service: service,
    };
    try {
      const result = await LandService.deleteSearchList(data);
      console.log('delete', result);
      searchApi();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    console.log('props.oninputfocus', props.onInputFocus);
  }, [props.onInputFocus]);
  return (
    <div
      className={`w-100per flex-row start-y fixed of-y-auto   browser-top-118 ${
        props.onInputFocus ? `z-999` : ``
      }`}
      style={{
        top: '40px',
        height: props.searchList.length < 6 ? 'auto' : 'calc(100vh - 40px)',
      }}
    >
      <div className="w-100per  bg-FFFFFF flex-column border-r-1-e2e2e2 padding-b-10">
        <div className="w-100per flex-column">
          {props.searchList.length === 0 ? (
            ''
          ) : props.onInputFocus ? (
            props.searchValue === '' ? (
              //최근검색 (검색어 없을 때)
              <div className="flex-column w-100per ">
                <div
                  className="border-t-171E26 border-b-171E26 padding-8-16 flex-row space-between center-y "
                  style={{ zIndex: '9' }}
                >
                  <div
                    className="flex-center border-radius-999 "
                    style={{
                      padding: '4px 12px',
                      backgroundColor: '#171E26C7',
                      border: '1px solid #171E261A',
                    }}
                  >
                    <div className="m-b2-14-m color-FFFFFF">최근검색</div>
                  </div>
                  <div
                    className="m-b2-14-m color-dark-secondary "
                    onClick={() => {
                      deleteSearchAll();
                    }}
                  >
                    전체삭제
                  </div>
                </div>
                <div className="flex-column padding-x-20">
                  {props.searchList.map((el, i: number) => {
                    return (
                      // <div
                      //   className={"w-100per padding-y-12 flex-row gap-12 hover"}
                      //   tabIndex={i}
                      //   onClick={() => {
                      //     props.searchState.setOnSearchList(false);
                      //     navigate(`?area=${el.address}`);
                      //   }}
                      // >
                      //   <AreaSearchContentLocation />
                      //   <div
                      //     className='font-medium f-size-16 line-h-22 color-18181B'
                      //     onMouseOver={() => {
                      //       setHover(i);
                      //     }}
                      //   >
                      //     {el.address}
                      //   </div>
                      // </div>
                      <div
                        className={'w-100per flex-row gap-12 hover'}
                        tabIndex={i}
                        // onClick={() => {
                        //       props.searchState.setOnSearchList(false);
                        //       navigate(`?area=${el.address}`);
                        //     }}

                        onMouseOver={() => {
                          setHover(i);
                        }}
                        onMouseOut={() => {
                          setHover(10000);
                        }}
                      >
                        <div
                          className="flex-row w-100per gap-16 center-y  padding-y-16 hover  border-b-171E26"
                          onClick={() => {
                            props.searchState.setOnSearchList(false);

                            navigate(
                              `${
                                locationHook.pathname.slice(-1) === '/'
                                  ? locationHook.pathname.slice(0, -1)
                                  : locationHook.pathname
                              }?area=${
                                el.jibunAddr ? el.jibunAddr : el.address
                              }`,
                            );
                          }}
                        >
                          <img
                            alt=""
                            className="w-24"
                            src="/asset/images/location/location.svg"
                          />
                          <div className="flex-column">
                            <div className="font-medium f-size-16 line-h-24 color-text-secondary">
                              {el.address}
                            </div>
                            <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                              {el.jibunAddr || el.detail}
                            </div>
                          </div>
                        </div>
                        <img
                          className="w-24 pointer"
                          src="/asset/images/close/CloseGray20.svg"
                          onClick={() => {
                            // console.log('awef');
                            deleteSearch(el.address, el.service);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="flex-column padding-x-20">
                {props.searchList.map((el, i: number) => {
                  return (
                    // <div
                    //   className={"w-100per padding-y-12 flex-row gap-12 hover"}
                    //   tabIndex={i}
                    //   onClick={() => {
                    //     props.searchState.setOnSearchList(false);
                    //     navigate(`?area=${el.address}`);
                    //   }}
                    // >
                    //   <AreaSearchContentLocation />
                    //   <div
                    //     className='font-medium f-size-16 line-h-22 color-18181B'
                    //     onMouseOver={() => {
                    //       setHover(i);
                    //     }}
                    //   >
                    //     {el.address}
                    //   </div>
                    // </div>
                    <div
                      className={'w-100per flex-row gap-12 hover'}
                      tabIndex={i}
                      // onClick={() => {
                      //       props.searchState.setOnSearchList(false);
                      //       navigate(`?area=${el.address}`);
                      //     }}
                      onClick={() => {
                        props.searchState.setOnSearchList(false);
                        navigate(
                          `${
                            locationHook.pathname.slice(-1) === '/'
                              ? locationHook.pathname.slice(0, -1)
                              : locationHook.pathname
                          }?area=${el.jibunAddr ? el.jibunAddr : el.address}`,
                        );
                      }}
                      onMouseOver={() => {
                        setHover(i);
                      }}
                      onMouseOut={() => {
                        setHover(10000);
                      }}
                    >
                      <div className="flex-row w-100per gap-16 center-y  padding-y-16 hover  border-b-171E26">
                        <img
                          alt=""
                          className="w-24"
                          src="/asset/images/location/location.svg"
                        />
                        <div className="flex-column">
                          <div className="font-medium f-size-16 line-h-24 color-text-secondary">
                            {el.address}
                          </div>
                          <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                            {el.jibunAddr || el.detail}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default AreaSearch;
