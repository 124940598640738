export const Title = ({ text, warn, onClick }: any) => {
  return (
    <div className="flex-column gap-5  padding-16-16-8-16">
      <div className="font-bold f-size-16 line-h-24 color-text-primary center-y">
        {text}{' '}
        {warn ? (
          <img
            src="/asset/images/warn/Warn24.svg"
            className="hover"
            onClick={() => onClick()}
            alt=""
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
