import { useEffect, useState } from 'react';
import { isMobile } from '../../../../component/function/function';
import MapContainer from './MapContainer';
import AuctionService from '../../../../service/auction/Auction';

export const FilterModal = ({
  open,
  setOpen,
  orderby,
  setOrderby,
  type,
}: any) => {
  if (!open) {
    return <></>;
  }
  return (
    <div
      className={
        isMobile(window.innerWidth) ? 'fixed end-y' : 'fixed center-y center-x'
      }
      onClick={() => setOpen(false)}
      style={{
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: '#00000025',
        zIndex: 30,
      }}
    >
      <div
        className={
          isMobile(window.innerWidth)
            ? 'bg-FFFFFF flex-column border-top-radius-16 w-100per gap-16'
            : 'bg-FFFFFF flex-column border-radius-16 gap-16'
        }
        style={isMobile(window.innerWidth) ? {} : { width: '360px' }}
      >
        <div className="padding-24-16-4">
          <div className="flex-row space-between">
            <div className="font-bold f-size-16 line-h-24 color-text-primary">
              정렬 옵션
            </div>
            <img
              src="/asset/images/close/CloseBlack24.svg"
              alt=""
              className="hover"
              onClick={() => setOpen(false)}
            />
          </div>
          <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
            원하는 옵션을 선택해주세요
          </div>
        </div>
        <div className="padding-8-16-24 of-y-auto">
          {(type === 'S'
            ? [
                '등록일 나중순',
                '등록일 최근순',
                '희망가 높은순',
                '희망가 낮은순',
                '좋아요 높은순',
                '좋아요 낮은순',
              ]
            : [
                '입찰일 나중순',
                '입찰일 최근순',
                '유찰횟수 높은순',
                '유찰횟수 낮은순',
                '감정가 높은순',
                '감정가 낮은순',
                '최저가 높은순',
                '최저가 낮은순',
              ]
          ).map((el) => (
            <div
              className="padding-8-16 space-between border-b-1-171E2610 flex-row pointer"
              onClick={() => setOrderby(el)}
            >
              <div
                className={
                  'font-medium f-size-16 line-h-24 ' +
                  (orderby === el ? 'color-primary-500' : '')
                }
              >
                {el}
              </div>
              {orderby === el ? (
                <img
                  src="/asset/images/check/Check24Green2.svg"
                  style={{ width: '16px', height: '16px' }}
                  alt=""
                />
              ) : (
                ''
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const FilterModal2 = ({
  open,
  setOpen,
  region,
  setRegion,
  totalCount,
}: any) => {
  if (!open) {
    return <></>;
  }
  return (
    <div
      className={
        isMobile(window.innerWidth) ? 'fixed end-y' : 'fixed center-y center-x'
      }
      style={{
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: '#00000025',
        zIndex: 30,
      }}
    >
      <div
        className={
          isMobile(window.innerWidth)
            ? 'bg-FFFFFF flex-column border-top-radius-16 w-100per gap-16'
            : 'bg-FFFFFF flex-column border-radius-16 gap-16'
        }
        style={isMobile(window.innerWidth) ? {} : { width: '360px' }}
      >
        <div className="padding-24-16-4">
          <div className="flex-row space-between">
            <div className="font-bold f-size-16 line-h-24 color-text-primary">
              지역 선택
            </div>
            <img
              src="/asset/images/close/CloseBlack24.svg"
              alt=""
              className="hover"
              onClick={() => setOpen(false)}
            />
          </div>
          <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
            원하는 지역을 모두 선택해주세요
          </div>
        </div>
        <div className="padding-8-16 gap-8">
          <div className="flex-row gap-8 flex-wrap of-y-auto">
            <div
              className={
                'padding-8-16 border-radius-6 flex-row space-between center-y hover ' +
                (region.length === 0 ? ' bg-primary-100 border-1-1E9B6B' : '')
              }
              style={{ width: '160px', height: '48px' }}
              onClick={() => setRegion([])}
            >
              <div
                className={
                  'f-size-16 line-h-24 ' +
                  (region.length === 0
                    ? 'color-primary-500 font-bold'
                    : 'color-text-primary font-medium')
                }
              >
                전국{' '}
              </div>
              {region.length === 0 ? (
                <img
                  src="/asset/images/check/Check24Green2.svg"
                  style={{ width: '24px', height: '24px' }}
                  alt=""
                />
              ) : (
                ''
              )}
            </div>
            {[
              '경기',
              '강원',
              '충북',
              '충남',
              '전북',
              '전남',
              '경북',
              '경남',
              '부산',
              '대구',
              '세종',
              '광주',
              '서울',
              '울산',
              '대전',
              '제주',
            ].map((el, idx) => (
              <div
                key={idx}
                className={
                  'padding-8-16 border-radius-6 flex-row space-between center-y hover ' +
                  (region.includes(el) ? ' bg-primary-100 border-1-1E9B6B' : '')
                }
                style={{ width: '160px', height: '48px' }}
                onClick={() => {
                  if (region.includes(el)) {
                    const idx = region.indexOf(el);
                    setRegion((prev: any) => {
                      prev.splice(idx, 1);
                      return [...prev];
                    });
                  } else {
                    setRegion((prev: any) => [...prev, el]);
                  }
                }}
              >
                <div
                  className={
                    'f-size-16 line-h-24 ' +
                    (region.includes(el)
                      ? 'color-primary-500 font-bold'
                      : 'color-text-primary font-medium')
                  }
                >
                  {el}
                </div>
                {region.includes(el) ? (
                  <img
                    src="/asset/images/check/Check24Green2.svg"
                    style={{ width: '24px', height: '24px' }}
                    alt=""
                  />
                ) : (
                  ''
                )}
              </div>
            ))}
          </div>
          <div className="padding-8-0-16 flex-row gap-8">
            <div
              className="border-radius-8 padding-8-12 gap-4 flex-row center-x center-y hover"
              style={{ flexGrow: 1 }}
              onClick={() => setRegion([])}
            >
              <img
                src="/asset/images/change/Refresh24Green.svg"
                style={{ height: '24px', width: '24px' }}
                alt=""
              />
              <div className="font-medium f-size-16 line-h-24 color-primary-500">
                초기화
              </div>
            </div>
            <div
              className="border-radius-8 gap-10 flex-row padding-15-46 bg-primary hover"
              onClick={() => setOpen(false)}
            >
              <div className="font-medium f-size-16 line-h-24 color-FFFFFF">
                결과보기 {totalCount}건
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FilterModal3 = ({ open, setOpen, modalAddress, saleIdx }: any) => {
  const [tab, setTab] = useState(1);
  const [spaceInfo, setSpaceInfo] = useState<any>({
    category: '',
    area: 0,
    address: '',
    officialPriceList: {},
    zoning: '',
  });
  const [spaceUseStatus, setSpaceUseStatus] = useState<any>([]);
  const getSpaceInfo = async () => {
    try {
      if (saleIdx) {
        const res = await AuctionService.spaceInfo(saleIdx);
        setSpaceInfo(res.info);
        setSpaceUseStatus(res.useStatus || []);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getSpaceInfo();
    setTab(1);
  }, [saleIdx]);

  if (!open) {
    return <></>;
  }
  return (
    <div
      className={
        isMobile(window.innerWidth) ? 'fixed end-y' : 'fixed center-y center-x'
      }
      style={{
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: '#00000025',
        zIndex: 30,
      }}
    >
      <div
        className={
          isMobile(window.innerWidth)
            ? 'bg-FFFFFF flex-column border-top-radius-16 w-100per '
            : 'bg-FFFFFF flex-column border-radius-16 '
        }
        style={
          isMobile(window.innerWidth)
            ? { height: window.innerHeight - 54 + 'px' }
            : { width: '688px', maxHeight: '80%' }
        }
      >
        <div className="padding-24-16">
          <div className="flex-row space-between">
            <div className="font-bold f-size-16 line-h-24 color-text-primary">
              토지 이용 현황
            </div>
            <img
              src="/asset/images/close/CloseBlack24.svg"
              alt=""
              className="hover"
              onClick={() => setOpen(false)}
            />
          </div>
        </div>
        <div className=" overflow-y-auto">
          <div className="padding-x-16 flex-column gap-4">
            {!spaceUseStatus.length
              ? [{ title: spaceInfo.zoning, isContain: true }].map(
                  (el, idx) => (
                    <div className="flex-row space-between" key={idx}>
                      <div className="mobile-body-1-medium color-text-primary">
                        {el.title}
                      </div>
                      <div className="mobile-body-1-medium color-text-primary">
                        {el.isContain ? '포함' : '저촉'}
                      </div>
                    </div>
                  ),
                )
              : spaceUseStatus.map((el: any, idx: any) => (
                  <div className="flex-row space-between" key={idx}>
                    <div
                      className="mobile-body-1-medium color-text-primary w-100per"
                      onMouseEnter={(
                        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
                      ) => {
                        if (isMobile(window.innerWidth)) {
                          return;
                        }
                        const target = e.target as HTMLDivElement;
                        // target.style.textOverflow = 'clip';
                        target.style.overflow = 'auto';
                        target.style.whiteSpace = 'wrap';
                      }}
                      onMouseLeave={(
                        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
                      ) => {
                        if (isMobile(window.innerWidth)) {
                          return;
                        }
                        const target = e.target as HTMLDivElement;
                        target.style.overflow = 'hidden';
                        target.style.whiteSpace = 'nowrap';
                        target.style.textOverflow = 'ellipsis';
                      }}
                      onTouchStart={(e: React.TouchEvent<HTMLDivElement>) => {
                        if (!isMobile(window.innerWidth)) {
                          return;
                        }
                        const target = e.target as HTMLDivElement;
                        // target.style.textOverflow = 'clip';
                        target.style.overflow = 'auto';
                        target.style.whiteSpace = 'wrap';
                      }}
                      onTouchEnd={(e: React.TouchEvent<HTMLDivElement>) => {
                        if (!isMobile(window.innerWidth)) {
                          return;
                        }
                        const target = e.target as HTMLDivElement;
                        target.style.overflow = 'hidden';
                        target.style.whiteSpace = 'nowrap';
                        target.style.textOverflow = 'ellipsis';
                      }}
                      style={{
                        width: '100%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {el.useStatus} {el.info ? `(${el.info})` : ''}
                    </div>
                    <div
                      className="mobile-body-1-medium color-text-primary"
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {el.includeInfo}
                    </div>
                  </div>
                ))}
          </div>
          <div className="padding-24-16 flex-column gap-16">
            <div className="border-radius-8 border-1-f0f0f0 flex-row bg-F0F0F0">
              {[
                { title: '지도', tab: 1 },
                { title: '공간정보', tab: 2 },
              ].map((el, key) => {
                return (
                  <div
                    key={key}
                    className={
                      tab === el.tab
                        ? 'flex-1 border-radius-8 border-1-171e2610 bg-FFFFFF padding-y-6 center-x flex-row pointer'
                        : 'flex-1 padding-y-6 center-x flex-row pointer'
                    }
                    onClick={() => setTab(el.tab)}
                  >
                    <div
                      className={
                        tab === el.tab
                          ? 'mobile-body-1-medium'
                          : 'mobile-body-1-medium color-disabled'
                      }
                    >
                      {el.title}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex-column">
              <div className="padding-10 bg-blue-gray-700">
                <div className="mobile-body-2-medium color-FFFFFF">
                  {modalAddress}
                </div>
              </div>
              <div style={{ width: '100%', height: '400px' }}>
                <MapContainer
                  modalAddress={modalAddress}
                  tab={tab}
                  spaceUseStatus={spaceUseStatus}
                  spaceInfo={spaceInfo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
