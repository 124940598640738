import { useState } from 'react';
import { ButtonSelectorBox } from './button/Button';

import { useLocation } from 'react-router-dom';
import MapContainer from './map/MapContainer';

const Main3 = ({ updateDate, dataCount }: any) => {
  const [tab, setTab] = useState(1);
  const now = new Date();

  const btnArr = [
    { children: '전국 농지 데이터', selected: 1 },
    '',
    { children: '전국 전원주택 데이터', selected: 2 },
  ];

  const [currentMarker, setCurrentMarker]: any = useState();
  const [fixMarker, setFixMarker]: any = useState(); //function
  const [panTo, setPanTo]: any = useState(); //function
  const [markerArray, setMarkerArray]: any = useState([]);
  const [areaList, setAreaList]: any = useState([]);
  const [panToAndMarker, setPanToAndMarker]: any = useState(); //function
  const locationHook = useLocation();
  const [isSearch, setIsSearch] = useState(true);
  return (
    <div className="flex-column bg-FFFFFF w-100per browser-center-y">
      <div className="browser-flex-column browser-max-w-1320 w-100per tablet-padding-32-40 browser-padding-80-60-0-60  padding-32-16 browser-gap-32">
        <div className="flex-column browser-flex-row browser-space-between gap-16">
          <div className="font-bold f-size-20 line-h-29 color-text-primary browser-f-size-32 browser-line-h-46">
            농촌생활을 위한 AI 가이드, <br />
            전국{' '}
            <span className="color-primary-500">
              {Number(dataCount).toLocaleString()}
            </span>
            건 누적 데이터로 제공합니다.
          </div>
          <div className="font-medium f-size-12 line-h-14 color-text-secondary end-y browser-f-size-14 browser-line-h-20">
            최근 업데이트 : {now.getFullYear()}-{now.getMonth() + 1}-
            {now.getDate().toString().length === 1
              ? '0' + now.getDate()
              : now.getDate()}
          </div>
        </div>

        <div className="flex-row center-x w-100per  mt-16 browser-mt-0">
          <ButtonSelectorBox tab={tab} setTab={setTab} btnArr={btnArr} />
        </div>
        <div
          className="w-100per padding-y-16 browser-padding-0   flex-row center-x of-hidden "
          style={{ height: '420px' }}
        >
          {tab === 1 ? (
            <MapContainer
              location="/aiFarming/map"
              setCurrentMarker={setCurrentMarker}
              setFixMarker={setFixMarker}
              setMarkerArray={setMarkerArray}
              setAreaList={setAreaList}
              setPanTo={setPanTo}
              setPanToAndMarker={setPanToAndMarker}
              setIsSearch={setIsSearch}
              hash={locationHook.hash}
            />
          ) : (
            ''
          )}
          {tab === 2 ? (
            <MapContainer
              location="/house/map"
              setCurrentMarker={setCurrentMarker}
              setFixMarker={setFixMarker}
              setMarkerArray={setMarkerArray}
              setAreaList={setAreaList}
              setPanTo={setPanTo}
              setPanToAndMarker={setPanToAndMarker}
              setIsSearch={setIsSearch}
              hash={locationHook.hash}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default Main3;
