import { styled } from 'styled-components';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  gap: 50px;
  width: 100%;
  white-space: normal;
`;
export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: start;
`;

export const CloseWrapper = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;
  width: 30%;
`;
