import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FacebookCode } from '../../store/codeSlice';
import Spinner from '../../component/browser/Spinner';
import { LoginId } from '../../store/user2Slice';
import UserService from '../../service/checkAccount/CheckAccount';
import GetActService from '../../service/act/Act';
import { GetUserInfo, useAuth } from '../../utilities/auth';
import { SignUpType } from '../../store/signUpInfoSlice';

const FacebookRedirect = () => {
  const { onLogin } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const facebookCode = useSelector((state: any) => {
    {
      return state.code.facebookCode;
    }
  });
  const redirectUrl = useSelector((state: any) => {
    {
      return state.code.facebookUrl;
    }
  });

  const FacebookGetCode = () => {
    const code = new URLSearchParams(window.location.search).get('code')!;
    dispatch(FacebookCode(code));
  };

  const FacebookGetAct = async () => {
    const provider = 'facebook';
    const data = {
      redirectUrl: sessionStorage.getItem('url'),
    };

    try {
      const result = await GetActService.getAct(
        facebookCode,
        provider,
        data as any,
      );
      if (result.data.accessToken === undefined) {
        dispatch(SignUpType('F'));
        navigate('/signUpSNS');
        sessionStorage.setItem('email', result.data.email);
        sessionStorage.setItem('name', result.data.name);
        sessionStorage.setItem('phone', result.data.phoneNumber);
        sessionStorage.setItem('socialId', result.data.socialId);
      } else {
        onLogin(result.data.accessToken);
        GetUserInfo();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const SignUpCheck = async () => {
    const accessToken = localStorage.getItem('accessToken');
    try {
      const result = await UserService.checkAccount();
      if (result.data.newUser) {
        navigate('/SignpSNS');
      } else {
        localStorage.setItem('isLoggined', 'login');
        navigate('/');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FacebookGetCode();
  }, []);

  useEffect(() => {
    if (facebookCode) {
      FacebookGetAct();
    }
  }, [facebookCode]);

  return (
    <div>
      <Spinner />
    </div>
  );
};

export default FacebookRedirect;
