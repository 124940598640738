const Tab2 = ({ top5FacilityCrop, top5FieldCrop }: any) => {
  return (
    <div className="flex-row gap-8 of-x-auto padding-y-16 browser-gap-16 browser-padding-0 none tablet-flex ">
      <div
        className="flex-row border-radius-8 border-1-171E2610 padding-16 gap-16 no-wrap"
        style={{ width: '45%' }}
      >
        <div className="flex-column space-between center-y">
          <div className="font-bold f-size-14 browser-f-size-16 line-h-20 browser-line-h-26 color-text-primary">
            노지작물
          </div>
          <img src="/asset/images/main/fieldcrop.svg" alt="" />
        </div>
        <div className="flex-column gap-4 ">
          {top5FieldCrop.map((el: any, i: number) => (
            <div className="flex-row gap-8">
              <div className="font-medium f-size-14 browser-f-size-16 line-h-20 browser-line-h-26 color-text-primary">
                {i + 1}
              </div>
              <div className="font-medium f-size-14 browser-f-size-16 lien-h-20 browser-line-h-26 color-text-secondary">
                {el.region} / {el.cropName}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className="flex-row border-radius-8 border-1-171E2610 padding-16 gap-16 browser-gap-24  no-wrap"
        style={{ width: '45%' }}
      >
        <div className="flex-column space-between center-y">
          <div className="font-bold f-size-14 browser-f-size-16 line-h-20 browser-line-h-26 color-text-primary">
            시설작물
          </div>
          <img src="/asset/images/main/facilitycrop.svg" alt="" />
        </div>
        <div className="flex-column gap-4 ">
          {top5FacilityCrop.map((el: any, i: number) => (
            <div className="flex-row gap-8">
              <div className="font-medium f-size-14 browser-f-size-16 line-h-20 browser-line-h-26 color-text-primary">
                {i + 1}
              </div>
              <div className="font-medium f-size-14 browser-f-size-16 lien-h-20 browser-line-h-26 color-text-secondary">
                {el.region} / {el.cropName}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Tab2;
