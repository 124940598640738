import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NaverCode } from '../../store/codeSlice';
import Spinner from '../../component/browser/Spinner';
import { useNavigate } from 'react-router-dom';
import { LoginId, UserName } from '../../store/user2Slice';
import GetActService from '../../service/act/Act';
import { useAuth, GetUserInfo } from '../../utilities/auth';
import { SignUpType } from '../../store/signUpInfoSlice';

interface BodyType {
  redirectUrl: string;
}
const NaverRedirect = () => {
  const { onLogin } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const code = useSelector((state: any) => {
    return state.code.naverCode;
  });
  const redirectUrl = useSelector((state: any) => {
    return state.code.naverUrl;
  });

  const NaverGetCode = () => {
    const code = new URLSearchParams(window.location.search).get('code')!;
    dispatch(NaverCode(code));
  };

  const NaverGetAct = async () => {
    const provider = 'naver';

    const data = { redirectUrl: sessionStorage.getItem('url') };
    try {
      const result = await GetActService.getNaverAct(
        code,
        provider,
        data as any,
      );
      sessionStorage.setItem('email', result.data.email);
      sessionStorage.setItem('name', result.data.name);
      sessionStorage.setItem('phone', result.data.phoneNumber);
      dispatch(LoginId(result.data.email));
      if (result.data.accessToken === undefined) {
        dispatch(SignUpType('N'));
        navigate('/signUpSNS');
        sessionStorage.setItem('email', result.data.email);
        sessionStorage.setItem('name', result.data.name);
        sessionStorage.setItem('phone', result.data.phoneNumber);
      } else {
        onLogin(result.data.accessToken);
        GetUserInfo();
      }
    } catch (error) {
      navigate('/');
      console.log(error);
    }
  };

  useEffect(() => {
    NaverGetCode();
  }, []);

  useEffect(() => {
    if (code && code !== '') {
      NaverGetAct();
    }
  }, [code]);

  return (
    <div>
      <Spinner />
    </div>
  );
};

export default NaverRedirect;
