import { useState } from 'react';
import { IReport_desc } from '../Main';
import { RowTable3 } from '../../../component/report/Row';
import { TriangleTooltip } from '../../../component/common/tooltip';
import { isMobile } from '../../../component/function/function';

const ReportInfo = ({ data, mypage }: { data: IReport_desc; mypage?: any }) => {
  const [areaType, setAreaType] = useState('P');
  const [dateTooltip, setDateTooltip] = useState(true);
  const facilityCode = {
    O: '노지',
    P: '비닐하우스',
    S: '스마트팜',
    W: '기후맞춤스마트팜',
  };

  return (
    <div className="padding-24-16 gap-16 flex-column relative">
      <RowTable3
        title="분석일"
        value={
          data.createdAt &&
          data.createdAt.split('T')[0] +
            ' ' +
            data.createdAt.split('T')[1].split('.')[0]
        }
      />
      <RowTable3 title="주소" value={data.address} />
      <div className="flex-row gap-24">
        <RowTable3
          title="주택 면적"
          value={
            <div className="font-medium f-size-16 line-h-24 color-text-secondary flex-row gap-3">
              {data.totalFloorArea &&
                (
                  Math.round(
                    data.totalFloorArea * (areaType === 'P' ? 0.3025 : 1) * 10,
                  ) / 10
                ).toLocaleString()}
              {areaType === 'P' ? '평' : 'm²'}
              {!mypage && (
                <div
                  className="hover bg-FFFFFF padding-2-8 cneter-x center-y flex-row border-radius-100 border-1-e2e2e2 font-medium f-size-12 line-h-20 color-text-tertiary"
                  onClick={() =>
                    setAreaType((prev) => (prev !== 'P' ? 'P' : 'M'))
                  }
                >
                  <img src="/asset/images/arrow/Refresh20.svg" alt="" />
                  {areaType === 'P' ? 'm²' : '평'}
                </div>
              )}
            </div>
          }
        />
      </div>
      <RowTable3
        title="특징"
        value={
          data && data.year_int
            ? `${data.year_int}년 (노후 ${
                Number(new Date().getFullYear()) - Number(data.year_int) || '26'
              }년 된 노후주택)`
            : '건물 정보가 없습니다.'
        }
      />
      {mypage && dateTooltip && (
        <TriangleTooltip
          text="분석 시점의 결과는 현재와 다를 수 있어요."
          text2="최신 분석 결과는 주소지 조회 후 다시 이용해 주세요."
          top="30"
          left="50"
          on={true}
          off={() => setDateTooltip(false)}
          xImg={true}
        />
      )}
    </div>
  );
};

export default ReportInfo;
