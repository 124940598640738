import { useEffect, useState } from 'react';
import { isMobile, transNum } from '../../../../component/function/function';
import { LineChart, Tooltip, XAxis, YAxis, Line, PolarGrid } from 'recharts';
import { RowTableValueBold } from '../../../../component/report/Row';
import { Tooltip2 } from '../../../../component/common/tooltip';
import UserService from '../../../../service/checkAccount/CheckAccount';
import { useSelector } from 'react-redux';
import { ButtonSelect1M } from '../../../../component/report/Button';
import SignController from '../../../../controller/sign/SignController';
// import { ITotalData } from "../../Main";

interface data {
  estimatedPrice: number;
  height: string;
  landTradeDate: null | string;
  landTradePrice: null | number;
  officialPriceList: { 2023: number } | any;
  perMeterEstimatedPrice: number;
  perPyeongEstimatedPrice: number;
  roadSurface: string;
  shape: string;
  useStatus: string;
  difference?: number;
  surroundings: Array<any>;
}

const ReportDetail1 = ({ data }: { data: any }) => {
  const state = useSelector((state: any) => {
    return state.type;
  });
  const [name, setName] = useState('');

  useEffect(() => {
    SignController.getName(setName);
  }, []);
  const [onToolTip, setOnToolTip] = useState(false);
  const [tab2, setTab2] = useState(1);

  return (
    <div className="padding-b-24 browser-bg-FFFFFF relative">
      <div className="flex-column">
        <div className="padding-16-16-0 flex-row ">
          <div className="font-bold f-size-16 line-h-24 color-text-secondary">
            주변 거래사례
          </div>
          <img
            src="/asset/images/warn/Warn24.svg"
            className="hover"
            onClick={() => setOnToolTip(true)}
            alt=""
          />
        </div>
        <Tooltip2
          bottom="-44"
          left="10"
          on={onToolTip}
          off={() => setOnToolTip(false)}
          text="더 많은 주변 거래사례는 고객센터에 문의 바랍니다."
        />
        <div className="padding-8-16 flex-row gap-8">
          <ButtonSelect1M
            onClick={() => {
              setTab2(1);
            }}
            selected={tab2 === 1}
          >
            거래이력
          </ButtonSelect1M>
          <ButtonSelect1M
            onClick={() => {
              console.log('awlekfjawekf');
              setTab2(2);
            }}
            selected={tab2 === 2}
          >
            주변 거래 사례
          </ButtonSelect1M>
        </div>
        {tab2 === 1 ? (
          <div className="padding-16">
            {(data && data.tradePrice === null) ||
            (data && data.tradePrice === null) ? (
              <RowTableValueBold
                title="거래이력"
                value="최근 5년 내 거래 이력이 없습니다"
              />
            ) : (
              <RowTableValueBold
                title="거래이력"
                value={
                  <div className="flex-column end-y">
                    <div className="font-medium f-size-16 line-h-24 color-secondary">
                      {data.isFarm === 'Y'
                        ? transNum(data && data.tradePrice)
                        : transNum(data && data.tradePrice)}
                      원
                    </div>
                    <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                      {data.isFarm === 'Y'
                        ? Math.round(
                            data && data.tradePrice / (data.area * 0.3025),
                          ).toLocaleString()
                        : Math.round(
                            data && data.tradePrice / (data.area * 0.3025),
                          ).toLocaleString()}
                      원/평
                    </div>
                    <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                      {data.isFarm === 'Y'
                        ? data &&
                          data.landTradeDate &&
                          `${data && data.landTradeDate.slice(0, 4)}-${
                            data && data.landTradeDate.slice(4, 6)
                          }-${data && data.landTradeDate.slice(6, 8)}`
                        : data &&
                          data.houseTradeDate &&
                          `${data && data.houseTradeDate.slice(0, 4)}-${
                            data && data.houseTradeDate.slice(4, 6)
                          }-${data && data.houseTradeDate.slice(6, 8)}`}
                    </div>
                  </div>
                }
              />
            )}
          </div>
        ) : tab2 === 2 ? (
          <div className="padding-16">
            {data.surrounding.length === 0 ? (
              <RowTableValueBold
                title="주변 거래 사례"
                value="주변 거래 사례가 없습니다."
              />
            ) : (
              <div className="padding-16 flex-column gap-16">
                {data &&
                  data.surrounding &&
                  data &&
                  data.surrounding.length > 0 &&
                  data &&
                  data.surrounding.map((el: any) => {
                    let arr = el.address.split(' ');
                    let num = arr.pop();
                    let num2 = '';
                    for (let i = 1; i < num.length; i++) {
                      num2 = num2 + '*';
                    }
                    const address =
                      arr.join(' ') + ' ' + num.slice(0, 1) + num2;
                    return (
                      <div className="flex-row space-between">
                        <div className="font-medium f-size-16 line-h-24 color-text-tertiary">
                          반경 <br /> {Math.round(el.distance)}m
                        </div>
                        <div className="flex-column end-y">
                          <div className="font-medium f-size-16 line-h-24 color-secondary">
                            {address}
                          </div>
                          <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                            {data.isFarm === 'Y'
                              ? Math.round(el.price_per_pyeong).toLocaleString()
                              : Math.round(
                                  el.perPyeongHouseTradePrice,
                                ).toLocaleString()}
                            원/평
                          </div>
                          <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                            {data.isFarm === 'Y'
                              ? el.landTradeDate.slice(0, 4) + '-'
                              : el.houseTradeDate.slice(0, 4) + '-'}
                            {data.isFarm === 'Y'
                              ? el.landTradeDate.slice(4, 6) + '-'
                              : el.houseTradeDate.slice(4, 6) + '-'}
                            {data.isFarm === 'Y'
                              ? el.landTradeDate.slice(6, 8) + ''
                              : el.houseTradeDate.slice(6, 8) + ''}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ReportDetail1;
