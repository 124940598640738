import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import '../../asset/styles/SaleInfo.css';
import FooterBrowser from '../../component/browser/Footer';
import { DefaultImg } from '../../asset/styles/mobile/mypage/Mypage';
import {
  TopNav2,
  TopNavNew,
  TopNavNewBrowser,
  Topnav,
} from '../../component/mobile/Topnav';
import Topnav2 from '../../component/browser/Topnav';
import { useLocation, useNavigate } from 'react-router-dom';
import AuctionList from './auction/AuctionList';
import InquiryList from './inquiry/InquiryList';
import ReportList from './report/ReportList';
import History from './history/History';
import Arrow from '../../asset/images/arrow/arrowRight.svg';

import '../../Main.css';
import { useAuth, GetUserInfo } from '../../utilities/auth';
import MobileFooter from '../../component/AIIntroduction/Footer';
import UserService from '../../service/checkAccount/CheckAccount';
import MypageSerivce from '../../service/mypage/mypage';
import { Calendar } from './calendar/Calendar';
import PointHistory from './history/PointHistory';
import { ReportDetail } from './report/ReportDetail';
import { isMobile } from '../../component/function/function';
import { MypageProfile } from './component/Profile';
import RefundPolicy from './refund/RefundPolicy';
import Notice from '../notice/Notice';
import FreePoint from './freePoint/FreePoint';
import Edit from './edit/Edit';

const Mypage = () => {
  const [refundCheck, setRefundCheck] = useState(false); // 환불 했을 때 api호출하기 위한 변수
  const [address, setAddress] = useState('');
  const location = useLocation();
  const [reportIdx, setReportIdx] = useState(0);
  const [type, setType] = useState('');
  const [auctionType, setAuctionType] = useState('');
  const [des, setDes] = useState('');
  const [onDetail2, setOnDetail2] = useState(false);
  const navigate = useNavigate();
  const { onLogout } = useAuth();
  const tab1 = useRef<HTMLDivElement>(null);
  const tab2 = useRef<HTMLDivElement>(null);
  const tab3 = useRef<HTMLDivElement>(null);
  const tab4 = useRef<HTMLDivElement>(null);
  const tab5 = useRef<HTMLDivElement>(null);
  const tab6 = useRef<HTMLDivElement>(null);
  const [onCalendar, setOnCalendar] = useState(false);
  const [tabWidth, setTabWidth] = useState(0);
  const [left, setLeft] = useState(0);
  const [menu, setMenu] = useState('tab6');
  const [point, setPoint] = useState(0);
  const [createdDate, setCreatedDate] = useState('');
  const [email, setEmail] = useState('');
  const [tab, setTab] = useState(
    sessionStorage.getItem('mypageTab') || 'point',
  );
  function changeTextType(item: string) {
    return reportType[item as keyof typeof reportType];
  }

  enum reportType {
    '매물 분석 - 지자체 매물' = 'S',
    '매물 분석 - 경매' = 'A',
    '매물 분석 - 공매' = 'P',
  }
  const date = new Date();
  const formattedStartDate = `${date.getFullYear()}-${String(
    date.getMonth() + 1,
  ).padStart(2, '0')}-01`;
  const formattedEndDate = `${date.getFullYear()}-${String(
    date.getMonth() + 1,
  ).padStart(2, '0')}-${new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
  ).getDate()}`;
  const [startDate, setStartDate] = useState(formattedStartDate);
  const [endDate, setEndDate] = useState(formattedEndDate);
  const mypageCategory = [
    { tabnumber: 'tab6', tabname: '즐겨찾기', tabref: tab6 },
    { tabnumber: 'tab5', tabname: '내게시물', tabref: tab5 },
    { tabnumber: 'tab3', tabname: '내보고서', tabref: tab3 },
    { tabnumber: 'tab1', tabname: '역경매', tabref: tab1 },
    // { tabnumber: "tab2", tabname: "문의내역", tabref: tab2 },
    // { tabnumber: "tab4", tabname: "히스토리", tabref: tab4 },
  ];

  const getInfo = async () => {
    try {
      const result = await MypageSerivce.getMyInfo();
      console.log(result);
      setPoint(result.data.data.freePoint + result.data.data.paidPoint);
      setEmail(result.data.data.email);
      setCreatedDate(result.data.data.createdDate);
    } catch (error) {
      console.log(error);
    }
  };
  useLayoutEffect(() => {
    const obj = {
      tab1: tab1 as any,
      tab2: tab2,
      tab3: tab3,
      tab4: tab4,
      tab5: tab5,
      tab6: tab6,
    };
    const tab = obj[menu as 'tab1'];
    if (tab.current) {
      setTabWidth(tab.current.offsetWidth);
      setLeft(tab.current.offsetLeft);
    }
  }, [menu]);

  useEffect(() => {
    getInfo();
  }, []);

  if (window.navigator.userAgent === 'ReactSnap') {
    return <></>;
  }
  return (
    <div className="flex-column tablet-center-x w-100per ">
      <TopNav2 text="마이페이지" hamburger back />
      <TopNavNewBrowser />
      {onDetail2 && (
        <ReportDetail
          open={onDetail2}
          setClose={setOnDetail2}
          reportIdx={reportIdx}
          type={type}
          address={address}
          des={des}
          setAuctionType={setAuctionType}
          auctionType={auctionType}
        />
      )}
      {onCalendar && (
        <Calendar
          onCalendar={onCalendar}
          setOnCalendar={setOnCalendar}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      )}
      <div className="flex-row w-100per w-1024-padding-80-60 w-1920-padding-80-0 browser-max-w-1320 w-1024-gap-40 browser-1920-gap-80 center-x  browser-1920-w-1200 ">
        <MypageProfile
          email={email}
          createdDate={createdDate}
          point={point}
          setTab={setTab}
          tab={tab}
          getInfo={getInfo}
          refundCheck={refundCheck}
        />
        {
          // sessionStorage.getItem('mypageTab') === 'point'
          tab === 'point' ? (
            <div
              className={`browser flex-column gap-24 ${
                window.innerWidth >= 1920 ? `w-1920-w-824` : `flex-1`
              }   `}
            >
              <div className="w-t1-32-b color-dark-primary">포인트 내역</div>
              {window.innerWidth >= 1024 && (
                <PointHistory
                  temp
                  setOnDetail2={setOnDetail2}
                  setReportIdx2={setReportIdx}
                  setType2={setType}
                  setOnCalendar={setOnCalendar}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  startDate={startDate}
                  endDate={endDate}
                  setAddress={setAddress}
                  setDes={setDes}
                  setAuctionType={setAuctionType}
                  auctionType={auctionType}
                  mypage
                  refundCheck={refundCheck}
                  setRefundCheck={setRefundCheck}
                />
              )}
            </div>
          ) : tab === 'freepoint' ? (
            <div
              className={`browser flex-column gap-24 ${
                window.innerWidth >= 1920 ? `w-1920-w-824` : `flex-1`
              }   `}
            >
              <div className="w-t1-32-b color-dark-primary">
                무료 포인트 내역
              </div>
              {window.innerWidth >= 1024 && (
                <FreePoint
                  des={des}
                  temp
                  setOnDetail2={setOnDetail2}
                  setReportIdx2={setReportIdx}
                  setType2={setType}
                  setOnCalendar={setOnCalendar}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  startDate={startDate}
                  endDate={endDate}
                  setAddress={setAddress}
                  setDes={setDes}
                  setAuctionType={setAuctionType}
                  auctionType={auctionType}
                />
              )}
            </div>
          ) : tab === 'edit' ? (
            <div
              className={`browser flex-column gap-24 ${
                window.innerWidth >= 1920 ? `w-1920-w-824` : `flex-1`
              }   `}
            >
              <div className="w-t1-32-b color-dark-primary">내 정보 수정</div>
              {window.innerWidth >= 1024 && <Edit />}
            </div>
          ) : (
            ''
          )
        }
      </div>
      <MobileFooter />
      <FooterBrowser />
    </div>
  );
};
export default Mypage;
