import Request from '../../@api/request';
const baseURL = `${process.env.REACT_APP_HOST}`;

export const request = new Request(baseURL);

interface testData {
  type: string;
  title: string;
  action: string;
}
export default class ABTest {
  static ABLog = async (data: testData) => {
    return await request.post('admin/log/landing', data);
  };
}
