import { useEffect, useState } from 'react';
import { isMobile, transNum } from '../../../../component/function/function';
import {
  RowSectionTitle,
  RowTableValueBold,
} from '../../../../component/report/Row';
import { Tooltip2 } from '../../../../component/common/tooltip';

import { useSelector } from 'react-redux';
import { ButtonSelect1M } from '../../../../component/report/Button';
import SignController from '../../../../controller/sign/SignController';
import { TextSectionTitle } from '../../../../component/report/Text';
import { ButtonSelectorBox } from '../../../../component/report/Button';
import { BoxTable3, BoxTableSelected } from '../../../../component/report/Box';
import './ReportDetail.css';

import {
  ScatterChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Scatter,
  Cell,
  Legend,
  ZAxis,
} from 'recharts';
import PriceService from '../../../../service/price/priceApi';
import { set } from 'lodash';
import Pagination from 'react-js-pagination';
import DistanceDistribution from '../../../aiFarming/map/saleRental2/DistanceDistribution';
import MapContainer from '../../../aiFarming/map/MapContainer';
import { useLocation, useNavigate } from 'react-router-dom';
import { _1 } from '../../../../asset/styles/mobile/aiFraming/AIContents';
import LandController from '../../../../controller/land/LandController';
import { ReportDetail } from '../../../mypage/report/ReportDetail';
// import { ITotalData } from "../../Main";

const ReportDetail6 = ({
  setKmTab,

  kmTab,
  address,
  realDeal,
  auction,
  pAuction,
  zMoelAuction,
  sales,
  zModelData,
  zModelGraph,
  estimatedPrice,
  mypage,
  divRef,
  des,
}: {
  setKmTab: any;

  kmTab: any;
  setDrawCircle?: any;
  address?: any;
  realDeal: any;
  auction: any;
  pAuction: any;
  zMoelAuction: any;
  sales: any;
  zModelData: any;
  zModelGraph: any;
  estimatedPrice: any;
  mypage?: boolean;
  divRef?: any;
  des?: string;
}) => {
  const locationHook = useLocation();
  const [drawCircle, setDrawCircle]: any = useState();
  const [mapClick, setMapClick] = useState(false);
  const [isSearch, setIsSearch] = useState(true);
  const [currentMarker, setCurrentMarker]: any = useState();
  const [areaList, setAreaList]: any = useState([]);
  const [fixMarker, setFixMarker]: any = useState(); //function
  const [zoomIn, setZoomIn]: any = useState(); //function
  const [zoomIn2, setZoomIn2]: any = useState(true); //function
  const [panTo, setPanTo]: any = useState(); //function
  const [markerArray, setMarkerArray]: any = useState([]);
  const [panToAndMarker, setPanToAndMarker]: any = useState(); //function
  const [stickPolygon, setStickPolygon]: any = useState(() => {});
  const [realCurrentPage, setRealCurrentPage] = useState<number>(1);
  const [salesCurrentPage, setSalesCurrentPage] = useState<number>(1);
  const [auctionCurrentPage, setAuctionCurrentPage] = useState<number>(1);
  const [pauctionCurrentPage, setPAuctionCurrentPage] = useState<number>(1);

  const state2 = useSelector((state2: any) => {
    return state2.type;
  });
  const [pyeong, setPyeong] = useState(true);
  const [pyeong2, setPyeong2] = useState(true);
  const [pyeong3, setPyeong3] = useState(true);

  const [hoverIndex, setHoverIndex] = useState(-1);

  const handlePageChange = (pageNumber: any) => {
    if (tab === 0) {
      setRealCurrentPage(pageNumber);
    } else if (tab === 1) {
      setSalesCurrentPage(pageNumber);
    } else if (tab === 2) {
      setAuctionCurrentPage(pageNumber);
    } else if (tab === 3) {
      setPAuctionCurrentPage(pageNumber);
    }
    // 페이지 변경에 따른 데이터 로딩 등의 작업을 수행할 수 있음
  };

  useEffect(() => {
    console.log(kmTab);
    if (kmTab === 1) {
      sessionStorage.setItem(
        'kmPrice',
        Math.round(estimatedPrice.list_1000[0].avgPrice).toLocaleString(),
      );
    } else if (kmTab === 2) {
      sessionStorage.setItem(
        'kmPrice',
        Math.round(estimatedPrice.list_2000[0].avgPrice).toLocaleString(),
      );
    } else if (kmTab == 3) {
      sessionStorage.setItem(
        'kmPrice',
        Math.round(estimatedPrice.list_3000[0].avgPrice).toLocaleString(),
      );
    }
  }, [kmTab]);
  const btnArr = [
    { children: '거리 기반 분석', selected: 1 },
    '',
    { children: '시간·거리 기반 분석', selected: 2 },
  ];
  const cropBtnArr = [
    { children: `실거래 ${realDeal.length}`, selected: 0 },
    state2.priceType === 'land' || des === '가격분석 - 농지'
      ? { children: `농지은행 ${sales.length}`, selected: 1 }
      : null,
    { children: `경매 ${auction.length}`, selected: 2 },
    { children: `공매 ${pAuction.length}`, selected: 3 },
  ];
  const SurroundingTitle = ({ title, num }: any) => (
    <div className="padding-8-16 flex start-x w-100per">
      <div
        className="flex-center border-radius-8 padding-4-8 gap-10 flex-row border-1E9B6B"
        style={{ width: 'fit-content' }}
      >
        <div className="m-b1-16-b color-primary-500 no-wrap">{title}</div>
        <div className="m-b1-16-b color-primary-500 no-wrap">{num}</div>
      </div>
    </div>
  );
  const table2 = {
    th: ['거리', '1년 이내', '2년 이내'],
    tb: [
      [
        '1km 이내',
        zModelData.estimatedPrice.distanceYearUnder1
          ? pyeong
            ? Math.round(
                zModelData.estimatedPrice.distanceYearUnder1,
              ).toLocaleString() + '원/평'
            : Math.round(
                zModelData.estimatedPrice.distanceYearUnder1 / 3.3058,
              ).toLocaleString() + '원/㎡'
          : '사례 없음',
        zModelData.estimatedPrice.distanceUnder1YearOver1
          ? pyeong
            ? Math.round(
                zModelData.estimatedPrice.distanceUnder1YearOver1,
              ).toLocaleString() + '원/평'
            : Math.round(
                zModelData.estimatedPrice.distanceUnder1YearOver1 / 3.3058,
              ).toLocaleString() + '원/㎡'
          : '사례 없음',
      ],
      [
        '2km 이내',
        zModelData.estimatedPrice.distanceOver1YearUnder1
          ? pyeong
            ? Math.round(
                zModelData.estimatedPrice.distanceOver1YearUnder1,
              ).toLocaleString() + '원/평'
            : Math.round(
                zModelData.estimatedPrice.distanceOver1YearUnder1 / 3.3058,
              ).toLocaleString() + '원/㎡'
          : '사례 없음',
        zModelData.estimatedPrice.distanceOver1YearOver1
          ? pyeong
            ? Math.round(
                zModelData.estimatedPrice.distanceOver1YearOver1,
              ).toLocaleString() + '원/평'
            : Math.round(
                zModelData.estimatedPrice.distanceOver1YearOver1 / 3.3058,
              ).toLocaleString() + '원/㎡'
          : '사례 없음',
      ],
    ],
  };

  const state = useSelector((state: any) => {
    return state.type;
  });
  const [name, setName] = useState('');

  useEffect(() => {
    SignController.getName(setName);
  }, []);

  useEffect(() => {
    if (isMobile(window.innerWidth)) {
      LandController.coordinate(panToAndMarker, drawCircle, address, isSearch);
    }
  }, [drawCircle]);

  //url을 변경해서 언마운트 했을 때
  useEffect(() => {
    sessionStorage.setItem('kmTab', '1');
    sessionStorage.setItem(
      'kmPrice',
      Math.round(estimatedPrice.list_1000[0].avgPrice).toLocaleString(),
    );
    sessionStorage.setItem('priceReport', 'open');

    const handleBeforeUnload = () => {
      sessionStorage.removeItem('kmTab');
      sessionStorage.removeItem('kmPrice');
      sessionStorage.removeItem('priceReport');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // 컴포넌트 언마운트 되었을때 remove
  useEffect(() => {
    // 컴포넌트가 마운트될 때 실행되는 부분

    // 클린업 함수
    return () => {
      // 컴포넌트가 언마운트될 때 실행되는 부분
      sessionStorage.removeItem('kmTab');
      sessionStorage.removeItem('kmPrice');
      sessionStorage.removeItem('priceReport');
    };
  }, []);
  // 나머지 컴포넌트 로직

  const [onToolTip, setOnToolTip] = useState(false);
  const [tab2, setTab2] = useState(1);
  const [cropTab, setCropTab] = useState(1);
  const [tab, setTab] = useState(0);

  const Title = ({ title }: any) => (
    <div className="padding-8-16 flex-center">
      <div
        className="flex-center border-radius-8 padding-4-8 gap-10 flex-row border-1E9B6B"
        style={{ width: '164px' }}
      >
        <div className="m-b1-16-b color-primary-500 no-wrap">{title}</div>
      </div>
    </div>
  );

  return (
    <div className="padding-b-24 browser-bg-FFFFFF">
      <div className="flex-column">
        <RowSectionTitle>
          <TextSectionTitle>주변 가격 분석</TextSectionTitle>
        </RowSectionTitle>

        {/* {isMobile(window.innerWidth) && (
          <div className='w-100per padding-16'>
            <MapContainer
              priceReport
              setStickPolygon={setStickPolygon}
              location={
                window.location.pathname.slice(-1) === '/'
                  ? window.location.pathname.slice(0, -1)
                  : window.location.pathname
              }
              setCurrentMarker={setCurrentMarker}
              setFixMarker={setFixMarker}
              setZoomIn={setZoomIn}
              setZoomIn2={setZoomIn2}
              setMarkerArray={setMarkerArray}
              setAreaList={setAreaList}
              setPanTo={setPanTo}
              setPanToAndMarker={setPanToAndMarker}
              setIsSearch={setIsSearch}
              hash={locationHook.hash}
              setDrawCircle={setDrawCircle}
              // setOnInputFocus={setOnInputFocus}
            />
          </div>
        )} */}

        <ButtonSelectorBox tab={tab2} setTab={setTab2} btnArr={btnArr} />

        <div ref={divRef}>
          {tab2 === 1 ? (
            <>
              {/* <DistanceDistribution /> */}
              <div className="tota w-100per">
                <div className="title" style={{ fontSize: '14px' }}>
                  최근 5년 평균 실거래가
                </div>
                <div
                  onClick={() => {
                    setPyeong3(!pyeong3);
                  }}
                  className="hover bg-FFFFFF padding-2-8 cneter-x center-y flex-row border-radius-100 border-1-e2e2e2 font-medium f-size-12 line-h-20 color-text-tertiary"
                >
                  <img src="/asset/images/arrow/Refresh20.svg" />
                  <div>{pyeong3 ? '㎡' : '평'}</div>
                </div>
              </div>
              <div className="padding-16">
                <div className="flex-column w-100per border-t-171E26 border-b-171E26">
                  <div className="flex-row w-100per  bg-F7F8F9 border-b-171E26">
                    <div className="flex-row w-50per flex-center padding-8-16">
                      <div className="m-b2-14-m color-text-tertiary">거리</div>
                    </div>
                    <div className="flex-row w-50per flex-center padding-8-16">
                      <div className="m-b2-14-m color-text-tertiary">
                        추정가
                      </div>
                    </div>
                  </div>
                  {['1km 이내', '2km 이내', '3km 이내'].map(
                    (item, index: number) => {
                      return (
                        <div
                          className={`flex-row w-100per pointer  ${
                            index !== 2 ? `border-b-171E26` : ``
                          }`}
                          onClick={() => {
                            setKmTab(index + 1);
                            sessionStorage.setItem(
                              'kmTab',
                              (index + 1).toString(),
                            );
                            sessionStorage.setItem(
                              'kmPrice',
                              index === 0
                                ? Math.round(
                                    estimatedPrice.list_1000[0].avgPrice,
                                  ).toLocaleString()
                                : index === 1
                                  ? Math.round(
                                      estimatedPrice.list_2000[0].avgPrice,
                                    ).toLocaleString()
                                  : Math.round(
                                      estimatedPrice.list_3000[0].avgPrice,
                                    ).toLocaleString(),
                            );
                          }}
                        >
                          <div className="flex-row w-50per flex-center padding-8-16">
                            <div>{item}</div>
                          </div>
                          <div className="flex-row w-50per flex-center padding-8-16">
                            <div className="w-100per text-end">
                              {index === 0
                                ? estimatedPrice.list_1000[0].avgPrice
                                  ? Math.round(
                                      pyeong3
                                        ? estimatedPrice.list_1000[0].avgPrice
                                        : estimatedPrice.list_1000[0].avgPrice /
                                            3.3058,
                                    ).toLocaleString() +
                                    `원/${pyeong3 ? '평' : '㎡'}`
                                  : '추정가 없음'
                                : index === 1
                                  ? estimatedPrice.list_2000[0].avgPrice
                                    ? Math.round(
                                        pyeong3
                                          ? estimatedPrice.list_2000[0].avgPrice
                                          : estimatedPrice.list_2000[0]
                                              .avgPrice / 3.3058,
                                      ).toLocaleString() +
                                      `원/${pyeong3 ? '평' : '㎡'}`
                                    : '추정가 없음'
                                  : estimatedPrice.list_3000[0].avgPrice
                                    ? Math.round(
                                        pyeong3
                                          ? estimatedPrice.list_3000[0].avgPrice
                                          : estimatedPrice.list_3000[0]
                                              .avgPrice / 3.3058,
                                      ).toLocaleString() +
                                      `원/${pyeong3 ? '평' : '㎡'}`
                                    : '추정가 없음'}
                            </div>
                          </div>
                        </div>
                      );
                    },
                  )}
                </div>
              </div>
              <div className="frame-2609057"></div>
            </>
          ) : (
            <>
              <div className="tota">
                <div className="title">트랜스파머 Z 모델</div>
                <div className="flex-row gap-8">
                  {state2.priceType === 'land' && (
                    <div className="flex-row gap-2">
                      <div
                        className="w-8 h-8 border-radius-999"
                        style={{ backgroundColor: '#E2963E' }}
                      />
                      <div className="m-c2-11-m color-dark-secondary">
                        농지 은행
                      </div>
                    </div>
                  )}
                  <div className="flex-row gap-2">
                    <div
                      className="w-8 h-8 border-radius-999"
                      style={{ backgroundColor: '#1BADC2' }}
                    />
                    <div className="m-c2-11-m color-dark-secondary">경매</div>
                  </div>
                  <div className="flex-row gap-2">
                    <div
                      className="w-8 h-8 border-radius-999"
                      style={{ backgroundColor: '#EA5B54' }}
                    />
                    <div className="m-c2-11-m color-dark-secondary">공매</div>
                  </div>
                  <div className="flex-row gap-2">
                    <div
                      className="w-8 h-8 border-radius-999"
                      style={{ backgroundColor: '#47C633' }}
                    />
                    <div className="m-c2-11-m color-dark-secondary">
                      실거래가
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-2609241">
                <div className="mask-group">
                  {/* <ScatterChart
                  width={350}
                  height={350}
                  margin={{
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray='0' />
                  <XAxis dataKey='x' type='number' name='stature' />
                  <YAxis dataKey='y' type='number' name='weight' />
                  <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                  <Legend
                    verticalAlign='top'
                    height={30}
                    iconSize={7}
                    align='right'
                  />
                  <Scatter name='농지은행' data={data} fill='#E2963E' />
                  <Scatter name='경매' data={data} fill='#1BADC2' />
                  <Scatter name='공매' data={data} fill='#EA5B54' />
                  <Scatter name='실거래가' data={data} fill='##47C633' />
                </ScatterChart> */}

                  <div className="frame-2609226 relative">
                    <div className="frame-2609224">
                      <div className="frame-2609205">
                        <div className="text">근거리</div>
                        <div className="text2">1km</div>
                        <div className="text">원거리</div>
                      </div>
                    </div>
                    <div className="frame-2609204 ">
                      <div className="frame-2609203">
                        <div className="rectangle-2948"></div>
                        <div className="rectangle-2949"></div>
                      </div>
                      <div className="frame-2609237">
                        <div className="rectangle-2948"></div>
                        <div className="rectangle-2949"></div>
                      </div>
                    </div>
                    <>
                      {zModelGraph &&
                        zModelGraph.map((item: any, index: number) => {
                          const temp = 130 * (item.distance / 1000);
                          const year =
                            item.type === 'realDealPrice'
                              ? parseInt(item.landtradedate?.split('-')[0])
                              : item.type === 'auction'
                                ? parseInt(item.auctiondate?.split('-')[0])
                                : item.type === 'pauction'
                                  ? parseInt(item.pauctiondate?.split('-')[0])
                                  : parseInt(item.regTime?.split('-')[0]);

                          const month =
                            item.type === 'realDealPrice'
                              ? parseInt(item.landtradedate?.split('-')[1])
                              : item.type === 'auction'
                                ? parseInt(item.auctiondate?.split('-')[1])
                                : item.type === 'pauction'
                                  ? parseInt(item.pauctiondate?.split('-')[1])
                                  : parseInt(item.regTime?.split('-')[1]); // 월은 0부터 시작하므로 1을 빼줍니다.
                          const day =
                            item.type === 'realDealPrice'
                              ? parseInt(item.landtradedate?.split('-')[2])
                              : item.type === 'auction'
                                ? parseInt(item.auctiondate?.split('-')[2])
                                : item.type === 'pauction'
                                  ? parseInt(item.pauctiondate?.split('-')[2])
                                  : parseInt(item.regTime?.split('-')[2]);

                          const specificDate = new Date(year, month - 1, day);

                          const currentDate = new Date();

                          const timeDiff =
                            currentDate.getTime() - specificDate.getTime();
                          const daysDiff = timeDiff / (1000 * 3600 * 24);
                          const temp2 = 45 + 137 * (daysDiff / 365); //45는 초기 left값 137은 중간값으로 양쪽 =,- 비율로 계산

                          return (
                            <>
                              <div
                                className="absolute z-999"
                                onMouseEnter={() => {
                                  setHoverIndex(index);
                                }}
                                onMouseLeave={() => {
                                  setHoverIndex(-1);
                                }}
                                style={{
                                  left: temp2 + `px`,
                                  // left: "182px", //중앙값
                                  top: temp + `px`,
                                  width: '8px',
                                  height: '8px',
                                  borderRadius: '999px',
                                  backgroundColor:
                                    item.type === 'realDealPrice'
                                      ? '#47C633'
                                      : item.type === 'auction'
                                        ? '#1BADC2'
                                        : item.type === 'pauction'
                                          ? '#E2963E'
                                          : item.type === 'sales'
                                            ? '#E2963E'
                                            : '',
                                }}
                              />
                              {index == hoverIndex && (
                                <div
                                  className="absolute bg-primary-100 border-radius-8 padding-8 z-9999 border-171E26"
                                  style={{
                                    // left: `${temp2 + 20}px`,
                                    left:
                                      temp2 > 182
                                        ? `${temp2 - 160}px`
                                        : `${temp2 + 20}px`,
                                    top: `${temp}px`,
                                  }}
                                >
                                  <div className="m-b2-14-m no-wrap">
                                    평당가 :{' '}
                                    {Math.round(
                                      item.pyeongprice,
                                    ).toLocaleString()}
                                    원/평
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })}
                    </>
                  </div>
                  <div className="frame-2609225">
                    <div className="frame-2609206">
                      <div className="text">최근</div>
                      <div className="text2">1년</div>
                      <div className="text">과거</div>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
              <div className="tota w-100per">
                <div className="flex-row gap-10 space-between w-100per">
                  <div className="title" style={{ fontSize: '14px' }}>
                    거리당 {state2.priceType === 'land' ? '토지' : '주택'}단가
                  </div>
                  <div
                    onClick={() => {
                      setPyeong(!pyeong);
                    }}
                    className="hover bg-FFFFFF padding-2-8 cneter-x center-y flex-row border-radius-100 border-1-e2e2e2 font-medium f-size-12 line-h-20 color-text-tertiary"
                  >
                    <img src="/asset/images/arrow/Refresh20.svg" />
                    <div>{pyeong ? '㎡' : '평'}</div>
                  </div>
                </div>
              </div>
              <div className="frame-26083552" style={{ maxHeight: '168px' }}>
                <BoxTableSelected table={table2} />
              </div>
              <div className="frame-2609057"></div>
            </>
          )}
        </div>
        {/* <div className="flex-column" style={{ overflowX: 'scroll' }}> */}
        <div className="flex-row space-between w-100per center-y padding-x-16 relative">
          <div className=" flex-row">
            <div className="font-bold f-size-16 line-h-24 color-text-secondary ">
              주변 가격 사례
            </div>
            <img
              src="/asset/images/warn/Warn24.svg"
              className="hover"
              onClick={() => setOnToolTip(true)}
              alt=""
            />
          </div>
          <div
            onClick={() => {
              setPyeong2(!pyeong2);
            }}
            className="hover bg-FFFFFF padding-2-8 cneter-x center-y flex-row border-radius-100 border-1-e2e2e2 font-medium f-size-12 line-h-20 color-text-tertiary"
          >
            <img src="/asset/images/arrow/Refresh20.svg" />
            <div>{pyeong2 ? '㎡' : '평'}</div>
          </div>
          <Tooltip2
            bottom="-40"
            on={onToolTip}
            off={() => setOnToolTip(false)}
            text="더 많은 주변 거래 사례는 고객센터에 문의 바랍니다."
          />
        </div>

        {/* </div> */}
        {/* <div className="padding-8-16 flex-row gap-8" style={{ transform: `translateX(-${calculatePathWidth()}px)`, whiteSpace: "nowrap"}}>
          {renderButtons()}
        </div> */}
        <div
          className="padding-8-16-12 flex-row gap-8 of-x-auto w-100per"
          style={{ whiteSpace: 'nowrap' }}
        >
          {cropBtnArr.map(
            (el, index) =>
              el && ( // null이 아닌 경우에만 렌더링합니다.
                <ButtonSelect1M
                  key={index}
                  price
                  selected={tab === el.selected}
                  onClick={() => {
                    setTab(el.selected);
                    // console.log("ef", pAuction.length);
                  }}
                >
                  {el.children}
                </ButtonSelect1M>
              ),
          )}
        </div>

        <div className="padding-16">
          {/* {data.landTradePrice === null || data.houseTradePrice === null ? (
            <RowTableValueBold
              title="거래이력"
              value="최근 5년 내 거래 이력이 없습니다"
            />
          ) : ( */}
          {tab === 0 ? (
            realDeal.length > 0 ? (
              realDeal
                .slice(0 + (realCurrentPage - 1) * 3, realCurrentPage * 3)
                .map((item: any) => {
                  return (
                    <div className="flex-row w-100per space-between">
                      <div className="m-b1-16-m color-text-tertiary">
                        반경 {Math.round(item.distance)}m
                      </div>
                      <div className="flex-column end-x padding-b-24">
                        <div className="m-b1-16-m color-dark-secondary text-end">
                          {item.address}
                        </div>
                        <div className="w-b1-18-b color-text-tertiary text-end">
                          {transNum(Number(item.landtradeprice))}원
                        </div>
                        <div className="m-b2-14-m color-text-tertiary text-end">
                          {Math.round(
                            pyeong2
                              ? item.pyeongprice
                              : item.pyeongprice / 3.3058,
                          ).toLocaleString()}
                          원/{pyeong2 ? '평' : '㎡'}
                        </div>
                        <div className="m-b2-14-m color-text-tertiary text-end">
                          {item.landtradedate}
                        </div>
                      </div>
                    </div>
                  );
                })
            ) : (
              <div className="text-center color-dark-primary">
                거래 사례가 없습니다
              </div>
            )
          ) : tab === 1 ? (
            sales.length > 0 ? (
              sales
                .slice(0 + (salesCurrentPage - 1) * 3, salesCurrentPage * 3)
                .map((item: any) => {
                  return (
                    <div className="flex-row w-100per space-between">
                      <div className="m-b1-16-m color-text-tertiary">
                        반경 {Math.round(item.distance)}m
                      </div>
                      <div className="flex-column end-x padding-b-24">
                        <div className="m-b1-16-m color-dark-secondary text-end">
                          {item.address}
                        </div>
                        <div className="w-b1-18-b color-text-tertiary text-end">
                          {transNum(Number(item.price))}원
                        </div>
                        <div className="m-b2-14-m color-text-tertiary text-end">
                          {Math.round(
                            pyeong2
                              ? item.pyeongprice
                              : item.pyeongprice / 3.3058,
                          ).toLocaleString()}
                          원/{pyeong2 ? '평' : '㎡'}
                        </div>
                        <div className="m-b2-14-m color-text-tertiary text-end">
                          {item.regTime}
                        </div>
                      </div>
                    </div>
                  );
                })
            ) : (
              <div className="text-center color-dark-primary">
                거래 사례가 없습니다
              </div>
            )
          ) : tab === 2 ? (
            auction.length > 0 ? (
              auction
                .slice(0 + (auctionCurrentPage - 1) * 3, auctionCurrentPage * 3)
                .map((item: any) => {
                  return (
                    <div className="flex-row w-100per space-between">
                      <div className="m-b1-16-m color-text-tertiary">
                        반경 {Math.round(item.distance)}m
                      </div>
                      <div className="flex-column end-x padding-b-24">
                        <div className="m-b1-16-m color-dark-secondary text-end">
                          {item.address}
                        </div>
                        <div className="w-b1-18-b color-text-tertiary text-end">
                          {transNum(Number(item.auctionprice))}원
                        </div>
                        <div className="m-b2-14-m color-text-tertiary text-end">
                          {Math.round(
                            pyeong2
                              ? item.pyeongprice
                              : item.pyeongprice / 3.3058,
                          ).toLocaleString()}
                          원/{pyeong2 ? '평' : '㎡'}
                        </div>
                        <div className="m-b2-14-m color-text-tertiary text-end">
                          {item.auctiondate}
                        </div>
                      </div>
                    </div>
                  );
                })
            ) : (
              <div className="text-center color-dark-primary">
                거래 사례가 없습니다
              </div>
            )
          ) : pAuction.length > 0 ? (
            pAuction
              .slice(0 + (pauctionCurrentPage - 1) * 3, pauctionCurrentPage * 3)
              .map((item: any) => {
                return (
                  <div className="flex-row w-100per space-between">
                    <div className="m-b1-16-m color-text-tertiary">
                      반경 {Math.round(item.distance)}m
                    </div>
                    <div className="flex-column end-x padding-b-24">
                      <div className="m-b1-16-m color-dark-secondary text-end">
                        {item.address}
                      </div>
                      <div className="w-b1-18-b color-text-tertiary text-end">
                        {transNum(Number(item.pauctionprice))}원
                      </div>
                      <div className="m-b2-14-m color-text-tertiary text-end">
                        {Math.round(
                          pyeong2
                            ? item.pyeongprice
                            : item.pyeongprice / 3.3058,
                        ).toLocaleString()}
                        원/{pyeong2 ? '평' : '㎡'}
                      </div>
                      <div className="m-b2-14-m color-text-tertiary text-end">
                        {item.pauctiondate}
                      </div>
                    </div>
                  </div>
                );
              })
          ) : (
            <div className="text-center color-dark-primary">
              거래 사례가 없습니다
            </div>
          )}
          <div
            className="padding-16 flex-column gap-16 no-wrap"
            style={{ width: '350px' }}
          >
            {tab === 0
              ? realDeal.length > 0 && (
                  <Pagination
                    activePage={realCurrentPage}
                    itemsCountPerPage={3}
                    totalItemsCount={realDeal.length}
                    pageRangeDisplayed={5} // 보여줄 페이지 범위
                    onChange={handlePageChange} // 페이지 변경 핸들러 설정
                    firstPageText="처음" // 처음 페이지 버튼 텍스트
                    lastPageText="마지막" // 마지막 페이지 버튼 텍스트
                    activeClass="active" // 활성 페이지 클래스 이름
                    itemClass="page-item" // 페이지 아이템 클래스 이름
                    linkClass="page-link" // 페이지 링크 클래스 이름
                  />
                )
              : tab === 1
                ? sales.length > 0 && (
                    <Pagination
                      activePage={salesCurrentPage}
                      itemsCountPerPage={3}
                      totalItemsCount={sales.length}
                      pageRangeDisplayed={5} // 보여줄 페이지 범위
                      onChange={handlePageChange} // 페이지 변경 핸들러 설정
                      firstPageText="처음" // 처음 페이지 버튼 텍스트
                      lastPageText="마지막" // 마지막 페이지 버튼 텍스트
                      activeClass="active" // 활성 페이지 클래스 이름
                      itemClass="page-item" // 페이지 아이템 클래스 이름
                      linkClass="page-link" // 페이지 링크 클래스 이름
                    />
                  )
                : tab === 2
                  ? auction.length > 0 && (
                      <Pagination
                        activePage={auctionCurrentPage}
                        itemsCountPerPage={3}
                        totalItemsCount={auction.length}
                        pageRangeDisplayed={5} // 보여줄 페이지 범위
                        onChange={handlePageChange} // 페이지 변경 핸들러 설정
                        firstPageText="처음" // 처음 페이지 버튼 텍스트
                        lastPageText="마지막" // 마지막 페이지 버튼 텍스트
                        activeClass="active" // 활성 페이지 클래스 이름
                        itemClass="page-item" // 페이지 아이템 클래스 이름
                        linkClass="page-link" // 페이지 링크 클래스 이름
                      />
                    )
                  : tab === 3
                    ? pAuction.length > 0 && (
                        <Pagination
                          activePage={pauctionCurrentPage}
                          itemsCountPerPage={3}
                          totalItemsCount={pAuction.length}
                          pageRangeDisplayed={5} // 보여줄 페이지 범위
                          onChange={handlePageChange} // 페이지 변경 핸들러 설정
                          firstPageText="처음" // 처음 페이지 버튼 텍스트
                          lastPageText="마지막" // 마지막 페이지 버튼 텍스트
                          activeClass="active" // 활성 페이지 클래스 이름
                          itemClass="page-item" // 페이지 아이템 클래스 이름
                          linkClass="page-link" // 페이지 링크 클래스 이름
                        />
                      )
                    : ''}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportDetail6;
