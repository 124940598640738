import Request from '../../@api/request';

// const baseURL = "http://localhost:3333/";
// const baseURL = "https://back.transfarmer.kr/";
const baseURL = `${process.env.REACT_APP_HOST}`;
export const request = new Request(baseURL);

export default class MapService {
  static getList = async (
    swLat: number,
    neLat: number,
    swLng: number,
    neLng: number,
    level: string,
    path: string,
  ) => {
    const result = await request.get(
      `map?swLat=${swLat}&neLat=${neLat}&swLng=${swLng}&neLng=${neLng}&level=${level}&path=${path}`,
    );
    return result.data;
  };

  static getPolygon = async (value: string, tb: string) => {
    const result = await request.get(`map/poly?value=${value}&tb=${tb}`);
    return result.data;
  };

  static getPolygon2 = async (address: string) => {
    const result = await request.get(`map/poly2?address=${address}`);
    return result.data;
  };
}
