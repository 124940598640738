import Request from '../../@api/request';

// const baseURL = "http://localhost:3333/";
// const baseURL = "https://back.transfarmer.kr/";
const baseURL = `${process.env.REACT_APP_HOST}`;
export const request = new Request(baseURL);

export default class MainService {
  static search = async () => {
    const result = await request.get('/search/list');
    return result.data;
  };

  static popup = async () => {
    const result = await request.get('banner');
    return result.data;
  };
}
