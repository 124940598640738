import { BoxGreen1 } from './Box';

interface data {
  accumalatedTempList?: any;
  avgYearWeather: any[];
  cropList: {
    recommendCrop: any[];
    anotherCrop: any[];
  };
  curYearWeather: any[];
  soilResult: any;
}
export default class WeatherCom {
  static WeatherGreenBox1 = ({ date, data }: { date: Date; data: data }) => {
    return (
      <BoxGreen1>
        <div className="font-bold f-size-14 line-h-20 color-text-secondary">
          {date.getMonth() + 1}월 평균 기온은{' '}
          {data.curYearWeather[data.curYearWeather.length - 1].avgTemp + ' ℃'}로
        </div>
        <div
          className="font-bold f-size-16 line-h-24 "
          style={{
            color:
              data.curYearWeather[data.curYearWeather.length - 1].avgTemp <
              data.avgYearWeather[data.avgYearWeather.length - 1].temp
                ? '#d62554'
                : '#1E9B6B',
          }}
        >
          평년 {data.avgYearWeather[data.avgYearWeather.length - 1].temp + ' ℃'}
          {(() => {
            let a = data.curYearWeather[data.curYearWeather.length - 1].avgTemp;
            let b = data.avgYearWeather[data.avgYearWeather.length - 1].temp;
            if (a > b) {
              return (
                '에 비해 ' + Math.round((a - b) * 10) / 10 + ' ℃ 높습니다.'
              );
            } else if (a < b) {
              return (
                '에 비해 ' + Math.round((b - a) * 10) / 10 + ' ℃ 낮습니다.'
              );
            } else {
              return '와 같습니다.';
            }
          })()}
        </div>
      </BoxGreen1>
    );
  };
}
