import { useState } from 'react';
import {
  BottomDetailChangeMark,
  BottomDetailPlusMark,
} from '../../../../asset/styles/mobile/aiFraming/AIFarmingMap';
import Button from '../../../../component/mobile/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import SignController from '../../../../controller/sign/SignController';
import { transNum } from '../../../../component/function/function';

interface IJointDetailData {
  areaList: Array<{ area: string; latLng: number; info: any }>;
  panTo: Function;
  markerArray: Array<any>;
  setAreaList: React.Dispatch<React.SetStateAction<Array<any>>>;
  setMarkerArray: React.Dispatch<React.SetStateAction<Array<any>>>;
  setOnMyLandList: React.Dispatch<React.SetStateAction<boolean>>;
  setOnToggle: any;
}

const JointDetail = (props: IJointDetailData) => {
  const location = useLocation();
  console.log(location);
  const navigate = useNavigate();
  const [areaType, setAreaType] = useState('P');
  const [onDetail, setOnDetail] = useState(true);
  const focusLand = (el: any, i: number) => {
    try {
      props.panTo(el.latLng);
      props.setOnMyLandList(false);
      navigate(`?area=list[${i}]${el.area}`);
    } catch (err) {
      console.log(err);
    }
  };

  const TitleColumn = ({ title, children }: any) => (
    <div
      className="font-medium f-size-14 line-h-20 color-text-tertiary text-left center-y"
      style={{ flex: 0.4 }}
    >
      {title} {children}
    </div>
  );

  const ItemColumn = ({ content }: any) => (
    <div
      className="font-bold f-size-14 line-h-20 color-text-secondary nowrap text-left"
      style={{ flex: 0.6 }}
    >
      {content}
    </div>
  );

  const ItemColumn2 = ({ content }: any) => (
    <div
      className="font-bold f-size-16 line-h-24 color-text-primary nowrap "
      style={{ flex: 0.6 }}
    >
      {content}
    </div>
  );

  const DefaultText = ({ content }: any) => (
    <div className="font-bold f-size-14 line-h-20 color-text-secondary nowrap">
      {content}
    </div>
  );

  const DefaultText2 = ({ content }: any) => (
    <div className="font-medium f-size-12 line-h-14 color-text-tertiary nowrap">
      {content}
    </div>
  );

  return (
    <div
      className="gap-16 padding-8-16-48 flex-column border-top-radius-27 w-100per z-1 fixed bg-FFFFFF transition tablet"
      style={{
        maxHeight: window.innerHeight - 200 + 'px',
        bottom: 0,
        paddingBottom: onDetail ? '48px' : '16px',
      }}
    >
      <div className="flex-column gap-8">
        <div
          className="flex-row center-x hover"
          onClick={() => {
            setOnDetail(!onDetail);
          }}
        >
          <img
            src={
              onDetail
                ? '/asset/images/arrow/ArrowBottom24.svg'
                : '/asset/images/arrow/ArrowTop24.svg'
            }
            alt=""
          />
        </div>
        <div className="flex-row space-between">
          <div
            className="font-bold f-size-14 line-h-20 color-18181B"
            style={{ whiteSpace: 'pre' }}
          >
            <div className="font-bold f-size-16 line-h-24 color-text-primary">
              내 농지 목록
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex-column gap-16 transition"
        style={{ maxHeight: onDetail ? window.innerHeight - 200 + 'px' : 0 }}
      >
        {props.areaList.length !== 0 ? (
          <>
            <div
              className="flex-column gap-18 of-y-auto"
              style={{
                maxHeight: window.innerHeight - 400 + 'px',
              }}
            >
              {props.areaList.map((el, i) => (
                <div className="flex-column gap-10 border-b-171E26 padding-b-14">
                  <div className="flex-row space-between center-y">
                    <div
                      className="hover flex-row gap-12"
                      onClick={() => focusLand(el, i)}
                    >
                      <div className="font-bold f-size-14 line-h-20 color-text-primary nowrap">
                        {el.area}
                      </div>
                    </div>

                    <div
                      className="hover"
                      onClick={() => {
                        props.markerArray[i].setMap(null);
                        props.setMarkerArray((prev) => {
                          prev.splice(i, 1);
                          return [...prev];
                        });
                        props.setAreaList((prev) => {
                          prev.splice(i, 1);
                          return [...prev];
                        });
                      }}
                    >
                      <img src="/asset/images/close/Close24.svg" alt="" />
                    </div>
                  </div>

                  <div className="flex-row center-y w-100per ">
                    <div
                      className="hover flex-column gap-8 w-100per"
                      onClick={() => focusLand(el, i)}
                    >
                      <div className="flex-row w-100per">
                        <TitleColumn title="토지면적" />
                        <div
                          className="flex-row space-between"
                          style={{ flex: 0.6 }}
                        >
                          <ItemColumn
                            content={
                              areaType === 'P'
                                ? `${el.info.pyeong} 평`
                                : `${el.info.area} m²`
                            }
                          />
                          <div
                            className="hover bg-FFFFFF padding-2-8 cneter-x center-y flex-row border-radius-100 border-1-e2e2e2 font-medium f-size-12 line-h-20 color-text-tertiary"
                            onClick={() =>
                              setAreaType((prev) => (prev !== 'P' ? 'P' : 'M'))
                            }
                          >
                            <img
                              src="/asset/images/arrow/Refresh20.svg"
                              alt=""
                            />
                            {areaType === 'P' ? 'm²' : '평'}
                          </div>
                        </div>
                      </div>
                      <div className="flex-row w-100per start-y">
                        <TitleColumn title="AI 추정가치">
                          <img src="/asset/images/warn/Warn20.svg" alt="" />
                        </TitleColumn>
                        <div
                          className="flex-column gap-2 "
                          style={{ flex: 0.6 }}
                        >
                          <DefaultText
                            content={`${transNum(
                              Number(el.info.estimatedPrice),
                            )}원`}
                          />
                          <DefaultText2
                            content={
                              areaType === 'P'
                                ? `(${transNum(
                                    Number(el.info.perPyeongPrice),
                                  )}원/평)`
                                : `(${transNum(
                                    Number(el.info.perMeterPrice),
                                  )}원/m²)`
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="w-100per padding-24 center-x center-y font-bold f-size-16 color-text-tertiary border-b-171E26">
            {/* 현재 운영 중인 내 농지 주소를 선택하여 목록에 추가하세요 */}
            현재 운영 중인 내 농지 주소를 선택해주세요
          </div>
        )}
        <Button
          description="내 농장 경영 진단 받기"
          onClick={async () => {
            if (props.areaList.length > 0) {
              sessionStorage.setItem(
                'areaList',
                JSON.stringify(props.areaList),
              );
              let res2 = await SignController.checkAgriculturalNum(navigate);
              if (res2) {
                navigate('/operate/report');
              }
            } else {
              alert('지도에서 농지를 선택해주세요');
            }
          }}
        >
          <BottomDetailPlusMark />
        </Button>
      </div>
    </div>
  );
};

export default JointDetail;
