import {
  Tooltip2,
  TooltipMobile,
} from '../../../../../component/common/tooltip';
import { RowSectionTitle } from '../../../../../component/report/Row';
import { TextSectionTitle } from '../../../../../component/report/Text';

import { useState } from 'react';
import ReportUtil from '../../../../../utilities/report';

interface data {
  createdAt: string;
  quantityRate: number;
  realIncome: number;
  realIncomeRate: number;
  yearOperationCost: number;
  yearSales: number;
  dsr: number;
  irr: number;
  unitPriceRate: number;
}

const Summary3 = ({ data, data2 }: { data: data; data2: any }) => {
  const [onToolTip, setOnToolTip] = useState(false);
  const obj = {
    1: {
      t: (
        <div className="font-bold f-size-16 line-h-24 color-FFFFFF">
          매우 <br></br>낮음
        </div>
      ),
      offset: '55px',
    },
    2: {
      t: <div className="font-bold f-size-16 line-h-24 color-FFFFFF">낮음</div>,
      offset: '67px',
    },
    3: {
      t: <div className="font-bold f-size-16 line-h-24 color-FFFFFF">보통</div>,
      offset: '67px',
    },
    4: {
      t: <div className="font-bold f-size-16 line-h-24 color-FFFFFF">높음</div>,
      offset: '67px',
    },
    5: {
      t: (
        <div className="font-bold f-size-16 line-h-24 color-FFFFFF">
          매우 <br></br>높음
        </div>
      ),
      offset: '55px',
    },
  };
  const cropCompetitive = (profitRate: string | number) => {
    return ReportUtil.cropCompetitive(profitRate);
  };
  const createCompetitive = (quantityRate: number) => {
    if (quantityRate < 0.8) {
      return 1;
    } else if (quantityRate < 1) {
      return 2;
    } else if (quantityRate === 1) {
      return 3;
    } else if (quantityRate < 1.2) {
      return 4;
    } else if (quantityRate >= 1.2) {
      return 5;
    }
    return 1;
  };

  const priceCompetitive = (unitPriceRate: number) => {
    if (unitPriceRate < 70) {
      return 1;
    } else if (unitPriceRate < 80) {
      return 2;
    } else if (unitPriceRate < 90) {
      return 3;
    } else if (unitPriceRate < 100) {
      return 4;
    } else if (unitPriceRate >= 100) {
      return 5;
    }
    return 1;
  };

  return (
    <div>
      <RowSectionTitle>
        <TextSectionTitle>파머님 농장은</TextSectionTitle>
      </RowSectionTitle>
      <div className="relative flex-column center-y">
        <img
          className="browser-w-352"
          src={`/asset/images/competitive/4Round${cropCompetitive(
            data2.profitRate,
          )}.svg`}
          alt=""
        />
        <div className="absolute" style={{ bottom: '16px' }}>
          <div className="font-medium f-size-14 line-h-20 color-text-primary">
            작물 경쟁력
          </div>
        </div>
        <div
          className="absolute"
          style={{
            bottom: obj[cropCompetitive(data2.profitRate) as 1].offset,
          }}
        >
          {obj[cropCompetitive(data2.profitRate) as 1].t}
        </div>
      </div>
      <div className="relative flex-column center-y">
        <img
          className="browser-w-352"
          src={`/asset/images/competitive/5Round${priceCompetitive(
            data.unitPriceRate,
          )}.svg`}
          alt=""
        />
        <div className="absolute flex-row center-y " style={{ bottom: '16px' }}>
          <div className="font-medium f-size-14 line-h-20 color-text-primary">
            판매단가 경쟁력
          </div>
          <img
            onClick={() => setOnToolTip(true)}
            className="hover"
            src="/asset/images/warn/Warn24.svg"
            style={{ width: '20px' }}
            alt=""
          />
        </div>
        <div
          className="absolute"
          style={{
            bottom: obj[priceCompetitive(data.unitPriceRate) as 1].offset,
          }}
        >
          <div className="font-bold f-size-16 line-h-24 color-FFFFFF">
            {obj[priceCompetitive(data.unitPriceRate) as 1].t}
          </div>
        </div>
        <div className="absolute">
          <Tooltip2
            bottom="-150"
            left="-133"
            width
            on={onToolTip}
            off={() => setOnToolTip(false)}
            text={
              <div className="text-left">
                당월 현재까지 상등품 평균 경락가격의 %
                <br />
                (출하시기 아님으로 정보없으면 전월 기준)
              </div>
            }
          />
        </div>
      </div>

      <div className="relative flex-column center-y">
        <img
          className="browser-w-352"
          src={`/asset/images/competitive/5Round${createCompetitive(
            data.quantityRate,
          )}.svg`}
          alt=""
        />
        <div className="absolute" style={{ bottom: '16px' }}>
          <div className="font-medium f-size-14 line-h-20 color-text-primary">
            생산량 경쟁력
          </div>
        </div>
        <div
          className="absolute"
          style={{
            bottom: obj[createCompetitive(data.quantityRate) as 1].offset,
          }}
        >
          <div className="font-bold f-size-16 line-h-24 color-FFFFFF">
            {obj[createCompetitive(data.quantityRate) as 1].t}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary3;
