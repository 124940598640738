import { useState } from 'react';
import { RowSectionTitle } from '../../../../component/report/Row';
import { TextSectionTitle } from '../../../../component/report/Text';
import { ButtonSelectorBox } from '../../../../component/report/Button';
import TempCom from '../../../../component/report/Temp';

interface data {
  accumulatedTemp: any[];
  avgYearWeather: any[];
  cropList: {
    recommendCrop: any[];
    anotherCrop: any[];
  };
  curYearWeather: any[];
  soilResult: any;
}

const ReportDetail2 = ({ data, mypage }: { data: data; mypage?: any }) => {
  const [tab, setTab] = useState(1);

  let date = new Date();

  const btnArr = [
    { children: '월평균 기온', selected: 1 },
    '',
    { children: '월평균 최고기온', selected: 2 },
  ];

  return (
    <div className="padding-b-24">
      <RowSectionTitle>
        <TextSectionTitle>2. 농업기상</TextSectionTitle>
      </RowSectionTitle>

      <ButtonSelectorBox tab={tab} setTab={setTab} btnArr={btnArr} />

      {tab === 1 ? (
        <TempCom.AvgTempOfMonth date={date} data={data} />
      ) : tab === 2 ? (
        <TempCom.AvgMaxTempOfMonth date={date} data={data} />
      ) : (
        ''
      )}
    </div>
  );
};

export default ReportDetail2;
