import { useLocation } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { SideTopnav } from '../../../../component/browser/SideTopnav';
import SideSearch from '../../../../component/browser/sidebar/Search';
import Auction from './Auction';
import AuctionReportMain from '../../../reportResultSale/Main';
import { set } from 'lodash';
import TopBanner from '../../../../component/banner/TopBanner';
interface AuctionItem {
  address: string;
  addresses: string;
  appraisedValue: number;
  auctionNumber: string;
  area: number;
  endDate: string;
  estimated: number;
  img: string;
  isFarm: string;
  keyword: string;
  likeCount: number;
  price: number;
  pyeong: number;
  regTime: string;
  roadSurface: string;
  salesIdx: number;
  startDate: string;
  views: number;
  auctionIdx: number;
  bidCount: number;
  bidDate: string;
  category: string;
  lowerPrice: number;
  type: string;
}

const AuctionPreview = ({ panto, setIsSales }: any) => {
  const locationHook = useLocation();
  const [selectedItem, setSelectedItem] = useState<AuctionItem | null>(null);
  const [isFarm, setIsFarm] = useState('');

  const [query, setQuery] = useState('');
  useEffect(() => {
    if (
      locationHook.search.split('=')[1] &&
      !locationHook.search.includes('n_media')
    ) {
      setQuery(decodeURI(locationHook.search.split('=')[1]));
    } else {
      setQuery('');
    }
  }, [locationHook]);
  const [close, setClose] = useState(false);

  const [houseReportIdx, setHouseReportIdx] = useState(0);
  const [type, setType] = useState('S');

  const temp = useRef<HTMLDivElement>(null);

  const openReport = (rIdx: any, type: any) => {
    setClose(false);
    setType(type);
    setHouseReportIdx(rIdx);
    // setClose(true);
  };

  return (
    <div
      className={`bg-FFFFFF fixed z-1 w-100per padding-8-16-48 border-top-radius-16 flex-column gap-16 transition browser-absolute browser-h-100per browser-top-0 browser-left-0 browser-border-radius-0 browser-flex-row browser-gap-0 browser-padding-0 browser-bg-F7F8F9" +
        ${!close ? 'browser-w-449' : 'browser-w-824'}`}
    >
      {/* {onInquiry && <InquiryWrite address={props.address} />} */}
      <div className="browser browser-flex w-74">
        <SideTopnav />
      </div>
      <div
        className={`flex-column browser-border-b-171E26 browser-w-375 browser-h-100per browser-of-scroll ${
          (locationHook.pathname.slice(-1) === '/'
            ? locationHook.pathname.slice(0, -1)
            : locationHook.pathname) === '/price/map' && `border-r-171E2610`
        }`}
        ref={temp}
        style={{ overflowY: 'hidden' }}
      >
        {/* {onLoginPopup ? (
          <LoginPopUp
            setOnLoginPopup={setOnLoginPopup}
            address={props.address}
            pathName={(locationHook.pathname.slice(-1) === "/" ? locationHook.pathname.slice(0,-1): locationHook.pathname)}
          />
        ) : (
          ""
        )} */}
        <div>
          <SideSearch
            isFarm={isFarm}
            on={false}
            recycle={true}
            location={
              query &&
              (decodeURI(query).slice(0, 4) !== 'list'
                ? decodeURI(query)
                : query.split(']')[1])
            }
          />
          <div className="flex-column gap-16  browser-bg-FFFFFF border-b-171E26 ">
            <Auction
              isFarm={isFarm}
              setIsFarm={setIsFarm}
              panto={panto}
              open={openReport}
              setIsSales={setIsSales}
              setClose={setClose}
              close={close}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              houseReportIdx={houseReportIdx}
              setHouseReportIdx={setHouseReportIdx}
              setType={setType}
              type={type}
            />
          </div>
        </div>
      </div>
      {houseReportIdx > 0 && (
        <div
          className="flex-column browser-border-b-171E26 browser-h-100per"
          style={{ marginLeft: '0px', width: !close ? '0px' : '375px' }}
        >
          <AuctionReportMain
            reportIdx={houseReportIdx}
            setClose={setClose}
            type={type}
            selectedItem={selectedItem}
          />
        </div>
      )}
    </div>
  );
};

export default AuctionPreview;
