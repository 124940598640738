import { useLocation, useNavigate } from 'react-router-dom';
import { Font, FontNoWrap } from '../../asset/styles/browser/font/font';
import locationImg from '../../asset/images/main/location.svg';
import locationNoImg from '../../asset/images/main/locationNoImg.svg';
import {
  AlramIcon,
  LogoIcon,
  SearchInputRow,
  SearchIcon,
  SearchInput,
  BackButton,
  SearchWrap,
  MarkGroupIcon,
} from '../../asset/styles/mobile/common/Search';
import close from './../../asset/images/close/close_black_big.svg';
import { useEffect, useRef, useState } from 'react';
import SearchService from '../../service/land/landApi';
import Logo from '../../asset/images/new/logo.svg';
import { useAuth } from '../../utilities/auth';
import Sidemenu from './Hamburger';
import { useSelector } from 'react-redux';
import { exportDefaultDeclaration } from '@babel/types';
import { result, set } from 'lodash';
import { isMobile } from '../function/function';
import useDidMountEffect from '../../hooks/UseDidMountEffect';
const Search = ({
  query,
  logo,
  target,
  joint,
  link,
  setSearchList,
  searchState,
  setOnInputFocus,
  mapClick,
  setMapClick,
  isFarm,
  noLand,
  onInputFocus,
  searchValue,
  setSearchValue,
  errorMessage,
}: any) => {
  const state = useSelector((state: any) => {
    return state.loading;
  });
  const state2 = useSelector((state: any) => {
    return state.type;
  });
  const state3 = useSelector((state3: any) => {
    return state3.onOff;
  });

  const ref = useRef<HTMLInputElement>(null);
  const [onHamburger, setOnHamburger] = useState(false);

  const navigate = useNavigate();
  const locationHook = useLocation();
  const [message, setMessage] = useState(false);

  const { isLogin, onLogout } = useAuth();

  const search = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // setMessage(false);

    if (!searchState.onSearchList) {
      searchState.setOnSearchList(true);
    }
    setSearchValue(e.target.value);
  };
  // console.log('searchValue', searchValue);

  const searchApi = async (value: string) => {
    try {
      let result: { searchList: Array<any> | Array<never> } | any;
      if (locationHook.pathname.startsWith('/auction/map')) {
        result = await SearchService.search2(value);
      } else {
        if (
          locationHook.pathname.startsWith('/house/map') ||
          state2.priceType === 'house'
        ) {
          result = await SearchService.searchRoad1st(value);
        } else {
          result = await SearchService.search(value);
        }
      }
      setSearchList(result.searchList);
    } catch (err) {
      console.log(err);

      setSearchList([]);
    }
  };
  useEffect(() => {
    ref.current?.blur();
  }, [query]);
  useEffect(() => {
    if (setSearchList) {
      searchApi(searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    if (locationHook.state?.address) {
      setSearchValue(locationHook.state?.address);
      //searchApi(locationHook.state?.address);
    }
  }, []);

  useDidMountEffect(() => {
    if (locationHook.pathname !== '/auction/map') {
      console.log('popup', state3.PopUp);
      if (!state3.PopUp) {
        ref.current?.focus();
      }
    }
  }, [state3.PopUp]);

  const isOnPricePage =
    (locationHook.pathname.slice(-1) === '/'
      ? locationHook.pathname.slice(0, -1)
      : locationHook.pathname) === '/price/map';

  const isOnMyHousePage =
    (locationHook.pathname.slice(-1) === '/'
      ? locationHook.pathname.slice(0, -1)
      : locationHook.pathname) === '/house/map';

  const isOnAuctionPage =
    (locationHook.pathname.slice(-1) === '/'
      ? locationHook.pathname.slice(0, -1)
      : locationHook.pathname) === '/auction/map';
  const isOnAnnuityPage =
    (locationHook.pathname.slice(-1) === '/'
      ? locationHook.pathname.slice(0, -1)
      : locationHook.pathname) === '/annuity/map';

  useEffect(() => {
    if (!isOnPricePage && !isOnAnnuityPage && !isOnAuctionPage) {
      ref.current?.focus();
    }
  }, [sessionStorage.getItem('tab')]);

  useEffect(() => {
    if (target) {
      setSearchValue(target);
    }
  }, [target]);
  useEffect(() => {
    if (onInputFocus) {
      searchState.setOnSearchList(true);
    }
  }, [onInputFocus]);
  return (
    <>
      <div
        className="flex-row gap-8 padding-9-16 w-100per browser-max-w-681 tablet border-171E26"
        style={{ height: '48px' }}
      >
        {logo ? (
          <img
            className=" hover"
            src={Logo}
            alt=""
            onClick={() => navigate('/')}
          />
        ) : (
          <BackButton className="hover" onClick={() => navigate(-1)} />
        )}

        {joint ? (
          <></>
        ) : (
          <>
            {/* {target ? (
          <div
            className="padding-y-6 w-100per overflow-auto hover"
            onClick={() => navigate("#2")}
          >
            <div className="font-bold f-size-16 line-h-24 color-18181B nowrap">
              {target}
            </div>
          </div>
        ) : ( */}
            {onHamburger && (
              <Sidemenu setOpen={setOnHamburger} open={onHamburger} />
            )}
            <div className=" border-radius-8 w-100per flex-row gap-4">
              {/* <img src='/asset/images/search/SearchGray20.svg' alt='' /> */}
              <input
                ref={ref}
                type="text"
                className="f-size-16 line-h-24 font-medium w-100per color-text-primary border-none input myCustomInput"
                style={{ background: 'none' }}
                value={searchValue}
                placeholder={
                  isOnMyHousePage
                    ? '주소를 입력하세요'
                    : isOnAuctionPage
                      ? isFarm === 'Y'
                        ? '농지 주소를 입력하세요'
                        : '주소를 입력하세요'
                      : isOnPricePage
                        ? state2.priceType === 'land'
                          ? '농지 주소를 입력하세요'
                          : '주소를 입력하세요'
                        : '농지 주소를 입력하세요.'
                }
                onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.code === 'Enter') {
                    searchState.setOnSearchList(false);
                    navigate(`?area=${searchValue}`);
                  }
                }}
                onKeyDown={(e: any) => {
                  if (e.keyCode === 13) {
                    searchState.setOnSearchList(false);
                    navigate(`?area=${searchValue}`);
                  }
                }}
                onBlur={(e) => {
                  if (e.relatedTarget === null) {
                    // searchState.setOnSearchList(false);
                  }
                  setTimeout(() => {
                    setOnInputFocus(false);
                  }, 100); // 전체삭제 버튼 클릭시에도 onBlur가 발생해서 setTimeout으로 처리
                }}
                onFocus={() => {
                  // searchValue !== "" && searchState.setOnSearchList(true);
                  setTimeout(() => {
                    setOnInputFocus(true);
                  }, 101);
                  setMapClick(true);
                }}
                onChange={search}
              />
              {searchValue && (
                <img
                  onClick={() => setSearchValue('')}
                  className="hover"
                  src="/asset/images/close/close_with_gray_circle.svg"
                  alt=""
                />
              )}
            </div>

            {/* <AlramIcon />
        {/* <AlarmNewIcon /> 
        <MarkGroupIcon /> */}
            {/* img
            className=" hover"
            src={CloseIcon}
            alt=""
            onClick={() => navigate((locationHook.pathname.slice(-1) === "/" ? locationHook.pathname.slice(0,-1): locationHook.pathname).slice(0, -3))}
          /> */}
          </>
        )}
        {/* <img
      src={close}
      className='hover'
      onClick={() => {
        navigate("/");
      }}
    /> */}
        <img
          src="/asset/images/main/hamburger_black.svg"
          className="pointer"
          alt="hamburger"
          onClick={() => {
            setOnHamburger(true);
          }}
        />
      </div>
      {/* {isMobile(window.innerWidth) && noLand && (
        <div
          className='w-100per flex-row gap-8 h-100per center-y bg-light-gray '
          style={{
            zIndex: 100,
            padding: '30px 20px',
            justifyContent: 'center',
          }}
        >
          <img src={locationNoImg} className='w-20 h-20' />
          <div className='center-x font-medium f-size-14 color-text-tertiary text-center line-h-24'>
            {!isOnMyHousePage &&
            !(isOnPricePage && state2.priceType === 'house')
              ? '해당 토지는 분석대상이 아닙니다.'
              : '해당 주택은 분석대상이 아닙니다.'}
          </div>
        </div>
      )} */}
    </>
  );
};

export default Search;
