import { createSlice } from '@reduxjs/toolkit';

const InitialLand = {
  location: '',
  estimatedPrice: 0,
  estimatedRentalPrice: 0,
};

const landSlice = createSlice({
  name: 'type',
  initialState: InitialLand,
  reducers: {
    Location(state, action) {
      state.location = action.payload;
    },
    // 농지 매입비
    EstimatedPrice(state, action) {
      state.estimatedPrice = action.payload;
    },
    // 농지 임차료
    EstimatedRentalPrice(state, action) {
      state.estimatedRentalPrice = action.payload;
    },
  },
});

export const { Location, EstimatedPrice, EstimatedRentalPrice } =
  landSlice.actions;
export default landSlice.reducer;
