import { createSlice } from '@reduxjs/toolkit';

const initialCheckState = {
  allCheck: false,
  privacy: false,
  transfarmerService: false,
  location: false,
  notification: false,
  btnCheck: false,
};

const checkSlice = createSlice({
  name: 'check',
  initialState: initialCheckState,
  reducers: {
    AllCheck(state) {
      state.allCheck = !state.allCheck;
      state.privacy = state.allCheck;
      state.transfarmerService = state.allCheck;
      state.location = state.allCheck;
      state.notification = state.allCheck;
    },
    Privacy(state) {
      state.privacy = !state.privacy;
    },
    TransfarmerService(state) {
      state.transfarmerService = !state.transfarmerService;
    },
    Location(state) {
      state.location = !state.location;
    },
    Notification(state) {
      state.notification = !state.notification;
    },
    BtnCheck(state, action) {
      state.btnCheck = action.payload;
    },
  },
});

export const {
  AllCheck,
  Privacy,
  TransfarmerService,
  Location,
  Notification,
  BtnCheck,
} = checkSlice.actions;
export default checkSlice.reducer;
