import { useState } from 'react';
import {
  RowSectionTitle,
  RowTableValue,
} from '../../../../../component/report/Row';
import { TextSectionTitle } from '../../../../../component/report/Text';
import { Graph } from './graph/Graph';
import { ButtonSelectorBox } from '../../../../../component/report/Button';
import { BoxGreen1 } from '../../../../../component/report/Box';
interface data {
  createdAt: string;
  quantityRate: number;
  realIncome: number;
  realIncomeRate: number;
  yearOperationCost: number;
  yearSales: number;
}
interface data2 {
  realIncome: number;
  realIncomeRate: number;
  yearOperationCost: number;
  yearSales: number;
}

interface data3 {
  address: string;
  cropName: string;
  crops: number;
  facility: string;
  farmArea: number;
  quantity: number;
  type: string;
  realIncome: number;
}
const Summary2 = ({
  data,
  data2,
  data3,
  data4,
  mypage,
}: {
  data: data;
  data2: data2;
  data3: data3;
  data4: any;
  mypage?: any;
}) => {
  const [tab, setTab] = useState(1);

  const [areaType, setAreaType] = useState('P');

  const btnArr = [
    { children: '매출', selected: 1 },
    '',
    { children: '비용', selected: 2 },
    '',
    { children: '영업이익', selected: 3 },
  ];

  const word = {
    1: ['매출', 'yearSales'],
    2: ['비용', 'yearOperationCost'],
    3: ['영업이익', 'realIncome'],
  };
  return (
    <div className="padding-b-24">
      <RowSectionTitle>
        <TextSectionTitle>농장 경쟁력</TextSectionTitle>
      </RowSectionTitle>

      <ButtonSelectorBox tab={tab} setTab={setTab} btnArr={btnArr} />

      <div className="padding-8-16 flex-row end-x">
        <div
          className="hover bg-FFFFFF padding-2-8 cneter-x center-y flex-row border-radius-100 border-1-e2e2e2 font-medium f-size-12 line-h-20 color-text-tertiary"
          onClick={() => setAreaType((prev) => (prev !== 'P' ? 'P' : 'M'))}
        >
          <img src="/asset/images/arrow/Refresh20.svg" alt="" />
          {areaType === 'P' ? 'm²' : '평'}
        </div>
      </div>

      <div className="flex-column">
        <Graph
          avg={
            data2[word[tab as 1][1] as 'yearSales'] /
            (areaType === 'P' ? data3.farmArea / 3.306 : data3.farmArea)
          }
          pri={
            data[word[tab as 1][1] as 'yearSales'] /
            (areaType === 'P' ? data3.farmArea / 3.306 : data3.farmArea)
          }
        />
        <div className="padding-24-16 flex-column gap-8">
          <RowTableValue
            title={`내 농장 ${word[tab as 1][0]}`}
            value={`${Math.floor(
              data[word[tab as 1][1] as 'yearSales'] /
                (areaType === 'P' ? data3.farmArea / 3.306 : data3.farmArea),
            ).toLocaleString()}${areaType === 'P' ? '원/평' : '/m²'}`}
          />
          <RowTableValue
            title={`지역 평균 농장 ${word[tab as 1][0]}`}
            value={`${Math.floor(
              data2[word[tab as 1][1] as 'yearSales'] /
                (areaType === 'P' ? data3.farmArea / 3.306 : data3.farmArea),
            ).toLocaleString()}${areaType === 'P' ? '원/평' : '/m²'}`}
          />
        </div>
        <div className="padding-8-16 gap-10">
          <BoxGreen1>
            <div className="font-bold f-size-14 line-h-20 color-text-secondary">
              파머님의 예상 {word[tab as 1][0]}은
            </div>
            <div
              className="font-bold f-size-16 line-h-24"
              style={{
                color:
                  data2[word[tab as 1][1] as 'yearSales'] <=
                  data[word[tab as 1][1] as 'yearSales']
                    ? '#1E9B6B'
                    : '#d62554',
              }}
            >
              지역 농장 평균
              {data2[word[tab as 1][1] as 'yearSales'] <
              data[word[tab as 1][1] as 'yearSales']
                ? ` 보다 ${Math.abs(
                    Math.round(
                      (data[word[tab as 1][1] as 'yearSales'] * 100) /
                        data2[word[tab as 1][1] as 'yearSales'],
                    ) - 100,
                  )}% 높습니다.`
                : data2[word[tab as 1][1] as 'yearSales'] >
                    data[word[tab as 1][1] as 'yearSales']
                  ? ` 보다 ${Math.abs(
                      100 -
                        Math.round(
                          (data[word[tab as 1][1] as 'yearSales'] * 100) /
                            data2[word[tab as 1][1] as 'yearSales'],
                        ),
                    )}% 낮습니다.`
                  : '과 같습니다.'}
            </div>
          </BoxGreen1>
        </div>
      </div>
    </div>
  );
};

export default Summary2;
