import { TopNav2 } from '../../../component/mobile/Topnav';
import { useState, useEffect, useRef, MouseEvent } from 'react';
import FacilitySelect from './input/Facility';
import CropSelect from './input/Crop';
import back from './../../../asset/images/report/goback.svg';
import CultivateCount from './input/CultivateCount';
import PurchaseType from './input/PurchaseType';
import { useLocation, useNavigate } from 'react-router-dom';
import LandService from '../../../service/land/landApi';
import ReportService from '../../../service/report/reportApi';
import { useDispatch } from 'react-redux';
import {
  EstimatedPrice,
  EstimatedRentalPrice,
  Location,
} from '../../../store/landSlice';
import { CropInfo } from './common';
import Area from './input/Area';
import CropSkill from './input/CropSkill';
import Button from '../../../component/mobile/Button';
import { isMobile } from '../../../component/function/function';
import backArrow from './../../../asset/images/arrow/arrow.svg';
import { LoadingDot } from '../../../store/loadingSlice';
import { BoxGreen1 } from '../../../component/report/Box';
import { PointAlert2 } from '../../../component/common/pointAlert2';
import WarnPoint from '../../../component/common/warnPoint';
import UserService from '../../../service/checkAccount/CheckAccount';
import { PointColumn } from '../../../component/common/pointColumn';
import useDidMountEffect from '../../../hooks/UseDidMountEffect';
import { CostControl, SectionTitle } from './component';
import { dummyCost } from '../../../dummy/dummy';
interface LandInfoType {
  area: number;
  avg: number;
  category: string;
  estimatedPrice: number;
  estimatedRentalPrice: number;
  officialPrice: any;
  perMeterPrice: number;
  perPyeongPrice: number;
  pnuCode: string;
  pyeong: number;
  tranApplyAt: null | string;
  transaction: null | string;
  warnning: string;
  zoning: string;
}

const LandValueReport = ({
  location2,
  estimatedPrice2,
  estimatedRentalPrice,
  setReportIdx2,
  setClose,
  position,
  temp,
  setOnReport2,
  type,
  originalArea,
}: any) => {
  const location = useLocation();
  const dummy = [
    { title: '재배환경', ref: 'menuRef1' },
    { title: '작물', ref: 'menuRef2' },
    { title: '재배 면적', ref: 'menuRef3' },
    { title: '1년 재배 횟수', ref: 'menuRef4' },
    { title: '농지 소유 여부', ref: 'menuRef5' },
    { title: '초기 투자비', ref: 'menuRef6' },
    { title: '나의 경작 능력', ref: 'menuRef7' },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [dragging, setDragging] = useState(false);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [clickPoint, setClickPoint] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const [spinner2, setSpinner2] = useState({
    recommend: false,
    another: false,
    weather: false,
    land: false,
  });
  const [facility, setFacility] = useState('');
  const [crop, setCrop] = useState('');
  const [cultivateCnt, setCultivateCnt] = useState(1);
  const [purchaseType, setPurchaseType] = useState('P');
  const [score, setScore] = useState(3);
  const [index, setIndex] = useState(-1);
  const [onPointAlert, setOnPointAlert] = useState(false);
  const [anotherCrop, setAnotherCrop] = useState<any>();
  const [open, setOpen] = useState(false);
  const [BtnOn, setBtnOn] = useState(false);

  // 6 재배 능력 state
  const [age, setAge] = useState('2');
  const [experience, setExperience] = useState('1');
  const [graduate, setGraduate] = useState('1');
  const [live, setLive] = useState('1');
  const [year, setYear] = useState('2');
  const today = new Date();
  const [actualHours, setActualHours] = useState(0);
  const [onlineHours, setOnlineHours] = useState(0);
  const [liveDate, setLiveDate] = useState(
    today.getFullYear() + '-' + (today.getMonth() + 1) || null,
  );
  const [farmDate, setFarmDate] = useState(
    today.getFullYear() + '-' + (today.getMonth() + 1) || null,
  );
  const menuRef1 = useRef<HTMLDivElement>(null);
  const menuRef2 = useRef<HTMLDivElement>(null);
  const menuRef3 = useRef<HTMLDivElement>(null);
  const menuRef4 = useRef<HTMLDivElement>(null);
  const menuRef5 = useRef<HTMLDivElement>(null);
  const menuRef6 = useRef<HTMLDivElement>(null);
  const menuRef7 = useRef<HTMLDivElement>(null);
  const refList: any = {
    menuRef1,
    menuRef2,
    menuRef3,
    menuRef4,
    menuRef5,
    menuRef6,
    menuRef7,
  };

  const locationHook = useLocation();
  const [areaType, setAreaType] = useState('M');
  const ref = useRef<HTMLDivElement>(null);
  // const [mArea, setMArea] = useState(isMobile() ? location.state.info.area : 0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [selectedCrop, setSelectedCrop] = useState<CropInfo>(new CropInfo());
  const [recommendCrop, setRecommendCrop] = useState<CropInfo[]>([]);
  const [area, setArea] = useState(0);
  const [pyeong, setPyeong] = useState(0);
  const [point, setPoint] = useState(0);
  const [menuHeight, setMenuHeight] = useState<number>(0);
  const [subTab, setSubTab] = useState(0);
  //const [farmFacilityCost, setFarmFacilityCost] = useState(0);
  const [landInfo, setLandInfo] = useState<LandInfoType>({
    area: 0,
    avg: 0,
    category: '',
    estimatedPrice: 0,
    estimatedRentalPrice: 0,
    officialPrice: {},
    perMeterPrice: 0,
    perPyeongPrice: 0,
    pnuCode: '',
    pyeong: 0,
    tranApplyAt: null,
    transaction: null,
    warnning: '',
    zoning: '',
  });

  const getLandInfoApi = async () => {
    dispatch(LoadingDot('Y'));
    const address = isMobile(window.innerWidth)
      ? location.state.location
      : location2;
    try {
      const result = await LandService.getLandInfo(address);
      setLandInfo(result);
      setArea(result.area);
      setPyeong(result.area / 3.3058);
      if (!isMobile(window.innerWidth)) {
        setOnReport2(true);
      }
      dispatch(LoadingDot('N'));
    } catch (error) {
      dispatch(LoadingDot('N'));
    }
  };
  const CheckPoint = async () => {
    try {
      const result = await UserService.checkPoint();

      setPoint(result.data.data.freePoint + result.data.data.paidPoint);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setMenuHeight(ref.current?.getBoundingClientRect().top || 0);
    CheckPoint();
  }, []);
  console.log(locationHook.state);
  const checkReport = async () => {
    try {
      const result = await ReportService.check(
        'FS',
        isMobile(window.innerWidth) ? locationHook.state.location : location2,
      );
      console.log('reuslt', result);

      if (result.isExist === false) {
        setOnPointAlert(true);
      } else {
        getReportApi();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const recommendCropApi = async () => {
    setSpinner(true);
    if (facility) {
      const pnuCode = [
        {
          pnuCode: landInfo.pnuCode,
          area: area,
          address: isMobile(window.innerWidth)
            ? location.state.location
            : location2,
        },
      ];
      // setMArea(pnuCode[0].area);
      try {
        const result = await LandService.cropList(pnuCode, facility, 'Y');
        setRecommendCrop(result.result.recommendCrop);
        setSpinner(false);
        // if (result.result.length === 0) {
        setAnotherCrop(result.result.anotherCrop);
        // }
      } catch (error: any) {
        if (error.response.data.message === 'Soil api cause an error') {
          setSpinner(false);
          recommendCropApi();
        }
        console.log(error);
        setSpinner(false);
      }
    }
  };

  const AnotherCropApi = async () => {
    setSpinner(true);
    const pnuCode = [
      {
        pnuCode: landInfo.pnuCode,
        area: area,
        address: isMobile(window.innerWidth)
          ? location.state.location
          : location2,
      },
    ];

    try {
      const result = await LandService.cropList(pnuCode, facility, 'N');
      const result2 = await LandService.cropListAll(pnuCode, facility);
      if (result.result.length > 0) {
        setAnotherCrop(result.result);
      } else {
        setAnotherCrop(result2.list);
      }
      setSpinner(false);
    } catch (error) {
      console.log(error);
      setSpinner(false);
    }
  };
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollLeft =
        (containerRef.current.scrollWidth - containerRef.current.clientWidth) /
        2;
    }
  }, [subTab]);

  const getReportApi = async () => {
    if (facility === '') {
      alert('재배 환경을 선택해주세요.');
    } else if (crop === '' && null) {
      alert('작물을 선택해주세요.');
    } else if (landInfo.area === 0) {
      alert('재배 면적을 선택해주세요.');
    } else {
      const data = {
        pnuCodes: [landInfo.pnuCode],
        facility:
          facility === '노지'
            ? 'O'
            : facility === '시설'
              ? 'P'
              : facility === '스마트팜'
                ? 'S'
                : 'W',
        farmArea: landInfo.area * (areaType === 'P' ? 3.3058 : 1),
        cropName: crop,
        crops: cultivateCnt,
        ownLaborYN: 'Y',
        avgQuantity: selectedCrop.quantity,
        unitPrice: selectedCrop.unitPrice,
        landCostType: purchaseType,
        avgLandPurchaseCost: landInfo.estimatedPrice,
        landPurchaseCost:
          selectedCrop.landPurchaseCost || landInfo.estimatedPrice,
        avgFacilityCost: selectedCrop.avgFacilityCost,
        facilityCost: selectedCrop.facilityCost,
        avgFarmMachineryCost: selectedCrop.farmMachineryCost || 0,
        farmMachineryCost: selectedCrop.farmMachineryCost || 0,
        avgEmpLaborCost: selectedCrop.empLaborCosts,
        avgOwnLaborCost: selectedCrop.ownLaborCosts,
        avgMaterialCost: selectedCrop.materialCost,
        avgOrchardCost: selectedCrop.orchardAdjustmentCost,
        avgWaterHeatingCost: selectedCrop.waterHeatingCost,
        avgFarmEquipmentCost: selectedCrop.farmEquipmentRentalCost,
        avgLandRentalCost: landInfo.estimatedRentalPrice,
        avgSGA: selectedCrop.SGA,
        age: age,
        farmLivedPeriod: liveDate,
        farmExperience: farmDate,
        farmCareer:
          graduate === '1'
            ? 'H'
            : graduate === '2'
              ? 'U'
              : graduate === '3'
                ? 'F'
                : graduate === '4'
                  ? 'S'
                  : null,
        actualHours: actualHours,
        onlineHours: onlineHours,
        farmScore: score,
        farmScorePeriod: year,
      };
      // console.log(data);
      try {
        if (!isMobile(window.innerWidth)) {
          setClose(false);
        }
        const result = await ReportService.search(data);
        console.log(result.reportIdx);

        if (result.success) {
          if (isMobile(window.innerWidth)) {
            navigate(`/findland/report/${result.reportIdx}`);
          } else {
            setReportIdx2(result.reportIdx);
            setClose(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleMouseDownEvent = (e: MouseEvent<HTMLDivElement>) => {
    setDragging(true);
    if (containerRef.current) {
      setClickPoint(e.pageX); // clickPoint는 처음 클릭한 지점
      setScrollLeft(containerRef.current.scrollLeft); // 스크롤움직인 거리, 처음은 0
    }
  };

  const handleMouseMoveEvent = (e: MouseEvent<HTMLDivElement>) => {
    if (!dragging) return; // onMouseDownEvent에서 dragging=true가 아니면 작동하지 않음
    e.preventDefault();
    if (containerRef.current) {
      const walk = e.pageX - clickPoint;
      containerRef.current.scrollLeft = scrollLeft - walk;
    }
  };

  const scrollTo = (text: string) => {
    temp.current?.scrollTo({
      behavior: 'smooth',
      top:
        refList[text].current?.getBoundingClientRect().top +
        temp.current?.scrollTop -
        150,
    });
  };
  useEffect(() => {
    const OnBtn = () => {
      if (facility !== '' && landInfo.area !== 0 && crop !== null) {
        setBtnOn(true);
      } else {
        setBtnOn(false);
      }
    };
    OnBtn();
  }, [facility, crop, area]);

  useDidMountEffect(() => {
    if (facility === '시설' || facility === '스마트팜') {
      // setArea(Math.floor(area / 50) * 50);
      if (areaType === 'M') {
        setArea(
          Math.round(Math.floor(Math.round(area / 3.3058) / 50) * 50 * 3.3058),
        );
      } else {
        setArea(Math.floor(area / 50) * 50);
      }
    }
    recommendCropApi();
    if (facility === '노지') {
      if (areaType === 'M') {
        if (isMobile(window.innerWidth)) {
          setArea(Math.round(location.state.originalArea));
        } else {
          setArea(Math.round(originalArea));
        }
      } else {
        if (isMobile(window.innerWidth)) {
          setArea(
            Math.round(((location.state.originalArea / 3.3058) * 10) / 10),
          );
        }
        setArea(Math.round(((originalArea / 3.3058) * 10) / 10));
      }
    }
  }, [facility]);
  useDidMountEffect(() => {
    if (areaType === 'M') {
      setArea(Math.round(area * 3.3058));
    } else {
      setArea(Math.round(area / 3.3058));
    }
  }, [areaType]);

  useEffect(() => {
    getLandInfoApi();
  }, [isMobile(window.innerWidth) ? location.state.location : location2]);

  useEffect(() => {
    if (index > recommendCrop.length - 1) {
      setSelectedCrop(anotherCrop[index - recommendCrop.length]);
    } else {
      setSelectedCrop(recommendCrop[index]);
    }
  }, [index]);

  useEffect(() => {
    if (type !== 'mypage') {
      dispatch(
        Location(
          isMobile(window.innerWidth) ? location.state.location : location2,
        ),
      );
      dispatch(
        EstimatedPrice(
          isMobile(window.innerWidth)
            ? location.state.info.estimatedPrice
            : estimatedPrice2,
        ),
      );
      dispatch(
        EstimatedRentalPrice(
          isMobile(window.innerWidth)
            ? location.state.info.estimatedRentalPrice
            : estimatedRentalPrice,
        ),
      );
    }
  }, []);

  useEffect(() => {
    if (position > 690 && position < 875) {
      setSubTab(1);
    } else if (position > 875 && position < 990) {
      setSubTab(2);
    } else if (position > 990 && position < 1085) {
      setSubTab(3);
    } else if (position > 1085 && position < 1220) {
      setSubTab(4);
    } else if (position > 1220) {
      setSubTab(5);
    }
  }, [position]);

  const SetCostControl = (item: any) => {
    if (!selectedCrop) {
      return (
        <CostControl
          title={item.title}
          item={item}
          value={0}
          minusClick={() => {}}
          disabled={true}
          onChange={() => {}}
          plusClick={() => {}}
        />
      );
    } else {
    }
    const updatedSelectedCrop = {
      ...selectedCrop,
      landPurchaseCost:
        selectedCrop.landPurchaseCost || landInfo.estimatedPrice,
      farmMachineryCost: selectedCrop.farmMachineryCost || 0,
    };
    return (
      <CostControl
        title={item.title}
        item={item}
        value={Number(
          updatedSelectedCrop[item.state as 'facility'],
        ).toLocaleString()}
        minusClick={() =>
          setSelectedCrop((prev: any) => {
            return {
              ...prev,
              [item.state]: Math.round(
                (Number(updatedSelectedCrop[item.state as 'facility']) * 90) /
                  100,
              ),
            };
          })
        }
        disabled={
          (locationHook.pathname.slice(-1) === '/'
            ? locationHook.pathname.slice(0, -1)
            : locationHook.pathname) === '/search/report/cost'
        }
        onChange={(e: any) => {
          setSelectedCrop(
            (prev: CropInfo) =>
              ({
                ...prev,
                [item.state]: Number(e.target.value.split(',').join('')),
              }) as CropInfo,
          );
        }}
        plusClick={() =>
          setSelectedCrop((prev: any) => {
            return {
              ...prev,
              [item.state]: Math.round(
                (Number(updatedSelectedCrop[item.state as 'facility']) * 110) /
                  100,
              ),
            };
          })
        }
      ></CostControl>
    );
  };

  const calculateTotalCost = () => {
    let total = 0;
    dummyCost.forEach((item) => {
      if (item.title !== '토지 매입비' || purchaseType !== 'R') {
        const stateKey: keyof CropInfo = item.state as keyof CropInfo;
        let costToAdd = Number(selectedCrop[stateKey]) || 0;
        if (stateKey === 'landPurchaseCost') {
          costToAdd = costToAdd === 0 ? landInfo.estimatedPrice : costToAdd;
        }
        total += costToAdd;
      }
    });
    return total.toLocaleString();
  };

  return (
    <div className="flex-column w-100per  bg-white">
      {onPointAlert ? (
        point >= 2000 ? (
          <PointAlert2
            point={point}
            setOnPointAlert={setOnPointAlert}
            getReportApi={getReportApi}
          />
        ) : (
          <WarnPoint setOnPointAlert={setOnPointAlert} point={point} />
        )
      ) : (
        ''
      )}
      <div className="flex-column bg-light-gray border-b-171E2610">
        <div className="flex-center h-100per"></div>
        <TopNav2 text="영농 사업 계획 입력" close={true} back={true} />
        <div className="flex-column w-100per h-100per gap-16">
          {position > menuHeight ? (
            <div className="flex-column  browser-fixed bg-white browser-w-360 top-0 z-20 border-r-171E2610">
              <div className="flex-row space-between  border-b-171E26 browser h-48 padding-12-23">
                <img
                  src={backArrow}
                  className="hover"
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                <div className="font-medium f-size-16 line-h-24 color-text-primary">
                  {location2}
                </div>
                <div>&nbsp;</div>
              </div>
              <div className="h-56 bg-white padding-22-16 border-b-171E26">
                <div className="font-bold f-size-18 line-h-26 color-text-primary">
                  영농 사업 계획 입력
                </div>
              </div>
              <div
                className="flex-row w-100per browser-of-scroll-horizontal h-60 padding-12-16 gap-8 border-b-171E26"
                ref={containerRef}
                onMouseDown={handleMouseDownEvent}
                onMouseLeave={() => setDragging(false)}
                onMouseUp={() => setDragging(false)}
                onMouseMove={handleMouseMoveEvent}
              >
                {dummy.map((item, index) => {
                  return (
                    <div
                      className={`border-radius-100 padding-6-16 border-171E26 pointer ${
                        subTab === index ? `bg-primary` : ``
                      }`}
                      onClick={() => {
                        setSubTab(index);
                        scrollTo(item.ref);
                      }}
                    >
                      <div
                        className={`font-medium f-size-16 line-h-24 no-wrap ${
                          subTab === index
                            ? `color-white`
                            : `color-text-tertiary`
                        }`}
                      >
                        {item.title}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="flex-column gap-8 padding-24-16-8-16">
              <BoxGreen1>
                <div className="font-bold f-size-14 line-h-20 color-primary-500">
                  영농 사업 계획을 입력하면 해당 농지의 예상 경작 수익을 확인할
                  수 있습니다.
                </div>
              </BoxGreen1>
              <div
                className="font-bold f-size-20 line-h-29 color-text-primary"
                ref={ref}
              >
                영농 사업 계획 입력
              </div>
              <div className="flex-row space-between center-y tablet">
                <div className="font-medium f-size-12 line-h-14 color-text-secondary">
                  {isMobile(window.innerWidth)
                    ? location.state.location
                    : location2}
                </div>
              </div>
            </div>
          )}
          <div className="flex-column padding-x-16 gap-16">
            <PointColumn
              type="FS"
              address={
                isMobile(window.innerWidth)
                  ? location.state.location
                  : location2
              }
            />

            <div ref={menuRef1}>
              <FacilitySelect
                setAreaType={setAreaType}
                facility={facility}
                setFacility={setFacility}
                area={area}
                originalArea={
                  isMobile(window.innerWidth)
                    ? location.state.originalArea
                    : originalArea
                }
                setArea={setArea}
              />
            </div>
            <div ref={menuRef2}>
              <CropSelect
                crop={crop}
                setCrop={setCrop}
                recommendCrop={recommendCrop}
                facility={facility}
                anotherCrop={anotherCrop}
                setIndex={setIndex}
                index={index}
                spinner={spinner}
              />
            </div>
            <div ref={menuRef3}>
              <Area
                areaType={areaType}
                setAreaType={setAreaType}
                number="3"
                area={area}
                setArea={setArea}
                pyeong={pyeong}
                setPyeong={setPyeong}
                recommendCropApi={recommendCropApi}
              />
            </div>
            <div ref={menuRef4}>
              <CultivateCount count={cultivateCnt} setCount={setCultivateCnt} />
            </div>
            <div ref={menuRef5}>
              <PurchaseType type={purchaseType} setType={setPurchaseType} />
            </div>
            <div ref={menuRef6}>
              <div className="report_result_box flex-column padding-20-20 border-radius-8 bg-white gap-20">
                <SectionTitle title="6. 초기 투자비" />
                {dummyCost.map((item) => {
                  if (item.title === '토지 매입비' && purchaseType === 'R') {
                    return '';
                  }
                  return SetCostControl(item);
                })}

                <div className="flex-row space-between">
                  <div className="font-bold f-size-14 line-h-20 color-text-primary">
                    총액
                  </div>
                  <div className="font-bold f-size-16 line-h-24 color-text-primary">
                    {selectedCrop ? calculateTotalCost() : 0}원
                  </div>
                </div>
              </div>
            </div>
            <div ref={menuRef7}>
              <CropSkill
                score={score}
                setScore={setScore}
                age={age}
                setAge={setAge}
                live={live}
                setLive={setLive}
                experience={experience}
                setExperience={setExperience}
                graduate={graduate}
                setGraduate={setGraduate}
                setYear={setYear}
                year={year}
                setLiveDate={setLiveDate}
                setFarmDate={setFarmDate}
                setActualHours={setActualHours}
                setOnlineHours={setOnlineHours}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="padding-16">
        <Button
          description="농지가치 평가보고서 보기"
          on={BtnOn}
          onClick={() => {
            if (BtnOn) {
              checkReport();
            }
          }}
        />
      </div>
    </div>
  );
};

export default LandValueReport;
