import { useLocation, useNavigate } from 'react-router-dom';
import UserService from '../../service/checkAccount/CheckAccount';
import { useEffect, useState } from 'react';
import Button, { Button2 } from '../mobile/Button';

const WarnPoint = ({ point, setOnPointAlert }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const Row = ({ title, content, green }: any) => (
    <div className="flex-row space-between w-100per">
      <div
        className={`m-b2-14-m ${
          green ? `color-1E9B6B` : `color-text-tertiary`
        }`}
      >
        {title}
      </div>
      <div
        className={`m-b2-14-b ${
          green ? `color-1E9B6B` : `color-dark-secondary`
        }`}
      >
        {content}
      </div>
    </div>
  );

  return (
    <div
      className="fixed z-999 center-x center-y padding-24"
      style={{
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: '#00000075',
      }}
    >
      <div className="bg-FFFFFF padding-24-16 flex-column flex-center border-radius-16 w-312 gap-24">
        <div className="flex-column gap-16 w-100per">
          <div className="flex-column w-100per">
            <div className="w-100per end-x">
              <img
                src="/asset/images/close/CloseGray24.svg"
                className="pointer"
                onClick={() => {
                  setOnPointAlert(false);
                }}
              />
            </div>
            <div className="flex-column gap-8 center-y">
              <div className="m-b1-16-b color-dark-primary">
                포인트가 부족합니다.
              </div>
              <div className="m-b2-14-m color-disabled">
                충전소에서 부족한 포인트를 채워보세요.
              </div>
            </div>
          </div>
          <div className="flex-column padding-16 gap-16 w-100per bg-light-gray-100 border-radius-8">
            <Row
              title="이용 서비스"
              content={
                location.pathname === '/price/map'
                  ? '가격 분석'
                  : location.pathname === '/auction/map'
                    ? '매물 분석'
                    : location.pathname === '/annuity/map'
                      ? '농지연금 분석'
                      : location.pathname === '/findland/map'
                        ? '농지 찾기'
                        : location.pathname === '/aiFarming/map'
                          ? '내 농장 진단'
                          : location.pathname === '/house/map'
                            ? '내 집 짓기'
                            : ''
              }
            />
            <Row title="차감 포인트" content="-2,000P" />
            <Row
              green
              title="부족한 포인트"
              content={(2000 - point).toLocaleString() + 'P'}
            />
          </div>
        </div>
        <div className="flex-row gap-8 w-100per">
          <div
            className="w-50per pointer border-radius-8 bg-blue-gray-100 padding-12 flex-center"
            onClick={() => {
              setOnPointAlert(false);
            }}
          >
            <div className="m-b2-14-m color-disabled">닫기</div>
          </div>
          <div
            className="w-50per pointer border-radius-8 bg-primary-500 padding-12 flex-center"
            onClick={() => {
              navigate('/point/recharge');
            }}
          >
            <div className="m-b2-14-m color-FFFFFF">충전소 바로가기</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarnPoint;
