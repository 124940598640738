import { TopNav2 } from '../../../component/mobile/Topnav';
import { useState, useEffect, useRef } from 'react';
import PurchaseType from './input/SelectBox';

import Button, { Button2 } from '../../../component/mobile/Button';
import SelectBox from './input/SelectBox';
import HouseService from '../../../service/house/landApi';
import LandService from '../../../service/land/landApi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { isMobile, transNum } from '../../../component/function/function';
import Base from './input/Base';
import Intro from './input/Intro';
import { Slider } from '@mui/material';
import PensionService from '../../../service/pension/pensionApi';

import { useDispatch, useSelector } from 'react-redux';

import Toast from '../../../component/common/Toast';
import UserService from '../../../service/checkAccount/CheckAccount';
import { useAuth } from '../../../utilities/auth';
import { Tooltip2 } from '../../../component/common/tooltip';
import { BoxDateSelector1 } from '../../../component/report/Box';
import SignController from '../../../controller/sign/SignController';
import { PointAlert2 } from '../../../component/common/pointAlert2';
import LoginPopUp from '../../../component/common/LoginPopUp';
import { LoginPopUp2 } from '../../../component/common/LoginPopUp2';
import { LoadingDot } from '../../../store/loadingSlice';
import useDidMountEffect from '../../../hooks/UseDidMountEffect';
import { PointColumn } from '../../../component/common/pointColumn';
import WarnPoint from '../../../component/common/warnPoint';
import ReportService from '../../../service/report/reportApi';

const PensionInput = ({
  setReportIdx,
  setClose,
  address,
  close,
  props,
  onReport3,
}: any) => {
  const location = useLocation();

  const temp = useRef<HTMLDivElement>(null);
  const temp2 = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state2 = useSelector((state2: any) => {
    return state2.type;
  });
  const isHave = [
    { title: '네', type: 'Y' },
    { title: '아니오', type: 'N' },
  ];
  const [point, setPoint] = useState(0);
  const locationHook = useLocation();
  const [query, setQuery] = useState('');
  const [landInfo, setLandInfo] = useState({
    pnuCode: '',
  });
  const [farmValueRange, setFarmValueRange] = useState({
    officialPrice: 618841600,
    estimatedPrice: 1869618655,
    estimatedPrice90Per: 1682656790,
  });
  const [farmValue, setFarmValue] = useState(1682656790);
  const params = useParams();

  useEffect(() => {
    if (isMobile(window.innerWidth)) {
      if (params.address) {
        setQuery(params.address);
      } else {
        setQuery('');
      }
    } else {
      if (
        locationHook.search.split('=')[1] &&
        !locationHook.search.includes('n_media')
      ) {
        setQuery(decodeURI(locationHook.search.split('=')[1]));
      } else {
        setQuery('');
      }
    }
  }, [locationHook]);

  useEffect(() => {
    let q = query;
    const info = async (query: string) => {
      dispatch(LoadingDot('Y'));
      try {
        if (query) {
          const res2 = await LandService.infoPension(query);
          const res3 = await PensionService.value(query);
          setFarmValueRange(res3.result);
          setFarmValue(res3.result.estimatedPrice90Per);
          setLandInfo({
            pnuCode: res2.result.pnuCode,
          });

          console.log('res2.result.pnuCode', res2.result.pnuCode);

          // dispatch(LoadingDot("N"));
        }
      } catch (err) {
        dispatch(LoadingDot('N'));
        console.log(err);
      }
    };
    info(q);
  }, [query]);
  useDidMountEffect(() => {
    console.log('tw', landInfo.pnuCode);
    if (landInfo.pnuCode !== '') {
      dispatch(LoadingDot('N'));
    }
  }, [landInfo.pnuCode]);
  const [maxPyeongValue, setMaxPyeongValue] = useState(81);
  const [loginPopUp, setLoginPopUp] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [onDate, setOnDate] = useState(false);
  const [onToast, setOnToast] = useState(false);
  const [age, setAge] = useState('1964-01');
  const [career, setCareer] = useState<number | undefined | string>(5);
  const [isFarm, setIsFarm] = useState('Y');
  const [isHave2, setIsHave2] = useState('Y');
  const [isNear, setIsNear] = useState('Y');
  const [isNear2, setIsNear2] = useState('Y');
  const [isRight, setIsRight] = useState('N');
  const [isIllegal, setIsIllegal] = useState('N');
  const [isShare, setIsShare] = useState('N');
  const [onPointAlert, setOnPointAlert] = useState(false);
  const CheckPoint = async () => {
    try {
      const result = await UserService.checkPoint();

      setPoint(result.data.data.freePoint + result.data.data.paidPoint);
    } catch (error) {
      console.log(error);
    }
  };

  const checkReport = async () => {
    try {
      const result = await ReportService.check(
        state2.priceType === 'land' ? 'PF' : 'PA',
        isMobile(window.innerWidth)
          ? location.state.address
          : decodeURI(locationHook.search.split('=')[1]),
      );
      console.log('reuslt', result);

      if (result.isExist === false) {
        setOnPointAlert(true);
      } else {
        save();
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    console.log('state2.priceType', state2.priceType);
  }, [state2.priceType]);
  const save = async () => {
    try {
      if (!isMobile(window.innerWidth)) {
        setClose(false);
      }
      const isNearPlace = isNear === 'Y' || isNear2 === 'Y' ? 'Y' : 'N';
      const result = await PensionService.save(
        state2.priceType === 'land' ? 'P' : 'A',
        landInfo.pnuCode,
        age,
        career,
        isFarm,
        isHave2,
        isNear,
        isNear2,
        isRight,
        isIllegal,
        isShare,
        farmValue,
      );
      if (result.data.success) {
        if (isMobile(window.innerWidth)) {
          navigate(`/annuity/report/${result.data.reportIdx}`);
        } else {
          setClose(true);
          setReportIdx(result.data.reportIdx);
        }
      }
    } catch (err: any) {
      console.log(err);
    }
  };
  function calculateAge(birthdate: string) {
    var currentDate = new Date();

    var birthdateDate = new Date(birthdate);

    var age = currentDate.getFullYear() - birthdateDate.getFullYear();

    if (
      currentDate.getMonth() < birthdateDate.getMonth() ||
      (currentDate.getMonth() === birthdateDate.getMonth() &&
        currentDate.getDate() < birthdateDate.getDate())
    ) {
      age--;
    }
    return age;
  }
  // Toast
  useEffect(() => {
    if (onToast) {
      setTimeout(() => {
        setOnToast(false);
      }, 3000);
    }
  }, [onToast]);
  const [name, setName] = useState('');
  const { isLogin } = useAuth();
  useEffect(() => {
    if (isLogin()) {
      SignController.getName(setName);
    }
    CheckPoint();
  }, []);

  const [toolTip, setToolTip] = useState(false);
  return (
    <div
      className={`flex-column w-100per bg-light-gray  browser-h-100per`}
      ref={temp2}
    >
      {onPointAlert ? (
        point >= 2000 ? (
          <PointAlert2
            setOnPointAlert={setOnPointAlert}
            save={save}
            point={point}
          />
        ) : (
          <WarnPoint setOnPointAlert={setOnPointAlert} point={point} />
        )
      ) : (
        ''
      )}
      <div className="flex-column bg-light-gray border-b-171E2610 h-100per">
        {loginPopUp && (
          // <LoginPopUp
          //   setOnLoginPopup={setLoginPopUp}
          //   address={props.address}
          //   pathName={locationHook.pathname}
          // />
          <LoginPopUp2
            address={props.address}
            setOnLoginPopup={setLoginPopUp}
            // setOnLoginPopup={setOnLoginPopup}
            // address={props.address}
            // pathName={locationHook.pathname}
          />
        )}
        {onToast && (
          <div className="flex-center z-100 ">
            <Toast text="가입조건을 모두 입력해주세요." bottom="100" />
          </div>
        )}
        <TopNav2 text="가입조건 확인하기" close back />
        <div
          className="flex-column w-100per h-100per gap-16 "
          style={{ overflowX: 'auto' }}
          // style={{ overflowX: "hidden" }}
        >
          <div className="flex-column" style={{ padding: '32px 16px 0 16px' }}>
            <span className="font-bold f-size-20 line-h-29 color-text-secondary padding-x-16">
              농지연금 가입 조건 입력하기
            </span>
            <div className="font-medium f-size-12 line-h-14 color-text-secondary padding-x-16">
              {isMobile(window.innerWidth) && location.state.address}
            </div>
          </div>
          <div className="padding-x-16">
            <PointColumn
              type={state2.priceType === 'land' ? 'PF' : 'PA'}
              address={
                isMobile(window.innerWidth)
                  ? location.state.address
                  : props.address
              }
            />
          </div>
          <Intro></Intro>
          <Base
            title="1. 농지가치 설정하기"
            warn
            onClick={() => setToolTip(true)}
          >
            <Tooltip2
              text="※ AI 추정가는 트랜스파머가 약 17억개 데이터와 인근 실거래를 기반으
              로 해당 농지의 예상 가치를 계산한 것입니다. 이는 농지연금 수령액
              결정에 영향을 미치지 않으며, 참고용으로만 사용하시길 권장합니다."
              on={toolTip}
              width
              top="0px"
              off={() => setToolTip(false)}
            />
            <div className="font-medium f-size-14 line-h-20 color-text-secondary padding-x-16">
              농지연금 수령액은 농지 가치에 따라 달라집니다. 트랜스파머 AI AI
              추정가를 참고하여 해당 농지의 가치를 설정해보세요.
            </div>
            <div className="flex-column padding-8-16-0 gap-16">
              <div className="flex-column w-100per gap-8">
                <div className="flex-row space-between">
                  <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                    안정적
                  </div>
                  <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                    공격적
                  </div>
                </div>
                <Slider
                  value={farmValue}
                  onChange={(e: any, value: any) => {
                    setFarmValue(Math.round(value / 10000) * 10000);
                  }}
                  onChangeCommitted={(e: any, value: any) => {}}
                  marks={(() => {
                    let arr = [];
                    for (
                      let i = farmValueRange.officialPrice;
                      i < farmValueRange.estimatedPrice;
                      i +=
                        (farmValueRange.estimatedPrice -
                          farmValueRange.officialPrice) /
                        10
                    ) {
                      arr.push({ value: i });
                    }
                    return arr;
                  })()}
                  valueLabelDisplay={'on'}
                  slotProps={{ valueLabel: { style: { display: 'none' } } }}
                  valueLabelFormat={(value: number, index: number) => (
                    <div
                      className="speech-bubble font-bold f-size-11 line-h-12 border-radius-2"
                      style={{
                        position: 'sticky',

                        backgroundColor: '#2A3746',
                        color: 'white',
                        padding: '4px 5px',
                        marginLeft: 0,
                        marginBottom: 0,
                      }}
                    >
                      {transNum(Math.round(value))}원
                    </div>
                  )}
                  step={
                    farmValueRange.officialPrice >= 100000000
                      ? 10000000
                      : farmValueRange.officialPrice >= 10000
                        ? 10000
                        : 1000
                  }
                  tabIndex={10}
                  // getAriaValueText={valuetext}
                  sx={{
                    height: '8px',
                    '& .MuiSlider-thumb': {
                      color: '#166F4D',
                    },
                    '& .MuiSlider-track': { color: '#1E9B6B' },
                    '& .MuiSlider-rail': { color: '#F0F0F0' },
                    '& .MuiSlider-active': {
                      color: 'green',
                    },
                    '& .Mui-active': {
                      boxShadow: '#1E9B6B30 0px 0px 0px 12px',
                    },
                    '& .MuiSlider-valueLabel': { background: 'none' },
                    '& .MuiSlider-mark': {
                      backgroundColor: '#FFFFFF',
                      height: 8,
                    },
                  }}
                  min={farmValueRange.officialPrice}
                  max={farmValueRange.estimatedPrice}
                />
                <div className="flex-row w-100per">
                  {[
                    { 1: '', 2: '공시지가', 3: 'start-y' },
                    { 1: '', 2: 'AI 추정가', 3: 'end-y' },
                  ].map((el: any) => (
                    <div className={'flex-column flex-1 ' + el['3']}>
                      <div className="font-medium f-size-12 line-h-14 color-text-tertiary">
                        {el['2']}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                ※ 실제 연금액을 결정하는 농지 가치는 공시지가 100% 또는 감정평가
                액의 90%로 산정합니다.
              </div>
            </div>
          </Base>
          <Base title="2. 가입연령">
            <div className="padding-x-16 gap-24 flex-column">
              <div className="flex-row space-between center-y padding-b-16 border-b-1-171E2610">
                <div className="font-bold f-size-16 line-h-24 color-text-secondary">
                  생년월일
                </div>
                <div
                  className="flex-row padding-6-8 bg-light-gray-200 border-radius-8 gap-8 hover"
                  onClick={() => {
                    setOnDate(true);
                  }}
                >
                  <img src="/asset/images/calender/Calender.svg" alt="" />
                  <div className="font-medium f-size-14 line-h-24 color-text-secondary">
                    {(typeof age === 'string' && (age as any).slice(0, 7)) ||
                      '1964-01'}
                  </div>
                </div>
              </div>
              {age ? (
                <div className="flex-row space-between">
                  <div className="font-bold f-size-16 line-h-24 color-text-secondary">
                    만 {calculateAge(age)}세
                  </div>
                  <div className="font-bold f-size-16 line-h-24 color-primary-500">
                    가입기준 {60 <= calculateAge(age) ? '' : '미'}
                    충족
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className="flex-column gap-4">
                <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                  ※ 농지연금은 만 60세 이상인 자만 가입 가능합니다. <br />
                  (신청년도 말일 기준)
                </div>
                <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                  ※ 2024년 기준 : 1964년 12월 31일 이전 출생자
                </div>
              </div>
            </div>
          </Base>
          {onDate ? (
            <BoxDateSelector1
              close={() => setOnDate(false)}
              date={age}
              setDate={setAge}
            />
          ) : (
            ''
          )}
          <Base title="3. 영농경력">
            <div className="padding-x-16 gap-16 flex-column">
              <div className="flex-row center-y space-between">
                <div
                  className="flex-row padding-8 gap-4 border-radius-8"
                  style={{
                    border: '#171e2610 1px solid',
                    width: 'fit-content',
                  }}
                >
                  <input
                    className="border-none font-medium f-size-14 line-h-20 text-end"
                    style={{ width: '118px' }}
                    placeholder="입력해주세요"
                    type="number"
                    value={career}
                    onChange={(e) => setCareer(e.target.value)}
                  />
                  <div className="font-bold f-size-16 line-h-24 color-text-secondary">
                    년
                  </div>
                </div>
                {career ? (
                  <div className="font-bold f-size-16 line-h-23 color-primary-500">
                    가입기준{' '}
                    {typeof Number(career) === 'number' && Number(career) > 4
                      ? ''
                      : '미'}
                    충족
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                ※ 농지연금 가입기준 영농경력은 5년 이상입니다.
              </div>
            </div>
          </Base>
          <Base title="4. 농지조건">
            <div className="padding-x-16">
              <div className="flex-row space-between center-y padding-b-24 border-b-1-171E2610">
                <SelectBox
                  num="1)"
                  title="실제 영농에 이용되고 있는 농지인가요?"
                  type={isFarm}
                  setType={setIsFarm}
                  arr={isHave}
                ></SelectBox>
              </div>
            </div>
            <div className="padding-x-16 padding-t-16">
              <div className="flex-column space-between center-y padding-b-24 border-b-1-171E2610 gap-16">
                <SelectBox
                  num="2)"
                  title="2년 이상 보유한 농지인가요?"
                  type={isHave2}
                  setType={setIsHave2}
                  arr={isHave}
                ></SelectBox>
                <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                  ※ 상속받은 농지의 경우, 피상속인의 보유 기간도 인정됩니다.
                </div>
              </div>
            </div>
            <div className="padding-x-16 padding-t-16">
              <div className="flex-column space-between center-y padding-b-24 border-b-1-171E2610 gap-16">
                <SelectBox
                  num="3)"
                  title="신청자의 거주지 주소지와 농지 소재지가 동일하거나 인접한 시/군/구인가요?"
                  type={isNear}
                  setType={setIsNear}
                  arr={isHave}
                ></SelectBox>
              </div>
            </div>
            <div className="padding-x-16 padding-t-16">
              <div className="flex-column space-between center-y gap-16">
                <SelectBox
                  num="4)"
                  title="신청자의 주소지와 농지까지의 직선거리가 30km 이내인가요?"
                  type={isNear2}
                  setType={setIsNear2}
                  arr={isHave}
                ></SelectBox>
              </div>
            </div>
          </Base>
          <Base title="5. 가입제한">
            <div className="padding-x-16">
              <div className="flex-column space-between center-y padding-b-24 border-b-1-171E2610 gap-16">
                <SelectBox
                  num="1)"
                  title="저당권, 제한물건, 압류 등 소유권 이외의 권리가 설정되어 있나요?"
                  type={isRight}
                  setType={setIsRight}
                  arr={isHave}
                ></SelectBox>
                <div className="flex-column gap-4">
                  <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                    ※ 제한물권 선순위 채권 최고액의 15% 미만은 가능합니다.
                  </div>
                  <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                    ※ 수시인출형 농지연금 가입시, 인출제한물권(선순위채권)이 30%
                    이하도 조건부 가능합니다.
                  </div>
                </div>
              </div>
            </div>
            <div className="padding-x-16 padding-t-16">
              <div className="flex-column space-between center-y padding-b-24 border-b-1-171E2610 gap-16">
                <SelectBox
                  num="2)"
                  title="농업용 목적이 아닌 시설 및 불법건축물이 설치되어 있나요?"
                  type={isIllegal}
                  setType={setIsIllegal}
                  arr={isHave}
                ></SelectBox>
              </div>
            </div>
            <div className="padding-x-16 padding-t-16">
              <div className="flex-column space-between center-y gap-16">
                <SelectBox
                  num="3)"
                  title="본인 및 배우자(부부) 이외의 자가 공동 소유했나요?"
                  type={isShare}
                  setType={setIsShare}
                  arr={isHave}
                ></SelectBox>
              </div>
            </div>
          </Base>
        </div>
      </div>
      <div
        className=" w-100per bg-FFFFFF "
        style={{ marginTop: '16px' }}
        ref={temp}
      >
        <div className="padding-16">
          <Button
            description="농지연금 분석보고서 보기"
            on={
              isFarm &&
              isHave2 &&
              isNear &&
              isNear2 &&
              isRight &&
              isIllegal &&
              isShare &&
              Number(career) > 0 &&
              age
            }
            onClick={() => {
              isLogin()
                ? isFarm &&
                  isHave2 &&
                  isNear &&
                  isNear2 &&
                  isRight &&
                  isIllegal &&
                  isShare &&
                  Number(career) > 0 &&
                  age
                  ? checkReport()
                  : setOnToast(true)
                : setLoginPopUp(true);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PensionInput;
