import { useEffect, useRef, useState } from 'react';
import Button from '../../component/mobile/Button';
import { TopNav2 } from '../../component/mobile/Topnav';
import { useLocation, useNavigate } from 'react-router-dom';
import { isMobile } from '../../component/function/function';
import { Btn } from '../../asset/styles/mobile/aiFraming/AIContents';
import { Refund } from './tab/Refund';

const PointMain = ({ setOpen }: any) => {
  const temp = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const PriceRow = ({ text1, price, src }: any) => (
    <div
      className={`flex-row gap-16 padding-16 ${
        text1 === '내 집 짓기' ? `` : `border-b-171E26`
      }`}
    >
      <div className="w-50per flex-row gap-10">
        <img src={src} />
        <div className="m-b1-16-m color-dark-primary no-wrap">{text1}</div>
      </div>
      <div className="w-50per flex-center">
        <div className="m-b1-16-b color-dark-primary no-wrap">{price}</div>
      </div>
    </div>
  );

  const Box1 = ({ text, text2, src }: any) => (
    <div className="flex-column flex-center gap-8 border-radius-8 border-171E26 padding-y-16 w-50per">
      <img
        // className={`w-24 ${text2 === "매물 분석" ? `h-24` : `h-24 `}`}
        src={src}
        alt="icon"
      />
      <div className="flex-column flex-center">
        <div className="m-c1-14-m color-dark-secondary ">{text}</div>
        <div className="m-b1-16-b color-primary-500 ">{text2}</div>
      </div>
    </div>
  );
  useEffect(() => {
    if (temp.current) {
      // 페이지 초기 렌더링 시에만 paddingBottom 설정
      if (!isMobile(window.innerWidth)) {
        document.body.style.paddingBottom = temp.current.clientHeight + 'px'; // paddingBottom 설정
      }
    }

    // cleanup 함수
    return () => {
      document.body.style.paddingBottom = ''; // 이전에 설정한 paddingBottom을 초기화
    };
  }, []);
  const [tab, setTab] = useState(0);
  return (
    <div
      style={{ paddingBottom: '92px' }}
      className={`w-100per flex-column  ${
        !isMobile(window.innerWidth) && `border-radius-16`
      }`}
    >
      <TopNav2 hamburger back text="포인트 안내" />
      {!isMobile(window.innerWidth) && (
        <div
          className="bg-FFFFFF padding-24-16 flex-row space-between center-y fixed border-radius-16-t-l border-radius-16-t-r"
          style={{ width: isMobile(window.innerWidth) ? '100%' : '370px' }}
        >
          <div className="m-b1-16-b color-dark-primary">포인트 안내</div>
          <img
            className="pointer"
            src="/asset/images/close/CloseBlack24.svg"
            alt="close"
            onClick={() => {
              setOpen(false);
            }}
          />
        </div>
      )}
      <div
        className="flex-column"
        style={{
          marginTop: isMobile(window.innerWidth) ? '0' : '72px',
          width: isMobile(window.innerWidth) ? '100%' : '370px',
        }}
      >
        <img
          src={
            tab === 0
              ? '/asset/images/banner/point_banner.svg'
              : '/asset/images/banner/refund_banner.svg'
          }
          alt="banner"
          className="w-100per"
        />
        <div className="w-100per flex padding-16 ">
          <div
            className="w-100per border-radius-8 flex-row"
            style={{
              border: '1px solid #F0F0F0',
              backgroundColor: '#F0F0F0',
              padding: '2px',
            }}
          >
            <div
              className="w-50per flex-center pointer"
              onClick={() => {
                setTab(0);
              }}
              style={{
                backgroundColor: tab === 0 ? '#FFFFFF' : '#F0F0F0',
                borderTopLeftRadius: '8px',
                borderBottomLeftRadius: '8px',
                borderTopRightRadius: '8px',
                borderBottomRightRadius: '8px',
                padding: '6px',
              }}
            >
              <div
                className={`m-b1-16-m ${
                  tab === 0 ? `color-dark-primary` : `color-disabled`
                }`}
              >
                포인트 안내
              </div>
            </div>
            <div
              className="w-50per flex-center pointer"
              style={{
                backgroundColor: tab === 1 ? '#FFFFFF' : '#F0F0F0',
                borderTopLeftRadius: '8px',
                borderBottomLeftRadius: '8px',
                borderTopRightRadius: '8px',
                borderBottomRightRadius: '8px',
                padding: '6px',
              }}
              onClick={() => {
                setTab(1);
              }}
            >
              <div
                className={`m-b1-16-m ${
                  tab === 1 ? `color-dark-primary` : `color-disabled`
                }`}
              >
                환불 안내
              </div>
            </div>
          </div>
        </div>
        {tab === 0 ? (
          <div className="flex-column">
            <div className="flex-column padding-40-16 gap-24 flex-center">
              <div className="m-t2-18-b color-primary-500">
                가장 쉬운 농지테크, 트랜스파머
              </div>
              <div className="flex-column gap-20 flex-center">
                <div
                  className="m-b2-14-m color-text-tertiary"
                  style={{ textAlign: 'center' }}
                >
                  트랜스파머는 더 편리하고 향상된 서비스 제공을 위해 <br /> 일부
                  서비스를 유료로 제공하고 있습니다.
                </div>
                <div
                  className="m-b2-14-m color-text-tertiary"
                  style={{ textAlign: 'center' }}
                >
                  유료 서비스는 포인트 충전을 통해 이용 가능하며, <br />첫 회원
                  가입 시에는 트랜스파머 서비스를 <br />
                  무료로 이용하실 수 있도록 <br />
                  가입 감사 포인트를 드리고 있습니다.
                </div>
              </div>
            </div>

            <div className="flex-column padding-40-16 gap-32">
              <div className="flex-column gap-16 flex-center">
                <div className="border-radius-20 padding-8-16 bg-primary-500">
                  <div className="n-b2-14-m color-FFFFFF">포인트 안내</div>
                </div>
                <div className="flex-column gap-8 flex-center">
                  <div className="m-t2-18-b color-dark-primary">
                    유료 서비스 이용에 필요한 포인트(P)입니다.
                  </div>
                </div>
              </div>
              <div className="border-171E26 padding-y-24 flex-center border-radius-16 flex-row gap-16">
                <div className="flex-row gap-8">
                  <img src="/asset/images/icon/point_point.svg" alt="" />
                  <div className="m-b1-16-b color-dark-primary">100P</div>
                </div>
                <img src="/asset/images/icon/point_equal.svg" alt="" />
                <div className="flex-row gap-8">
                  <img src="/asset/images/icon/point_money.svg" alt="" />
                  <div className="m-b1-16-b color-dark-primary">
                    100원
                    <span className="f-size-12 font-medium color-disabled">
                      {' '}
                      (VAT 포함)
                    </span>
                  </div>
                </div>
              </div>
              <div className="border-171E26 padding-24-16 flex-center border-radius-16 flex-column gap-24">
                <div className="m-b1-16-b color-dark-primary">
                  유료 서비스 이용금액
                </div>
                <div className="w-100per  padding-16 bg-F7F8F9 border-radius-8 flex-row space-between">
                  <span className="font-medium f-size-16">이용금액</span>
                  <span className="font-bold f-size-16">2,000원</span>
                </div>
                <div className="flex-column gap-20 flex-center">
                  <div
                    className="m-b2-14-m color-text-tertiary"
                    style={{ textAlign: 'center' }}
                  >
                    AI 추천 작물별 예상 소득 분석, 농지연금 예상 수령액 <br />{' '}
                    분석, 전원주택 건축비 분석 등의 전문적인 서비스를 <br />
                    저렴한 유료 서비스로 제공하여 귀농, 귀촌 생활에 <br />{' '}
                    도움을 드리고 있습니다.
                  </div>
                </div>
              </div>
              <div className="w-100per  padding-16 bg-F7F8F9 border-radius-8 flex-column gap-8">
                <span className="font-bold f-size-12 color-text-tertiary">
                  유의 사항
                </span>
                <div className="flex-row gap-8 flex-start">
                  <div
                    style={{
                      width: '2px',
                      height: '2px',
                      borderRadius: '50px',
                      backgroundColor: 'rgba(23, 30, 38, 0.4)',
                      boxSizing: 'border-box',
                      marginTop: '6px',
                    }}
                  ></div>
                  <p className="f-size-12 font-medium color-disabled">
                    충전하신 유료 포인트는 충전하신 날로부터 5년 이내에 소진해야
                    합니다.
                  </p>
                </div>
                <div className="flex-row gap-8 flex-start">
                  <div
                    style={{
                      width: '2px',
                      height: '2px',
                      borderRadius: '50px',
                      backgroundColor: 'rgba(23, 30, 38, 0.4)',
                      boxSizing: 'border-box',
                      marginTop: '6px',
                    }}
                  ></div>
                  <p className="f-size-12 font-medium color-disabled">
                    충전 취소 및 환불에 대한 자세한 사항은 환불 안내 페이지를
                    확인해 주세요.{' '}
                    <span
                      className="font-bold"
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => {
                        setTab(1);
                      }}
                    >
                      환불 안내 확인
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="flex-column gap-32 padding-40-16 bg-light-gray-100">
              <div className="flex-column gap-24">
                <div className="flex-center">
                  <div className="border-radius-20 padding-8-16 bg-primary-500 fit-content">
                    <div className="m-b2-14-m color-FFFFFF">추가 이용안내</div>
                  </div>
                </div>
                <div className="flex-center">
                  <div className="m-t2-18-b color-dark-primary">
                    궁금하신 사항이 있으신가요?
                  </div>
                </div>
              </div>

              <div className="flex-column gap-8">
                <div className="color-dark-secondary font-bold f-size-14 line-h-20">
                  ・법인 이용 문의
                </div>
                <div className="font-medium f-size-16 line-h-24 color-text-tertiary">
                  법인회원(기관, 조합, 기업, 단체 등) 가입과 이용권 구매 할인 및
                  세금계산서 발행을 도와 드립니다.
                </div>
                <div className="flex-row gap-8">
                  <img src="/asset/images/icon/point_phone.svg" />
                  <div className="m-b2-14-m color-dark-secondary">
                    02) 555-2822
                  </div>
                </div>
                <div className="flex-row gap-8">
                  <img src="/asset/images/icon/point_email.svg" />
                  <div className="m-b2-14-m color-dark-secondary">
                    biz@transfarmer.co.kr
                  </div>
                </div>
              </div>
              <div className="flex-column gap-8">
                <div className="flex-column gap-8">
                  <div className="m-b2-14-b color-dark-secondary">
                    ・일반 사용자 문의
                  </div>
                  <div className="flex-row gap-8">
                    <img src="/asset/images/icon/point_phone.svg" />
                    <div className="m-b2-14-m color-dark-secondary">
                      02) 555-2822
                    </div>
                  </div>
                  <div className="flex-row gap-8">
                    <img src="/asset/images/icon/point_email.svg" />
                    <div className="m-b2-14-m color-dark-secondary">
                      biz@transfarmer.co.kr
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-171E26 padding-y-24 flex-center border-radius-8 flex-row gap-16">
                <div className="f-size-14 font-medium color-text-tertiary">
                  평일 09:00 ~ 18:00 (주말 및 공휴일 휴무)
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Refund />
        )}
      </div>
      <div className=" flex relative">
        <div
          className=" flex border-t-171E26  fixed bg-FFFFFF"
          style={{
            padding: '16px 16px 24px 16px',
            height: '92px',
            bottom: isMobile(window.innerWidth) ? '0px' : '10%',
            width: isMobile(window.innerWidth) ? '100%' : '370px',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
          }}
        >
          <div
            className=" padding-14-16 bg-primary border-radius-8 pointer flex-center "
            style={{ width: isMobile(window.innerWidth) ? '100%' : '370px' }}
            onClick={() => {
              isMobile(window.innerWidth) ? navigate(-1) : setOpen(false);
            }}
          >
            <div className="m-b1-16-m color-FFFFFF">확인했어요</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointMain;
