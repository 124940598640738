import { useEffect, useState } from 'react';
import SignUpService from '../../../service/signUp/SignUp';
import Button from '../../../component/mobile/Button';
import { useNavigate } from 'react-router-dom';
import { RegId, RegPassword } from '../../../utilities/reg';

export const Find2 = ({
  name,
  phone,
  setStep,
  setTab,
  tab,
  setSelectedId,
  selectedId,
  setOnPopUp,
  signSns,
  setSignSns,
}: any) => {
  const navigate = useNavigate();

  const [idList, setIdList] = useState([
    { email: '', createdDate: '', loginType: '' },
  ]);

  const [idCheck, setIdCheck] = useState(false);
  const [pwd, setPwd] = useState('');
  const [pwd2, setPwd2] = useState('');
  const [selected, setSelected] = useState(0);
  const [pwdShow, setPwdShow] = useState(false);
  const [pwdCheck, setPwdCheck] = useState(false);
  const [pwdShow2, setPwdShow2] = useState(false);

  const [pwdEqualCheck, setPwdEqualCheck] = useState(false);
  const findId = async () => {
    try {
      const result = await SignUpService.FindId(name, phone);
      console.log('findid', result);
      setIdCheck(true);
      setIdList(result.data.result);
      if (result.data.result === 'No data found') {
        setIdCheck(false);
      }
      if (tab === 0) {
        setSelectedId(result.data.result[0].email);
      }
    } catch (error: any) {
      console.log(error);
      if (error.response.data.message === 'No data found') {
        setIdCheck(false);
      }
    }
  };
  const checkUser = async () => {
    try {
      const result = await SignUpService.CheckUser(selectedId, name, phone);
      console.log(result);
      if (result.data.result === 'Success check user') {
        setIdCheck(true);
      } else if (result.data.result === 'No data found') {
        setIdCheck(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const FindPwdNavigate = () => {
    setTab(1);
    setStep(0);
  };
  const changePwd = async () => {
    const data = {
      email: selectedId,
      phone: phone,
      password: pwd,
    };
    try {
      const result = await SignUpService.ChangePwd(data);
      setOnPopUp(true);
      console.log(result);
    } catch (error: any) {
      console.log(error);
      alert(error.response.data.message);
    }
  };
  useEffect(() => {
    if (tab === 1) {
      setIdCheck(true);
      checkUser();
    }
    findId();
  }, []);
  useEffect(() => {
    if (RegPassword.test(pwd)) {
      setPwdCheck(true);
    } else {
      setPwdCheck(false);
    }
  }, [pwd]);
  useEffect(() => {
    if (pwd === pwd2) {
      setPwdEqualCheck(true);
    } else {
      setPwdEqualCheck(false);
    }
  }, [pwd2, pwd]);
  useEffect(() => {
    if (tab === 0) {
      if (idList) {
        setSelectedId(idList[0].email);
      }
    }
  }, [idList]);
  return (
    <div className="w-100per flex-column space-between h-100per w-1024-max-w-468">
      <div
        className={`flex-column w-100per ${
          window.innerWidth < 1024 && `h-100per`
        }`}
      >
        {idCheck ? (
          tab === 0 ? (
            <div className="flex-column h-100per space-between ">
              <div className=" flex-column gap-32 flex-center padding-x-16">
                <div className="mobile-body-1-bold">아이디 찾기 완료</div>
                <div className="flex-column gap-12 w-1024-w-100per">
                  {idList.map((item, index) => {
                    return (
                      <div className="flex-column gap-4">
                        <div
                          className={`border-radius-16 padding-16 bg-FAFAFA flex-row gap-10 pointer w-1024-w-100per ${
                            selected === index
                              ? item.loginType === 'C'
                                ? `border-2-primary-400`
                                : `border-2-increase`
                              : `border-none`
                          }`}
                          onClick={() => {
                            setSelected(index);
                            setSelectedId(item.email);
                            item.loginType === 'C'
                              ? setSignSns(false)
                              : setSignSns(true);
                          }}
                        >
                          <img
                            src={
                              selected === index
                                ? '/asset/images/check/Check24GreenCircle2.svg'
                                : '/asset/images/check/checkWithGrayCircle.svg'
                            }
                          />
                          <div className="flex-column gap-10 w-100per">
                            <div className="flex-row gap-8">
                              <img
                                src={
                                  item.loginType === 'K'
                                    ? '/asset/images/icon/kakao.svg'
                                    : item.loginType === 'N'
                                      ? '/asset/images/icon/naver.svg'
                                      : ''
                                }
                              />
                              <div className="mobile-title-2-bold color-dark-primary">
                                {item.email}
                              </div>
                            </div>
                            <div className="flex-row space-between center-y w-100per">
                              <div className="mobile-body-2-medium color-primary-300">
                                {index === 0 && '최근사용'}
                              </div>
                              <div className="mobile-body-2-medium color-disabled">
                                {item.createdDate.split('T')[0]} 가입{' '}
                              </div>
                            </div>
                          </div>
                        </div>
                        {selected === index && item.loginType !== 'C' && (
                          <div className="mobile-body-2-medium color-text-increase">
                            {item.loginType === 'K'
                              ? '카카오톡'
                              : item.loginType === 'N'
                                ? '네이버'
                                : ''}{' '}
                            간편 가입으로 가입한 계정입니다. 비밀번호 찾기는
                            이메일로 가입한 경우에만 가능합니다.
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              {!signSns && (
                <div className="padding-16 flex-center">
                  <div className="flex-row space-between gap-8 padding-x-25">
                    <div className="mobile-body-1-medium color-disabled">
                      비밀번호가 기억나지 않나요?
                    </div>
                    <div
                      className="mobile-body-1-medium color-primary-500 pointer"
                      onClick={() => {
                        !signSns && FindPwdNavigate();
                      }}
                    >
                      비밀번호 찾기
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="flex-column padding-24-16-0-16">
              <div className="flex-column gap-32">
                <div className="mobile-body-1-medium color-000000">
                  비밀번호 재설정
                </div>
                <div className="flex-column gap-12">
                  <div
                    className="flex-column gap-2 padding-12-12-12-16  border-radius-13"
                    style={{ backgroundColor: 'rgba(23, 30, 38, 0.1)' }}
                  >
                    <div className="font-regular f-size-12 line-h-18 color-888B90">
                      아이디
                    </div>
                    <div className="flex-row space-between ">
                      <div className="mobile-title-2-medium color-dark-primary">
                        {selectedId}
                      </div>
                    </div>
                  </div>
                  <div className="flex-column gap-4">
                    <div
                      className={`flex-column gap-2 padding-12-12-12-16 bg-FAFAFA border-radius-13 ${
                        !pwdCheck && pwd.length > 0 && `border-increase`
                      } `}
                    >
                      <div className="font-regular f-size-12 line-h-18 color-888B90">
                        새 비밀번호 입력
                      </div>
                      <div className="flex-row space-between bg-FAFAFA">
                        <input
                          type={pwdShow ? 'text' : 'password'}
                          className="border-none w-90per bg-FAFAFA font-medium f-size-17 line-h-23 color-dark-primary"
                          placeholder="새 비밀번호 입력"
                          value={pwd}
                          onChange={(e) => {
                            setPwd(e.target.value);
                          }}
                          onBlur={() => {
                            // RegPassword.test(pwd)
                            //   ? setPwdCheck(true)
                            //   : setPwdCheck(false);
                          }}
                        />
                        <div className="flex-row gap-5">
                          <img
                            className="pointer"
                            onClick={() => {
                              setPwdShow(!pwdShow);
                            }}
                            src={
                              pwdShow
                                ? '/asset/images/pwd/pwdNoShow.svg'
                                : '/asset/images/pwd/pwdShow.svg'
                            }
                          />
                          {pwd.length > 0 && (
                            <img
                              className="pointer"
                              src="/asset/images/close/close_with_circle.svg"
                              onClick={() => {
                                setPwd('');
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    {!pwdCheck && pwd.length > 0 && (
                      <div className="flex padding-x-16">
                        <div className="mobile-body-2-medium color-text-increase mobile-tite-2-medium">
                          영문/숫자/특수문자를 사용하여 8자~16자로 입력
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex-column gap-4">
                    <div className="flex-column gap-2 padding-12-12-12-16 bg-FAFAFA border-radius-13">
                      <div className="font-regular f-size-12 line-h-18 color-888B90">
                        새 비밀번호 확인
                      </div>
                      <div className="flex-row space-between bg-FAFAFA">
                        <input
                          type={pwdShow2 ? 'text' : 'password'}
                          className="border-none w-90per bg-FAFAFA font-medium f-size-17 line-h-23 color-dark-primary"
                          placeholder="새 비밀번호 입력"
                          value={pwd2}
                          onChange={(e) => {
                            setPwd2(e.target.value);
                          }}
                          //   onBlur={() => {
                          //     pwd === pwd2
                          //       ? setPwdEqualCheck(true)
                          //       : setPwdEqualCheck(false);
                          //   }}
                        />
                        <div className="flex-row gap-5 ">
                          <img
                            className="pointer"
                            onClick={() => {
                              setPwdShow2(!pwdShow2);
                            }}
                            src={
                              pwdShow2
                                ? '/asset/images/pwd/pwdNoShow.svg'
                                : '/asset/images/pwd/pwdShow.svg'
                            }
                          />
                          {pwd2.length > 0 && (
                            <img
                              className="pointer"
                              src="/asset/images/close/close_with_circle.svg"
                              onClick={() => {
                                setPwd2('');
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    {!pwdEqualCheck && pwd2.length > 0 && (
                      <div className="flex padding-x-16">
                        <div className="mobile-body-2-medium color-text-increase">
                          패스워드가 일치하지 않습니다.
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="flex-column space-between h-100per padding-24-16-0-16">
            <div className=" gap-28 flex-column ">
              <img
                src="/asset/images/warn/WarnWithGrayCircle.svg"
                className="w-48 h-48"
              />
              <div className="flex-column w-100per flex-center">
                <div className="mobile-body-1-bold color-000000 text-center">
                  입력하신 내용과 일치하는 ID가 없습니다. <br />
                  확인 후 다시 시도해 주세요.
                </div>
                <div className="mobile-body-2-medium color-disabled">
                  이름, 휴대폰번호를 확인해 보세요.
                </div>
              </div>
            </div>
            <div className="flex-row space-between gap-8 padding-16-18">
              <div className="mobile-body-1-medium color-disabled">
                아직 트랜스파머 회원이 아니신가요?
              </div>
              <div
                className="mobile-body-1-medium color-primary-500 pointer"
                onClick={() => {
                  // navigate("/terms");
                  navigate('/signup');
                }}
              >
                회원가입
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="padding-16-16-48-16 flex-center border-t-171E26 ">
        <Button
          on={tab === 1 ? pwdCheck && pwdEqualCheck : true}
          description={
            tab === 1
              ? idCheck
                ? '변경하기'
                : '다시시도'
              : idCheck
                ? '로그인'
                : '다시시도'
          }
          onClick={() => {
            tab === 1
              ? idCheck
                ? changePwd()
                : setStep(0)
              : idCheck
                ? navigate('/login')
                : setStep(0);
          }}
        />
      </div>
    </div>
  );
};
