import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PropertyOperationsBuilder, createInstance } from '@hackler/react-sdk';

export const hackleClient = createInstance(
  process.env.REACT_APP_HACKLE_API_KEY || '',
);

type SignUpMethod = 'N' | 'K' | 'C' | 'G' | 'F';

type MemberType = 'W' | 'F' | 'N' | 'E' | 'O' | 'P' | 'C' | 'G';

const methodMapping: { [key in SignUpMethod]: string } = {
  N: 'naver',
  K: 'kakao',
  C: 'general',
  G: 'google',
  F: 'facebook',
};

const memeberTypeMapping: { [key in MemberType]: string } = {
  W: '농업인',
  F: '귀농 희망자',
  N: '귀촌 희망자',
  E: '농산업기업체',
  O: '지자체 담당자',
  P: '정부부처(공공기관)',
  C: '농산물 유통',
  G: '기타(해당사항 없음)',
};

interface UserInfo {
  userId: string;
  email: string;
  firstVisitAt: string;
  createdDate: string;
  region: string;
  category: MemberType;
  point: string;
  isNotification: string;
}

export const initializeHackleClientEvent = (result: UserInfo) => {
  const operations = new PropertyOperationsBuilder()
    .set('userId', result.email)
    .set('firstVisitAt', result.firstVisitAt || '')
    .set('createdAt', result.createdDate)
    .set('region', result.region)
    .set('type', memeberTypeMapping[result.category] || '')
    .set('point', result.point)
    .set('marketing', result.isNotification)
    .build();

  hackleClient.updateUserProperties(operations);
};

export const hackleSignUptrack = (
  method: SignUpMethod,
  memberType: MemberType,
  userId: string,
) => {
  const event = {
    key: 'signup',
    properties: {
      created_at: new Date().toISOString().split('T')[0],
      memberType: memeberTypeMapping[memberType] || '',
      method: methodMapping[method] || '',
      userId: userId,
    },
  };
  hackleClient.track(event);
};

export const hackleClickGotoPricePage = () => {
  const event = {
    key: 'click_signup_price',
    properties: {
      title: null,
      page_path: null,
    },
  };

  hackleClient.track(event);
};
export const hackleClickGotoMain = () => {
  const event = {
    key: 'click_signup_main',
    properties: {
      title: null,
      page_path: null,
    },
  };

  hackleClient.track(event);
};

export const hackleClickReport = (path: any) => {
  const event = {
    key: 'click_detail',
    properties: {
      title: null,
      page_path: path,
    },
  };

  hackleClient.track(event);
};
export const clickAnnuityMenu = () => {
  const event = {
    key: 'click_nav_annuity',
    properties: {
      title: null,
      page_path: null,
    },
  };

  hackleClient.track(event);
};

export const clickGoToDetail = (path: any) => {
  const event = {
    key: 'click_detail',
    properties: {
      title: null,
      page_path: path,
    },
  };

  hackleClient.track(event);
};

export const hackleClickShowReportButton = (menu: string) => {
  const event = {
    key: 'click_report',
    properties: {
      title: null,
      page_name: menu,
    },
  };

  hackleClient.track(event);
};

const TrackClickEvents = () => {
  const location = useLocation();

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const clickEvent = {
        key: 'click',
        properties: {
          page_path: location.pathname,
        },
      };
      hackleClient.track(clickEvent);
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [location]);

  return null;
};

export default TrackClickEvents;
