import React, { useEffect, useState } from 'react';
import './ReportResultLanduse.css';
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  ResponsiveContainer,
} from 'recharts';
import ReportService from '../../service/report/reportApi';
import { isMobile } from '../../component/function/function';
interface IReportData {
  type: string;
  score: number;
  level: number;
  location: number;
  zoning: number;
  category: number;
  shape: number;
  slope: number;
  roadsurface: number;
  area: number;
  grade: string;
}

const LandPotential = ({
  reportIdx4,
  tab,
  setTab,
}: {
  reportIdx4: number;
  tab: string;
  setTab: any;
}) => {
  const [loading, setLoading] = useState(false);
  const [grade, setGrade] = useState('');
  const [data, setData] = useState<IReportData[]>([]);
  const [filteredData, setFilteredData] = useState({
    type: '',
    score: 0,
    level: 0,
    location: 0,
    zoning: 0,
    category: 0,
    shape: 0,
    slope: 0,
    roadsurface: 0,
    area: 0,
    grade: '',
  });
  const [chartDataSet, setChartDataSet] = useState([
    { category: '지역 위치', value: 80 },
    { category: '용도 지역', value: 90 },
    { category: '토지 종류', value: 50 },
    { category: '토지 모양', value: 100 },
    { category: '경사도', value: 70 },
    { category: '도로 유형', value: 60 },
    { category: '토지 면적', value: 60 },
  ]);

  const getReportData = async () => {
    try {
      setLoading(true);
      const res = await ReportService.getReport(reportIdx4);
      setData(res.result.list);
      const filteredData = res.result.list.sort(
        (a: any, b: any) => b.score - a.score,
      );
      const filteredDataItem = filteredData[0];
      setFilteredData(filteredDataItem);
      setTab(filteredDataItem.type);
      setChartDataSet([
        { category: '지역 위치', value: filteredDataItem.location },
        { category: '용도 지역', value: filteredDataItem.zoning },
        { category: '토지 종류', value: filteredDataItem.category },
        { category: '토지 모양', value: filteredDataItem.shape },
        { category: '경사도', value: filteredDataItem.slope },
        { category: '도로 유형', value: filteredDataItem.roadsurface },
        { category: '토지 면적', value: filteredDataItem.area },
      ]);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getReportData();
  }, [reportIdx4]);

  const sortedData = data.sort((a, b) => b.score - a.score);

  const CustomTick = React.memo((props: any) => {
    const { payload, x, y, textAnchor } = props;
    const lines = payload.value.split('\n');

    const levelColor = '#616161';

    return (
      <text
        x={x}
        y={y + 5}
        textAnchor="middle"
        fill={levelColor}
        fontSize={10}
        fontFamily="Pretendard-Medium"
      >
        {lines.map((line: string, index: number) => (
          <tspan key={index} x={x} dy={index === 0 ? 0 : 15}>
            {line}
          </tspan>
        ))}
      </text>
    );
  });

  const makeFilterData = (tab: string) => {
    const filteredDataItem = sortedData.find((item) => item.type === tab);

    if (filteredDataItem) {
      setFilteredData(filteredDataItem);
      setGrade(filteredDataItem?.grade);
      setChartDataSet([
        { category: '지역 위치', value: filteredDataItem.location },
        { category: '용도 지역', value: filteredDataItem.zoning },
        { category: '토지 종류', value: filteredDataItem.category },
        { category: '토지 모양', value: filteredDataItem.shape },
        { category: '경사도', value: filteredDataItem.slope },
        { category: '도로 유형', value: filteredDataItem.roadsurface },
        { category: '토지 면적', value: filteredDataItem.area },
      ]);
    }
  };

  useEffect(() => {
    makeFilterData(tab);
  }, [tab, reportIdx4]);

  const changeText = (text: string) => {
    if (text === 'S') {
      return '태양광발전';
    } else if (text === 'W') {
      return '창고';
    } else if (text === 'P') {
      return '주차장차고지';
    } else if (text === 'C') {
      return '캠핑(야영장)';
    } else if (text === 'L') {
      return '농어촌민박';
    } else if (text === 'E') {
      return '펜션(관광펜션)';
    }
  };

  const renderSampleImg = (type: string) => {
    if (type === 'S') {
      return '/asset/images/landUse/Sample_img_solar.png';
    }
    if (type === 'W') {
      return '/asset/images/landUse/Sample_img_warehouse.png';
    }
    if (type === 'P') {
      return '/asset/images/landUse/Sample_img_parkinglot.png';
    }
    if (type === 'C') {
      return '/asset/images/landUse/Sample_img_camping.png';
    }

    if (type === 'L') {
      return '/asset/images/landUse/Sample_img_b&b.png';
    }
    if (type === 'E') {
      return '/asset/images/landUse/Sample_img_vacationhome.png';
    }
  };

  const renderIcon = (type: string) => {
    if (type === 'S') {
      return '/asset/images/landUse/solarIcon.svg';
    }
    if (type === 'W') {
      return '/asset/images/landUse/warehouse_icon.svg';
    }
    if (type === 'P') {
      return '/asset/images/landUse/parkinglot_icon.svg';
    }
    if (type === 'C') {
      return '/asset/images/landUse/camping_icon.svg';
    }

    if (type === 'L') {
      return '/asset/images/landUse/b&b_icon.svg';
    }
    if (type === 'E') {
      return '/asset/images/landUse/vacationhome_icon.svg';
    }
  };

  return (
    <div className="land-use-report-block">
      <div className="land-use-report-block-title">토지활용 가능성 AI 진단</div>
      <div className="nearby-land-example-tab-box">
        <div className="nearby-land-example-tab-box-inner">
          {sortedData.map((item, index) => (
            <div
              key={index}
              className={
                tab === item.type
                  ? 'nearby-land-tab-item-active'
                  : 'nearby-land-tab-item'
              }
              onClick={() => {
                setTab(item.type);
              }}
            >
              <img src={renderIcon(item.type)} alt="" />
              {changeText(item.type)}
            </div>
          ))}
        </div>
      </div>
      <div className="level-box">
        <div className="level-box-text">
          <span>토지활용 등급</span>
          <span className="land-use-level">{grade}</span>
          <img
            src={renderSampleImg(tab)}
            alt=""
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          />
          <div className="image-overlay"></div>
        </div>
        <div className="land-use-level-box">
          {sortedData.map((item, index) => (
            <div className="land-use-gray-box-item" key={index}>
              <div className="land-use-gray-box-item-title">
                <span
                  className="level-color-dot"
                  style={{
                    backgroundColor:
                      item.level === 1
                        ? '#1E9B6B'
                        : item.level === 2
                          ? '#E78B3E'
                          : '#D84836',
                  }}
                ></span>
                <span>{changeText(item.type)}</span>
              </div>
              <span
                className="level-color-text"
                style={{
                  color:
                    item.level === 1
                      ? '#1E9B6B'
                      : item.level === 2
                        ? '#E78B3E'
                        : '#D84836',
                }}
              >
                {item.level === 1 ? '높음' : item.level === 2 ? '보통' : '낮음'}
              </span>
            </div>
          ))}
        </div>
      </div>

      <div
        className="land-use-gray-box"
        style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
      >
        <div className="land-use-tab-point">
          상위 <span>{Number(filteredData?.score).toFixed(0)}점</span>
        </div>
        <div className="chart-box">
          <div className="chart-box-inner">
            <ResponsiveContainer width={'100%'} height={300}>
              <RadarChart
                data={chartDataSet}
                outerRadius={isMobile(window.innerWidth) ? '70%' : '85%'}
              >
                <PolarGrid
                  stroke="rgba(112, 115, 124, 0.16)"
                  strokeDasharray="5 5"
                />
                <PolarAngleAxis dataKey="category" tick={<CustomTick />} />
                <Radar
                  name="Category"
                  dataKey="value"
                  stroke="#00a676"
                  fill="#00a676"
                  fillOpacity={0.1}
                />
              </RadarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="land-use-prograssbar-box">
          <div className="land-use-prograssbar-item">
            <span className="land-use-prograssbar-title">지역 위치</span>
            <div className="land-use-prograssbar">
              <span
                className="land-use-prograssbar-per"
                style={{ width: `${filteredData?.location}%` }}
              ></span>
            </div>
            <span>{filteredData?.location}점</span>
          </div>
          <div className="land-use-prograssbar-item">
            <span className="land-use-prograssbar-title">용도 지역</span>
            <div className="land-use-prograssbar">
              <span
                className="land-use-prograssbar-per"
                style={{ width: `${filteredData?.zoning}%` }}
              ></span>
            </div>
            <span>{filteredData?.zoning}점</span>
          </div>
          <div className="land-use-prograssbar-item">
            <span className="land-use-prograssbar-title">토지 종류</span>
            <div className="land-use-prograssbar">
              <span
                className="land-use-prograssbar-per"
                style={{ width: `${filteredData?.category}%` }}
              ></span>
            </div>
            <span>{filteredData?.category}점</span>
          </div>
          <div className="land-use-prograssbar-item">
            <span className="land-use-prograssbar-title">토지 모양</span>
            <div className="land-use-prograssbar">
              <span
                className="land-use-prograssbar-per"
                style={{ width: `${filteredData?.shape}%` }}
              ></span>
            </div>
            <span>{filteredData?.shape}점</span>
          </div>
          <div className="land-use-prograssbar-item">
            <span className="land-use-prograssbar-title">경사도</span>
            <div className="land-use-prograssbar">
              <span
                className="land-use-prograssbar-per"
                style={{ width: `${filteredData?.slope}%` }}
              ></span>
            </div>
            <span>{filteredData?.slope}점</span>
          </div>
          <div className="land-use-prograssbar-item">
            <span className="land-use-prograssbar-title">도로 유형</span>
            <div className="land-use-prograssbar">
              <span
                className="land-use-prograssbar-per"
                style={{ width: `${filteredData?.roadsurface}%` }}
              ></span>
            </div>
            <span>{filteredData?.roadsurface}점</span>
          </div>
          <div className="land-use-prograssbar-item">
            <span className="land-use-prograssbar-title">토지 면적</span>
            <div className="land-use-prograssbar">
              <span
                className="land-use-prograssbar-per"
                style={{ width: `${filteredData?.area}%` }}
              ></span>
            </div>
            <span>{filteredData?.area}점</span>
          </div>
        </div>
      </div>
      <div className="land-use-prograssbar-desc">
        동사의 토지활용 가능성 진단은 <br />
        <span>빅데이터 기반 AI 분석</span>
        으로 <br />
        추가적으로 상세 분석이 필요합니다
      </div>
    </div>
  );
};

export default LandPotential;
