import { Title } from '../Content';
import '../../../../../asset/styles/SaleInfo.css';
import '../../../../../Main.css';
import Refresh from '../../../../../asset/images/search/refresh.svg';
import HeartIcon from '../../../../../asset/images/new/HeartIcon.svg';
import Close from '../../../../../asset/images/new/Close.svg';
import Logo from '../../../../../asset/images/new/logo.svg';
import { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import HouseService from '../../../../../service/house/landApi';
import Button from '../../../../../component/mobile/Button';
import { useNavigate } from 'react-router-dom';
import { isMobile } from '../../../../../component/function/function';
import useDidMountEffect from '../../../../../hooks/UseDidMountEffect';
import { min, set } from 'lodash';
const Pyeong = ({
  title,
  pyeongValue,
  setPyeongValue,
  minPyeongValue,
  maxPyeongValue,
  currentHouse,
  landInfo,
  landPyeong,
  selectedDesignFloor,
  stdFloorAreaRatio,
  stdBuildingToLandRatio,
  selectedDesignIdx,
}: any) => {
  const [sliderstdBuildingToLandRatio, setSliderstdBuildingToLandRatio] =
    useState(0);
  const [sliderstdFloorAreaRatio, setSliderstdFloorAreaRatio] = useState(0);
  const TableSubTitle = ({ title }: any) => (
    <div
      className="font-medium f-size-14 line-h-20 color-text-tertiary nowrap"
      style={{ flex: 0.5 }}
    >
      {title}
    </div>
  );

  const TableItemContent = ({ content }: any) => (
    <div
      className="font-bold f-size-14 line-h-20 color-text-secondary nowrap"
      style={{ flex: 0.5 }}
    >
      {content}
    </div>
  );

  // useEffect(() => {
  //   setSliderstdBuildingToLandRatio(
  //     Math.round((minPyeongValue / landPyeong) * 100)
  //   );
  //   setSliderstdFloorAreaRatio(Math.round((minPyeongValue / landPyeong) * 100));
  // }, [minPyeongValue, landPyeong]);

  useEffect(() => {
    if (selectedDesignFloor === 1) {
      setSliderstdBuildingToLandRatio(
        Math.round((minPyeongValue / landPyeong) * 1000) / 10,
      );
      setSliderstdFloorAreaRatio(
        Math.round((minPyeongValue / landPyeong) * 1000) / 10,
      );
    } else if (selectedDesignFloor === 2) {
      setSliderstdBuildingToLandRatio(
        Math.round((pyeongValue / landPyeong) * 1000) / 2 / 10,
      );
      setSliderstdFloorAreaRatio(
        Math.round((minPyeongValue / landPyeong) * 1000) / 10,
      );
    }
  }, [selectedDesignIdx]);
  useEffect(() => {
    if (selectedDesignFloor === 1) {
      setSliderstdBuildingToLandRatio(
        Math.round((pyeongValue / landPyeong) * 1000) / 10,
      );
    } else if (selectedDesignFloor === 2) {
      setSliderstdBuildingToLandRatio(
        Math.round((pyeongValue / landPyeong) * 1000) / 2 / 10,
      );
    }
    if (selectedDesignFloor === 1) {
      setSliderstdFloorAreaRatio(
        Math.round((pyeongValue / landPyeong) * 1000) / 10,
      );
    } else if (selectedDesignFloor === 2) {
      setSliderstdFloorAreaRatio(
        Math.round((pyeongValue / landPyeong) * 1000) / 10,
      );
    }
  }, [pyeongValue, landPyeong]);
  return (
    <div className="flex-column report_result_box bg-white  border-radius-8 gap-8 padding-b-16">
      <Title text={title} />
      <div className="f-size-14 color-text-tertiary line-h-20 font-medium padding-x-16">
        선택하신 설계안의 평형이 기본값으로 자동 설정되며, 원하시는 다른
        평형으로 값을 조정할 수 있습니다.
      </div>

      <div className="flex-column gap-24">
        <div className="flex-row space-between">
          {/* 
          <div className="padding-2-8 flex-row border-1-e2e2e2 border-radius-100 hover">
            <select
              name=""
              id=""
              value={floor}
              onChange={(e: any) => {
                setFloor(e.target.value);
              }}
            >
              <option
                value="1"
                className="font-medium f-size-12 line-h-20 color-text-primary"
              >
                1층
              </option>
              <option
                value="2"
                className="font-medium f-size-12 line-h-20 color-text-primary"
              >
                2층
              </option>
            </select>
          </div> */}
        </div>
        <div className="flex-column w-100per padding-x-16">
          <div className="w-100per flex-row space-between">
            <div className="font-bold f-size-12 line-h-14 color-text-primary flex-1">
              {Math.round(minPyeongValue * 10) / 10}평 (기본)
            </div>
            <div className="font-bold f-size-12 line-h-14 color-text-primary flex-1 center-x">
              {Math.round(
                ((Number(minPyeongValue) + Number(maxPyeongValue)) * 10) / 2,
              ) / 10}
              평
            </div>
            <div className="font-bold f-size-12 line-h-14 color-text-primary flex-1 end-x">
              {Math.round(maxPyeongValue * 10) / 10}평
            </div>
          </div>
          <Slider
            value={pyeongValue}
            onChange={(e: any) => {
              setPyeongValue(e.target.value);
            }}
            valueLabelDisplay="auto"
            max={maxPyeongValue}
            min={minPyeongValue}
            // getAriaValueText={valuetext}
            sx={{ color: '#1E9B6B', height: '8px' }}
          />
        </div>
        <div
          className=" flex-column w-100per gap-8 padding-x-16"
          style={{ flexWrap: 'wrap' }}
        >
          {[
            {
              title: '층수',
              content: `지상 ${currentHouse.floor}층` || '지상 2층',
            },

            {
              title: '용적률',
              content:
                // `${
                //   Math.round(
                //     ((Number(pyeongValue) * currentHouse.floor) /
                //       Number(landInfo.pyeong)) *
                //       100 *
                //       10
                //   ) / 10
                // }%` || "11.8평",
                sliderstdFloorAreaRatio + '%',
            },
            {
              title: '건폐율',
              content:
                // `${
                //   Math.round(
                //     (Number(pyeongValue) / Number(landInfo.pyeong)) * 100 * 10
                //   ) / 10
                // }%` || "20% (법정 60% 이하)",
                sliderstdBuildingToLandRatio + '%',
            },
          ].map((el) => (
            <div
              className="flex-row w-100per gap-8 center-y"
              style={{ width: 'fit-content' }}
            >
              <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                •
              </div>
              <TableSubTitle title={el.title} />
              <TableItemContent content={el.content} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pyeong;
