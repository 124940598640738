import { useLocation, useParams } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { TopNav2 } from '../../component/mobile/Topnav';
import ReportInfo from './components/Info';
import ReportNav from './components/Nav';
import ReportDetail1 from './components/detail/ReportDetail1';
import ReportDetail2 from './components/detail/ReportDetail2';
import ReportDetail3 from './components/detail/ReportDetail3';
import ReportDetail4 from './components/detail/ReportDetail4';
import ReportDetail5 from './components/detail/ReportDetail5';
import ReportDetail7 from './components/detail/ReportDetail7';
import ReportDetail8 from './components/detail/ReportDetail8';
import Summary1 from './components/summary/Summary1';
import Summary2 from './components/summary/Summary2';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { isMobile } from '../../component/function/function';
import ReportDetail6 from './components/detail/ReportDetail6';
import HouseService from '../../service/house/landApi';
import { LoadingDot } from '../../store/loadingSlice';
import { useDispatch } from 'react-redux';
import ReportDetail10 from '../reportResultPrice/components/detail/ReportDetail10';
import PriceService from '../../service/price/priceApi';
import { SaveBtn } from '../../component/common/saveBtn';
import { set } from 'lodash';
import useDidMountEffect from '../../hooks/UseDidMountEffect';
import { SaveReportPopUp } from '../../component/common/saveReportPopUp';

const HouseReportMain = ({
  reportIdx,
  setClose,
  mypage,
  search,
  setOnHouseReport,
  setOnPointHistory,
  selectedDesignFloor,
  setCloseSearchPointDetail,
}: any) => {
  let param: any;
  const param2 = useParams();
  if (isMobile(window.innerWidth)) {
    param = { reportIdx: param2.reportIdx };
  } else {
    param = { reportIdx: reportIdx };
  }
  const [constructionOwnMoney, setConstructionOwnMoney] = useState(0);
  const [constructionLoanCost, setConstructionLoanCost] = useState(0);
  const [houseLoanInfo, setHouseLoanInfo] = useState({
    loanCost: 75000000,
    loanRate: 0.02,
    loanType: 'T',
    redemptionPeriod: 10,
    retentionPeriod: 5,
  });
  const [constructionLoanInfo, setConstructionLoanInfo] = useState({
    loanCost: 75000000,
    loanRate: 0.02,
    loanType: 'T',
    redemptionPeriod: 10,
    retentionPeriod: 5,
  });
  const [report_desc, setReport_desc] = useState({
    PerMeterEstimatedHousingPrice: null,
    address: '서울특별시 종로구 사간동 6',
    buildingToLandRatio: 0,
    createdAt: '2023-10-17T09:59:22.000Z',
    estimatedHousingPrice: null,
    floor: 2,
    floorAreaRatio: 0,
    houseTradeDate: null,
    houseTradePrice: null,
    perPyeongEstimatedHousingPrice: null,
    totalFloorArea: 80.56,
    totalFloorAreaPyeong: 24.4,
    year_int: 1930,
    type: 'N',
  });

  const [cropAnalysis, setCropAnalysis] = useState({
    accumulatedTemp: [],
    avgYearWeather: [],
    cropList: [],
    curYearWeather: [],

    soilResult: {
      erosion: '없음',
      frstGrd: '임지_4급지',
      fruitGrd: '과수상전_4급지',
      height: '평지',
      pastureGrd: '초지_3급지',
      pfldGrd: '밭_3급지',
      rfldGrd: '논_2급지',
      roadSurface: '중로한면',
      shape: '부정형',
      soilType: '논_보통답',
      soilUseRec: '논',
      soildra: '약간불량',
      soilslope: '경사_2-7%',
      useStatus: '상업용',
    },
  });
  const [savePopUp, setSavePopUp] = useState(false);
  const [landInfo, setLandInfo] = useState({
    category: '대',
    height: '평지',
    landArea: 248,
    landPyeong: 75,
    officialPriceList: {
      2014: 28100,
      2015: 30800,
      2016: 32600,
      2017: 32600,
      2018: 35500,
      2019: 38200,
      2020: 37700,
    },
    roadSurface: '세로각지(불)',
    useStatus: '단독',
    zoning: '계획관리지역',
    CAGR: 300,
    shape: '',
    roadSurface_desc: '',
    pnuCode: '',
  });

  const [costAnalysis, setCostAnalysis] = useState({
    stdBuildingToLandRatio: 40,
    stdFloorAreaRatio: 100,
    ownHouseYN: 'Y',
    myBuildingToLandRatio: 0.27,
    myFloorAreaRatio: 0.27,

    myTotalFloorArea: 66.4,
    myTotalFloorAreaPyeong: 20.1,

    constructionLoanInfo: {
      totalConstructionCost: 129181157,
      ownMoney: 121044677,
      loanCost: 8136480,
      details: {
        boundaryCost: 750197,
        designCost: 2010000,
        housePermitFee: 2010000,
        constructionCost: 120600000,
        cHouseAcquisitionTax: 2412000,
        cFarmSpecialTax: 241200,
        registrationTax: 964800,
        cLocalEducationTax: 192960,
      },
      loanInfo: {
        loanCost: 8136480,
        loanRate: 0.02,
        loanType: 'T',
        retentionPeriod: 5,
        redemptionPeriod: 10,
      },
      loanPlan: [
        {
          round: 1,
          total: 13561,
          principal: 0,
          interest: 13561,
          principalSum: 0,
          remainSum: 8136480,
        },
      ],
      loanPlan1Year: [
        {
          round: 1,
          total: 13561,
          principal: 0,
          interest: 13561,
          principalSum: 0,
          remainSum: 8136480,
        },
      ],
    },
    houseCostLoanInfo: {
      totalHouseCost: 10170600,
      ownMoney: 2034120,
      details: {
        houseCost: 10050000,
        pHouseAcquisitionTax: 100500,
        pLocalEducationTax: 10050,
        pFarmSpecialTax: 10050,
      },
      loanInfo: {
        loanCost: 8136480,
        loanRate: 0.02,
        loanType: 'T',
        retentionPeriod: 5,
        redemptionPeriod: 10,
      },
      loanPlan: [
        {
          round: 1,
          total: 13561,
          principal: 0,
          interest: 13561,
          principalSum: 0,
          remainSum: 8136480,
        },
      ],
      loanPlan1Year: [
        {
          round: 1,
          total: 13561,
          principal: 0,
          interest: 13561,
          principalSum: 0,
          remainSum: 8136480,
        },
      ],
    },
    eligibility: {
      constructionArea: 66.44658000000001,
      liveInFarmYN: 'Y',
      returnToFarmYN: 'Y',
      floor: 1,
    },
  });

  const [housePriceAnalysis, setHousePriceAnalysis] = useState({
    estimatedHousingPrice: 26546478.400000002,
    perPyeongEstimatedHousingPrice: 353953,
    PerMeterEstimatedHousingPrice: 107042,
    surroundings: [
      {
        address: '충청북도 괴산군 칠성면 외사리 364',
        perPyeongHouseTradePrice: 5328498,
        houseTradeDate: '20200901',
        distance: 254.6340774011368,
      },
      {
        address: '충청북도 괴산군 칠성면 외사리 160',
        perPyeongHouseTradePrice: 5136648,
        houseTradeDate: '20221208',
        distance: 390.93157605764566,
      },
      {
        address: '충청북도 괴산군 칠성면 외사리 283-1',
        perPyeongHouseTradePrice: 5478817,
        houseTradeDate: '20180707',
        distance: 506.8175158035264,
      },
    ],
    surroundingAvgValue: 5751244.652662432,
  });

  const [indexInfo, setIndexInfo] = useState({
    houseIndexList: [
      {
        gugun: '경기도 용인시',
        stdYear: '2023',
        stdMonth: '01',
        index: 100,
      },
    ],
    newConstructionIndexList: [
      {
        gugun: '경기도 용인시',
        stdYear: '2023',
        stdMonth: '01',
        index: 74,
      },
    ],
    remodelingIndexList: [
      {
        gugun: '경기도 용인시',
        stdYear: '2023',
        stdMonth: '01',
        index: 133,
      },
    ],
    avgHouseRate: 3.3,
    avgNewConstructionRate: -0.1,
    avgRemodelingRate: 9.4,
  });

  const [salesAnalysis, setSalesAnalysis] = useState('');
  const [houseTotalPeriod, setHouseTotalPeriod] = useState();
  const [facility, setFacility] = useState('노지');
  const [type, setType] = useState('L');
  const [isStart, setIsStart] = useState(false);
  const [origin, setOrigin] = useState();
  const [eligibilityData, setEligibilityData] = useState();
  const dispatch = useDispatch();
  const getOriginData = async () => {
    try {
      const result = await PriceService.origin();
      setOrigin(result.list);
    } catch (error) {
      console.log(error);
    }
  };
  const report = async () => {
    try {
      if (!mypage) {
        dispatch(LoadingDot('W'));
      } else {
        dispatch(LoadingDot('W2'));
      }

      const data: any = await HouseService.load(param.reportIdx);
      // const data2 = await ReportService.reportFarm(param.reportIdx);

      setReport_desc(data.result.report_desc);
      setLandInfo(data.result.landInfo);
      setCostAnalysis(data.result.costAnalysis);
      setHousePriceAnalysis(data.result.housePriceAnalysis);
      setIndexInfo(data.result.indexInfo);
      setIsStart(true);
      setHouseLoanInfo(data.result.costAnalysis.houseCostLoanInfo.loanInfo);
      setEligibilityData(data.result.eligibility);
      setConstructionLoanInfo(
        data.result.costAnalysis.constructionLoanInfo.loanInfo,
      );
      setConstructionOwnMoney(
        Math.round(
          (data.result.costAnalysis.constructionLoanInfo.ownMoney /
            data.result.costAnalysis.constructionLoanInfo
              .totalConstructionCost) *
            100,
        ),
      );
      setConstructionLoanCost(
        100 -
          Math.round(
            (data.result.costAnalysis.constructionLoanInfo.ownMoney /
              data.result.costAnalysis.constructionLoanInfo
                .totalConstructionCost) *
              100,
          ),
      );
      dispatch(LoadingDot('N'));
    } catch (err) {
      dispatch(LoadingDot('N'));
      console.log(err);
    }
  };

  // 변경 api (대출), 저장 api(저장하기 버튼)
  const report2 = async (save: string) => {
    const data = {
      reportIdx: reportIdx,
      loanOfHouseCost: {
        loanCost: houseLoanInfo.loanCost,
        loanRate: houseLoanInfo.loanRate,
        loanType: houseLoanInfo.loanType,
        retentionPeriod: houseLoanInfo.retentionPeriod,
        redemptionPeriod: houseLoanInfo.redemptionPeriod,
      },
      loanOfConstructionCost: {
        loanCost: constructionLoanInfo.loanCost,
        loanRate: constructionLoanInfo.loanRate,
        loanType: constructionLoanInfo.loanType,
        retentionPeriod: constructionLoanInfo.retentionPeriod,
        redemptionPeriod: constructionLoanInfo.redemptionPeriod,
      },
      saveYN: save,
    };
    try {
      if (mypage) {
        dispatch(LoadingDot('W2'));
      } else if (save === 'Y') {
        dispatch(LoadingDot('S'));
      } else {
        dispatch(LoadingDot('W'));
      }
      const result = await HouseService.report2(data);

      setCostAnalysis(result.costAnalysis);
      setEligibilityData(result.eligibility);

      dispatch(LoadingDot('N'));
      if (save === 'Y') {
        setSavePopUp(true);
      }
    } catch (error) {
      console.log(error);
      dispatch(LoadingDot('N'));
    }
  };
  useEffect(() => {
    report();
    getOriginData();
  }, []);
  useDidMountEffect(() => {
    report2('N');
  }, [houseLoanInfo, constructionLoanInfo]);

  const conRef = useRef<HTMLDivElement>(null);
  const menuRef1 = useRef<HTMLDivElement>(null);
  const menuRef2 = useRef<HTMLDivElement>(null);
  const menuRef3 = useRef<HTMLDivElement>(null);
  const menuRef4 = useRef<HTMLDivElement>(null);
  const menuRef5 = useRef<HTMLDivElement>(null);
  const menuRef6 = useRef<HTMLDivElement>(null);
  const menuRef7 = useRef<HTMLDivElement>(null);
  const menuRef8 = useRef<HTMLDivElement>(null);
  const menuRef9 = useRef<HTMLDivElement>(null);
  const menuRef10 = useRef<HTMLDivElement>(null);
  const temp = useRef<HTMLDivElement>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const divRef2 = useRef<HTMLDivElement>(null);
  const divRef3 = useRef<HTMLDivElement>(null);
  const divRef4 = useRef<HTMLDivElement>(null);
  const divRef5 = useRef<HTMLDivElement>(null);
  const divRef6 = useRef<HTMLDivElement>(null);
  const divRef7 = useRef<HTMLDivElement>(null);
  const divRef8 = useRef<HTMLDivElement>(null);
  const divRef9 = useRef<HTMLDivElement>(null);
  const convertElementToImage = async (element: any) => {
    if (element) {
      let canvas = await html2canvas(element);
      return canvas.toDataURL('image/svg');
    }
    return null;
  };

  const convertHTMLToImages = async () => {
    const elements = [
      divRef.current,
      divRef2.current,
      divRef3.current,
      divRef4.current,
      divRef5.current,
      divRef6.current,
      divRef7.current,
      divRef8.current,
      divRef9.current,
    ];
    const imageDataArray = await Promise.all(
      elements.map(async (element) => {
        return await convertElementToImage(element);
      }),
    );
    return imageDataArray.filter((imageData) => imageData !== null);
  };

  const handleButtonClick = async () => {
    let imgDataArray = await convertHTMLToImages();

    if (imgDataArray.length > 0) {
      let doc = new jsPDF();
      imgDataArray.forEach((imgData: any, index) => {
        doc.addImage(imgData, 'SVG', 0, 0, 210, 297);
        if (index < imgDataArray.length - 1) {
          doc.addPage();
        }
      });
      doc.save('a4.pdf');
    }
  };
  const location = useLocation();
  console.log(location.state);
  return (
    <div
      className={`w-100per  browser-h-100per   browser-bg-FFFFFF ${
        mypage ? `` : ``
      }`}
      style={
        isMobile(window.innerWidth)
          ? //  { height: window.innerHeight, zIndex: 100 }
            {
              height: mypage ? '100%' : `${window.innerHeight - 60}px`,
              zIndex: 100,
              overflowX: 'hidden',
            }
          : {
              overflowX: 'hidden',
              overflowY: mypage ? 'hidden' : 'auto',
            }
      }
    >
      {savePopUp && <SaveReportPopUp setSavePopUp={setSavePopUp} />}
      {/* <button
        onClick={() => {
          handleButtonClick();
        }}
      >
        다운
      </button> */}
      {!search ? (
        <TopNav2
          browser
          text="내 집 건축보고서"
          closeBack
          close
          setClose={setClose}
        />
      ) : (
        ''
      )}
      {search && (
        <div className="flex-row space-between padding-12-16 center-y">
          <img
            src="/asset/images/arrow/arrowBack.svg"
            alt="arrow"
            className="pointer"
            onClick={() => {
              setCloseSearchPointDetail(false);
            }}
          />
          <div className="m-b1-16-b color-dark-primary">내 집 건축보고서</div>
          <img
            src="/asset/images/close/CloseBlack24.svg"
            className="pointer"
            alt="close"
            onClick={() => {
              setClose(false);
            }}
          />
        </div>
      )}
      <div
        className="of-y-auto"
        style={{
          overflowX: mypage || search ? 'hidden' : 'auto',

          height: isMobile(window.innerWidth)
            ? window.innerHeight - 48
            : mypage
              ? '90%'
              : window.innerHeight - 50,
        }}
        ref={temp}
      >
        <div ref={divRef}>
          <ReportInfo data={report_desc} mypage={mypage} />

          <ReportNav
            refList={{
              conRef,
              menuRef1,
              menuRef2,
              menuRef3,
              menuRef4,
              menuRef5,
              menuRef6,
              menuRef7,
              menuRef8,
              menuRef9,
              menuRef10,
              temp,
            }}
            isHave={costAnalysis.ownHouseYN}
          />

          <div
            style={{ height: '16px', background: '#F7F8F9' }}
            ref={menuRef1}
          />
          <Summary1 data={report_desc} />

          <div
            style={{ height: '16px', background: '#F7F8F9' }}
            ref={menuRef2}
          />
          <Summary2 data={indexInfo} />
        </div>
        <div style={{ height: '16px', background: '#F7F8F9' }} ref={menuRef3} />
        <div ref={divRef2}>
          <ReportDetail1
            mypage={mypage && true}
            data2={report_desc}
            data3={housePriceAnalysis}
            data={landInfo}
          />
        </div>
        <div
          style={{ height: '16px', background: '#F7F8F9' }}
          ref={menuRef4}
        ></div>
        <div ref={divRef3}>
          <ReportDetail2 data={indexInfo} />
        </div>
        <div style={{ height: '16px', background: '#F7F8F9' }} ref={menuRef5} />
        <div ref={divRef4}>
          <ReportDetail3 data={report_desc} />
        </div>
        <div style={{ height: '16px', background: '#F7F8F9' }} ref={menuRef6} />
        <div ref={divRef5}>
          <ReportDetail4 data={landInfo} />
        </div>
        <div style={{ height: '16px', background: '#F7F8F9' }} ref={menuRef7} />
        <div ref={divRef6}>
          <ReportDetail5 data={landInfo} mypage={mypage} />
        </div>
        <div style={{ height: '16px', background: '#F7F8F9' }} ref={menuRef8} />
        <div ref={divRef7}>
          <ReportDetail6
            data={costAnalysis}
            data2={landInfo}
            selectedDesignFloor={
              isMobile(window.innerWidth)
                ? location.state.selectedDesignFloor
                : selectedDesignFloor
            }
            mypage={mypage}
          />
        </div>
        {costAnalysis.ownHouseYN === 'N' ? (
          <>
            <div
              style={{ height: '16px', background: '#F7F8F9' }}
              ref={menuRef9}
            />
            <div ref={divRef8}>
              <ReportDetail8
                report2={report2}
                mypage={mypage && true}
                data={costAnalysis}
                data2={landInfo}
                type={report_desc.type}
                report={report}
                isStart={isStart}
                totalPeriod={houseTotalPeriod}
                setTotalPeriod={setHouseTotalPeriod}
                loanInfo={houseLoanInfo}
                setLoanInfo={setHouseLoanInfo}
              />
            </div>
          </>
        ) : (
          ''
        )}{' '}
        <div
          style={{ height: '16px', background: '#F7F8F9' }}
          ref={costAnalysis.ownHouseYN === 'N' ? menuRef10 : menuRef9}
        />
        <div ref={divRef9}>
          <ReportDetail7
            report2={report2}
            mypage={mypage && true}
            data={costAnalysis}
            report={report}
            data2={landInfo}
            type={report_desc.type}
            isStart={isStart}
            ownMoney={constructionOwnMoney}
            setOwnMoney={setConstructionOwnMoney}
            totalLoanCost={constructionLoanCost}
            setTotalLoanCost={setConstructionLoanCost}
            loanInfo={constructionLoanInfo}
            setLoanInfo={setConstructionLoanInfo}
          />
        </div>
        {!mypage && <div style={{ height: '16px', background: '#F7F8F9' }} />}
        {!mypage && <ReportDetail10 originData={origin} mypage={mypage} />}
        {!search && !mypage && (
          <div className="w-100per flex-center padding-b-16">
            <SaveBtn
              onClick={() => {
                report2('Y');
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HouseReportMain;

export interface IReport_desc {
  address: string;
  totalFloorArea: number;
  totalFloorAreaPyeong: number;
  floor: number;
  year_int: number;
  createdAt: string;
  estimatedHousingPrice: any;
  houseTradePrice: any;
  houseTradeDate: any;
  perPyeongEstimatedHousingPrice: any;
  PerMeterEstimatedHousingPrice: any;
  floorAreaRatio: number;
  buildingToLandRatio: number;
  type: string;
}

export interface ILandInfo {
  landArea: number;
  landPyeong: number;
  zoning: string;
  category: string;
  useStatus: string;
  height: string;
  roadSurface: string;
  CAGR: number;
  shape: string;
  roadSurface_desc: string;
  pnuCode: string;
  officialPriceList:
    | {
        2014: number;
        2015: number;
        2016: number;
        2017: number;
        2018: number;
        2019: number;
        2020: number;
        2021: number;
        2022: number;
        2023: number;
      }
    | any;
}

export interface ICostAnalysis {
  stdBuildingToLandRatio: number;
  stdFloorAreaRatio: number;
  myBuildingToLandRatio: number;
  ownHouseYN: string;
  myTotalFloorArea: number;
  myTotalFloorAreaPyeong: number;
  myFloorAreaRatio: number;
  constructionLoanInfo: {
    totalConstructionCost: number;
    ownMoney: number;
    loanCost: number;
    details: {
      boundaryCost: number;
      designCost: number;
      housePermitFee: number;
      constructionCost: number;
      cHouseAcquisitionTax: number;
      cFarmSpecialTax: number;
      registrationTax: number;
      cLocalEducationTax: number;
    };
    loanInfo: {
      loanCost: number;
      loanRate: number;
      loanType: string;
      retentionPeriod: number;
      redemptionPeriod: number;
    };
    loanPlan: Array<{
      round: number;
      total: number;
      principal: number;
      interest: number;
      principalSum: number;
      remainSum: number;
    }>;
    loanPlan1Year: Array<{
      round: number;
      total: number;
      principal: number;
      interest: number;
      principalSum: number;
      remainSum: number;
    }>;
  };
  houseCostLoanInfo: {
    totalHouseCost: number;
    ownMoney: number;
    details: {
      houseCost: number;
      pHouseAcquisitionTax: number;
      pLocalEducationTax: number;
      pFarmSpecialTax: number;
    };
    loanInfo: {
      loanCost: number;
      loanRate: number;
      loanType: string;
      retentionPeriod: number;
      redemptionPeriod: number;
    };
    loanPlan: Array<{
      round: number;
      total: number;
      principal: number;
      interest: number;
      principalSum: number;
      remainSum: number;
    }>;
    loanPlan1Year: Array<{
      round: number;
      total: number;
      principal: number;
      interest: number;
      principalSum: number;
      remainSum: number;
    }>;
  };
  eligibility: {
    constructionArea: number;
    liveInFarmYN: string;
    returnToFarmYN: string;
    floor: number;
  };
}

export interface IHousePriceAnalysis {
  estimatedHousingPrice: number;
  perPyeongEstimatedHousingPrice: number;
  PerMeterEstimatedHousingPrice: number;
  surroundings: {
    address: string;
    perPyeongHouseTradePrice: number;
    houseTradeDate: string;
    distance: number;
  }[];
  surroundingAvgValue: number;
}

export interface IIndexInfo {
  houseIndexList: Array<{
    gugun: string;
    stdYear: string;
    stdMonth: string;
    index: number;
  }>;
  newConstructionIndexList: Array<{
    gugun: string;
    stdYear: string;
    stdMonth: string;
    index: number;
  }>;
  remodelingIndexList: Array<{
    gugun: string;
    stdYear: string;
    stdMonth: string;
    index: number;
  }>;
  avgHouseRate: number;
  avgNewConstructionRate: number;
  avgRemodelingRate: number;
}
