import '../../asset/styles/SaleInfo.css';
import { Topnav } from '../../component/mobile/Topnav';
import sample from './../../asset/images/community/sample.svg';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { getComment, ModifyComment } from './Common';

interface ReCommentType {
  articleIdx: number;
  commentAmount: number | undefined;
  commentIdx: number;
  createdDate: string;
  email: string;
  likeAmount: number | undefined;
  content: string;
}
interface ReComment extends Array<ReCommentType> {}
interface CommentType {
  articleIdx: number;
  commentAmount: number | null;
  commentIdx: number;
  content: string;
  createdDate: string;
  email: string;
  likeAmount: number | null;
}

const CommentModify = () => {
  const [newcomment, setNewComment] = useState('');
  const [comment, setComment] = useState<CommentType>();
  const ref = useRef<HTMLInputElement>(null);
  const params = useParams();

  const commentIdx = params.commentIdx as string;

  useEffect(() => {
    const commentIdx = params.commentIdx as string;
    getComment(commentIdx, setComment);
  }, []);

  return (
    <div
      ref={ref}
      className="flex-column w-100per overflow-auto"
      style={{ paddingBottom: '120px', height: window.innerHeight }}
    >
      <Topnav text="커뮤니티" />
      <div className="flex-column w-100per padding-0-20 mt-20">
        <div className="flex-column w-100per padding-b-20 borderbottom-1px-e8ebe6 gap-10">
          <div className="flex-row center-y space-between">
            <div className="flex-row center-y gap-10">
              <img src={sample} />
              <div className="flex-column">
                <div className="font-normal">{comment && comment.email}</div>
                <div className="font-normal w-100per">
                  {comment && comment.createdDate}
                </div>
              </div>
            </div>
          </div>
          <div
            className="font-normal"
            style={{ fontSize: '20px', wordBreak: 'break-all' }}
          >
            {comment && comment.content}
          </div>
        </div>
      </div>
      <div
        style={{
          position: 'fixed',
          bottom: '71px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',

          justifyContent: 'space-between',
        }}
      >
        <div
          className="flex-row space-between w-100per"
          style={{
            border: '1px solid black',
            padding: '10px 20px',
            backgroundColor: 'white',
          }}
        >
          <input
            className="font-normal"
            placeholder="댓글을 수정해주세요."
            style={{
              width: '100%',
              border: 'none',
            }}
            onBlur={(e) => {
              setNewComment(e.target.value);
            }}
          />
          <div className="flex-center">
            <div
              style={{ whiteSpace: 'nowrap' }}
              onClick={() => {
                ModifyComment(commentIdx, newcomment);
              }}
              className="font-normal"
            >
              등록
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CommentModify;
