import { createSlice } from '@reduxjs/toolkit';

const initialUserState = {
  userId: '',
  name: '',
};

const user2Slice = createSlice({
  name: 'userInfo',
  initialState: initialUserState,
  reducers: {
    LoginId(state, action) {
      // 이메일 ID
      state.userId = action.payload;
    },

    UserName(state, action) {
      state.name = action.payload;
    },
  },
});

export const { LoginId, UserName } = user2Slice.actions;
export default user2Slice.reducer;
