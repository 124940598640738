import { useEffect, useRef, useState } from 'react';
import './Consulting.css';

import FooterMobile from '../../../component/AIIntroduction/Footer';
import { isMobile } from '../../../component/function/function';
import { TopNavNewBrowser } from '../../../component/mobile/Topnav';
import TopBanner from '../../../component/banner/TopBanner';
import Footer from '../../../component/browser/Footer';

import { Helmet } from 'react-helmet';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Personal } from './component/Personal';
import { Corporate } from './component/Corporate';
import { Team } from './component/Team';
import { Article } from './component/Article';
import Sidemenu from '../../../component/mobile/Hamburger';
import { useAuth } from '../../../utilities/auth';
import StarRatings from 'react-star-ratings';

const Consulting = () => {
  const { isLogin } = useAuth();
  const [hamOpen, setHamOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const fullRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [scrollY, setScrollY] = useState(0);

  const params = useParams();
  const tab2 = params.tab;
  const [tab, setTab] = useState(tab2 || 'personal');
  const [tabHeight, setTabHeight] = useState(0);
  useEffect(() => {
    if (ref.current) {
      setTabHeight(ref.current?.getBoundingClientRect().top);
    }
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY || window.pageYOffset);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    sessionStorage.setItem('landingType', 'consulting');
    // sessionStorage.setItem('beforeUrl', document.referrer);
    sessionStorage.setItem('beforeUrl', window.location.href);
  }, []);

  return (
    <div
      ref={fullRef}
      className="w-100per flex-column relative"
      style={{ overflowY: 'auto' }}
    >
      {!isLogin() && (
        <div
          className="fixed w-100per z-100 flex-row space-between center-y"
          style={{
            bottom: '0',
            justifyContent: 'center',
            backgroundColor: '#0B0B0B',
            height: isMobile(window.innerWidth) ? '80px' : '120px',
            // padding: isMobile(window.innerWidth) ? `16px` : `16px 360px`,
            padding: window.innerWidth < 768 ? '16px' : '16px 40px',
          }}
        >
          <div className="w-100per max-w-1200 flex-row space-between center-y">
            <div
              style={{
                fontFamily: 'NanumSquareNeo-Bold',
                fontSize: isMobile(window.innerWidth) ? `16px` : '20px',
                lineHeight: isMobile(window.innerWidth) ? '20px' : '29px',
                color: '#FFFFFF',
              }}
            >
              토지 거래 전 <br />{' '}
              <span
                style={{
                  fontFamily: 'NanumSquareNeo-ExtraBold',
                  color: '#00EA00',
                }}
              >
                토지분석이 먼저!
              </span>
            </div>
            <div
              className="border-radius-8 flex-center pointer"
              style={{
                backgroundColor: '#00EA00',
                padding: isMobile(window.innerWidth)
                  ? '4px 13.5px'
                  : '15.5px 24px',
              }}
              onClick={() => {
                navigate('/signup');
              }}
            >
              <div
                style={{
                  textAlign: 'center',
                  fontFamily: 'NanumSquareNeo-Heavy',
                  fontSize: isMobile(window.innerWidth) ? '16px' : '24px',
                  lineHeight: isMobile(window.innerWidth) ? '25px' : '25px',
                }}
              >
                회원 10%할인 {isMobile(window.innerWidth) ? <br /> : ''}{' '}
                토지분석 시작하기
              </div>
            </div>
          </div>
        </div>
      )}
      <Helmet>
        <title>
          토지전문 농업·금융 데이터 분석팀의 맞춤형 토지 컨설팅을 합리적
          가격으로 받아보세요
        </title>
        <meta
          name="description"
          content="토지 투자 손실 최소화, 수익 극대화를 위한 토지·농업·금융 데이터 융합의 전문 분석과 사업 타당성 분석-트랜스파머 토지 투자 컨설팅"
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="keyword"
          content="부동산,
          부동산경매,
          농업경영체 등록,
          부동산 전망,
          토지매매,
          부동산투자,
          땅매매,
          임야 매매,
          토지경매,
          토지가격,
          농지매매,
          부동산 조각투자,
          조각투자,
          토지시세,
          부동산재테크,
          토지상속,
          토지증여,
          토지투자,
          지분투자,
          농지상속,
          땅 투자,
          농지경매,
          농지투자,
          전답매매,
          농업컨설팅,
          임야투자,
          토지컨설팅,
          임야매입 
          "
        />
        <link
          rel="canonical"
          href="https://www.transfarmer.co.kr/promotion/트랜스파머-맞춤-토지-컨설팅"
        />
        {/* 오픈그래프 영역 */}
        <meta property="og:title" content="트랜스파머" />
        <meta
          property="og:description"
          content="정확한 토지 분석과 투자 솔루션"
        />
        <meta property="og:image" content="/asset/images/logo/og-img2.png" />
        <meta
          property="og:url"
          content="https://www.transfarmer.co.kr/promotion/전원주택-설계와-건축비-분석을-AI로-간편하게"
        />
      </Helmet>
      <TopBanner />
      <Sidemenu setOpen={setHamOpen} open={hamOpen} />
      {/* topnav, hamburger */}
      {isMobile(window.innerWidth) ? (
        <div className="w-100per padding-10-16 flex-row space-between">
          <div
            className="flex-column gap-6"
            style={{ width: '166px' }}
            onClick={() => {
              navigate('/');
            }}
          >
            <img
              src="/asset/images/logo/logo_korean_green.svg"
              style={{ width: '100px' }}
            />
            <div
              className="text-end"
              style={{
                fontWeight: '500',
                fontSize: '12px',
                lineHeight: '14px',
              }}
            >
              AI가이드로 농촌생활 똑똑하게!
            </div>
          </div>
          <img
            src="/asset/images/icon/hamburger_green.svg"
            style={{ width: '20px' }}
            onClick={() => {
              setHamOpen(!hamOpen);
            }}
          />
        </div>
      ) : (
        <TopNavNewBrowser />
      )}

      {/* body1 */}
      <div className="body-1-image">
        <div className="flex-column" style={{ gap: '80px' }}>
          <div
            className="text-center"
            style={{
              fontWeight: '800',
              fontSize: isMobile(window.innerWidth) ? '32px' : '48px',
              lineHeight: isMobile(window.innerWidth) ? '46px' : '69px',
              color: '#FFFFFF',
            }}
          >
            토지에 대한 모든 질문 <br />
            한 걸음 더, <br />
            정확하고 정직한 분석으로{' '}
          </div>
          {isMobile(window.innerWidth) && (
            <div className="flex-center">
              <img
                style={{ width: '30px', height: '30px' }}
                src="/asset/images/arrow/ArrowBottom12White.svg"
              />
            </div>
          )}
          <div
            className="flex-column w-312 flex-center border-radius-16 browser-w-720"
            style={{
              padding: '24px 16px 16px 16px',
              gap: '16px',
              backgroundColor: '#171E2660',
            }}
          >
            <div className="flex-row gap-4 center-y w-100per">
              <div
                style={{
                  fontFamily: 'GmarketSansMedium',
                  fontSize: '14px',
                  lineHeight: '14px',
                  color: '#FFFFFFA3',
                }}
              >
                만족도
              </div>
              <StarRatings
                rating={5}
                starDimension="18.77px"
                starSpacing="1px"
                starRatedColor="#00C474"
              />
            </div>
            <div className="flex-column gap-8 start-x w-100per">
              <div
                style={{
                  fontFamily: 'GmarketSansBold',
                  fontSize: isMobile(window.innerWidth) ? '22px' : '28px',
                  lineHeight: isMobile(window.innerWidth) ? '31.9px' : '40.6px',
                  color: '#FFFFFF',
                  textAlign: 'start',
                }}
              >
                전문가의 검증된 분석 보고서
              </div>
              <div
                style={{
                  fontFamily: 'GmarketSansMedium',
                  fontSize: isMobile(window.innerWidth) ? '20px' : '24px',
                  lineHeight: isMobile(window.innerWidth) ? '29px' : '34.8px',
                  color: '#FFFFFF',
                }}
              >
                “표지의 박사 실명 기재부터{' '}
                {isMobile(window.innerWidth && <br />)} 내용까지 모두
                만족스럽습니다”{' '}
              </div>
            </div>
            <div className="w-100per flex end-x">
              <div
                style={{
                  fontFamily: 'GmarketSansLight',
                  fontSize: isMobile(window.innerWidth) ? '10px' : '12px',
                  lineHeight: '20px',
                  color: '#FFFFFFA3',
                  textAlign: 'end',
                  marginRight: '30px',
                }}
              >
                ra*** , 소송 진행 중 농지 분석 의뢰 고객
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* body2 */}
      <div className="flex-column w-100per relative">
        {/* 800은 임시 나중에 수정해야함 */}
        <div
          className={`flex-column padding-x-16   w-100per border-b-171E26 flex-center bg-FFFFFF ${
            scrollY > 800 ? 'fixed top-0' : ''
          }`}
        >
          <div
            className={`flex-row padding-y-24 w-100per border-radius-4 `}
            style={{ maxWidth: '600px' }}
          >
            <div
              className={`w-50per pointer ${
                tab === 'personal' ? `bg-primary-500` : `bg-F6F6F6`
              } flex-center padding-8-16`}
              onClick={() => {
                setTab('personal');
              }}
              style={{ borderRadius: '4px 0 0 4px' }}
            >
              <div
                className={`m-b1-16-b w-t2-24-b ${
                  tab === 'personal' ? ` color-FFFFFF` : `color-dark-secondary`
                }`}
              >
                개인용
              </div>
            </div>
            <div
              className={`w-50per pointer ${
                tab === 'enterprise' ? `bg-primary-500` : `bg-F6F6F6`
              } flex-center`}
              onClick={() => {
                setTab('enterprise');
              }}
              style={{ borderRadius: '0 4px 4px 0' }}
            >
              <div
                className={`m-b1-16-b w-t2-24-b ${
                  tab === 'enterprise'
                    ? ` color-FFFFFF`
                    : `color-dark-secondary`
                }`}
              >
                기업용
              </div>
            </div>
          </div>
        </div>
        <div
          ref={ref}
          className="flex-column w-100per"
          style={{ padding: '80px 0' }}
        >
          {tab === 'personal' ? <Personal /> : <Corporate />}
        </div>
      </div>
      <div
        style={{ height: '16px', backgroundColor: '#F7F8F9', width: '100%' }}
      ></div>
      {/* body3 */}
      <Team />
      <div
        style={{ height: '16px', backgroundColor: '#F7F8F9', width: '100%' }}
      ></div>
      <Article />
      <div
        className="w-100per flex-column bg-light-gray flex-center"
        style={{ padding: '80px 16px' }}
      >
        <div className="flex-column" style={{ maxWidth: '1200px' }}>
          <div
            style={{ fontWeight: '700', fontSize: '18px', lineHeight: '29px' }}
            className="color-text-tertiary"
          >
            안내 및 유의사항
          </div>
          <div
            className="color-dark-secondary"
            style={{ fontWeight: '400', fontSize: '14px', lineHeight: '20px' }}
          >
            트랜스파머가 제공하는 보고서는 전문가의 노하우를 바탕으로 제공되는
            토지 가격 및 솔루션 상담 서비스 성격의 자료이며, 투자 수익 또는
            손해를 예측하는 데 있어서는 참고 자료로서만 활용하시길 바랍니다.
            상담 건과 관련해 본 보고서의 내용에 대한 의사결정 책임은 의뢰한
            고객에 있으며, 고객 자신의 판단과 책임하에 이루어져야 하며
            트랜스파머가 투자 권유 또는 보장을 하는 것으로 해석되어서는 안
            됩니다. ‘Transfarmer의 가격 분석 컨설팅 보고서'는 향후 경제상황이나
            부동산 정책 등 제반 가정 변화에 따라 그 내용이나 결과가 달라질 수
            있음에 유의 바랍니다{' '}
          </div>
        </div>
      </div>
      <FooterMobile />
      <Footer />
    </div>
  );
};

export default Consulting;
