import { useEffect, useState } from 'react';
import {
  RowTableGreenBold,
  RowSectionTitle,
  RowTableValueBold,
} from '../../../../component/report/Row';
import { TextSectionTitle } from '../../../../component/report/Text';
import { transNum } from '../../../../component/function/function';
import { Tooltip2 } from '../../../../component/common/tooltip';
import { IHousePriceAnalysis, ILandInfo, IReport_desc } from '../../Main';
import { ButtonSelect1M } from '../../../../component/report/Button';
import { BoxGreen1 } from '../../../../component/report/Box';

const ReportDetail1 = ({
  data2,
  data3,
  data,
  mypage,
}: {
  data2: IReport_desc;
  data3: IHousePriceAnalysis;
  data: ILandInfo;
  mypage?: boolean;
}) => {
  const [onToolTip, setOnToolTip] = useState(false);
  const [tab2, setTab2] = useState(1);

  return (
    <div className="padding-b-24 browser-bg-FFFFFF">
      <RowSectionTitle>
        <TextSectionTitle>1. 주택가격</TextSectionTitle>
      </RowSectionTitle>
      <div className="flex-column">
        <div className="padding-16-16-0 flex-column end-y">
          <RowTableGreenBold
            title="AI 주택 추정가"
            value={`${transNum(data3.estimatedHousingPrice)}원`}
          />
          <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
            {transNum(data3.perPyeongEstimatedHousingPrice)}
            원/평
          </div>
        </div>

        <div className="padding-8-16 gap-10">
          <BoxGreen1>
            <div className="font-bold f-size-14 line-h-20 color-text-secondary">
              해당 주택의 AI 추정가는{' '}
              {`${transNum(data3.estimatedHousingPrice, true)}원`}
              으로
            </div>
            <div
              className="font-bold f-size-16 line-h-24 "
              style={{
                color:
                  data3.estimatedHousingPrice >=
                  data.officialPriceList[2023] * data.landArea
                    ? '#1E9B6B'
                    : '#d62554',
              }}
            >
              공시지가
              {data3.estimatedHousingPrice &&
              data.officialPriceList[2023] &&
              data3.estimatedHousingPrice >=
                data.officialPriceList[2023] * data.landArea
                ? `보다 높습니다(${
                    Math.round(
                      (data3.estimatedHousingPrice /
                        (data.officialPriceList[2023] * data.landArea)) *
                        100 *
                        10,
                    ) / 10
                  }%)`
                : `보다 낮습니다(${
                    Math.round(
                      (data3.estimatedHousingPrice /
                        (data.officialPriceList[2023] * data.landArea)) *
                        100 *
                        10,
                    ) / 10
                  }%)`}
            </div>
          </BoxGreen1>
        </div>
        <div className="padding-8-16 gap-10">
          <BoxGreen1>
            {data3.surroundingAvgValue ? (
              <>
                <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                  해당 주택의 평당 AI 추정가는{' '}
                  {Math.round(
                    Number(data3.perPyeongEstimatedHousingPrice),
                  ).toLocaleString()}
                  원으로
                </div>
                <div
                  className="font-bold f-size-16 line-h-24 color-primary-500"
                  style={{
                    color:
                      Number(data3.perPyeongEstimatedHousingPrice) <
                      Number(data3.surroundingAvgValue)
                        ? '#d62554'
                        : '#1E9B6B',
                  }}
                >
                  인근 주택 평당 가격{' '}
                  {Math.round(
                    Number(data3.surroundingAvgValue),
                  ).toLocaleString()}
                  원
                  {Number(data3.perPyeongEstimatedHousingPrice) >
                  Number(data3.surroundingAvgValue) ? (
                    (Number(data3.perPyeongEstimatedHousingPrice) -
                      Number(data3.surroundingAvgValue)) /
                      Number(data3.surroundingAvgValue) <
                    0.05 ? (
                      <span>과 유사</span>
                    ) : (
                      <span>
                        보다 <br /> 높습니다(
                        {Math.abs(
                          Math.round(
                            (data3.perPyeongEstimatedHousingPrice /
                              data3.surroundingAvgValue) *
                              100,
                          ),
                        ).toFixed(1)}
                        %)
                      </span>
                    )
                  ) : Number(data3.perPyeongEstimatedHousingPrice) <
                    Number(data3.surroundingAvgValue) ? (
                    (Number(data3.surroundingAvgValue) -
                      Number(data3.perPyeongEstimatedHousingPrice)) /
                      Number(data3.surroundingAvgValue) <
                    0.05 ? (
                      <span> 과 유사</span>
                    ) : (
                      <span>
                        {' '}
                        보다 <br /> 낮습니다(
                        {Math.abs(
                          Math.round(
                            (data3.perPyeongEstimatedHousingPrice /
                              data3.surroundingAvgValue) *
                              100,
                          ),
                        ).toFixed(1)}
                        %)
                      </span>
                    )
                  ) : (
                    '과 같음'
                  )}
                </div>
              </>
            ) : (
              <div className="font-bold f-size-14 line-h-20 color-dark-primary">
                최근 5년 동안 해당 물건 주변 실거래 사례는 없습니다.
              </div>
            )}
          </BoxGreen1>
        </div>
        <div className="padding-16">
          <div
            style={{ background: '#171e2610', width: '100%', height: '1px' }}
          ></div>
        </div>

        <div className="padding-8-16 flex-row gap-8">
          <ButtonSelect1M
            onClick={() => {
              setTab2(1);
            }}
            selected={tab2 === 1}
          >
            거래이력
          </ButtonSelect1M>
          <ButtonSelect1M
            onClick={() => {
              setTab2(2);
            }}
            selected={tab2 === 2}
          >
            주변 거래 사례
          </ButtonSelect1M>
        </div>

        {tab2 === 1 ? (
          <div className="padding-16">
            {data2.houseTradePrice === null ? (
              <RowTableValueBold
                title=""
                value="최근 5년 내 거래 이력이 없습니다"
              />
            ) : (
              <RowTableValueBold
                title=""
                value={
                  <div className="flex-column end-y">
                    <div className="font-medium f-size-16 line-h-24 color-secondary">
                      {transNum(data2.houseTradePrice)}원
                    </div>
                    <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                      {Math.round(
                        data2.houseTradePrice / (data2.totalFloorArea * 0.3025),
                      ).toLocaleString()}
                      원/평
                    </div>
                    <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                      {data2.houseTradeDate &&
                        `${data2.houseTradeDate.slice(
                          0,
                          4,
                        )}-${data2.houseTradeDate.slice(
                          4,
                          6,
                        )}-${data2.houseTradeDate.slice(6, 8)}`}
                    </div>
                  </div>
                }
              />
            )}
          </div>
        ) : (
          <div className="flex-column">
            {/* <div className="padding-16-16-0 flex-row ">
              <div className="font-bold f-size-16 line-h-24 color-text-secondary">
                주변 거래사례
              </div>
              <img
                src="/asset/images/warn/Warn24.svg"
                className="hover"
                onClick={() => setOnToolTip(true)}
                alt=""
              />
            </div> */}
            <Tooltip2
              bottom="0"
              on={onToolTip}
              off={() => setOnToolTip(false)}
              text="더 많은 주변 거래사례는 고객센터에 문의 바랍니다."
            />
            <div className="padding-16 flex-column gap-16">
              {data3.surroundings && data3.surroundings.length > 0 ? (
                data3.surroundings.map((el) => {
                  let arr = el.address.split(' ');
                  let num = arr.pop() || '';
                  let num2 = '';
                  for (let i = 1; i < num.length; i++) {
                    num2 = num2 + '*';
                  }
                  const address = arr.join(' ') + ' ' + num.slice(0, 1) + num2;
                  return (
                    <div className="flex-row space-between">
                      <div className="font-medium f-size-16 line-h-24 color-text-tertiary">
                        반경 {Math.round(el.distance)}m
                      </div>
                      <div className="flex-column end-y">
                        <div className="font-medium f-size-16 line-h-24 color-secondary">
                          {address}
                        </div>
                        <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                          {Math.round(
                            el.perPyeongHouseTradePrice,
                          ).toLocaleString()}
                          원/평
                        </div>
                        <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                          {/* {el.houseTradeDate.slice(0, 4)}-
                          {el.houseTradeDate.slice(4, 6)}-
                          {el.houseTradeDate.slice(6, 8)} */}
                          {el.houseTradeDate &&
                            el.houseTradeDate.slice(0, 4) +
                              '-' +
                              el.houseTradeDate.slice(4, 6) +
                              '-' +
                              el.houseTradeDate.slice(6, 8)}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                  주변 거래 사례가 없습니다
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportDetail1;
