import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from '../../utilities/auth';

type RouteProp = {
  children: JSX.Element;
};

const ProtectedRoute = ({ children }: RouteProp) => {
  const { isLogin, setRedirect } = useAuth();
  const location = useLocation();
  setRedirect(
    location.pathname.slice(-1) === '/'
      ? location.pathname.slice(0, -1)
      : location.pathname,
  );

  if (window.navigator.userAgent === 'ReactSnap') {
    return children;
  }
  if (!isLogin()) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;
