import Request from '../../@api/request';

// const baseURL = "http://localhost:3333/";
// const baseURL = "https://back.transfarmer.kr/";
const baseURL = `${process.env.REACT_APP_HOST}`;
export const request = new Request(baseURL);

export default class CouponService {
  static check = async (couponCode: string) => {
    const result = await request.get(`/coupon/check?couponCode=${couponCode}`);
    return result.data;
  };
}
