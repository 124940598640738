import { createSlice } from '@reduxjs/toolkit';

const initialUserState = {
  userId: '',
  location: '',
  type: '',
  name: '',
  userPwd: '',
};

const userSlice = createSlice({
  name: 'userInfo',
  initialState: initialUserState,
  reducers: {
    LoginId(state, action) {
      // 이메일 ID
      state.userId = action.payload;
    },

    UserReset(state, action) {
      state.userPwd = initialUserState.userPwd;
    },
    UserLocation(state, action) {
      state.location = action.payload;
    },
    UserType(state, action) {
      state.type = action.payload;
    },
    UserName(state, action) {
      state.name = action.payload;
    },
  },
});

export const { LoginId, UserLocation, UserType, UserName, UserReset } =
  userSlice.actions;
export default userSlice.reducer;
