import './SignUpPromotion.css';
import './Font.css';
import Banner from '../../../component/AIIntroduction/Footer';
import FooterBrowser from '../../../component/browser/Footer';
import { Helmet } from 'react-helmet';
import { TopNavNewBrowser } from '../../../component/mobile/Topnav';
import { useEffect, useState } from 'react';
import { PromotionTopNav } from '../../../component/mobile/PromotionTopNav';
import CTA from '../ctaBtn/CTA';
import CompanyInfoSlider from '../infoSlider/InfoSlider';
import ABTest from '../../../service/promotion/Promotion';
import TopBanner from '../../../component/banner/TopBanner';
import SliderTab from './components/SliderTab';
import LandingBanner from './components/Landing-banner';
import ScrollToTopButton from '../../../component/common/ScrollToTop';
import { useNavigate } from 'react-router-dom';

const SignupMyHouse = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    sessionStorage.setItem('landingType', 'house');
    const fetchData = async () => {
      try {
        const data = { type: 'X', title: '내 집 짓기', action: 'N' };
        const result = await ABTest.ABLog(data);
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const actionUpdate = async () => {
    try {
      const data = { type: 'X', title: '내 집 짓기', action: 'Y' };
      const result = await ABTest.ABLog(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    sessionStorage.setItem('landingType', 'house');
    // sessionStorage.setItem('beforeUrl', document.referrer);
    sessionStorage.setItem('beforeUrl', window.location.href);
  }, []);
  return (
    <div className="wrap">
      <Helmet>
        <title>전원주택 신축 설계, 1분 만에 시공비 견적과 대출진단까지</title>
        <meta
          name="description"
          content="전원주택·시골집의 장소/면적/유형/조건 선택하면 신축 설계도면, 예상 시공비, 건축법 적합 여부 등을 확인합니다. AI 빅데이터 활용 주택설계-트랜스파머"
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="keyword"
          content="전원주택, 단독주택, 시골집, 시공설계, 주택 설계, 예상 시공비, 시공비 견적, 세컨하우스, 농가주택, 농막, 조립식주택"
        />
        <link
          rel="canonical"
          href="https://www.transfarmer.co.kr/promotion/전원주택-설계와-건축비-분석을-AI로-똑똑하게"
        />
        {/* 오픈그래프 영역 */}
        <meta property="og:title" content="트랜스파머" />
        <meta
          property="og:description"
          content="전원주택 설계와 시공비 분석- AI로 똑똑하게"
        />
        <meta property="og:image" content="/asset/images/logo/og-img.png" />
        <meta
          property="og:url"
          content="https://www.transfarmer.co.kr/promotion/전원주택-설계와-건축비-분석을-AI로-간편하게"
        />
      </Helmet>
      <div className="container">
        <ScrollToTopButton bottom={'130px'} mbottom={'90px'} isVisible={true} />
        {/* <TopBanner />
        <TopNavNewBrowser /> */}
        <PromotionTopNav open={open} setOpen={setOpen} />
        <div className="section section-house">
          <div className="section01-title-box">
            <h1 className="menu-tag">내 집 짓기</h1>
            <div className="section-description">나의 첫 전원주택</div>
            <div className="section-title-box">
              <div className="section-title">
                <div className="text40">
                  장소, 설계, 건축
                  <br />
                  비용 분석을 한번에
                </div>
              </div>
            </div>
          </div>
          <div className="section01-content-box section01-content-box-2">
            <img src="/asset/images/promotion/houseMain.svg" alt="houseMain" />
          </div>
        </div>
        <div className="section section02-1 house-content">
          <div className="section02-title-box">
            <img
              src="/asset/images/promotion/worry-face.svg"
              alt="걱정하는 이모지"
            />
            <p className="text40 text-light-green">
              나만의 <br />
              전원주택 짓기,
            </p>
            <p className="text40 text-grey">전문가 도움없이</p>
            <p className="text40 text-light-green">알아볼 수 있을까?</p>
          </div>
          <div className="section02-content-box-house">
            <img
              src="/asset/images/promotion/myhouse-self-1.svg"
              alt="myhouse-self-1"
            />
            <img
              src="/asset/images/promotion/myhouse-self-2.svg"
              alt="myhouse-self-2"
            />
            <img
              src="/asset/images/promotion/myhouse-self-3.svg"
              alt="myhouse-self-3"
            />
          </div>
        </div>
        <div className="section section05">
          <div className="section05-title-box">
            <div className="text32">누구나 간편하게</div>
            <div className="section05-title--box-1">
              <p className="text32 text-color-box"> 주택 구입비,</p>
              <p className="text32 text-color-box">설계, 건축비, 대출까지 </p>
              <p className="text32">한번에 알아볼 수 있어요</p>
            </div>
            <div className="line-column"></div>
          </div>
          <div className="section05-content-box">
            <div className="section05-speech-bubble-box">
              <div className="section05-speech-bubble">
                <div className="text20">
                  <span>주말농장</span>
                  에 자그마한
                  <br />
                  <span>조립식주택 </span>짓고 주말마다 가서 <br />
                  지내고 싶어요
                </div>
                <img
                  className="color-bubble"
                  src="/asset/images/promotion/grey-bubble-big.svg"
                  alt="말풍선"
                />
              </div>
              <div className="people-img">
                <img
                  src="/asset/images/promotion/people_illust_01.svg"
                  alt="사람1"
                />
              </div>
            </div>
            <div className="section05-speech-bubble-box-right">
              <div className="section05-speech-bubble">
                <div className="text20">
                  <span>귀농</span>
                  하려고 산 <span>시골집</span>
                  <br />
                  멋지게 <span>재건축 </span>하고 싶어요
                </div>
                <img
                  className="line-bubble"
                  src="/asset/images/promotion/line-bubble.svg"
                  alt="말풍선"
                />
              </div>
              <div className="people-img people-img-right">
                <img
                  src="/asset/images/promotion/people_illust_03.svg"
                  alt="사람2"
                />
              </div>
            </div>
            <div className="section05-speech-bubble-box-left">
              <div className="section05-speech-bubble">
                <div className="text20">
                  노후된 전원주택 <br />
                  <span>신축설계 견적</span>을 받았어요, <br />이{' '}
                  <span>비용이 </span>합리적인지 <br />
                  확인하고 싶어요
                </div>
                <img
                  className="color-bubble-big"
                  src="/asset/images/promotion/grey-bubble-big2.svg"
                  alt="말풍선"
                />
              </div>
              <div className="people-img">
                <img
                  src="/asset/images/promotion/people_illust_02.svg"
                  alt="사람3"
                />
              </div>
            </div>
            <div className="section05-speech-bubble-box-right">
              <div className="section05-speech-bubble">
                <div className="text20">
                  시골에 <span>세컨하우스</span>를
                  <br />
                  갖고 싶은데, 여러가지 <span>설계</span>를
                  <br />
                  알아보고 싶어요.
                </div>
                <img
                  className="line-bubble line-bubble"
                  src="/asset/images/promotion/line-bubble-big.svg"
                  alt="말풍선"
                />
              </div>
              <div className="people-img people-img-right">
                <img
                  src="/asset/images/promotion/people_illust_04.svg"
                  alt="사람4"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="section section06 section06-1">
          <div className="section06-title-box">
            <div className="section06-title-1">
              <p className="text27 text27-green">가장 쉬운</p>
              <p className="text27 text-round-box">주택설계</p>
            </div>
            <div className="section06-title-2">
              <p className="text24 text-dot-right-1">전문가 도움없이</p>
              <p className="text24 text-dot-right">주택보유 상관없이</p>
            </div>
          </div>
          <div className="section06-content-box">
            <div className="section06-content-01">
              <div className="content-01-title">
                <p className="text-20-bold">전원주택 시공/설계</p>
                <p className="text24-green">AI인공지능 빅 데이터 활용</p>
              </div>
              <div className="section06-content-01-imgs">
                <div className="content-01-img">
                  <img
                    src="/asset/images/promotion/myhouse-easy-1.svg"
                    alt="myhouse-easy-1"
                  />
                  <p className="text20 text-20-extra-bold light-green">
                    건축 전문가 의뢰없이 간편하게
                  </p>
                  <p className="text14 white">
                    건축법, 건폐율, 용적률, 비용계산 등 <br />
                    전문가 도움없이 간편하게 확인하세요.
                  </p>
                </div>
                <div className="content-01-img">
                  <img
                    src="/asset/images/promotion/myhouse-easy-2.svg"
                    alt="myhouse-easy-2"
                  />
                  <p className="text20 text-20-extra-bold light-green">
                    개별맞춤 설정으로 디테일하게
                  </p>
                  <p className="text14 white">
                    사용자 상황에 따른 다양한 옵션 선택으로 <br />
                    구체적인 설정이 가능합니다.
                  </p>
                </div>
                <div className="content-01-img">
                  <img
                    src="/asset/images/promotion/myhouse-easy-3.svg"
                    alt="myhouse-easy-3"
                  />
                  <p className="text20 text-20-extra-bold light-green">
                    주택 외관, 내부, 설계도면까지
                  </p>
                  <p className="text14 white">
                    40여개 주택모델의 세부정보를 꼼꼼하게 확인하며 <br /> 원하는
                    모델을 선택해보세요.
                  </p>
                </div>
                <div className="content-01-img">
                  <img
                    src="/asset/images/promotion/myhouse-easy-4.svg"
                    alt="myhouse-easy-4"
                  />
                  <p className="text20 text-20-extra-bold light-green">
                    건축법규 적합 여부 확인도 쉽게
                  </p>
                  <p className="text14 white">
                    해당 지역의 법정 용적률, 건폐율에
                    <br /> 설정한 주택이 적합한지 자동 확인됩니다.
                  </p>
                </div>
                <div className="content-01-img">
                  <img
                    src="/asset/images/promotion/myhouse-easy-5.svg"
                    alt="myhouse-easy-5"
                  />
                  <p className="text20 text-20-extra-bold light-green">
                    시공비 확인과 대출 진단까지
                  </p>
                  <p className="text14 white">
                    주택 구입비, 시공비의 세부 내역 확인하시고 <br />
                    대출 금액 설정, 상환계획을 세워보세요.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section section08 section08-1">
          <div className="section08-title-box">
            <img src="/asset/images/promotion/Lightbulb.svg" alt="전구" />
            <p className="text27">이렇게 이용하세요</p>
            <p className="text20">
              주소 선택 후 <br />
              내 집 컨셉 결정하면
              <br />
              <span>예상 시공비</span>를 알려드려요
            </p>
          </div>
          <div className="section08-01-content-box">
            <div>
              <div className="usage-step-title text-20-extra-bold  green">
                {' '}
                1 <br />
                홈에서 '내 집 짓기' 선택
              </div>
              <div className="usage-step-content2">
                <img
                  className="aiFarming-gif"
                  src="/asset/images/promotion/myhouse-gif-1.gif"
                  alt="이용가이드1"
                />
              </div>
            </div>
            <div>
              <div className="usage-step-title text-20-extra-bold  green">
                {' '}
                2 <br />내 조건 입력
              </div>
              <div className="usage-step-content2">
                <img
                  className="aiFarming-gif"
                  src="/asset/images/promotion/myhouse-gif-2.gif"
                  alt="이용가이드2"
                />
              </div>
            </div>
            <div>
              <div className="usage-step-title text-20-extra-bold  green">
                {' '}
                3 <br />내 집 건축보고서 확인
              </div>
              <div className="usage-step-content2">
                <img
                  className="aiFarming-gif"
                  src="/asset/images/promotion/myhouse-gif-3.gif"
                  alt="이용가이드3"
                />
              </div>
            </div>
          </div>
          <div
            className="w-100per flex-center padding-12 pointer"
            style={{ backgroundColor: '#171E26' }}
            onClick={() => {
              navigate(
                '/promotion/%ED%8A%B8%EB%9E%9C%EC%8A%A4%ED%8C%8C%EB%A8%B8-%EB%A7%9E%EC%B6%A4-%ED%86%A0%EC%A7%80-%EC%BB%A8%EC%84%A4%ED%8C%85?utm_source=TF&utm_medium=banner&utm_campaign=consulting_banner&utm_id=promotion&utm_content=consulting_banner',
              );
            }}
          >
            <div
              className="flex-row space-between w-100per"
              style={{ maxWidth: '1200px' }}
            >
              <div className="flex-row gap-8 w-100per center-y">
                <div
                  className="bg-primary-400 border-radius-2"
                  style={{ padding: '2px 6px' }}
                >
                  <div className="m-b2-14-b color-dark-primary">
                    맞춤 컨설팅
                  </div>
                </div>
                <div className="m-b1-16-b color-FFFFFF">
                  토지 매매, 이제 <span style={{ color: '#09CE7D' }}>안심</span>
                  하세요
                </div>
              </div>
              <img
                className="w-24"
                src="/asset/images/arrow/ArrowRightWhite20.svg"
              />
            </div>
          </div>
          <LandingBanner />
        </div>
        <div className="section section09">
          <div className="section09-title-box">
            <img src="/asset/images/promotion/Logo_Symbol.svg" alt="로고" />
            <p className="text32">트랜스파머는</p>
            <p className="text20">
              농지 검색에서 수익창출까지 <br />
              농지 위의 모든 것을 데이터화하여 <br />
              가치를 만들어 갑니다
            </p>
          </div>
          <div className="section09-content-box">
            <CompanyInfoSlider />
          </div>
        </div>
        <Banner />
        <FooterBrowser />
      </div>
      <CTA
        text1="나만의 전원주택"
        text3="설계하고 시공비 확인"
        btnText1="온라인 전원주택"
        btnText2="AI 설계 시작하기"
        actionUpdate={actionUpdate}
        greenTextColor="#00EA00"
        actionBtnBackground="#00EA00"
      />
    </div>
  );
};
export default SignupMyHouse;
