import { useLocation, useNavigate } from 'react-router-dom';
import {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  MouseEvent,
} from 'react';
import Button from '../../../../component/mobile/Button';
import { useAuth } from '../../../../utilities/auth';
import SignController from '../../../../controller/sign/SignController';
import LoginPopUp from '../../../../component/common/LoginPopUp';
import { isMobile, transNum } from '../../../../component/function/function';
import { Tooltip2, TooltipMobile } from '../../../../component/common/tooltip';
import { SideTopnav } from '../../../../component/browser/SideTopnav';
import back from './../../../../asset/images/topnav/arrowBack.svg';
import Current from '../../../report/Current';
import Cost from '../../../report/Cost';
import FarmingReportMain from '../../report/Main';
import InquiryWrite from '../../../inquiry/InquiryWrite';
import SideSearch from '../../../../component/browser/sidebar/Search';
import LandValueReport from '../../../findLand/component/Report';
import SearchReportMain from '../../../reportResultSearch/Main';
import PriceReportMain from '../../../reportResultPrice/Main';
import PensionInput from '../../../pension/report/Report';
import PensionReportMain from '../../../reportResultPension/Main';
import Detail from './Detail';
import { LoginPopUp2 } from '../../../../component/common/LoginPopUp2';
import useDidMountEffect from '../../../../hooks/UseDidMountEffect';
import ReportResultLanduse from '../../../reportResultLandUse/ReportResultLanduse';

interface ISaleRentalPreview2Data {
  address: string;
  fixMarker: Function;
  currentMarker: any;
  query: string;
  areaList: any;
  currentInfo: any;
  setOnMyLandList: any;
  markerArray: Array<any>;
  setAreaList: React.Dispatch<React.SetStateAction<Array<any>>>;
  setMarkerArray: React.Dispatch<React.SetStateAction<Array<any>>>;
  onMyLandList: boolean;
  setKmTab?: any;
  kmTab?: any;
  setDrawCircle?: any;
  drawCircle?: any;
}

const SaleRentalPreview2 = (props: ISaleRentalPreview2Data) => {
  const navigate = useNavigate();
  const element = useRef<HTMLDivElement>(null);
  const element2 = useRef<HTMLDivElement>(null);
  const tab1 = useRef<HTMLDivElement>(null);
  const tab2 = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const locationHook = useLocation();
  const [refresh, setRefresh] = useState(false);
  const [detailCloseHeight, setDetailCloseHeight] = useState(0);
  const [close, setClose] = useState(false);

  const [priceDetail, setPriceDetail] = useState(false);
  const [ownLaborYN, setOwnLaborYN] = useState('Y');
  const dummy = [
    { name: '재배 환경', index: 0, onclick: 'subcategory1' },
    { name: '재배 면적', index: 1, onclick: 'subcategory2' },
    { name: '작물', index: 2, onclick: 'subcategory3' },
    { name: '자가 노동비', index: 3, onclick: 'subcategory4' },
  ];
  const dummy2 = [
    { name: '농지 소유 여부', index: 0, onclick: 'subcategory5' },
    { name: '초기 투자비', index: 1, onclick: 'subcategory6' },
    { name: '연간 운영비', index: 2, onclick: 'subcategory7' },
    { name: '농업 자금 대출 현황', index: 3, onclick: 'subcategory8' },
  ];
  // console.log('props', props);

  const [query, setQuery] = useState('');
  useEffect(() => {
    if (
      locationHook.search.split('=')[1] &&
      !locationHook.search.includes('n_media')
    ) {
      setQuery(decodeURI(locationHook.search.split('=')[1]));
    } else {
      setQuery('');
    }
    setClose(false);
  }, [locationHook]);
  const [menu, setMenu] = useState('tab1');

  const [subCategory, setSubCategory] = useState('subcategory1');
  const [onInquiry, setOnInquiry] = useState(false);
  const [onReport, setOnReport] = useState(false);
  const [onReport2, setOnReport2] = useState(false);
  const [onReport3, setOnReport3] = useState(true);
  const [onDetail, setOnDetail] = useState(true);

  const [onLoginPopup, setOnLoginPopup] = useState(false);

  const [tabWidth, setTabWidth] = useState(0);
  const [left, setLeft] = useState(0);

  const [top, setTop] = useState(0);
  const [reportIdx, setReportIdx] = useState(0);
  const [reportIdx2, setReportIdx2] = useState(0);
  const [reportIdx3, setReportIdx3] = useState(0);
  const [reportIdx4, setReportIdx4] = useState(0);
  const [area, setArea] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [clickPoint, setClickPoint] = useState(0);
  const [menuHeight, setMenuHeight] = useState<number>(0);
  const [isCropAvailable, setIsCropAvailable] = useState(false);
  const [landUseReportIdx, setLandUseReportIdx] = useState(0);

  const handleMouseDownEvent = (e: MouseEvent<HTMLDivElement>) => {
    // 마우스 클릭하면
    setDragging(true);
    if (containerRef.current) {
      setClickPoint(e.pageX); // clickPoint는 처음 클릭한 지점
      setScrollLeft(containerRef.current.scrollLeft); // 스크롤움직인 거리, 처음은 0
    }
  };

  const handleMouseMoveEvent = (e: MouseEvent<HTMLDivElement>) => {
    if (!dragging) return; // onMouseDownEvent에서 dragging=true가 아니면 작동하지 않음
    e.preventDefault();
    if (containerRef.current) {
      const walk = e.pageX - clickPoint;
      containerRef.current.scrollLeft = scrollLeft - walk;
    }
  };
  const [position, setPosition] = useState<number>(0);

  const ItemColumn = ({ content }: any) => (
    <div
      className="font-bold f-size-16 line-h-26 color-text-secondary nowrap  text-end"
      style={{ flex: 0.6 }}
    >
      {content}
    </div>
  );

  const DefaultText = ({ content }: any) => (
    <div className={`m-b1-16-b color-dark-primary text-end`}>{content}</div>
  );

  useLayoutEffect(() => {
    const obj = {
      tab1: tab1 as any,
      tab2: tab2,
    };
    const tab = obj[menu as 'tab1'];
    if (tab?.current) {
      setTabWidth(tab.current.offsetWidth);
      setTop(tab.current.offsetTop + tab.current.offsetHeight);
      setLeft(tab.current.offsetLeft);
    }
  }, [menu]);
  useEffect(() => {
    setMenuHeight(tab1?.current?.getBoundingClientRect().top || 0);
    setMenu('tab1');
  }, [onReport]);

  const temp = useRef<HTMLDivElement>(null);

  function onScroll() {
    setPosition(temp.current?.scrollTop || 0);
  }
  useEffect(() => {
    temp.current?.addEventListener('scroll', onScroll);

    return () => {
      temp.current?.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    let info = {
      area: props.query,
      latLng: props.currentMarker ? props.currentMarker.latLng : 0,
      info: props.currentInfo,
    };
    props.setAreaList([info]);
    sessionStorage.setItem('areaList', JSON.stringify([info]));
  }, [query]);

  const PriceMobileNext = () => {
    setPriceDetail(true);
    setClose(true);
  };
  function scrollToElement(elementId: any) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' }); // 요소의 맨 위로 스크롤을 부드럽게 이동
    }
  }
  useDidMountEffect(() => {
    if (onReport3) {
      scrollToElement('temp');
    }
  }, [onReport3]);

  const scrollTo = (text: string) => {
    if (text === 'current') {
      temp.current?.scrollTo(0, 600);
    } else if (text === 'cost') {
      temp.current?.scrollTo(0, 1603);
    } else if (text === 'subcategory1') {
      temp.current?.scrollTo(0, 559);
    } else if (text === 'subcategory2') {
      temp.current?.scrollTo(0, 685);
    } else if (text === 'subcategory3') {
      temp.current?.scrollTo(0, 802);
    } else if (text === 'subcategory4') {
      temp.current?.scrollTo(0, 1153);
    } else if (text === 'subcategory5') {
      temp.current?.scrollTo(0, 1471);
    } else if (text === 'subcategory6') {
      temp.current?.scrollTo(0, 1624);
    } else if (text === 'subcategory7') {
      temp.current?.scrollTo(0, 2059);
    } else if (text === 'subcategory8') {
      temp.current?.scrollTo(0, 2366);
    }
  };

  useEffect(() => {
    if (position > 599 && position < 1349) {
      setMenu('tab1');
      if (position > 599 && position < 648) {
        setSubCategory('subcategory1');
      } else if (position > 648 && position < 801) {
        setSubCategory('subcategory2');
      } else if (position > 801 && position < 1150) {
        setSubCategory('subcategory3');
      } else if (position > 1150 && position < 1349) {
        setSubCategory('subcategory4');
      }
    } else if (position > 1349) {
      setMenu('tab2');
      if (position > 1349 && position < 1478) {
        setSubCategory('');
      }
      if (position > 1478 && position < 1621) {
        setSubCategory('subcategory5');
      } else if (position > 1621 && position < 2056) {
        setSubCategory('subcategory6');
      } else if (position > 2056 && position < 2175) {
        setSubCategory('subcategory7');
      } else if (position < 2366) {
        setSubCategory('subcategory8');
      }
    }
  }, [position]);

  useEffect(() => {
    setOnReport(false);
    setReportIdx(0);
    setOnReport2(false);
    setReportIdx2(0);
    setOnReport3(false);
    setReportIdx3(0);
    setOnDetail(true);
    setClose(false);
    setIsCropAvailable(false);
  }, [props.currentInfo.area]);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(false);
  }, [locationHook.search]);

  const PricePage =
    (locationHook.pathname.slice(-1) === '/'
      ? locationHook.pathname.slice(0, -1)
      : locationHook.pathname) === '/price/map';

  const LandUsePage =
    (locationHook.pathname.slice(-1) === '/'
      ? locationHook.pathname.slice(0, -1)
      : locationHook.pathname) === '/landUse/map';

  const renderContent = () => {
    const farmCategories = ['전', '답', '과수원'];
    const landCatergories = ['전', '답', '과수원', '임야', '대'];
    if (
      !PricePage &&
      !LandUsePage &&
      props.currentInfo.category &&
      farmCategories.includes(props.currentInfo.category)
    ) {
      return (
        <Detail
          pnuCode={props.currentInfo.pnuCode}
          setDetailCloseHeight={setDetailCloseHeight}
          setOnDetail={setOnDetail}
          onDetail={onDetail}
          query={query}
          props={props}
          DefaultText={DefaultText}
          ItemColumn={ItemColumn}
          onReport={onReport}
          setOnLoginPopup={setOnLoginPopup}
          setRefresh={setRefresh}
          refresh={refresh}
          setOnReport={setOnReport}
          onReport2={onReport2}
          setOnReport2={setOnReport2}
          close={close}
          PriceMobileNext={PriceMobileNext}
          setReportIdx4={setReportIdx4}
          reportIdx4={reportIdx4}
          priceDetail={priceDetail}
          setOnReport3={setOnReport3}
          setOnInquiry={setOnInquiry}
        />
      );
    } else if (
      PricePage &&
      props.currentInfo.category &&
      landCatergories.includes(props.currentInfo.category)
    ) {
      return (
        <Detail
          pnuCode={props.currentInfo.pnuCode}
          setDetailCloseHeight={setDetailCloseHeight}
          setOnDetail={setOnDetail}
          onDetail={onDetail}
          query={query}
          props={props}
          DefaultText={DefaultText}
          ItemColumn={ItemColumn}
          onReport={onReport}
          setOnLoginPopup={setOnLoginPopup}
          setRefresh={setRefresh}
          refresh={refresh}
          setOnReport={setOnReport}
          onReport2={onReport2}
          setOnReport2={setOnReport2}
          close={close}
          PriceMobileNext={PriceMobileNext}
          setReportIdx4={setReportIdx4}
          reportIdx4={reportIdx4}
          priceDetail={priceDetail}
          setOnReport3={setOnReport3}
          setOnInquiry={setOnInquiry}
        />
      );
    } else if (LandUsePage && props.currentInfo.category) {
      return (
        <Detail
          pnuCode={props.currentInfo.pnuCode}
          setDetailCloseHeight={setDetailCloseHeight}
          setOnDetail={setOnDetail}
          onDetail={onDetail}
          query={query}
          props={props}
          DefaultText={DefaultText}
          ItemColumn={ItemColumn}
          onReport={onReport}
          setOnLoginPopup={setOnLoginPopup}
          setRefresh={setRefresh}
          refresh={refresh}
          setOnReport={setOnReport}
          onReport2={onReport2}
          setOnReport2={setOnReport2}
          close={close}
          PriceMobileNext={PriceMobileNext}
          setReportIdx4={setReportIdx4}
          reportIdx4={reportIdx4}
          priceDetail={priceDetail}
          setOnReport3={setOnReport3}
          setOnInquiry={setOnInquiry}
          setLandUseReportIdx={setLandUseReportIdx}
        />
      );
    } else {
      return (
        <SideSearch
          noList={true}
          on={false}
          location={
            query &&
            (decodeURI(query).slice(0, 4) !== 'list'
              ? decodeURI(query)
              : query.split(']')[1])
          }
        />
      );
    }
  };

  return (
    <div
      className={`bg-FFFFFF fixed z-1 w-100per padding-8-16 border-top-radius-16 flex-column gap-16 transition browser-absolute browser-h-100per browser-top-0 browser-left-0 browser-border-radius-0 browser-flex-row browser-gap-0 browser-padding-0 browser-bg-F7F8F9" +
      ${!close ? 'browser-w-449' : 'browser-w-824'}`}
      style={
        window.innerWidth < 1024
          ? {
              // height: props.currentInfo.estimatedPrice ? "550px" : "50px",
              bottom: onDetail ? 0 : `-${detailCloseHeight}px`,
              // bottom: onDetail ? 0 : `-429px`,
            }
          : {}
      }
    >
      {onInquiry && (
        <InquiryWrite address={props.address} setOnInquiry={setOnInquiry} />
      )}
      <div className="browser browser-flex w-74">
        {' '}
        <SideTopnav />
      </div>
      <div
        style={{ overflowX: 'hidden' }}
        className={
          'flex-column browser-border-b-171E26  browser-h-100per browser-w-375 browser-of-scroll ' +
          ((locationHook.pathname.slice(-1) === '/'
            ? locationHook.pathname.slice(0, -1)
            : locationHook.pathname) === '/price/map' &&
            !isMobile(window.innerWidth) &&
            `border-r-171E2610`)
        }
        ref={temp}
      >
        {onLoginPopup ? (
          <LoginPopUp2
            address={props.address}
            setOnLoginPopup={setOnLoginPopup}
            // setOnLoginPopup={setOnLoginPopup}
            // address={props.address}
            // pathName={(locationHook.pathname.slice(-1) === "/" ? locationHook.pathname.slice(0,-1): locationHook.pathname)}
          />
        ) : (
          ''
        )}

        {renderContent()}
        {!isMobile(window.innerWidth) && onReport ? (
          <div className="flex-column browser-mt-20 browser">
            <div
              className={`flex-column bg-FFFFFF  w-100per relative border-b-171E26 padding-10-10-0-10 ${
                position > menuHeight
                  ? `browser-fixed browser-w-360 browser-top-0  browser-z-20  ${
                      reportIdx > 0 ? ` border-r-171E2610` : ``
                    }`
                  : ''
              }`}
            >
              {position > menuHeight && (
                <div className="flex-row space-between padding-y-12 border-b-171E26">
                  <img
                    src={back}
                    alt=""
                    className="hover"
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                  <div className="font-medium f-size-16 line-h-24 color-text-primary">
                    {props.address}
                  </div>
                  <div>&nbsp;</div>
                </div>
              )}
              <div className="flex-row  gap-20 browser-mt-12 browser-relative border-b-171E26">
                <div
                  className={
                    menu === 'tab1'
                      ? 'font-bold f-size-18 line-h-26 color-text-primary hover padding-b-10 '
                      : 'font-bold f-size-18 line-h-26 color-171E2640 hover padding-b-10'
                  }
                  ref={tab1}
                  onClick={() => {
                    setMenu('tab1');
                    scrollTo('current');
                  }}
                >
                  농장 현황 입력
                </div>
                <div
                  className={
                    menu === 'tab2'
                      ? 'font-bold f-size-18 line-h-26 color-text-primary hover padding-b-10'
                      : 'font-bold f-size-18 line-h-26 color-171E2640 hover padding-b-10'
                  }
                  ref={tab2}
                  onClick={() => {
                    setMenu('tab2');
                    scrollTo('cost');
                  }}
                >
                  영농 비용 입력
                </div>
                <div
                  className="max-1024-slider "
                  style={{
                    display: 'flex',
                    width: tabWidth,
                    left: left,
                    top: top,
                  }}
                />
              </div>
              <div
                className="flex-row browser-mt-12 gap-5 browser-of-scroll-horizontal w-100per browser-padding-b-15"
                ref={containerRef}
                onMouseDown={handleMouseDownEvent}
                onMouseLeave={() => setDragging(false)}
                onMouseUp={() => setDragging(false)}
                onMouseMove={handleMouseMoveEvent}
              >
                {menu === 'tab1'
                  ? dummy.map((item, index) => {
                      return (
                        <a
                          style={{ textDecoration: 'none' }}
                          href="#"
                          className={`padding-12-16 flex-center border-radius-100 pointer ${
                            item.onclick === subCategory
                              ? 'bg-primary'
                              : 'bg-white border-f0f0f0'
                          }`}
                          onClick={(e: any) => {
                            scrollTo(item.onclick);

                            setSubCategory(item.onclick);
                          }}
                        >
                          <div
                            className={`f-size-16 line-h-24 no-wrap ${
                              item.onclick === subCategory
                                ? 'color-white font-bold'
                                : 'color-text-171E2656 font-medium'
                            }`}
                          >
                            {item.name}
                          </div>
                        </a>
                      );
                    })
                  : dummy2.map((item, index) => {
                      return (
                        <div
                          className={`padding-12-16 flex-center border-radius-100 pointer ${
                            item.onclick === subCategory
                              ? 'bg-primary'
                              : 'bg-white border-f0f0f0 '
                          }`}
                          onClick={() => {
                            scrollTo(item.onclick);
                            setSubCategory(item.onclick);
                          }}
                        >
                          <div
                            className={`f-size-16 line-h-24 no-wrap ${
                              item.onclick === subCategory
                                ? 'color-white font-bold'
                                : 'color-text-171E2656 font-medium'
                            }`}
                          >
                            {item.name}
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>

            <div className="flex w-100per" ref={element}>
              <Current
                subcategory1={dummy[0].onclick}
                setSelected={setSelected}
                selected={selected}
                area={area}
                setArea={setArea}
                refresh={refresh}
                setIsCropAvailable={setIsCropAvailable}
                setOwnLaborYN2={setOwnLaborYN}
                address={props.address}
              />
            </div>
            <div className="flex w-100per" ref={element2}>
              {selected ? (
                <Cost
                  PriceMobileNext={PriceMobileNext}
                  setAreaList={props.setAreaList}
                  query={props.query}
                  latlng={props.currentMarker.latLng}
                  info={props.currentInfo}
                  setReportIdx={setReportIdx}
                  selected={selected}
                  landPrice={props.currentInfo.estimatedPrice}
                  setLeft={setLeft}
                  setTabWidth={setTabWidth}
                  setClose={setClose}
                  isCropAvailable={isCropAvailable}
                  ownLaborYN={ownLaborYN}
                  setOwnLaborYN={setOwnLaborYN}
                  address={props.address}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        ) : (
          ''
        )}
        {!isMobile(window.innerWidth) && onReport2 && (
          <div className="flex-column browser  bg-light-gray">
            {/* 영농사업계획 농지찾기(input) */}
            <LandValueReport
              originalArea={props.currentInfo.area}
              setClose={setClose}
              setOnReport2={setOnReport2}
              setReportIdx2={setReportIdx2}
              location2={props.address}
              estimatedPrice2={props.currentInfo.estimatedPrice}
              estimatedRentalPrice={props.currentInfo.estimatedRentalPrice}
              position={position}
              temp={temp}
            />
          </div>
        )}
        {(locationHook.pathname.slice(-1) === '/'
          ? locationHook.pathname.slice(0, -1)
          : locationHook.pathname) === '/annuity/map' &&
          props.currentInfo.estimatedPrice !== 0 && //이상한 0이 나와서 수정 기존에는 props.currentInfo.estimatedPrice &&으로 되어있었음
          !isMobile(window.innerWidth) &&
          onReport3 && (
            <div className="flex-column browser  bg-light-gray" id="temp">
              <PensionInput
                onReport3={onReport3}
                address={props.address}
                setClose={setClose}
                close={close}
                setReportIdx={setReportIdx3}
                props={props}
              />
            </div>
          )}
      </div>
      {/* 내 농장 진단보고서 */}
      {reportIdx > 0 && close ? (
        <div
          className="flex-column browser-border-b-171E26 browser-w-375 browser-h-100per"
          style={{ marginLeft: '0px' }}
        >
          <FarmingReportMain reportIdx={reportIdx} setClose={setClose} />
        </div>
      ) : (
        ''
      )}
      {/* 농지찾기 보고서 */}
      {reportIdx2 > 0 && close ? (
        <div
          className="flex-column browser-border-b-171E26 browser-w-375 browser-h-100per"
          style={{ marginLeft: '0px' }}
        >
          <SearchReportMain reportIdx={reportIdx2} setClose={setClose} />
        </div>
      ) : (
        ''
      )}
      {/* 가격분석 보고서 */}
      {close &&
        priceDetail &&
        reportIdx4 > 0 &&
        (locationHook.pathname.slice(-1) === '/'
          ? locationHook.pathname.slice(0, -1)
          : locationHook.pathname) === '/price/map' && (
          <div
            className="flex-column browser-border-b-171E26 browser-w-375 browser-h-100per"
            style={{ marginLeft: '0px' }}
          >
            <PriceReportMain
              address={props.address}
              reportIdx={reportIdx4}
              setClose={setClose}
              info={props.currentInfo}
              setKmTab={props.setKmTab}
              kmTab={props.kmTab}
              setDrawCircle={props.setDrawCircle}
            />
          </div>
        )}
      {/* 토지 활용 보고서  */}

      {close &&
        priceDetail &&
        reportIdx4 > 0 &&
        (locationHook.pathname.slice(-1) === '/'
          ? locationHook.pathname.slice(0, -1)
          : locationHook.pathname) === '/landUse/map' && (
          <div
            className="flex-column browser-border-b-171E26 browser-w-375 browser-h-100per"
            style={{ marginLeft: '0px' }}
          >
            <ReportResultLanduse setClose={setClose} reportIdx4={reportIdx4} />
          </div>
        )}
      {/* 연금 보고서 */}
      {reportIdx3 > 0 && close && (
        <div
          className="flex-column browser-border-b-171E26 browser-w-375 browser-h-100per"
          style={{ marginLeft: '0px' }}
        >
          <PensionReportMain setClose={setClose} reportIdx={reportIdx3} />
        </div>
      )}
    </div>
  );
};

export default SaleRentalPreview2;
