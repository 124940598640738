import './SignUpPromotion.css';
import './Font.css';
import Banner from '../../../component/AIIntroduction/Footer';
import FooterBrowser from '../../../component/browser/Footer';
import { Helmet } from 'react-helmet';
import { TopNavNewBrowser } from '../../../component/mobile/Topnav';
import { useEffect, useState } from 'react';
import { PromotionTopNav } from '../../../component/mobile/PromotionTopNav';
import CTA from '../ctaBtn/CTA';
import CompanyInfoSlider from '../infoSlider/InfoSlider';
import ABTest from '../../../service/promotion/Promotion';
import TopBanner from '../../../component/banner/TopBanner';
import LandingBanner from './components/Landing-banner';
import ScrollToTopButton from '../../../component/common/ScrollToTop';
import { useNavigate } from 'react-router-dom';

const SignupAnnuity = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    sessionStorage.setItem('landingType', 'pension');
    const fetchData = async () => {
      try {
        const data = { type: 'X', title: '농지연금', action: 'N' };
        const result = await ABTest.ABLog(data);
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const actionUpdate = async () => {
    try {
      const data = { type: 'X', title: '농지연금', action: 'Y' };
      const result = await ABTest.ABLog(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    sessionStorage.setItem('landingType', 'pension');
    // sessionStorage.setItem('beforeUrl', document.referrer);
    sessionStorage.setItem('beforeUrl', window.location.href);
  }, []);
  return (
    <div className="wrap">
      <Helmet>
        <title>간편한 농지연금 분석 - 트랜스파머 </title>
        <meta
          name="description"
          content="트랜스파머는 빅데이터 기반으로 실거래가 95%이상 정확도의 농지가격 추정과 농지연금 분석이 가능합니다. 선택하는 농지연금 상품별 월 수령액을 확인하고 연금 시뮬레이션을 통해 투자수익을 분석한 보고서도 확인할 수 있습니다."
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="keyword"
          content="농지, 토지가격, 농지가격, 연금, 농지연금, 수령액, 연금계획, 연금조회"
        />
        <link
          rel="canonical"
          href="https://www.transfarmer.co.kr/promotion/농지_가격_확인부터_농지연금_계획까지_한번에"
        />
        {/* 오픈그래프 영역 */}
        <meta property="og:title" content="트랜스파머" />
        <meta
          property="og:description"
          content="농지가격부터 농지연금 수령액 확인까지 한번에"
        />
        <meta property="og:image" content="/asset/images/logo/og-img.png" />
        <meta
          property="og:url"
          content="https://transfarmer.co.kr/promotion/농지_가격_확인부터_농지연금_계획까지_한번에"
        />
      </Helmet>
      <div className="container">
        <ScrollToTopButton bottom={'130px'} mbottom={'90px'} isVisible={true} />
        {/* <TopBanner />
        <TopNavNewBrowser /> */}
        <PromotionTopNav open={open} setOpen={setOpen} />
        <div className="section section01">
          <div className="section01-title-box">
            <h1 className="menu-tag">농지연금 분석</h1>
            <div className="section-description">
              경제적 안정을 이루는 노후 소득 설계
            </div>
            <div className="section-title-box">
              <div className="section-title">
                <div className="text40">
                  농지 분석<span className="text40 text-light-grey">에서</span>{' '}
                  <br />
                  연금 계획<span className="text40 text-light-grey">
                    까지
                  </span>{' '}
                  <br />
                  한번에!
                </div>
              </div>
              <div className="highlight"></div>
            </div>
          </div>
          <div className="section01-content-box">
            <img
              className="section01-money-tree"
              src="/asset/images/promotion/moneyTree.svg"
              alt="나무트리"
            />
          </div>
        </div>
        <div className="section section02">
          <img
            src="/asset/images/promotion/worry-face.svg"
            alt="걱정하는 이모지"
          />
          <p className="text40 text-light-green">노후소득,</p>
          <p className="text40 text-grey">국민연금만으로</p>
          <p className="text40 text-light-green">충분하세요?</p>
        </div>
        <div className="section section03">
          <p className="text32 text-gradient">노후의 경제적 불안을</p>
          <img src="/asset/images/promotion/red-arrow.svg" alt="빨간 화살표" />
          <p className="text32">경제적 안전으로 만드는</p>
          <div>
            <div>
              <span className="text32 text-green">“든든한 연금 전략”</span>
              <span className="text32 text-semi-light">이</span>
            </div>
            <div className="text-with-img">
              <p className="text32 text-semi-light">필요합니다!</p>
              <img src="/asset/images/promotion/moneySack.svg" alt="돈주머니" />
            </div>
          </div>
        </div>
        <div className="section section04">
          <div className="section04-title-box">
            <div className="section04-titles">
              <div>
                <span className="text32 text-color-box">국가가 보증</span>
                <span className="text32 text-light">하는</span>
              </div>
              <div className="text32 text-light">안정적인 농지연금</div>
            </div>
            <div className="white-line"></div>
            <div className="text-with-img">
              <img src="/asset/images/promotion/directhit.svg" alt="과녁" />
              <span className="text27">
                매력포인트 <strong>5가지</strong>
              </span>
            </div>
          </div>
          <div className="section04-content-box">
            <div className="section04-card">
              <p className="card-text-number">1</p>
              <img src="/asset/images/promotion/money_illust.svg" alt="돈" />
              <div className="card-text">
                <div className="text27 text-27-heavy">
                  1인 월 <span>300</span>만 원,
                  <br />
                  부부 합산 <span>500</span>만 원까지
                </div>
                <div className="text20">
                  배우자 승계 가능
                  <br />
                  부부 모두 사망 시까지 종신지급
                </div>
              </div>
            </div>
            <div className="section04-card">
              <p className="card-text-number">2</p>
              <img src="/asset/images/promotion/leaf_illust.svg" alt="세일" />
              <div className="card-text">
                <div className="text27 text-27-heavy">
                  농사 수익은 <span>당연</span>,<br />
                  임대도 <span>가능</span>
                </div>
                <div className="text20">
                  농지연금 받으면서 직접 경작 또는
                  <br />
                  임대 주고 임대료 받기 가능
                </div>
              </div>
            </div>
            <div className="section04-card">
              <p className="card-text-number">3</p>
              <img src="/asset/images/promotion/sale_illust.svg" alt="세일" />
              <div className="card-text">
                <div className="text27 text-27-heavy">
                  똑똑하게 구입하고
                  <br />
                  <span>재산세 감면</span>까지
                </div>
                <div className="text20">
                  토지 경매로 반값 취득
                  <br /> + 재산세 6억까지 감면
                </div>
              </div>
            </div>
            <div className="section04-card">
              <p className="card-text-number">4</p>
              <img src="/asset/images/promotion/map_illust.svg" alt="지도" />
              <div className="card-text">
                <div className="text27 text-27-heavy">
                  경작할 수 있는 농지면
                  <br />
                  <span>모두 신청 가능</span>
                </div>
                <div className="text20">
                  안 팔려서 고민되는 농지도
                  <br />
                  농지연금 수령가능
                </div>
              </div>
            </div>
            <div className="section04-card">
              <p className="card-text-number">5</p>
              <img src="/asset/images/promotion/up_illust.svg" alt="업" />
              <div className="card-text">
                <div className="text27 text-27-heavy">
                  농지 가격은
                  <br />
                  <span>지속적으로 상승</span>
                </div>
                <div className="text20">
                  토지 공시지가 평균 연 5% 상승
                  <br />
                  개발 호재로 가격 급등 가능성까지
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section section05">
          <div className="section05-title-box">
            <div className="text32">
              농지 가격조회부터
              <br />월 수령액 확인까지
            </div>
            <div>
              <p className="text32 text-color-box">간편하게 농지연금</p>
              <p className="text32">계획하세요</p>
            </div>
            <div className="line-column"></div>
          </div>
          <div className="section05-content-box">
            <div className="section05-speech-bubble-box">
              <div className="section05-speech-bubble">
                <div className="text20">
                  국민연금만으로는
                  <br />
                  너무 적어요. 노후에 받게 될<br />
                  <span>연금액을 더 늘리고 싶어요.</span>
                </div>
                <img
                  className="color-bubble"
                  src="/asset/images/promotion/grey-bubble-big.svg"
                  alt="말풍선"
                />
              </div>
              <div className="people-img">
                <img
                  src="/asset/images/promotion/people_illust_01.svg"
                  alt="사람1"
                />
              </div>
            </div>
            <div className="section05-speech-bubble-box-right">
              <div className="section05-speech-bubble">
                <div className="text20">
                  적은 투자금으로
                  <br />
                  <span>실속 있는 연금을 받고 싶어요.</span>
                </div>
                <img
                  className="line-bubble"
                  src="/asset/images/promotion/line-bubble.svg"
                  alt="말풍선"
                />
              </div>
              <div className="people-img people-img-right">
                <img
                  src="/asset/images/promotion/people_illust_03.svg"
                  alt="사람3"
                />
              </div>
            </div>
            <div className="section05-speech-bubble-box">
              <div className="section05-speech-bubble">
                <div className="text20">
                  지금 당장은 아니더라도
                  <br />
                  언젠가 은퇴 후 살 <span>땅도 마련하고</span>
                  <br />
                  <span>연금도 계획하고 싶어요.</span>
                </div>
                <img
                  className="color-bubble"
                  src="/asset/images/promotion/grey-bubble-big.svg"
                  alt="말풍선"
                />
              </div>
              <div className="people-img">
                <img
                  src="/asset/images/promotion/people_illust_02.svg"
                  alt="사람1"
                />
              </div>
            </div>
            <div className="section05-speech-bubble-box-right">
              <div className="section05-speech-bubble">
                <div className="text20">
                  취미 삼아 텃밭을
                  <br />
                  가꾸고 싶은데,<span>농지연금도</span>
                  <br />
                  <span>받을 수 있다면 좋겠어요.</span>
                </div>
                <img
                  className="line-bubble"
                  src="/asset/images/promotion/line-bubble-big.svg"
                  alt="말풍선"
                />
              </div>
              <div className="people-img people-img-right">
                <img
                  src="/asset/images/promotion/people_illust_04.svg"
                  alt="사람3"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="section section06">
          <div className="section06-title-box">
            <div className="section06-title-1">
              <p className="text27 text27-green">가장 쉬운</p>
              <p className="text27 text-round-box">농지테크</p>
            </div>
            <div className="text27">트랜스파머에서</div>
            <div className="flex-column flex-center relative">
              <div className="text27">쉽고편리하게</div>
              <div className="text27">농지연금 확인하세요!</div>
              <div
                className="flex-row  absolute"
                style={{ bottom: '75px', left: '112px', gap: '16px' }}
              >
                {[1, 2, 3, 4].map(() => {
                  return (
                    <div
                      className="border-radius-999"
                      style={{
                        width: '9px',
                        height: '9px',
                        backgroundColor: '#1E9B6B',
                      }}
                    >
                      &nbsp;
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="section06-content-box">
            <div className="section06-content-01">
              <div className="section06-text-block">
                <div className="section06-block-box-left text17">
                  복잡하고 <br />
                  어려웠던 <br />
                  농지연금
                  <br />
                  준비하기
                </div>
                <div className="section06-block-box-middle">
                  <p className="text20">쉽게</p>
                  <img src="/asset/images/promotion/play.svg" alt="플레이" />
                </div>
                <div className="section06-block-box-right text17">
                  내 농지 OK <br />
                  부모님 농지 OK <br />
                  이웃 집 농지 OK
                  <br />
                  사고 싶은 농지 OK
                </div>
              </div>
              <div className="section06-text-block">
                <div className="section06-block-box-left text17">
                  <div className="block-box-text">
                    <img src="/asset/images/promotion/check.svg" alt="체크" />
                    <p>가입 조건 확인</p>
                  </div>
                  <div className="block-box-text">
                    <img src="/asset/images/promotion/check.svg" alt="체크" />
                    <p>농지가격 찾기</p>
                  </div>
                  <div className="block-box-text">
                    <img src="/asset/images/promotion/check.svg" alt="체크" />
                    <p>연금 상품 찾기</p>
                  </div>
                </div>
                <div className="section06-block-box-middle">
                  <p className="text20">한번에</p>
                  <img src="/asset/images/promotion/play.svg" alt="플레이" />
                </div>
                <div className="section06-block-box-right text17">
                  <div>
                    연금 설정부터
                    <br />
                    연금 수익률
                    <br />
                    분석까지
                  </div>
                  <div className="white-box">
                    한 번에 <br />
                    모두 가능
                  </div>
                </div>
              </div>
            </div>
            <div className="section06-content-02">
              <p className="text27 text-27-extra-bold">
                전국 농지 정보와 연금 조회
              </p>
              <p className="text20 text-20-extra-bold">모든 것이 가능한 곳은</p>
              <img src="/asset/images/promotion/onlyTF.svg" alt="only" />
            </div>
            <div className="section06-content-03">
              <div className="section06-text-block">
                <div className="section06-block-box-left text17 box-with-img">
                  <div>
                    수령액은? <br />
                    수익률은?
                  </div>
                </div>
                <div className="section06-block-box-middle">
                  <p className="text20">정확하게</p>
                  <img src="/asset/images/promotion/play.svg" alt="플레이" />
                </div>
                <div className="section06-block-box-right text17">
                  <div>
                    특허출원
                    <br />
                    시스템으로
                  </div>
                  <div className="white-box">95% 정확도</div>
                </div>
              </div>
            </div>
            <div className="section06-content-04 text20">
              <p>
                머신러닝 기반
                <br />
                <span>농지 시세 예측 시스템</span>과<br />
                Z모형 분석
                <br />
                <span>트랜스파머 추정가 시스템</span>
              </p>
            </div>
          </div>
        </div>
        <div className="section section07">
          <div className="section07-title-box">
            <p className="text27 text27-white">
              실제 농지
              <br />
              농지연금 예상 수령액
            </p>
            <img src="/asset/images/promotion/grey-arrow.svg" alt="arrow" />
          </div>
          <div className="section07-content-box">
            <div className="section07-content-01 text14">
              *연금 조회 대상 : 64년생
            </div>
            <div className="section07-content-02">
              <img
                src="/asset/images/promotion/annuity-sample-1.svg"
                alt="연금분석1"
              />
              <img
                src="/asset/images/promotion/annuity-sample-2.svg"
                alt="연금분석2"
              />
              <img
                src="/asset/images/promotion/annuity-sample-3.svg"
                alt="연금분석3"
              />
            </div>
          </div>
        </div>
        <div className="section section08">
          <div className="section08-title-box">
            <img src="/asset/images/promotion/Lightbulb.svg" alt="전구" />
            <p className="text27">이렇게 이용하세요</p>
            <p className="text20">
              <span>쉽게</span> 농지 찾고 <br /> <span>빠르게</span> 연금 수령액
              확인하세요 <br />
              연금 분석 보고서까지 보실 수 있어요
            </p>
          </div>
          <div className="section08-content-box">
            <div>
              <div className="usage-step-title text-20-extra-bold  green">
                {' '}
                1 <br />
                농지찾기
              </div>
              <div className="usage-step-content">
                <img
                  className="usage-gif"
                  src="/asset/images/promotion/usage-01.gif"
                  alt="이용가이드1"
                />
                <div className="usage-text-box text16">
                  <div className="usage-text">
                    <img src="/asset/images/promotion/number-1.svg" alt="1" />
                    <p>
                      트랜스파머 메인 홈 화면에서 농지연금 분석 메뉴를
                      선택합니다.
                    </p>
                  </div>
                  <div className="usage-text">
                    <img src="/asset/images/promotion/number-2.svg" alt="2" />
                    <p>
                      내 농지, 경공매 농지 중 연금 분석을 <br />
                      원하는 항목을 선택합니다.
                    </p>
                  </div>
                  <div className="usage-text">
                    <img src="/asset/images/promotion/number-3.svg" alt="3" />
                    <p>
                      농지의 주소를 입력하거나 지도를 움직여 원하는 장소로
                      이동합니다.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="usage-step-title text20">
                {' '}
                2 <br />
                농지연금 가입 확인하기
              </div>
              <div className="usage-step-content">
                <img
                  className="usage-gif"
                  src="/asset/images/promotion/usage-02.gif"
                  alt="이용가이드2"
                />
                <div className="usage-text-box text16">
                  <div className="usage-text">
                    <img src="/asset/images/promotion/number-1.svg" alt="1" />
                    <p>
                      선택한 농지의 추정가, 용도, 면적을 볼 수 <br />
                      있습니다.
                    </p>
                  </div>
                  <div className="usage-text">
                    <img src="/asset/images/promotion/number-2.svg" alt="2" />
                    <p>
                      농지연금 가입 가능성 확인하기 버튼을 <br />
                      눌러주세요.
                    </p>
                  </div>
                  <div className="usage-text">
                    <img src="/asset/images/promotion/number-3.svg" alt="3" />
                    <p>가입조건을 확인합니다.</p>
                  </div>
                  <div className="usage-text">
                    <img src="/asset/images/promotion/number-4.svg" alt="4" />
                    <p>
                      농지연금 가입 가능성 확인하기 버튼을
                      <br /> 눌러주세요.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="usage-step-title text20">
                {' '}
                3 <br />
                농지연금 분석보고서 확인
              </div>
              <div className="usage-step-content">
                <img
                  className="usage-gif"
                  src="/asset/images/promotion/usage-03.gif"
                  alt="이용가이드3"
                />
                <div className="usage-text-box text16">
                  <div className="usage-text">
                    <img src="/asset/images/promotion/number-1.svg" alt="1" />
                    <p>
                      농지연금 가입가능성을 확인하고 농지연금 예상 수령액
                      확인하기 버튼을 눌러주세요.
                    </p>
                  </div>
                  <div className="usage-text">
                    <img src="/asset/images/promotion/number-2.svg" alt="2" />
                    <p>
                      농지연금 계산기로 각 상품별 월 수령액을 볼 수 있습니다.
                    </p>
                  </div>
                  <div className="usage-text">
                    <img src="/asset/images/promotion/number-3.svg" alt="3" />
                    <p>
                      연금 시뮬레이션으로 연금 수익과 <br />
                      투자 수익률을 확인할 수 있습니다.
                    </p>
                  </div>
                  <div className="usage-text">
                    <img src="/asset/images/promotion/number-4.svg" alt="4" />
                    <p>
                      10년간 데이터를 기반으로 실익 분석결과와 투자 가치를
                      한눈에 확인 할 수 있습니다.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="w-100per flex-center padding-12 pointer"
            style={{ backgroundColor: '#171E26' }}
            onClick={() => {
              navigate(
                '/promotion/%ED%8A%B8%EB%9E%9C%EC%8A%A4%ED%8C%8C%EB%A8%B8-%EB%A7%9E%EC%B6%A4-%ED%86%A0%EC%A7%80-%EC%BB%A8%EC%84%A4%ED%8C%85?utm_source=TF&utm_medium=banner&utm_campaign=consulting_banner&utm_id=promotion&utm_content=consulting_banner',
              );
            }}
          >
            <div
              className="flex-row space-between w-100per"
              style={{ maxWidth: '1200px' }}
            >
              <div className="flex-row gap-8 w-100per center-y">
                <div
                  className="bg-primary-400 border-radius-2"
                  style={{ padding: '2px 6px' }}
                >
                  <div className="m-b2-14-b color-dark-primary">
                    맞춤 컨설팅
                  </div>
                </div>
                <div className="m-b1-16-b color-FFFFFF">
                  토지 매매, 이제 <span style={{ color: '#09CE7D' }}>안심</span>
                  하세요
                </div>
              </div>
              <img
                className="w-24"
                src="/asset/images/arrow/ArrowRightWhite20.svg"
              />
            </div>
          </div>
          <LandingBanner />
        </div>
        <div className="section section09">
          <div className="section09-title-box">
            <img src="/asset/images/promotion/Logo_Symbol.svg" alt="로고" />
            <p className="text32">트랜스파머는</p>
            <p className="text20">
              농지 검색에서 수익창출까지 <br />
              농지 위의 모든 것을 데이터화하여 <br />
              가치를 만들어 갑니다
            </p>
          </div>
          <div className="section09-content-box">
            <CompanyInfoSlider />
          </div>
        </div>
        <Banner />
        <FooterBrowser />
      </div>
      <CTA
        text1="월 50만? 130만?"
        text3="내 노후연금 얼마나 될까?"
        btnText="농지연금 조회하기"
        actionUpdate={actionUpdate}
      />
    </div>
  );
};
export default SignupAnnuity;
