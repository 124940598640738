import { useEffect, useState } from 'react';
import { ButtonSelectorBox } from '../../../../../component/report/Button';
import {
  RowSectionTitle,
  RowTableValueBold,
  RowTableGreen,
  RowTable1,
} from '../../../../../component/report/Row';
import { TextSectionTitle } from '../../../../../component/report/Text';
import { LineChart, Tooltip, XAxis, YAxis, Line } from 'recharts';
import ReportService from '../../../../../service/report/reportApi';
import useDidMountEffect from '../../../../../hooks/UseDidMountEffect';
import RowCom from '../../../../../component/report/Row';
import {
  BoxDateSelector2,
  BoxGreen1,
} from '../../../../../component/report/Box';
import TempCom from '../../../../../component/report/Temp';

interface data {
  accumalatedTempList?: any;
  avgYearWeather: any[];
  cropList: {
    recommendCrop: any[];
    anotherCrop: any[];
  };
  curYearWeather: any[];
  soilResult: any;
}

const ReportDetail2 = ({
  data,
  setType,
  type,
  facility,
  setFacility,
  rIdx,
  reportSales,
  mypage,
  setcurFarmDate,
  setPrevFarmDate,
  setPrevHarvestDate,
  curFarmDate,
  prevFarmDate,
  prevHarvestDate,
  reportAiFarm,
}: {
  data: data;
  setType: any;
  type: any;
  facility: any;
  setFacility: any;
  rIdx: any;
  reportSales: any;
  mypage?: any;
  setcurFarmDate?: any;
  setPrevFarmDate?: any;
  setPrevHarvestDate?: any;
  curFarmDate?: string;
  prevFarmDate?: string;
  prevHarvestDate?: string;
  reportAiFarm?: any;
}) => {
  const [tab, setTab] = useState(3);
  const [onToolTip, setOnToolTip] = useState(false);
  const [highRate, setHighRate] = useState(['']);
  console.log('data.cropList.recommendCrop', data.cropList.recommendCrop);
  let date = new Date();
  const [onDateSelector, setOnDateSelector]: any = useState({
    on: false,
    date: '',
    setDate: '',
    name: '',
  });
  var regex = /[^0-9]/g;

  const soilData = {
    논적성등급: 6 - Number(data.soilResult.rfldGrd.replace(regex, '')),
    밭적성등급: 6 - Number(data.soilResult.pfldGrd.replace(regex, '')),
    과수상전적성등급: 6 - Number(data.soilResult.fruitGrd.replace(regex, '')),
    초지적성등급: 6 - Number(data.soilResult.pastureGrd.replace(regex, '')),
    임지적성등급: 6 - Number(data.soilResult.frstGrd.replace(regex, '')),
  };

  useEffect(() => {
    let sArr: any = [];
    let arr: any = [];
    let max = 0;
    let isThree = 0;
    for (let i = 0; i < Object.keys(soilData).length; i++) {
      console.log(Object.values(soilData)[i]);
      if (Object.values(soilData)[i] > max) {
        max = Object.values(soilData)[i] || max;
        arr = ['N', Object.keys(soilData)[i].split('적성등급')[0]];
      } else if (Object.values(soilData)[i] === max) {
        arr.push(Object.keys(soilData)[i].split('적성등급')[0]);
      }
      if (Object.values(soilData)[i] >= 3) {
        isThree++;
        sArr.push(Object.keys(soilData)[i].split('적성등급')[0]);
      }
    }
    console.log(arr);
    if (isThree > 0) {
      setHighRate(sArr);
    } else {
      setHighRate(arr);
    }
  }, [data.soilResult]);

  const btnArr = [
    { children: 'AI 추천 작물', selected: 3 },
    '',
    { children: '토양 분석', selected: 2 },
    '',
    { children: '농업기상 분석', selected: 1 },
  ];

  const [isSet, setIsSet] = useState(false);

  // useDidMountEffect(() => {
  //   if (isSet) {
  //     farmDate();
  //     setIsSet(false);
  //   }
  // }, [curFarmDate, prevFarmDate, prevHarvestDate, isSet]);

  return (
    <div className="padding-b-24">
      <RowSectionTitle>
        <TextSectionTitle>2. 적합작물</TextSectionTitle>
      </RowSectionTitle>

      <ButtonSelectorBox tab={tab} setTab={setTab} btnArr={btnArr} />

      {tab === 1 ? (
        <div className="flex-column ">
          <TempCom.AvgTempOfMonth date={date} data={data} />
          <TempCom.AvgMaxTempOfMonth date={date} data={data} />
          <div className="padding-16-16-0 gap-2 flex-row">
            <div className="font-bold f-size-16 line-h-24 color-text-secondary">
              적산온도
            </div>
            <img
              src="/asset/images/warn/Warn24.svg"
              className="hover"
              alt=""
              onClick={() => setOnToolTip(true)}
            />
            <div className="relative center-x">
              {onToolTip && (
                <div
                  className="flex-row bg-blue-gray-700 border-radius-8 padding-12-16 tool-tip start-y center-x w-218 gap-10 z-10 absolute"
                  style={{
                    top: `${0}px`,
                    left: '0px',
                  }}
                >
                  <div className="flex-column gap-8">
                    <div className="font-medium f-size-11 line-h-12 color-EFF0F6">
                      <span className="font-bold">적산온도 :</span> <br />
                      작물의 생육에 필요한 열량을 나타내기 위한 것으로 생육
                      일수의 일평균 기온을 적산한 것을 말함.
                    </div>
                    <div className="font-medium f-size-11 line-h-12 color-EFF0F6">
                      <span className="font-bold">• 저온성 작물 :</span> 가을
                      채소, 상추, 배추, 시금치 등
                    </div>
                    <div className="font-medium f-size-11 line-h-12 color-EFF0F6">
                      <span className="font-bold">• 온대성 작물 :</span> 벼 등
                    </div>
                    <div className="font-medium f-size-11 line-h-12 color-EFF0F6">
                      <span className="font-bold">• 고온성 작물 :</span> 토마토,
                      고추, 수박, 옥수수 등
                    </div>
                  </div>
                  <img
                    alt=""
                    src="/asset/images/close/CloseWhite12.svg"
                    onClick={() => setOnToolTip(false)}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="padding-16-16-8">
            <div className="font-bold f-size-16 line-h-24 color-text-secondary">
              생육 온도 선택
            </div>
          </div>
          <div className="flex-row padding-8-16">
            {/* // type = 저온성:L, 온대성: N, 고온성: H
      // facility = 노지 | 시설 | 스마트팜 */}
            {[
              { title: '저온성', value: 'L' },
              { title: '온대성', value: 'N' },
              { title: '고온성', value: 'H' },
            ].map((el) => (
              <div
                className="flex-row gap-6 w-100per center-y hover"
                onClick={() => setType(el.value)}
              >
                <img
                  style={{ width: '24px' }}
                  src={
                    type === el.value
                      ? '/asset/images/check/Check24Green.svg'
                      : '/asset/images/check/Check24Gray.svg'
                  }
                  alt=""
                />
                <div
                  className="font-medium f-size-16 line-h-26"
                  style={{
                    color: type === el.value ? '#1E9B6B' : '#171E2666',
                  }}
                >
                  {el.title}
                </div>
              </div>
            ))}
          </div>
          <div className="padding-16-16-8 flex-row space-between center-y">
            <div className="font-bold f-size-16 line-h-24 color-text-secondary">
              농사 시작일 선택
            </div>
            <div
              className="flex-row padding-6-8 bg-light-gray-200 border-radius-8 gap-8 hover"
              onClick={() =>
                setOnDateSelector({
                  date: curFarmDate,
                  setDate: setcurFarmDate,
                  on: true,
                  name: 'curFarmDate',
                })
              }
            >
              <img src="/asset/images/calender/Calender.svg" alt="" />
              <div className="font-medium f-size-14 line-h-24 color-text-secondary">
                {curFarmDate}
              </div>
            </div>
            {onDateSelector.on ? (
              <BoxDateSelector2
                close={() =>
                  setOnDateSelector((prev: any) => {
                    return { ...prev, on: false, name: '' };
                  })
                }
                onDate
                date={onDateSelector.date}
                setDate={onDateSelector.setDate}
                name={onDateSelector.name}
                setIsSet={setIsSet}
                curFarmDate={curFarmDate}
                prevFarmDate={prevFarmDate}
                prevHarvestDate={prevHarvestDate}
                setcurFarmDate={setcurFarmDate}
                setPrevFarmDate={setPrevFarmDate}
                setPrevHarvestDate={setPrevHarvestDate}
                reportAiFarm={reportAiFarm}
              />
            ) : (
              ''
            )}
          </div>

          <div className="padding-16-16-8 flex-row space-between center-y">
            <div className="font-bold f-size-16 line-h-24 color-text-secondary">
              이전 농사 수확일 선택
            </div>
            <div
              className="flex-row padding-6-8 bg-light-gray-200 border-radius-8 gap-8 hover"
              onClick={() =>
                setOnDateSelector({
                  date: prevHarvestDate,
                  setDate: setPrevHarvestDate,
                  on: true,
                  name: 'prevHarvestDate',
                })
              }
            >
              <img src="/asset/images/calender/Calender.svg" alt="" />
              <div className="font-medium f-size-14 line-h-24 color-text-secondary">
                {prevHarvestDate}
              </div>
            </div>
          </div>
          <div className="padding-16-16-8 flex-row space-between center-y">
            <div className="font-bold f-size-16 line-h-24 color-text-secondary">
              이전 농사 시작일 선택
            </div>
            <div
              className="flex-row padding-6-8 bg-light-gray-200 border-radius-8 gap-8 hover"
              onClick={() =>
                setOnDateSelector({
                  date: prevFarmDate,
                  setDate: setPrevFarmDate,
                  on: true,
                  name: 'prevFarmDate',
                })
              }
            >
              <img src="/asset/images/calender/Calender.svg" alt="" />
              <div className="font-medium f-size-14 line-h-24 color-text-secondary">
                {prevFarmDate}
              </div>
            </div>
          </div>
          {data.accumalatedTempList && data.accumalatedTempList.curList ? (
            <>
              <div className="padding-16-16-0">
                <RowTableGreen
                  title="내 작물의 적산온도"
                  value={
                    data.accumalatedTempList &&
                    data.accumalatedTempList.curList &&
                    data.accumalatedTempList.curList.length !== 0
                      ? `${
                          Math.round(
                            data.accumalatedTempList.curList[
                              data.accumalatedTempList.curList.length - 1
                            ]?.value * 10,
                          ) / 10
                        } ℃`
                      : ''
                  }
                />
              </div>
              <div className="flex-column of-x-auto">
                <LineChart
                  className="flex-medium f-size-11 center-x"
                  width={360 - 16}
                  height={181}
                  data={
                    data.accumalatedTempList &&
                    data.accumalatedTempList.curList &&
                    data.accumalatedTempList.curList.map(
                      (el: any, i: number) => {
                        return {
                          day: `${el.date} (${i + 1}일차)`,
                          day2: data.accumalatedTempList.prevList[i]
                            ? Math.round(
                                data.accumalatedTempList.prevList[i].value * 10,
                              ) / 10
                            : undefined,
                          value: Math.round(el.value * 10) / 10,
                        };
                      },
                    )
                  }
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <Line
                    type="monotone"
                    dataKey="value"
                    name="적산온도"
                    stroke="#09CE7D"
                  />
                  <Line
                    type="monotone"
                    dataKey="day2"
                    name="적산온도 (이전 동일회차)"
                    stroke="#869CB6"
                  />

                  <XAxis dataKey="day" />
                  <YAxis />
                  <Tooltip />
                </LineChart>
              </div>
              <div className="padding-8-16 gap-10">
                <BoxGreen1>
                  <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                    현재 내 작물의 적산온도는{' '}
                    {data.accumalatedTempList &&
                    data.accumalatedTempList.curList &&
                    data.accumalatedTempList.curList.length !== 0
                      ? `${
                          Math.round(
                            data.accumalatedTempList.curList[
                              data.accumalatedTempList.curList.length - 1
                            ]?.value * 10,
                          ) / 10
                        } ℃`
                      : ''}
                  </div>
                  <div className="font-bold f-size-16 line-h-24 color-primary-500">
                    이전{' '}
                    {data.accumalatedTempList &&
                      data.accumalatedTempList.curList &&
                      data.accumalatedTempList.prevList &&
                      Math.round(
                        (data.accumalatedTempList.prevList[
                          data.accumalatedTempList.curList.length - 1
                        ]
                          ? data.accumalatedTempList.prevList[
                              data.accumalatedTempList.curList.length - 1
                            ].value
                          : data.accumalatedTempList.prevList[
                              data.accumalatedTempList.prevList.length - 1
                            ].value) * 10,
                      ) / 10}
                    ℃
                    {(() => {
                      let curTemp =
                        data.accumalatedTempList &&
                        data.accumalatedTempList.curList &&
                        data.accumalatedTempList.curList.length !== 0 &&
                        Math.round(
                          data.accumalatedTempList.curList[
                            data.accumalatedTempList.curList.length - 1
                          ].value * 10,
                        ) / 10;

                      let prevTemp =
                        data.accumalatedTempList &&
                        data.accumalatedTempList.prevList &&
                        Math.round(
                          (data.accumalatedTempList.prevList[
                            data.accumalatedTempList.curList.length - 1
                          ]
                            ? data.accumalatedTempList.prevList[
                                data.accumalatedTempList.curList.length - 1
                              ].value
                            : data.accumalatedTempList.prevList[
                                data.accumalatedTempList.prevList.length - 1
                              ].value) * 10,
                        ) / 10;

                      if (prevTemp < curTemp) {
                        return `에 비해 ${
                          Math.round((curTemp - prevTemp) * 10) / 10
                        }℃ 높습니다.`;
                      } else if (prevTemp > curTemp) {
                        return `에 비해 ${
                          Math.round((prevTemp - curTemp) * 10) / 10
                        }℃ 낮습니다.`;
                      } else {
                        return '와 같습니다.';
                      }
                    })()}
                  </div>
                </BoxGreen1>
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      ) : tab === 2 ? (
        <div className="flex-column">
          <div className="padding-24-16 gap-16 flex-column">
            <div className="flex-row gap-24">
              <RowTable1 title="경사" value={data.soilResult.soilslope} />
              <RowTable1 title="배수등급" value={data.soilResult.soildra} />
            </div>
            <div className="flex-row gap-24">
              <RowTable1 title="토양추천" value={data.soilResult.soilUseRec} />
              <RowTable1 title="침식등급" value={data.soilResult.erosion} />
            </div>
            <div className="flex-row gap-24">
              <RowTable1 title="토양유형" value={data.soilResult.soilType} />
            </div>
          </div>
          <div className="flex-column w-100per gap-16 padding-24-16">
            {[
              { title: '논적성등급', ratio: soilData.논적성등급 },
              { title: '밭적성등급', ratio: soilData.밭적성등급 },
              { title: '과수상전적성등급', ratio: soilData.과수상전적성등급 },
              { title: '초지적성등급', ratio: soilData.초지적성등급 },
              { title: '임지적성등급', ratio: soilData.임지적성등급 },
            ].map((el) => (
              <div className="flex-row w-100per space-between">
                <div className="font-medium f-size-16 line-h-24 color-text-secondary">
                  {el.title}
                </div>
                <RowCom.StarRow ratings={el.ratio} />
              </div>
            ))}
          </div>
          <div className="padding-8-16 gap-10">
            <BoxGreen1>
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                이 농지는
              </div>
              <div className="font-bold f-size-16 line-h-24 color-primary-500">
                {highRate[0] === 'N'
                  ? highRate.slice(1).join(',') +
                    '농사에 상대적으로 적합한 토양입니다'
                  : highRate.join(',') + ' 농사에 적합한 토양입니다.'}
              </div>
            </BoxGreen1>
          </div>
        </div>
      ) : tab === 3 ? (
        <div className="flex-column">
          <div className="padding-16-16-0">
            <div className="font-bold f-size-16 line-h-24 color-text-secondary">
              재배 환경 선택
            </div>
          </div>
          <div className="padding-16-16-8 flex-row">
            {[
              { title: '노지', value: '노지' },
              { title: '비닐하우스', value: '시설' },
              { title: '스마트팜', value: '스마트팜' },
            ].map((el) => (
              <div
                className="flex-row gap-6 w-100per center-y hover"
                onClick={() => setFacility(el.value)}
              >
                <img
                  style={{ width: '24px' }}
                  src={
                    facility === el.value
                      ? '/asset/images/check/Check24Green.svg'
                      : '/asset/images/check/Check24Gray.svg'
                  }
                  alt=""
                />
                <div
                  className="font-medium f-size-16 line-h-26"
                  style={{
                    color: facility === el.value ? '#1E9B6B' : '#171E2666',
                  }}
                >
                  {el.title}
                </div>
              </div>
            ))}
          </div>
          <div className="padding-16-16-0">
            <div className="font-bold f-size-16 line-h-24 color-text-secondary">
              재배 적합 작물
            </div>
          </div>
          <div className="flex-row padding-16 gap-8 of-x-auto">
            {data.cropList.recommendCrop.map((el: any) => (
              <div
                className="flex-column border-radius-8 padding-12"
                style={{
                  width: '90px',
                  height: '120px',
                  border: true ? '2px solid #1e9b6b' : '1px solid #171E261A',
                }}
              >
                <img
                  src={`https://back.transfarmer.kr/img/${
                    el.img || 'houseDesign/rural/농림-10-12-가-2/image1.jpg'
                  }`}
                  style={{ width: '66px', height: '50px' }}
                  alt=""
                />
                <div
                  className="font-bold f-size-14 line-h-20 color-text-secondary text-center center-y center-x"
                  style={{ height: '40px' }}
                >
                  {el.name}
                </div>
              </div>
            ))}
          </div>
          <div className="padding-8-16 gap-10">
            <BoxGreen1>
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                토양·기상 빅데이터 분석에 기반한{' '}
              </div>
              <div className="font-bold f-size-16 line-h-24 color-primary-500">
                AI 추천 작물은{' '}
                {data.cropList.recommendCrop
                  .map((el: any, i) => {
                    if (i > 2) {
                      return;
                    } else {
                      return el.name;
                    }
                  })
                  .slice(0, 2)
                  .join(', ')}{' '}
                순입니다.
              </div>
            </BoxGreen1>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ReportDetail2;
