import './Mobile.css';
const Button = ({ children, description, onClick, on, menu, loading }: any) => {
  return (
    <div
      className={`${
        menu === 'idfind' ? (on ? `hover` : ``) : `hover`
      } w-100per padding-14-16 center-x center-y gap-10 flex-row border-radius-8 ${
        !on ? 'bg-light-gray-200' : 'bg-primary-500'
      }`}
      onClick={onClick}
    >
      {children}
      {loading ? (
        <img
          src="/asset/images/icon/loading-white.svg"
          alt="Loading"
          className="loading-icon"
        />
      ) : (
        description && (
          <div
            className={`font-bold f-size-18 line-h-26 ${
              on ? ` color-FFFFFF` : `color-disabled`
            }`}
          >
            {description}
          </div>
        )
      )}
    </div>
  );
};

export default Button;

export const Button2 = ({
  children,
  description,
  onClick,
  on,
  normal,
}: any) => {
  return (
    <div
      className={`hover w-100per padding-14-16 center-x center-y gap-10 flex-row bg-FFFFFF `}
      style={{
        border: !on ? '1px solid #171e2610' : '1px solid #1e9b6b',
        borderRadius: normal ? '8px' : '100px',
      }}
      onClick={onClick}
    >
      {children}
      {description && (
        <div
          className="font-bold f-size-18 line-h-26 "
          style={{ color: !on ? '#171e2640' : '#1e9b6b' }}
        >
          {description}
        </div>
      )}
    </div>
  );
};
