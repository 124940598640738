import CompanyInfoSlider from '../../infoSlider/InfoSlider';

export const Article = () => {
  return (
    <div
      className="flex-column w-100per"
      style={{ padding: '80px 16px', overflowX: 'hidden' }}
    >
      <div className="flex-column gap-24 flex-center">
        <img
          style={{ width: '55px', height: '55px' }}
          src="/asset/images/logo/Logo24.svg"
        />
        <div className="flex-column">
          <div className="m-b1-16-m color-dark-secondary text-center">
            No.1 토지테크 컨설팅
          </div>
          <div
            style={{
              fontFamily: 'NanumSquareNeo-Bold',
              fontWeight: '800',
              fontSize: '32px',
              lineHeight: '45px',
              color: '#0B0B0B',
            }}
          >
            트랜스파머
          </div>
        </div>
        <div className="m-t2-18-m text-center" style={{ color: '#565656' }}>
          토지·농지 위 들어 선 모든 것을 데이터화 해, <br />
          가치를 만들어 냅니다.
        </div>
        <CompanyInfoSlider />
      </div>
    </div>
  );
};
