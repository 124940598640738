import Request from '../../@api/request';

const baseURL = `${process.env.REACT_APP_HOST}`;
export const request = new Request(baseURL);

interface IReportPatch extends IAnalyzeData {
  reportIdx: number;
  facility: 'O' | 'P' | 'S' | 'W'; //시설 ex) 노지: O, 비닐하우스: P, 스마트팜: S, 기후맞춤스마트팜: W
  cropName: string; //작물이름(노지포도)
}
interface IAnalyzeData {
  crops: number; //기작
  landCostType: string; //매입: "P", 임대: "R"
  quantity: number; //생산량
  unitPrice: number; //단가
  landPurchaseCost: number;
  avgLandPurchaseCost: number;
  landRentalCost: number;
  avgLandRentalCost: number;
  facilityCost: number; //시설비
  avgFacilityCost: number;
  farmMachineryCost: number; //농기계 구입비
  avgFarmMachineryCost: number;
  ownLaborYN: string; //자가 노동비 포함: "Y", 제외: "N"
  laborCost: number;
  avgEmpLaborCost: number;
  avgOwnLaborCost: number;
  materialCost: number;
  avgMaterialCost: number;
  orchardCost: number;
  avgOrchardCost: number;
  waterHeatingCost: number;
  avgWaterHeatingCost: number;
  farmEquipmentCost: number;
  avgFarmEquipmentCost: number;
  SGA: number;
  avgSGA: number;
  loanInfo?: Array<{
    loanName: string;
    loanDate: string;
    loanRate: string;
    loanCost: string;
    retentionPeriod: string;
    redemptionPeriod: string;
  }>; // 대출정보
}

export default class ReportService {
  static list = async (limit: number, offset: number, type: string) => {
    const result = await request.get(
      `land/report/list?limit=${limit}&offset=${offset}&type=${type}`,
    );

    return result.data;
  };
  static list2 = async (limit: number, offset: number, type: string) => {
    const result = await request.get(
      `land/report/list?limit=${limit}&offset=${offset}&type=${type}`,
    );

    return result.data;
  };

  static view = async (reportIdx: string | number | undefined) => {
    const result = await request.get(`land/report?reportIdx=${reportIdx}`);
    return result.data;
  };

  static patch = async (data: IReportPatch | any) => {
    const result = await request.patch('land/report', data);
    return result.data;
  };
  static patchTw = async (data: any) => {
    const result = await request.post('land/report', data);
    return result.data;
  };
  static calculate = async (data: IAnalyzeData) => {
    const result = await request.post('land/report/calculation', data);
    return result.data;
  };

  static houseList = async (limit: number, offset: number) => {
    const result = await request.get(
      `land/report/list/house?limit=${limit}&offset=${offset}`,
    );
    return result.data;
  };

  static report = async (reportIdx: string | undefined) => {
    const result = await request.get(`report?reportIdx=${reportIdx}`);
    return result.data;
  };

  static reportFarm = async (reportIdx: string | undefined) => {
    const result = await request.get(`/report/farm/${reportIdx}`);
    return result.data.result;
  };

  static cropAnalysis = async (
    reportIdx: string | undefined,
    facility: string,
    type: string,
  ) => {
    const result = await request.get(
      `report/cropAnalysis?reportIdx=${reportIdx}&facility=${facility}&type=${type}`,
    );
    return result.data;
  };
  static search = async (data: any) => {
    const result = await request.post('/report/search', data);
    return result.data;
  };

  static patchLoan = async (
    reportIdx: any,
    loanType: string,
    loanCost: number,
    loanRate: number,
    totalPeriod: number,
    retentionPeriod: number,
    saveYN: string,
  ) => {
    const data = {
      reportIdx,
      loanType,
      loanCost,
      loanRate,
      totalPeriod,
      retentionPeriod,
      saveYN,
    };
    const result = await request.patch('/report/search', data);
    return result.data;
  };

  static farmDate = async (
    farmStartDate: any,
    prevFarmStartDate: any,
    prevFarmEndDate: any,
    reportIdx: any,
  ) => {
    const payload = {
      farmStartDate,
      prevFarmStartDate,
      prevFarmEndDate,
      reportIdx,
    };
    await request.post('/report/farmDate', payload);
    return;
  };
  static priceReport = async (data: any) => {
    const result = await request.post('/price/report', data);
    return result.data;
  };
  static reportAiFarm = async (data: any) => {
    const result = await request.patch('/report/farm', data);
    return result.data;
  };
  static aifarmReportNumber = async (data: any) => {
    const result = await request.post('/report', data);
    return result.data;
  };
  static check = async (type: string, address: string) => {
    const result = await request.get(
      `/report/check?type=${type}&address=${address}`,
    );
    return result.data;
  };

  static getReport = async (reportIdx: number) => {
    const result = await request.get(`report/${reportIdx}`);
    return result.data;
  };
}
