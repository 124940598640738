import { useEffect, useState } from 'react';
import { RowSectionTitle, RowBold2 } from '../../../../component/report/Row';
import { TextSectionTitle } from '../../../../component/report/Text';
import { isMobile, transNum } from '../../../../component/function/function';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Tooltip2 } from '../../../../component/common/tooltip';
import DoughnutChart2 from '../../../../component/mobile/DoughnutChart2';
import UserService from '../../../../service/checkAccount/CheckAccount';
import React from 'react';
import { Slider } from '@mui/material';
import arrowTop from './../../../../asset/images/arrow/arrowTopGray.svg';
import arrowBot from './../../../../asset/images/arrow/arrowBotGray.svg';
import ReportService from '../../../../service/report/reportApi';
import useDidMountEffect from '../../../../hooks/UseDidMountEffect';
import { ButtonSelectorBox } from '../../../../component/report/Button';
import { BoxGreen1, BoxTable2 } from '../../../../component/report/Box';
import SignController from '../../../../controller/sign/SignController';
import { LoadingDot } from '../../../../store/loadingSlice';
import { useDispatch } from 'react-redux';

interface data {
  loanList: Array<{
    loanCost: number;
    loanDate: string;
    loanName: string;
    loanRate: number;
    loanType: string;
    retentionPeriod: number;
    redemptionPeriod: number;
  }>;
  recentRepayment: {
    info: Array<{
      round: number;
      total: number;
      principal: number;
      interest: number;
      principalSum: number;
      remainSum: number;
    }>;
  };
  loanPlan: Array<{
    round: number;
    total: number;
    principal: number;
    interest: number;
    principalSum: number;
    remainSum: number;
  }>;
  totalCost: number;
  ownMoney: number;
  totalLoanCost: number;
  loanRequirement: {
    age: string;
    farmLivedPeriod: any;
    farmExperience: any;
    farmCareer: any;
    actualHours: number;
    onlineHours: number;
  };
}

interface data2 {
  createdAt: string;
  quantityRate: number;
  realIncome: number;
  realIncomeRate: number;
  yearOperationCost: number;
  yearSales: number;
  dsr: number;
  irr: number;
  unitPriceRate: number;
}
interface data3 {
  address: string;
  cropName: string;
  crops: number;
  facility: string;
  farmArea: number;
  quantity: number;
  type: string;
  landArea: number;
  pnuCode: string;
}

const ReportDetail6 = ({
  data,
  data2,
  data3,
  report,
  isStart,
  mypage,
  reportIdx,
  setLoanCostInterest,
  setProfitData,
  setLoanAnalysis,
  save,
  setSave,
  setSavePopUp,
}: {
  data: data;
  data2: data2;
  data3: data3;
  report: any;
  isStart: boolean;
  mypage?: boolean;
  reportIdx?: any;
  setLoanCostInterest?: any;
  setProfitData?: any;
  setLoanAnalysis?: any;
  save?: boolean;
  setSave?: any;
  setSavePopUp?: any;
}) => {
  let maxLoanCost = data.totalCost > 300000000 ? 300000000 : data.totalCost;
  const [ownMoney, setOwnMoney] = useState(
    Math.round(
      ((data.totalCost - data.loanList[0].loanCost) / data.totalCost) * 100,
    ),
  );

  const [totalLoanCost, setTotalLoanCost] = useState(
    (data.totalLoanCost * 100) /
      (data3.facility === 'S' ? data.totalCost : maxLoanCost),
  );

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const dummy = [
    {
      color: '#09CE7D',
      text: '자부담금',
      rate: ownMoney / 100,
      value: (ownMoney * data.totalCost) / 100,
    },
    {
      color: '#1E9B6B',
      text: '대출금',
      rate: 1 - ownMoney / 100,
      value:
        (totalLoanCost *
          (data3.facility === 'S' ? data.totalCost : maxLoanCost)) /
        100,
    },
  ];

  const [loanInfo, setLoanInfo] = useState(data.loanList[0]);
  const [totalPeriod, setTotalPeriod] = useState(
    data.loanList[0].redemptionPeriod + data.loanList[0].retentionPeriod,
  );

  const dummy2 = [
    { title: '원리금균등', type: 'T' },
    { title: '원금균등', type: 'P' },
    { title: '만기일시', type: 'B' },
  ];

  const obj2 = {
    1: '매우 낮음',

    2: '낮음',
    3: '보통',
    4: '높음',
    5: '매우 높음',
  };
  const cropCompetitive = (dsr: number | undefined, irr: number) => {
    if (typeof dsr === 'number') {
      if (dsr < 1 || irr < 1) {
        return 2;
      } else if (dsr >= 1 && irr < 5) {
        return 3;
      } else if (dsr >= 1 && irr < 7) {
        return 4;
      } else if (dsr >= 1 && irr >= 7) {
        return 5;
      }
      return 1;
    } else {
      if (irr < 1) {
        return 2;
      } else if (irr < 5) {
        return 3;
      } else if (irr < 7) {
        return 4;
      } else if (irr >= 7) {
        return 5;
      }
      return 1;
    }
  };

  const [tab, setTab] = useState(1);
  const [onToolTip, setOnToolTip] = useState(false);
  const [open, setOpen] = useState(true);
  const [areaType, setAreaType] = useState('P');
  const obj = {
    P: '원금균등',
    T: '원리금균등',
    B: '만기일시',
  };
  let tbList =
    data.recentRepayment &&
    data.recentRepayment.info.map((el: any, i: any) => {
      return [
        i + 1,
        `${transNum(el.total)}원`,
        `${transNum(el.principal)}원`,
        `${transNum(el.interest)}원`,
        `${transNum(el.principalSum)}원`,
        `${transNum(el.remainSum)}원`,
      ];
    });
  console.log(
    'ownmoney',
    ownMoney,
    Math.round(
      ((data.totalCost - data.loanList[0].loanCost) / data.totalCost) * 100,
    ),
    data.totalCost,
    data.loanList[0].loanCost,
  );
  let tbList2 =
    data.loanPlan &&
    data.loanPlan.map((el: any, i: any) => {
      return [
        i + 1,
        `${transNum(el.total)}원`,
        `${transNum(el.principal)}원`,
        `${transNum(el.interest)}원`,
        `${transNum(el.principalSum)}원`,
        `${transNum(el.remainSum)}원`,
      ];
    });
  const table = {
    th: ['회차', '월상환금', '납입원금', '이자', '납입원금누계', '잔금'],
    tb: tbList,
  };
  const table2 = {
    th: ['회차', '월상환금', '납입원금', '이자', '납입원금누계', '잔금'],
    tb: tbList2,
  };

  const btnArr = [
    { children: '대출 적격 여부', selected: 1 },
    '',
    { children: '1년 상환계획', selected: 2 },
    '',
    { children: '전체 상환계획', selected: 3 },
  ];

  const [name, setName] = useState('');

  useEffect(() => {
    SignController.getName(setName);
  }, []);

  useEffect(() => {
    if (loading) {
      if (!isMobile(window.innerWidth)) {
        dispatch(LoadingDot('W2'));
      } else {
        dispatch(LoadingDot('Y'));
      }
    } else {
      dispatch(LoadingDot('N'));
    }
  }, [loading]);

  function calculateYearsSince(dateString: string) {
    const inputDate = new Date(dateString);
    const today = new Date();
    let yearsSince = today.getFullYear() - inputDate.getFullYear();
    if (
      today.getMonth() < inputDate.getMonth() ||
      (today.getMonth() === inputDate.getMonth() &&
        today.getDate() < inputDate.getDate())
    ) {
      yearsSince--;
    }
    return yearsSince;
  }

  const patchLoan = async (loanType: any, save: string) => {
    try {
      if (!isStart) {
        return;
      }
      if (save === 'Y') {
        dispatch(LoadingDot('S'));
      } else {
        setLoading(true);
      }
      const result = await ReportService.patchLoan(
        reportIdx,
        loanType,
        (data.totalCost * (100 - ownMoney)) / 100,
        loanInfo.loanRate,
        totalPeriod,
        loanInfo.retentionPeriod,
        save,

        // data3.cropName,
        // loanType,
        // (totalLoanCost *
        //   (data3.facility === "S" ? data.totalCost : maxLoanCost)) /
        //   100,
        // loanInfo.loanRate,
        // totalPeriod,
        // loanInfo.retentionPeriod
      );

      // await report();
      setProfitData(result.report.profitAnalysis);
      if (save === 'Y') {
        dispatch(LoadingDot('N'));
      } else {
        setLoading(false);
      }
      setLoanAnalysis(result.report.loanAnalysis);
      setSave(false);
      if (save === 'Y') {
        setSavePopUp(true);
      }
    } catch (err) {
      console.log(err);
      if (save === 'Y') {
        dispatch(LoadingDot('N'));
      } else {
        setLoading(false);
      }
    }
  };

  // useDidMountEffect(() => {
  //   patchLoan(loanInfo.loanType);
  // }, [totalLoanCost]);

  // useDidMountEffect(() => {
  //   patchLoan();
  // }, [data.loanList[0].loanType]);

  useEffect(() => {
    setLoanInfo(data.loanList[0]);
    setTotalPeriod(
      data.loanList[0].redemptionPeriod +
        Number(data.loanList[0].retentionPeriod),
    );
    // setOwnMoney(
    //   Math.round(
    //     ((data.totalCost - data.loanList[0].loanCost) / data.totalCost) * 100
    //   )
    // );

    // setTotalLoanCost(
    //   (data.loanList[0].loanCost /
    //     (data3.facility === "S" ? data.totalCost : maxLoanCost)) *
    //     100
    // );
  }, [data.loanList[0]]);
  useEffect(() => {
    if (save) {
      patchLoan(loanInfo.loanType, 'Y');
    }
  }, [save]);
  useEffect(() => {
    if (mypage) {
      setOwnMoney(
        Math.round(
          ((data.totalCost - data.loanList[0].loanCost) / data.totalCost) * 100,
        ),
      );
      setTotalLoanCost(
        100 -
          Math.round(
            ((data.totalCost - data.loanList[0].loanCost) / data.totalCost) *
              100,
          ),
      );
    }
  }, [data.loanList]);
  const [onToolTip1, setOnToolTip1] = useState(false);
  const [onToolTip2, setOnToolTip2] = useState(false);
  const [onToolTip3, setOnToolTip3] = useState(false);
  const [onToolTip4, setOnToolTip4] = useState(false);
  const [onToolTip5, setOnToolTip5] = useState(false);
  const [onToolTip10, setOnToolTip10] = useState(false);
  const temp = `1. 원리금균등 분할상환 : \n 매달 원금 + 이자금액을 균등하게 납부하는 방법\n\n2. 원금균등 분할상환 : \n 매달 원금을 균등하게 납부하고 이에 따른 이자는 점차 줄어드는 방법\n\n3. 만기일시 상환 : \n 대출기간동안 이자만 납부하고 원금은 만기에 일시상환하는 방법`;
  useEffect(() => {
    console.log('loadinfo', loanInfo);
  }, [loanInfo]);
  return (
    <div className="padding-b-24">
      <RowSectionTitle>
        <TextSectionTitle>6. 대출 가능성</TextSectionTitle>
      </RowSectionTitle>
      <div className="padding-16-16-0">
        <div className="font-bold f-size-16 line-h-24 color-text-secondary">
          대출 신청 금액 설정
        </div>
      </div>
      <div
        className="flex-column"
        style={{
          overflow: 'hidden',
          transition: 'all 0.5s',
          maxHeight: !open ? 0 : '2000px',
        }}
      >
        <div className="padding-16-16-8">
          <div className="font-bold f-size-16 line-h-24 color-text-secondary">
            1) 자부담금 설정
          </div>
        </div>
        <div className="padding-8-16">
          <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
            자신의 총사업비를 참고하여 <br />
            예상 자부담금을 설정(최소 10% 이상 권장)하세요.
          </div>
        </div>
        <div className="flex-row space-between center-y padding-8-16-16">
          <div className="font-bold f-size-16 line-h-24 color-text-secondary">
            총 사업비
          </div>
          <div className="font-medium f-size-16 line-h-24 color-text-secondary">
            {transNum(data.totalCost)}원
          </div>
        </div>
        <div
          className="flex-row w-100per gap-40 center-y h-100per padding-x-16 "
          style={{ height: '180px' }}
        >
          <DoughnutChart2 list={dummy} />
          <div className="flex-column w-100per gap-8 ">
            <div className="flex-row space-between center-y w-100per">
              <div className="flex-row center-y gap-6">
                <div className="w-8 h-8 bg-primary-400 border-radius-100">
                  &nbsp;
                </div>
                <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                  자부담금
                </div>
              </div>
              <div className="font-bold f-size-14 line-h-20 color-text-primary">
                {Math.round(dummy[0].rate * 1000) / 10}%
              </div>
            </div>
            <div className="flex-row space-between center-y w-100per">
              <div className="flex-row center-y gap-6">
                <div className="w-8 h-8 bg-primary-500 border-radius-100">
                  &nbsp;
                </div>
                <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                  대출금
                </div>
              </div>
              <div className="font-bold f-size-14 line-h-20 color-text-primary">
                {Math.round(dummy[1].rate * 1000) / 10}%
              </div>
            </div>
          </div>
        </div>
        {[
          {
            title: '자부담금',
            price: ownMoney,
          },
          {
            title: '대출금',
            price: totalLoanCost,
          },
        ].map((el) => (
          <div className="flex-center flex-column padding-8-16-24 gap-30">
            <div className="flex-column flex-center">
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                {el.title}
              </div>
              <div className="font-bold f-size-16 line-h-24 color-text-primary ">
                {el.title === '대출금' && data3.facility !== 'S'
                  ? transNum((el.price * maxLoanCost) / 100)
                  : transNum((el.price * data.totalCost) / 100)}
                원
              </div>
            </div>
            <div className="flex-column w-100per">
              <Slider
                value={el.price}
                onChange={(e: any, value: any) => {
                  if (el.title === '자부담금') {
                    if (
                      data3.facility === 'S'
                        ? true
                        : ((100 - value) * data.totalCost) / 100 <= maxLoanCost
                    ) {
                      setOwnMoney(value);

                      setTotalLoanCost(
                        data3.facility === 'S'
                          ? 100 - value
                          : ((100 - value) * data.totalCost) / maxLoanCost,
                      );
                    }
                  } else {
                    setTotalLoanCost(value);
                    setOwnMoney(
                      data3.facility === 'S'
                        ? 100 - value
                        : 100 - (value * maxLoanCost) / data.totalCost,
                    );
                  }
                }}
                onChangeCommitted={(e: any, value: any) => {
                  if (el.title === '자부담금') {
                    if (
                      data3.facility === 'S'
                        ? true
                        : ((100 - value) * data.totalCost) / 100 <= maxLoanCost
                    ) {
                      patchLoan(loanInfo.loanType, 'N');
                    } else {
                      patchLoan(loanInfo.loanType, 'N');
                    }
                  } else {
                    patchLoan(loanInfo.loanType, 'N');
                  }
                }}
                marks={(() => {
                  let arr = [];
                  for (let i = 10; i < 100; i += 10) {
                    arr.push({ value: i });
                  }
                  return arr;
                })()}
                valueLabelDisplay={'on'}
                slotProps={{ valueLabel: { style: { display: 'none' } } }}
                valueLabelFormat={(value: number, index: number) => (
                  <div
                    className="speech-bubble font-bold f-size-11 line-h-12 border-radius-2"
                    style={{
                      backgroundColor: '#2A3746',
                      color: 'white',
                      padding: '4px 5px',
                      marginLeft: 0,
                      marginBottom: 0,
                    }}
                  >
                    {Math.round(
                      el.title === '대출금' ? 100 - ownMoney : ownMoney,
                    )}
                    %
                  </div>
                )}
                step={1}
                tabIndex={10}
                // getAriaValueText={valuetext}
                sx={{
                  height: '8px',
                  '& .MuiSlider-thumb': {
                    color: '#166F4D',
                  },
                  '& .MuiSlider-track': { color: '#1E9B6B' },
                  '& .MuiSlider-rail': { color: '#F0F0F0' },
                  '& .MuiSlider-active': {
                    color: 'green',
                  },
                  '& .Mui-active': {
                    boxShadow: '#1E9B6B30 0px 0px 0px 12px',
                  },
                  '& .MuiSlider-valueLabel': { background: 'none' },
                  '& .MuiSlider-mark': {
                    backgroundColor: '#FFFFFF',
                    height: 8,
                  },
                }}
                min={0}
                max={100}
              />
              <div className="flex-row w-100per">
                {[
                  { 1: '0%', 2: '최소', 3: 'start-y' },
                  { 1: '50%', 2: '기준', 3: 'center-y' },
                  { 1: '100%', 2: '최대', 3: 'end-y' },
                ].map((el) => (
                  <div className={'flex-column flex-1 ' + el['3']}>
                    <div className="font-medium f-size-12 line-h-14 color-text-secondary">
                      {el['1']}
                    </div>
                    <div className="font-medium f-size-12 line-h-14 color-text-tertiary">
                      {el['2']}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
        <div className="padding-16-16-8">
          <div className="font-bold f-size-16 line-h-24 color-secondary">
            2) 대출 조건 설정
          </div>
        </div>
        <div className="flex-column gap-16 padding-8-16">
          <div className="flex-row gap-24">
            <div className="w-100per flex-column gap-8">
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                대출금액
              </div>
              <div
                className="flex-row border-radius-8 border-171E26 padding-6-8  gap-4"
                style={{ justifyContent: 'end' }}
              >
                <input
                  className="border-none w-70per text-end font-medium f-size-14 line-h-24 color-text-primary w-70per"
                  placeholder="입력해주세요"
                  readOnly
                  value={transNum(((100 - ownMoney) * data.totalCost) / 100)}
                />
                <div className="font-medium f-size-14 line-h-24 color-text-primary">
                  원
                </div>
              </div>
            </div>
            <div className="w-100per flex-column gap-8">
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                연이자율
              </div>
              <div
                className="flex-row border-radius-8 border-171E26 padding-6-8  gap-4"
                style={{ justifyContent: 'end' }}
              >
                <input
                  className="border-none w-70per text-end font-medium f-size-14 line-h-24 color-text-primary w-70per"
                  placeholder="입력해주세요"
                  value={Math.round(loanInfo.loanRate * 100)}
                  type="number"
                  style={{ background: 'none' }}
                  onChange={(e: any) => {
                    setLoanInfo((prev: any) => {
                      return {
                        ...prev,
                        loanRate: e.target.value / 100,
                      };
                    });
                  }}
                  onBlur={() => patchLoan(loanInfo.loanType, 'N')}
                />
                <div className="font-medium f-size-14 line-h-24 color-text-primary">
                  %
                </div>
              </div>
            </div>
          </div>
          <div className="flex-row gap-24">
            <div className="w-50per flex-column gap-8">
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                대출기간
              </div>
              <div
                className="flex-row border-radius-8 border-171E26 padding-6-8  gap-4"
                style={{ justifyContent: 'end' }}
              >
                <input
                  className="border-none w-70per text-end font-medium f-size-14 line-h-24 color-text-primary w-70per"
                  style={{ background: 'none' }}
                  placeholder="입력해주세요"
                  value={totalPeriod}
                  type="number"
                  onChange={(e: any) => setTotalPeriod(e.target.value)}
                  onBlur={() => patchLoan(loanInfo.loanType, 'N')}
                />
                <div className="font-medium f-size-14 line-h-24 color-text-primary">
                  년
                </div>
              </div>
            </div>
            <div className="w-50per flex-column gap-8">
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                거치기간
              </div>
              <div
                className="flex-row border-radius-8 border-171E26 padding-6-8  gap-4"
                style={{ justifyContent: 'end' }}
              >
                <input
                  className="border-none w-70per text-end font-medium f-size-14 line-h-24 color-text-primary w-70per"
                  style={{ background: 'none' }}
                  placeholder="입력해주세요"
                  type="number"
                  value={loanInfo.retentionPeriod}
                  onChange={(e) =>
                    setLoanInfo((prev: any) => {
                      return { ...prev, retentionPeriod: e.target.value };
                    })
                  }
                  onBlur={() => patchLoan(loanInfo.loanType, 'N')}
                />
                <div className="font-medium f-size-14 line-h-24 color-text-primary">
                  년
                </div>
              </div>
            </div>
          </div>

          <div className="flex-column gap-8 relative">
            <div className="flex-row center-y">
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                상환방식
              </div>
              <img
                src="/asset/images/warn/Warn24.svg"
                alt=""
                className="hover"
                onClick={() => setOnToolTip10(true)}
              />
            </div>
            <Tooltip2
              bottom="162"
              left="0"
              on={onToolTip10}
              off={() => setOnToolTip10(false)}
              text={temp}
              width
            />
            <div className="flex-row gap-12">
              {dummy2.map((item, index) => {
                return (
                  <div
                    className={`padding-6-8  border-radius-8 w-100per flex-center pointer ${
                      loanInfo.loanType === item.type
                        ? `bg-primary-600`
                        : `bg-light-gray-200`
                    }`}
                    onClick={() => {
                      setLoanInfo((prev) => {
                        return { ...prev, loanType: item.type };
                      });
                      patchLoan(item.type, 'N');
                    }}
                  >
                    <div
                      className={`font-bold f-size-12 line-h-24 ${
                        loanInfo.loanType === item.type
                          ? `color-white`
                          : `color-disabled`
                      }`}
                    >
                      {item.title}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="w-100epr center-x padding-b-16 border-b-171E26">
        <div
          className="flex-center w-32 h-32 bg-light-gray-200 border-radius-999 pointer"
          onClick={() => {
            setOpen(!open);
          }}
        >
          <img className="w-14 h-8" src={!open ? arrowBot : arrowTop} />
        </div>
      </div>

      <Tooltip2
        bottom="0"
        on={onToolTip}
        off={() => setOnToolTip(false)}
        text="대출 이자율은 고정금리에 한해 분석합니다."
      />

      <ButtonSelectorBox tab={tab} setTab={setTab} btnArr={btnArr} />

      {tab === 1 ? (
        <div className="flex-column">
          {data.loanList.map((el: any, i: any) => (
            <div className="flex-column">
              <div className="padding-16-16-8">
                <div className="font-bold f-size-16 line-h-24 color-text-secondary">
                  대출 적격 여부 진단
                </div>
              </div>

              {[
                {
                  title: '1) 나이',
                  case:
                    // data.loanRequirement.age === "1" ||
                    data.loanRequirement.age === '2',
                  onClick: () => {
                    setOnToolTip1(true);
                  },
                  toolTip: (
                    <Tooltip2
                      width
                      top="30"
                      on={onToolTip1}
                      off={() => setOnToolTip1(false)}
                      text="만 65세 이하"
                    />
                  ),
                },
                {
                  title: '2) 신청금액',
                  case: data.totalLoanCost <= 300000000,
                  onClick: () => {
                    setOnToolTip2(true);
                  },
                  toolTip: (
                    <Tooltip2
                      width
                      top="30"
                      on={onToolTip2}
                      off={() => setOnToolTip2(false)}
                      text="3억원 이내"
                    />
                  ),
                },
                {
                  title: '3) 농촌지역 전입일',
                  case:
                    calculateYearsSince(data.loanRequirement.farmLivedPeriod) >=
                      1 &&
                    calculateYearsSince(data.loanRequirement.farmLivedPeriod) <=
                      5,
                  onClick: () => {
                    setOnToolTip3(true);
                  },
                  toolTip: (
                    <Tooltip2
                      width
                      top="30"
                      on={onToolTip3}
                      off={() => setOnToolTip3(false)}
                      text={
                        <p>
                          • 전입 전 1년 이상 농촌 외 지역 거주 <br />• 농촌지역
                          전입 5년 이내
                        </p>
                      }
                    />
                  ),
                },
                {
                  title: '4) 영농경력',
                  case:
                    calculateYearsSince(data.loanRequirement.farmExperience) <
                    5,
                  onClick: () => {
                    setOnToolTip4(true);
                  },
                  toolTip: (
                    <Tooltip2
                      width
                      top="30"
                      on={onToolTip4}
                      off={() => setOnToolTip4(false)}
                      text="영농경력 5년 미만"
                    />
                  ),
                },
                {
                  title: '5) 영농기술 교육이수',
                  case:
                    data.loanRequirement.actualHours +
                      data.loanRequirement.onlineHours >=
                    100,
                  onClick: () => {
                    setOnToolTip5(true);
                  },
                  toolTip: (
                    <Tooltip2
                      width
                      top="30"
                      on={onToolTip5}
                      off={() => setOnToolTip5(false)}
                      text="농식품부·농진청·산림청 등에서 주관 혹은 위탁하는 귀농·영농 교육 100시간 이상 이수"
                    />
                  ),
                },
              ].map((el) => (
                <div className="padding-16-16-8 flex-column relative">
                  <RowBold2
                    title={el.title}
                    warn
                    onClick={el.onClick}
                    green={el.case}
                    value={el.case ? '적격' : '부적격'}
                  />
                  {el.toolTip}
                </div>
              ))}

              <div className="padding-8-16 gap-10">
                <BoxGreen1>
                  <div className="flex-column gap-10">
                    <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                      {name}님의 영농사업 대출 적격 여부 진단 결과, <br />'
                      {/* {obj2[cropCompetitive(data2.dsr, data2.irr)]} */}
                      {[
                        data.loanRequirement.age === '2',
                        data.totalLoanCost <= 300000000,
                        calculateYearsSince(
                          data.loanRequirement.farmLivedPeriod,
                        ) >= 1 &&
                          calculateYearsSince(
                            data.loanRequirement.farmLivedPeriod,
                          ) <= 5,
                        calculateYearsSince(
                          data.loanRequirement.farmExperience,
                        ) < 5,
                        data.loanRequirement.actualHours +
                          data.loanRequirement.onlineHours >=
                          100,
                      ].filter((el) => el).length >= 4
                        ? '높음'
                        : '낮음'}
                      '으로 판단됩니다. <br />
                      {[
                        data.loanRequirement.age === '2',
                        data.totalLoanCost <= 300000000,
                        calculateYearsSince(
                          data.loanRequirement.farmLivedPeriod,
                        ) >= 1 &&
                          calculateYearsSince(
                            data.loanRequirement.farmLivedPeriod,
                          ) <= 5,
                        calculateYearsSince(
                          data.loanRequirement.farmExperience,
                        ) < 5,
                        data.loanRequirement.actualHours +
                          data.loanRequirement.onlineHours >=
                          100,
                      ].filter((el) => el).length === 5
                        ? '대출 적격 요건은 모든 항목에 부합합니다.'
                        : `${
                            5 -
                            [
                              data.loanRequirement.age === '2',
                              data.totalLoanCost <= 300000000,
                              calculateYearsSince(
                                data.loanRequirement.farmLivedPeriod,
                              ) >= 1 &&
                                calculateYearsSince(
                                  data.loanRequirement.farmLivedPeriod,
                                ) <= 5,
                              calculateYearsSince(
                                data.loanRequirement.farmExperience,
                              ) < 5,
                              data.loanRequirement.actualHours +
                                data.loanRequirement.onlineHours >=
                                100,
                            ].filter((el) => el).length
                          }개 항목에서 적격 요건을 충족하지 못하여 부적격 항목의 기준을 달성해야 합니다 .`}
                    </div>
                    <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                      {name}님의 영농사업 대출 가능성 진단 결과, <br />'
                      {obj2[cropCompetitive(data2.dsr, data2.irr)]}
                      '으로 판단됩니다. <br />
                    </div>
                  </div>
                </BoxGreen1>
              </div>
            </div>
          ))}
        </div>
      ) : (
        ''
      )}
      {tab === 2 ? (
        <div className="flex-column">
          <div className="padding-16-16-8">
            <div className="font-bold f-size-16 line-h-24 color-text-secondary">
              향후 1년 상환 계획
            </div>
          </div>
          <div className="padding-8-16-0">
            <div className="font-medium f-size-16 line-h-24 color-text-secondary">
              {obj[data.loanList[0].loanType as 'P']}
            </div>
          </div>
          <div
            className={`padding-16 relative of-x-auto ${`of-y-auto`}`}
            style={{ height: '224px' }}
          >
            {data.recentRepayment && <BoxTable2 table={table} />}
          </div>
        </div>
      ) : (
        ''
      )}
      {tab === 3 ? (
        <div className="flex-column">
          <div className="padding-16-16-8">
            <div className="font-bold f-size-16 line-h-24 color-text-secondary">
              전체 상환 계획
            </div>
          </div>
          <div className="padding-8-16-0">
            <div className="font-medium f-size-16 line-h-24 color-text-secondary">
              {obj[data.loanList[0].loanType as 'P']}
            </div>
          </div>

          <div
            className="padding-16 relative of-x-auto of-y-auto"
            style={{ height: '224px' }}
          >
            {data.loanPlan && <BoxTable2 table={table2} />}
          </div>
          <div className="flex- row padding-12-16 gap-8 end-x">
            <div className="flex-row gap-4 center-y">
              <div
                className="border-radius-100per"
                style={{ width: '6px', height: '6px', background: '#09CE7D' }}
              />
              <div className="font-medium f-size-11 line-h-12 color-primary-400">
                이자
              </div>
            </div>
            <div className="flex-row gap-4 center-y">
              <div
                className="border-radius-100per"
                style={{ width: '6px', height: '6px', background: '#869CB6' }}
              />
              <div className="font-medium f-size-11 line-h-12 color-blue-gray-500">
                납입원금
              </div>
            </div>
          </div>
          <div className="padding-x-16">
            <div
              className="of-x-auto"
              style={{
                width: `${
                  isMobile(window.innerWidth)
                    ? window.innerWidth - 32
                    : 365 - 32
                }px`,
              }}
            >
              <AreaChart
                className="flex-medium f-size-11 center-x"
                width={
                  isMobile(window.innerWidth)
                    ? window.innerWidth - 32
                    : 365 - 32
                }
                height={181}
                data={data.loanPlan}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <XAxis dataKey="round" />
                <YAxis />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="interest"
                  name="이자"
                  fill="#09CE7D"
                />
                <Area
                  type="monotone"
                  dataKey="principal"
                  name="납입원금"
                  fill="#869CB6"
                />
              </AreaChart>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ReportDetail6;
