import { Topnav } from '../../component/mobile/Topnav';
import '../../asset/styles/SaleInfo.css';
import arrowDown from './../../asset/images/arrow/arrowDown.svg';

const Question = () => {
  return (
    <div className="flex-column w-100per">
      <Topnav text="자주하는 질문" />
      <div className="flex-column w-100per padding-0-20">
        <div className="title-wrapper-no-border mt-20 flex-column">
          <div className="font-bold">자주하는 질문</div>
          <div className="flex-column mt-20 gap-10">
            <div className="flex-row space-between center-y padding-b-10 borderbottom-1px-e8ebe6">
              <div className="flex-row gap-20">
                <div
                  className="font-bold"
                  style={{ fontSize: '15px', color: 'green' }}
                >
                  Q
                </div>
                <div className="font-bold" style={{ fontSize: '15px' }}>
                  트랜스파머 이용방법에 대해 알려주세요!
                </div>
              </div>
              <img src={arrowDown} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Question;
