import { useState } from 'react';
import { RowSectionTitle } from '../../../../component/report/Row';
import { TextSectionTitle } from '../../../../component/report/Text';
import {
  BoxCard2,
  BoxTable,
  BoxGreen1,
} from '../../../../component/report/Box';
import { transNum } from '../../../../component/function/function';
import { Tooltip2 } from '../../../../component/common/tooltip';
interface data {
  loanCost: number;
  loanCostInterest: number;
  loanCostPrincipal: number;
  profit: number;
  profitRate: number;
  realIncome: number;
  realIncomeRate: number;
  yearSales: number;
  yearSalesCost: number;
  yearSalesSGA: number;
}

interface data2 {
  realIncome: number;
  realIncomeRate: number;
  yearOperationCost: number;
  yearSales: number;
}

const ReportDetail7 = ({ data, data2 }: { data: data; data2: data2 }) => {
  const [onToolTip1, setOnToolTip1] = useState(false);

  const table1 = {
    th: ['매출액', `${transNum(data.yearSales)}원`],
    tb: [
      ['매출원가', `${transNum(data.yearSalesCost)}원`],
      ['판매관리비', `${transNum(data.yearSalesSGA)}원`],
    ],
  };
  const table2 = {
    th: ['영업이익', `${transNum(data.profit)}원`],
    tb: [['영업이익률', `${data.profitRate}%`]],
  };
  const table3 = {
    th: ['금융비(대출)', `${transNum(data.loanCost)}원`],
    tb: [
      ['이자비용', `${transNum(data.loanCostInterest)}원`],
      ['상환원금', `${transNum(data.loanCostPrincipal)}원`],
    ],
  };
  const table4 = {
    th: ['순이익', `${transNum(data.realIncome)}원`],
    tb: [['순이익률', `${data.realIncomeRate}%`]],
  };
  const [onToolTip2, setOnToolTip2] = useState(false);
  return (
    <div className="padding-b-24 relative">
      <RowSectionTitle>
        <TextSectionTitle>7. 예상 이익</TextSectionTitle>
      </RowSectionTitle>

      <div className="flex-row padding-x-16 relative">
        <BoxCard2
          title="영업이익(률)"
          value={`${transNum(data.profit)}원(${data.profitRate}%)`}
          onClick={() => setOnToolTip1(true)}
        />

        <BoxCard2
          title="순이익(률)"
          value={`${transNum(data.realIncome)}원(${data.realIncomeRate}%)`}
          onClick={() => setOnToolTip2(true)}
        />
      </div>
      <div style={{ position: 'absolute', zIndex: 10 }}>
        <Tooltip2
          bottom="40"
          left="10"
          width
          on={onToolTip1}
          off={() => setOnToolTip1(false)}
          text={
            <div className="text-left">
              영업이익은 총 매출액에서 금융비를 제외한
              비용(매출원가+판매관리비)을 차감한 금액을 뜻함.
            </div>
          }
        />
      </div>
      <div style={{ position: 'absolute', zIndex: 10 }}>
        <Tooltip2
          bottom="40"
          left="110"
          width
          on={onToolTip2}
          off={() => setOnToolTip2(false)}
          text="순이익은 총 매출액에서 금융비 등 모든 비용을 제외하고 남은 최종 수익을 뜻함."
        />
      </div>
      <div className="padding-8-16 flex-column gap-8">
        <BoxTable table={table1} />
        <BoxTable table={table2} />
        <BoxTable table={table3} />
        <BoxTable table={table4} />
      </div>
      <div className="padding-8-16 gap-10">
        {/* <BoxGreen1>
          <div className="font-bold f-size-14 line-h-20 color-text-secondary">
            AI 예상 {data.realIncome >= 0 ? "순이익" : "순손실"}은{" "}
            {`${transNum(data.realIncome)}원`}으로
          </div>
          <div
            className="font-bold f-size-16 line-h-24"
            style={{
              color:
                data2.realIncome <= data.realIncome ? "#1E9B6B" : "#d62554",
            }}
          >
            지역 평균 {data2.realIncome >= 0 ? "순이익 " : "순손실 "}
            {`${transNum(Math.abs(data2.realIncome))}원`}
            {data2.realIncome < data.realIncome
              ? `보다 ${Math.abs(
                  Math.round((data.realIncome / data2.realIncome) * 100) - 100
                )}% 높습니다.`
              : data2.realIncome > data.realIncome
              ? `보다 ${Math.abs(
                  100 - Math.round((data2.realIncome / data.realIncome) * 100)
                )}% 낮습니다.`
              : "과 같습니다."}
          </div>
        </BoxGreen1> */}
      </div>
    </div>
  );
};

export default ReportDetail7;
