const Tab1 = ({ field, paddy, fruit }: any) => {
  return (
    <div className="flex-row gap-8 of-x-auto padding-y-16 browser-gap-16 browser-padding-0 none tablet-flex ">
      <div
        className="flex-row border-radius-8 border-1-171E2610 padding-16 gap-16 no-wrap"
        style={{ width: '33%' }}
      >
        <div className="flex-column space-between center-y">
          <div className="font-bold f-size-14 browser-f-size-16 line-h-20 browser-line-h-26 color-text-primary">
            전
          </div>
          <img src="/asset/images/main/farm1.svg" alt="" />
        </div>
        <div className="flex-column gap-4 ">
          {field.map((el: any, i: number) => (
            <div key={i} className="flex-row gap-8">
              <div className="font-medium f-size-14 browser-f-size-16 line-h-20 browser-line-h-26 color-text-primary">
                {i + 1}
              </div>
              <div className="font-medium f-size-14 browser-f-size-16 lien-h-20 browser-line-h-26 color-text-secondary">
                {el.searchWord}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className="flex-row border-radius-8 border-1-171E2610 padding-16 gap-16 browser-gap-24  no-wrap"
        style={{ width: '33%' }}
      >
        <div className="flex-column space-between center-y">
          <div className="font-bold f-size-14 browser-f-size-16 line-h-20 browser-line-h-26 color-text-primary">
            답
          </div>
          <img src="/asset/images/main/farm2.svg" alt="" />
        </div>
        <div className="flex-column gap-4 ">
          {paddy.map((el: any, i: number) => (
            <div key={i} className="flex-row gap-8">
              <div className="font-medium f-size-14 browser-f-size-16 line-h-20 browser-line-h-26 color-text-primary">
                {i + 1}
              </div>
              <div className="font-medium f-size-14 browser-f-size-16 lien-h-20 browser-line-h-26 color-text-secondary">
                {el.searchWord}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className="flex-row border-radius-8 border-1-171E2610 padding-16 gap-16 browser-gap-24  no-wrap"
        style={{ width: '33%' }}
      >
        <div className="flex-column space-between center-y">
          <div className="font-bold f-size-14 browser-f-size-16 line-h-20 browser-line-h-26 color-text-primary">
            과수
          </div>
          <img src="/asset/images/main/farm3.svg" alt="" />
        </div>
        <div className="flex-column gap-4 ">
          {fruit.map((el: any, i: number) => (
            <div key={i} className="flex-row gap-8">
              <div className="font-medium f-size-14 browser-f-size-16 line-h-20 browser-line-h-26 color-text-primary">
                {i + 1}
              </div>
              <div className="font-medium f-size-14 browser-f-size-16 lien-h-20 browser-line-h-26 color-text-secondary">
                {el.searchWord}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Tab1;
