import { createSlice } from '@reduxjs/toolkit';

const mypageInitialState = {
  title: 'modify',
};

const mypageSlice = createSlice({
  name: 'clicked',
  initialState: mypageInitialState,
  reducers: {
    BtnClick(state, action) {
      if (action.payload === 'modify') {
        state.title = action.payload;
      } else if (action.payload === 'board') {
        state.title = action.payload;
      } else if (action.payload === 'report') {
        state.title = action.payload;
      } else if (action.payload === 'favorites') {
        state.title = action.payload;
      } else if (action.payload === 'notice') {
        state.title = action.payload;
      } else if (action.payload === 'inquiry') {
        state.title = action.payload;
      }
    },
    BtnClickMobile(state, action) {
      state.title = action.payload;
    },
  },
});

export const { BtnClick, BtnClickMobile } = mypageSlice.actions;
export default mypageSlice.reducer;
