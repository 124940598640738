import { RowTableValueBold } from './Row';
import WeatherCom from './Weather';
import { LineChart, Tooltip, XAxis, YAxis, Line } from 'recharts';
import { BoxGreen1 } from './Box';
interface data {
  accumalatedTempList?: any;
  avgYearWeather: any[];
  cropList: {
    recommendCrop: any[];
    anotherCrop: any[];
  };
  curYearWeather: any[];
  soilResult: any;
}

export default class TempCom {
  static AvgTempOfMonth = ({ data, date }: { data: data; date: Date }) => {
    return (
      <div className="flex-column ">
        <div className="padding-16-16-0">
          <RowTableValueBold
            title="월평균 기온"
            value={
              Math.round(
                data.curYearWeather.reduce((acc: any, cur: any, i: number) => {
                  return acc + cur.avgTemp;
                }, 0) / data.curYearWeather.length,
              ) + ' ℃'
            }
          />
        </div>
        <div className="flex-row padding-12-16 gap-8 end-x">
          <div className="flex-row gap-4 center-y">
            <div
              style={{ width: '12px', height: '2px', background: '#09CE7D' }}
            />
            <div className="font-medium f-size-11 line-h-12 color-primary-400">
              월평균 기온
            </div>
          </div>
          <div className="flex-row gap-4 center-y">
            <div
              style={{ width: '12px', height: '2px', background: '#869CB6' }}
            />
            <div className="font-medium f-size-11 line-h-12 color-blue-gray-500">
              평년값
            </div>
          </div>
        </div>
        <div className="flex-column of-x-auto">
          <LineChart
            className="flex-medium f-size-11 center-x"
            width={360 - 16}
            height={181}
            data={data.curYearWeather.map((el: any, i: number) => {
              return {
                day: `${el.year}-${el.month}`,
                temp:
                  (data.avgYearWeather[i] && data.avgYearWeather[i].temp) || 0,
                avgTemp: el.avgTemp,
              };
            })}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <Line
              type="monotone"
              dataKey="avgTemp"
              name="월평균 기온"
              stroke="#09CE7D"
            />
            <Line
              type="monotone"
              dataKey="temp"
              name="평년값"
              stroke="#869CB6"
            />
            <XAxis dataKey="day" />
            <YAxis />
            <Tooltip />
          </LineChart>
        </div>
        <div className="padding-8-16 gap-10">
          <WeatherCom.WeatherGreenBox1 date={date} data={data} />
        </div>
      </div>
    );
  };

  static AvgMaxTempOfMonth = ({ data, date }: { data: data; date: Date }) => {
    return (
      <div className="flex-column">
        <div className="padding-16-16-0">
          <RowTableValueBold
            title="월평균 최고기온"
            value={
              Math.round(
                data.curYearWeather.reduce((acc: any, cur: any, i: number) => {
                  return acc + cur.avgMaxTemp;
                }, 0) / data.curYearWeather.length,
              ) + ' ℃'
            }
          />
        </div>
        <div className="flex- row padding-12-16 gap-8 end-x">
          <div className="flex-row gap-4 center-y">
            <div
              style={{ width: '12px', height: '2px', background: '#09CE7D' }}
            />
            <div className="font-medium f-size-11 line-h-12 color-primary-400">
              월평균 최고기온
            </div>
          </div>
          <div className="flex-row gap-4 center-y">
            <div
              style={{ width: '12px', height: '2px', background: '#869CB6' }}
            />
            <div className="font-medium f-size-11 line-h-12 color-blue-gray-500">
              평년값
            </div>
          </div>
        </div>
        <div className="flex-column of-x-auto">
          <LineChart
            className="flex-medium f-size-11 center-x"
            width={360 - 16}
            height={181}
            data={data.curYearWeather.map((el: any, i: number) => {
              return {
                day: `${el.year}-${el.month}`,
                temp: Math.round(data.avgYearWeather[i].maxTemp * 100) / 100,
                avgTemp: Math.round(el.avgMaxTemp * 100) / 100,
              };
            })}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <Line
              type="monotone"
              dataKey="avgTemp"
              name="월평균 최고기온"
              stroke="#09CE7D"
            />
            <Line
              type="monotone"
              dataKey="temp"
              name="평년값"
              stroke="#869CB6"
            />
            <XAxis dataKey="day" />
            <YAxis />
            <Tooltip />
          </LineChart>
        </div>
        <div className="padding-8-16 gap-10">
          <BoxGreen1>
            <div className="font-bold f-size-14 line-h-20 color-text-secondary">
              {date.getMonth() + 1}월 평균 최고기온은{' '}
              {data.curYearWeather[data.curYearWeather.length - 1].avgMaxTemp +
                ' ℃'}
              로
            </div>
            <div
              className="font-bold f-size-16 line-h-24 "
              style={{
                color:
                  data.curYearWeather[data.curYearWeather.length - 1]
                    .avgMaxTemp <
                  data.avgYearWeather[data.avgYearWeather.length - 1].maxTemp
                    ? '#d62554'
                    : '#1E9B6B',
              }}
            >
              평년{' '}
              {data.avgYearWeather[data.avgYearWeather.length - 1].maxTemp +
                ' ℃'}
              {(() => {
                let a =
                  data.curYearWeather[data.curYearWeather.length - 1]
                    .avgMaxTemp;
                let b =
                  data.avgYearWeather[data.avgYearWeather.length - 1].maxTemp;
                if (a > b) {
                  return (
                    '에 비해 ' + Math.round((a - b) * 10) / 10 + ' ℃ 높습니다.'
                  );
                } else if (a < b) {
                  return (
                    '에 비해 ' + Math.round((b - a) * 10) / 10 + ' ℃ 낮습니다.'
                  );
                } else {
                  return '와 같습니다.';
                }
              })()}
            </div>
          </BoxGreen1>
        </div>
      </div>
    );
  };
}
