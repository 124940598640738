export const ConsultingBtn = () => {
  return (
    <div
      className="padding-8-16 flex-row bg-primary-500 border-radius-999 center-y"
      style={{ width: '80px', height: '40px' }}
    >
      <div className="m-b1-16-b color-FFFFFF no-wrap">신청</div>
      <img
        style={{ width: '30px' }}
        src="/asset/images/arrow/ArrowRightWhite20.svg"
        alt=""
      />
    </div>
  );
};
