import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../utilities/auth';
import SideSearch from '../../../../component/browser/sidebar/Search';
import { Tooltip2, TooltipMobile } from '../../../../component/common/tooltip';
import { isMobile, transNum } from '../../../../component/function/function';
import Button from '../../../../component/mobile/Button';
import SignController from '../../../../controller/sign/SignController';
import { PointAlert2 } from '../../../../component/common/pointAlert2';
import ReportDetail4 from '../../../reportResultSale/components/detail/ReportDetail4';
import UsageArea from './UsageArea';
import { clickGoToDetail } from '../../../../hackler';
import './Detail.css';
import UserService from '../../../../service/checkAccount/CheckAccount';
import { useDispatch, useSelector } from 'react-redux';
import ReportService from '../../../../service/report/reportApi';
import { LoadingDot } from '../../../../store/loadingSlice';
import WarnPoint from '../../../../component/common/warnPoint';
import LandUseOption from './landUseOption/LandUseOption';
import LandService from '../../../../service/land/landApi';

const Detail = ({
  setOnDetail,
  onDetail,
  query,
  props,
  DefaultText,
  ItemColumn,
  onReport,
  setOnLoginPopup,
  setRefresh,
  refresh,
  setOnReport,
  onReport2,
  setOnReport2,
  close,
  PriceMobileNext,
  priceDetail,
  setOnReport3,
  setOnInquiry,
  setReportIdx4,
  reportIdx4,
  setLandUseReportIdx,
}: any) => {
  const [tooltipClick, settooltipClick] = useState({
    score: false,
    estimate: false,
    info: false,
  });
  const dispatch = useDispatch();
  const state = useSelector((state: any) => {
    return state.type;
  });
  const tempRef = useRef<HTMLImageElement>(null);
  const [areaType, setAreaType] = useState('P');
  const locationHook = useLocation();
  const navigate = useNavigate();
  const { isLogin } = useAuth();
  const [onPointAlert, setOnPointAlert] = useState(false);
  const temp = useRef<HTMLDivElement>(null);
  const [point, setPoint] = useState(0);
  const [isAvaiableLandUse, setIsAvaiableLandUse] = useState(false);

  const CheckPoint = async () => {
    try {
      const result = await UserService.checkPoint();

      setPoint(result.data.data.freePoint + result.data.data.paidPoint);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    CheckPoint();
  }, []);

  const getLandUseReportIdx = async () => {
    try {
      const res = await LandService.getLandReportIdx(props.currentInfo.pnuCode);
      setReportIdx4(res.result);

      const areaListItem = {
        area: props.query,
        latLng: props.currentMarker.latLng,
        info: props.currentInfo,
      };
      props.setAreaList([areaListItem]);
      sessionStorage.setItem('areaList', JSON.stringify([areaListItem]));
    } catch (e) {
      console.log(e);
    }
  };

  const TitleColumn = ({ title, children }: any) => (
    <div
      className="m-b1-16-m color-text-tertiary center-y "
      style={{ flex: 0.4 }}
    >
      {title} {children}
    </div>
  );

  const removeSlash = (pathname: string) => {
    return pathname.slice(-1) === '/' ? pathname.slice(0, -1) : pathname;
  };

  const currentPathname = removeSlash(locationHook.pathname);

  const isOnAiFarmingPage = currentPathname === '/aiFarming/map';
  const isOnFindLandPage = currentPathname === '/findland/map';
  const isOnPricePage = currentPathname === '/price/map';
  const isOnAnnuityPage = currentPathname === '/annuity/map';
  const isOnLandUsePage = currentPathname === '/landUse/map';

  const renderInquiryButton = () => (
    <div
      className="w-100per padding-12-16 border-E5EAF0 border-radius-8 flex-center hover mb-24 flex-column flex-center"
      style={{ backgroundColor: '#EAF8F2' }}
      onClick={() => {
        isMobile(window.innerWidth)
          ? navigate('/inquiry/write', { state: { address: props.address } })
          : setOnInquiry(true);
      }}
    >
      <span className="font-bold f-size-16 color-text-primary">
        전국 토지·주택 매매 문의
      </span>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 2,
          marginBottom: 8,
        }}
      >
        <img src="/asset/images/icon/red-phone.svg" alt="" />
        <span
          style={{
            fontFamily: 'Pretendard-Medium',
            fontSize: 14,
            color: '#171E26',
            marginRight: 4,
          }}
        >
          02)555-2822
        </span>
        <span
          style={{
            fontFamily: 'Pretendard-Medium',
            fontSize: 14,
            color: 'rgba(23, 30, 38, 0.56)',
          }}
        >
          (평일 10:00~19:00)
        </span>
      </div>
      <div
        style={{
          padding: '8px 24px',
          borderRadius: 100,
          backgroundColor: '#09CE7D',
          fontFamily: 'Pretendard-Bold',
          fontSize: 14,
        }}
        onClick={() => {
          isMobile(window.innerWidth)
            ? navigate('/inquiry/write', { state: { address: props.address } })
            : setOnInquiry(true);
        }}
      >
        온라인 문의
      </div>
    </div>
  );

  const openLogin = (pathName: string) => {
    if (isMobile(window.innerWidth)) {
      navigate('/loginpopup', {
        state: {
          pathname: pathName,
          address: props.address,
        },
      });
    } else {
      setOnLoginPopup(true);
    }
    return;
  };

  // 내 농장 진단 하기 합필일 경우 보고서 보기 함수
  const showAifarmingReportCombinedVersion = () => {
    return () => {
      const index = Number(props.query.split('[')[1].split(']')[0]);
      props.markerArray[index].setMap(null);
      props.setAreaList((prev: any) => {
        prev.splice(index, 1);
        return [...prev];
      });
      props.setMarkerArray((prev: any) => {
        prev.splice(index, 1);
        return [...prev];
      });
      props.setOnMyLandList(true);
      navigate('');
    };
  };
  const state2 = useSelector((state2: any) => {
    return state2.type;
  });

  const [haveReport, setHaveReport] = useState(false);

  const checkReport = async (tab: string) => {
    let type: string = '';

    if (state2.priceType === 'land') {
      type = 'AF';
    } else if (state.priceType === 'house') {
      type = 'AH';
    }

    // else if (locationHook.pathname === '/annuity/map/') {
    //   if (state.priceType === 'land') {
    //     type = 'PF';
    //   } else if (state.priceType === 'house') {
    //     type = 'PA';
    //   }
    // } else if (locationHook.pathname === '/findland/map/') {
    //   type = 'FS';
    // } else if (locationHook.pathname === '/house/map/') {
    //   type = 'HH';
    // }
    try {
      const result = await ReportService.check(
        type,
        decodeURI(locationHook.search.split('=')[1]),
      );

      if (result.isExist === false) {
        setOnPointAlert(true);
      } else {
        showPriceReport();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showAifarmingReport = async () => {
    clickGoToDetail(locationHook.pathname.split('/')[1]);
    if (!isLogin()) {
      return openLogin(currentPathname);
    } else {
      props.fixMarker(
        props.currentMarker,
        props.query,
        props.areaList,
        props.currentInfo,
      );
      sessionStorage.setItem('areaList', JSON.stringify(props.areaList));
      let res2 = await SignController.checkAgriculturalNum(navigate);
      if (res2) {
        if (isMobile(window.innerWidth)) {
          navigate('/operate/report', { state: { address: props.address } });
        } else {
          setRefresh(!refresh);
          setOnReport(true);
        }
      }
    }
  };

  const showAnnuityReport = () => {
    clickGoToDetail(locationHook.pathname.split('/')[1]);
    props.setAreaList([
      {
        area: props.query,
        latLng: props.currentMarker.latLng,
        info: props.currentInfo,
      },
    ]);
    sessionStorage.setItem(
      'areaList',
      JSON.stringify([
        {
          area: props.query,
          latLng: props.currentMarker.latLng,
          info: props.currentInfo,
        },
      ]),
    );
    if (!isLogin()) {
      return openLogin(currentPathname);
    } else {
      isMobile(window.innerWidth)
        ? navigate(`/annuity/input/${props.address}`, {
            state: { address: props.address, type: 'PF' },
          })
        : setOnReport3(true);
    }
  };
  const showFindlandReport = () => {
    clickGoToDetail(locationHook.pathname.split('/')[1]);
    props.setAreaList([
      {
        area: props.query,
        latLng: props.currentMarker.latLng,
        info: props.currentInfo,
      },
    ]);
    sessionStorage.setItem(
      'areaList',
      JSON.stringify([
        {
          area: props.query,
          latLng: props.currentMarker.latLng,
          info: props.currentInfo,
        },
      ]),
    );
    if (!isLogin()) {
      return openLogin(currentPathname);
    } else {
      isMobile(window.innerWidth)
        ? navigate('/findland/report', {
            state: {
              location: props.query,
              info: props.currentInfo,
              originalArea: props.currentInfo.area,
            },
          })
        : setOnReport2(true);
    }
  };
  const priceReport = async () => {
    const data = {
      type:
        state.priceType === 'land'
          ? 'L'
          : state.priceType === 'house'
            ? 'H'
            : '',
      address: props.address,
    };
    try {
      const result = await ReportService.priceReport(data);
      setReportIdx4(result.reportIdx);
      if (isMobile(window.innerWidth)) {
        navigate(`/price/report/${props.address}`, {
          state: {
            address: props.address,
            pnuCode: props.currentInfo.pnuCode,
            reportIdx: result.reportIdx,
          },
        });
      } else {
        PriceMobileNext();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const showPriceReport = async () => {
    dispatch(LoadingDot('W'));

    try {
      // 비동기 함수 호출 대기
      await priceReport();

      clickGoToDetail(locationHook.pathname.split('/')[1]);

      const areaListItem = {
        area: props.query,
        latLng: props.currentMarker.latLng,
        info: props.currentInfo,
      };
      props.setAreaList([areaListItem]);
      sessionStorage.setItem('areaList', JSON.stringify([areaListItem]));

      dispatch(LoadingDot('N'));
    } catch (err) {
      console.error(err);
      dispatch(LoadingDot('N'));
    }
  };

  // console.log('props', props);
  const getReportApi = async () => {
    try {
      const areaListItem = {
        area: props.query,
        latLng: props.currentMarker.latLng || '',
        info: props.currentInfo,
      };
      props.setAreaList([areaListItem]);
      sessionStorage.setItem('areaList', JSON.stringify([areaListItem]));

      if (isMobile(window.innerWidth)) {
        showPriceReport();
      } else {
        PriceMobileNext();
        showPriceReport();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const TitleColumn2 = ({ title, children }: any) => (
    <div
      className="font-medium f-size-16 line-h-24 color-text-tertiary"
      style={{ flex: 1, display: 'flex', alignItems: 'center' }}
    >
      {title} {children}
    </div>
  );
  return (
    <div
      ref={temp}
      className="flex-column gap-16 browser-padding-b-24 browser-bg-FFFFFF browser-padding-b-24 transition"
      style={{
        maxHeight: onDetail
          ? // '651px'
            (window.innerHeight * 80) / 100
          : (tempRef.current?.offsetHeight || 0) +
            (tempRef.current?.offsetTop || 0),
      }}
    >
      {onPointAlert && isOnPricePage && (
        <PointAlert2
          point={point}
          setOnPointAlert={setOnPointAlert}
          getReportApi={showPriceReport}
        />
      )}

      {onPointAlert &&
      (isOnAiFarmingPage || isOnFindLandPage || isOnAnnuityPage) ? (
        point >= 2000 ? (
          <PointAlert2
            point={point}
            setOnPointAlert={setOnPointAlert}
            getReportApi={showPriceReport}
          />
        ) : (
          <WarnPoint setOnPointAlert={setOnPointAlert} point={point} />
        )
      ) : (
        ''
      )}

      <div className="flex-column gap-8">
        <div
          className="flex-row center-x hover"
          onClick={() => setOnDetail(!onDetail)}
        >
          <img
            ref={tempRef}
            className="tablet hover"
            src={
              onDetail
                ? '/asset/images/arrow/ArrowBottom24.svg'
                : '/asset/images/arrow/ArrowTop24.svg'
            }
            alt=""
            onClick={() => {
              setOnDetail(!onDetail);
              // navigate("");
            }}
          />
        </div>
        <div className="flex-column gap-8">
          <SideSearch
            on={false}
            recycle={true}
            location={
              query &&
              (decodeURI(query).slice(0, 4) !== 'list'
                ? decodeURI(query)
                : query.split(']')[1])
            }
          />
          {/* <div
            className={`${
              isMobile(window.innerWidth) ? `padding-y-16` : `padding-x-16`
            } flex `}
          >
            <Link
              to='https://transfarmer.notion.site/e3eb7cc33250494c96a32ec1c0d4b759'
              style={{
                textDecoration: 'none',
                width: '100%',
              }}
            >
              <img
                src='/asset/images/banner/map-banner.gif'
                alt=''
                style={{ width: '100%' }}
              />
            </Link>
          </div> */}
          <div className="flex-column browser-padding-x-16 mt-16">
            <div
              className="m-b1-16-b color-dark-primary w-b1-18-m color-dark-primary"
              style={{ whiteSpace: 'pre' }}
            >
              {props.address.slice(0, 4) === 'list'
                ? props.address.slice(7)
                : props.address}
            </div>
            <div className="flex-row gap-8">
              {props.currentInfo.tagAuction === 'Y' ? (
                <div className="padding-4-8 border-radius-6 bg-EDF6FF">
                  <div className="font-medium f-size-12 line-h-14 color-info">
                    경매물건
                  </div>
                </div>
              ) : (
                ''
              )}
              {props.currentInfo.tagPublicSale === 'Y' ? (
                <div className="padding-4-8 border-radius-6 bg-FFE9E9">
                  <div className="font-medium f-size-12 line-h-14 color-error">
                    공매물건
                  </div>
                </div>
              ) : (
                ''
              )}
              {props.currentInfo.check === 'Y' ? (
                <div className="padding-4-8 border-radius-6 bg-primary-100">
                  <div className="font-medium f-size-12 line-h-14 color-positive">
                    확인매물
                  </div>
                </div>
              ) : (
                ''
              )}
              {props.currentInfo.tagFarmLandBank === 'Y' ? (
                <div className="padding-4-8 border-radius-6 bg-FFFAE9">
                  <div className="font-medium f-size-12 line-h-14 color-notice">
                    지자체매물
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
      {!props.onMyLandList && (
        <div className="w-100per flex-column gap-24 browser-padding-x-16">
          <div
            className="w-100per flex-column gap-16 padding-20-16 bg-F7F8F9 border-radius-8 "
            style={{ position: 'relative' }}
          >
            <div className="flex-column gap-12">
              {(locationHook.pathname.slice(-1) === '/'
                ? locationHook.pathname.slice(0, -1)
                : locationHook.pathname) !== '/annuity/map' &&
                false && (
                  <div className="w-100per flex-row start-y padding-b-10 border-b-171E26 relative">
                    <Tooltip2
                      text="토양·기후 데이터의 AI 분석을 기반으로 해당 농지에서의 작물 재배 가능성을 점수화함"
                      top="30"
                      width
                      left="0"
                      on={tooltipClick.score}
                      off={() => {
                        settooltipClick((prev: any) => {
                          return {
                            ...prev,
                            score: false,
                          };
                        });
                      }}
                    />
                  </div>
                )}
              <div
                className="w-100per flex-row start-y padding-b-12 border-b-171E26 relative nowrap"
                style={{ zIndex: 10 }}
              >
                <>
                  <TitleColumn2 title="AI 농지추정가">
                    <img
                      className="hover"
                      src="/asset/images/warn/Warn20.svg"
                      alt=""
                      onClick={() => {
                        settooltipClick((prev: any) => {
                          return {
                            ...prev,
                            estimate: !tooltipClick.estimate,
                          };
                        });
                      }}
                    />
                  </TitleColumn2>
                  <div className="flex-column">
                    <div className="w-100per flex-row space-between center-y">
                      <div className="gap-5">
                        <div className="font-bold line-h-24 f-size-16 color-primary-500 text-right nowrap ">
                          {isLogin()
                            ? Number(props.currentInfo.estimatedPrice) !== 0
                              ? `${transNum(
                                  Number(props.currentInfo.estimatedPrice),
                                  true,
                                )}원`
                              : '심층분석 문의 필요'
                            : '? 원'}
                        </div>
                        {!isLogin() && (
                          <div
                            className="speech-bubble7 flex-row pointer"
                            style={{
                              position: 'absolute',
                              backgroundColor: '#3D5066',
                              borderRadius: '8px',
                              right: '50px',
                              padding: '8px',
                              marginLeft: 0,
                              marginBottom: 0,
                              top: '5px',
                            }}
                            onClick={() => {
                              isMobile(window.innerWidth)
                                ? navigate('/loginpopup', {
                                    state: {
                                      pathname:
                                        locationHook.pathname.slice(-1) === '/'
                                          ? locationHook.pathname.slice(0, -1)
                                          : locationHook.pathname,
                                      address: props.address,
                                    },
                                  })
                                : setOnLoginPopup(true);
                            }}
                          >
                            <div className="m-b2-14-m color-FFFFFF">
                              로그인하고 확인
                            </div>
                            <img
                              src="/asset/images/arrow/ArrowRightWhite20.svg"
                              alt=""
                            />
                          </div>
                        )}
                        <div className="font-medium f-size-14 line-h-20 color-text-primary nowrap">
                          {isLogin()
                            ? props.currentInfo.estimatedPrice !== 0
                              ? areaType === 'P'
                                ? `${Number(
                                    props.currentInfo.perPyeongPrice,
                                  ).toLocaleString()}원/평`
                                : `${Number(
                                    props.currentInfo.perMeterPrice,
                                  ).toLocaleString()}원/m²`
                              : ''
                            : '?원/평'}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="flex-column"
                    style={{
                      position: 'absolute',
                      zIndex: 100,
                    }}
                  >
                    <div className="relative"></div>
                    <div className="flex-row gap-4 end-x center-y ">
                      <Tooltip2
                        width
                        text="기준가 반올림 및 소수점 이하 절사로 인해 표기에 약간의 차이가 발생할 수 있음"
                        top="60"
                        left="238"
                        on={tooltipClick.estimate}
                        off={() => {
                          settooltipClick((prev: any) => {
                            return {
                              ...prev,
                              estimate: false,
                            };
                          });
                        }}
                      />
                    </div>
                  </div>
                </>
              </div>
              <div className="w-100per flex-row start-y padding-b-10 border-b-171E26">
                <TitleColumn title="실거래가" />
                <div className="flex-column end-x end-y" style={{ flex: 0.6 }}>
                  <DefaultText
                    content={
                      props.currentInfo.transaction ? (
                        <div className="font-bold f-size-16 line-h-24 color-text-primary nowrap">
                          {transNum(Number(props.currentInfo.transaction))}원
                        </div>
                      ) : (
                        <div className="font-bold f-size-16 line-h-24 color-text-primary nowrap">
                          거래 이력 없음
                        </div>
                      )
                    }
                  />
                  <div className="font-medium f-size-14 line-h-20 color-text-primary nowrap">
                    {props.currentInfo.tranApplyAt &&
                      ` ${props.currentInfo.tranApplyAt.slice(
                        0,
                        4,
                      )}.${props.currentInfo.tranApplyAt.slice(
                        4,
                        6,
                      )}.${props.currentInfo.tranApplyAt.slice(6, 8)}`}
                  </div>
                </div>
              </div>
              <div className="w-100per flex-row start-y padding-b-10 border-b-171E26 space-between">
                <TitleColumn title="용도지역" />
                <ItemColumn content={`${props.currentInfo.zoning}`} />
              </div>
              <div className="w-100per flex-row start-y padding-b-10 border-b-171E26 space-between">
                <TitleColumn title="농지면적" />
                <div
                  className="flex-row center-y w-100per end-x"
                  style={{ flex: 1 }}
                >
                  <ItemColumn
                    content={
                      areaType === 'P'
                        ? props.currentInfo.pyeong &&
                          `${props.currentInfo.pyeong.toLocaleString()} 평`
                        : props.currentInfo.area &&
                          `${props.currentInfo.area.toLocaleString()} m²`
                    }
                  />
                  <div
                    className="hover bg-FFFFFF padding-2-8 cneter-x center-y flex-row border-radius-100 border-1-e2e2e2 font-medium f-size-12 line-h-20 color-text-tertiary"
                    onClick={() =>
                      setAreaType((prev) => (prev !== 'P' ? 'P' : 'M'))
                    }
                  >
                    <img src="/asset/images/arrow/Refresh20.svg" alt="" />
                    {areaType === 'P' ? 'm²' : '평'}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-column">
              <div className="flex-row gap-4 end-x center-y  relative">
                <TooltipMobile
                  text={
                    <>
                      <p className="line-h-14">
                        추정가는 트랜스파머가 약 17억개
                        <br />
                        데이터 분석을 기반으로 실거래와 인근
                        <br />
                        유사 거래 사례를 고려하여 해당 농지(주택) 가치를 계산한
                        값입니다.&emsp;&emsp;&emsp;
                      </p>
                    </>
                  }
                  text2={
                    <div>
                      <br />
                      <p className="line-h-14">{'<유의사항>'}</p>
                      <br />
                      <ol className="line-h-14 mt-l-10">
                        <li>
                          추정가는 실제 시장에서 거래되는 가격과는 다소 차이가
                          있을 수 있습니다. 참고용으로만 사용하시길 권장하며,
                          이를 기반으로 행해진 거래 손실 등에 대해 당사는
                          책임지지 않습니다
                        </li>
                        <br />
                        <li>
                          추정가를 무단 크롤링하거나 사용할 경우, 법적 책임을
                          물을 수 있습니다. 사용 관련 문의는{' '}
                          <a
                            href="mailto:biz@transfarmer.co.kr"
                            className="color-FFFFFF"
                          >
                            biz@transfarmer.co.kr
                          </a>
                          로 연락 바랍니다.
                        </li>
                      </ol>
                    </div>
                  }
                  on={tooltipClick.info}
                  top="100"
                  right="-15"
                  width
                  off={() => {
                    settooltipClick((prev: any) => {
                      return {
                        ...prev,
                        info: false,
                      };
                    });
                  }}
                />
                {!isMobile(window.innerWidth) && (
                  <Tooltip2
                    text={
                      <>
                        <p className="line-h-14">
                          추정가는 트랜스파머가 약 17억개
                          <br />
                          데이터 분석을 기반으로 실거래와 인근
                          <br />
                          유사 거래 사례를 고려하여 해당 농지(주택) 가치를
                          계산한 값입니다.&emsp;&emsp;&emsp;
                        </p>
                      </>
                    }
                    text2={
                      <div>
                        <br />
                        <p className="line-h-14">{'<유의사항>'}</p>
                        <br />
                        <ol className="line-h-14 mt-l-10">
                          <li>
                            추정가는 실제 시장에서 거래되는 가격과는 다소 차이가
                            있을 수 있습니다. 참고용으로만 사용하시길 권장하며,
                            이를 기반으로 행해진 거래 손실 등에 대해 당사는
                            책임지지 않습니다
                          </li>
                          <br />
                          <li>
                            추정가를 무단 크롤링하거나 사용할 경우, 법적 책임을
                            물을 수 있습니다. 사용 관련 문의는{' '}
                            <a
                              href="mailto:biz@transfarmer.co.kr"
                              className="color-FFFFFF"
                            >
                              biz@transfarmer.co.kr
                            </a>
                            로 연락 바랍니다.
                          </li>
                        </ol>
                      </div>
                    }
                    top="140"
                    width
                    left="15"
                    on={tooltipClick.info}
                    off={() => {
                      settooltipClick((prev: any) => {
                        return {
                          ...prev,
                          info: false,
                        };
                      });
                    }}
                  />
                )}
                <img
                  className="hover"
                  src="/asset/images/warn/Warn20.svg"
                  alt=""
                  onClick={() => {
                    settooltipClick((prev: any) => {
                      return {
                        ...prev,
                        info: !tooltipClick.info,
                      };
                    });
                  }}
                />
                <div className="font-medium f-size-12 line-h-14 color-text-tertiary">
                  법적한계 안내 보기
                </div>
              </div>
            </div>
          </div>
          {/*가격분석 로그인 상태 일때 왼쪽 영역에 나오는 부분 */}
          {isOnPricePage && isLogin() && (
            <div className="price-basic-info">
              {renderInquiryButton()}
              <div>
                <div
                  style={{
                    height: '16px',
                    background: '#F7F8F9',
                    marginTop: '16px',
                  }}
                />
                <ReportDetail4 data={props.currentInfo} />
              </div>
              <UsageArea data={props.currentInfo} />
              {/* <DistanceDistribution /> */}
            </div>
          )}
          {/* 토지활용 로그인 상태 일때 왼쪽 영역에 나오는 부분 */}
          {isOnLandUsePage && isLogin() && (
            <div className="price-basic-info">
              {renderInquiryButton()}
              <div>
                <div
                  style={{
                    height: '16px',
                    background: '#F7F8F9',
                    marginTop: '16px',
                  }}
                />
                <LandUseOption
                  pnu={props.currentInfo.pnuCode}
                  setIsAvaiableLandUse={setIsAvaiableLandUse}
                />
                <ReportDetail4 data={props.currentInfo} />
              </div>
              <UsageArea data={props.currentInfo} />
              {/* <DistanceDistribution /> */}
            </div>
          )}
          {/* 페이지에 따른 버튼 렌더링 영역 */}
          <div className="flex-column relative center-y gap-16">
            {/* 내 농장 진단 하기에서 합필일 경우  */}
            {/* {isOnAiFarmingPage &&
              decodeURI(props.query).slice(0, 4) === 'list' && (
                <Button
                  description='빼기'
                  on
                  onClick={showAifarmingReportCombinedVersion()}
                ></Button>
              )} */}
            {isOnAiFarmingPage && !onReport && (
              <Button
                on={
                  props.address &&
                  Number(props.currentInfo.estimatedPrice) !== 0
                    ? true
                    : false
                }
                description="내 농장 경영 진단 받기"
                onClick={() => {
                  if (Number(props.currentInfo.estimatedPrice) !== 0) {
                    showAifarmingReport();
                  }
                }}
              ></Button>
            )}
            {isOnFindLandPage && !onReport2 && (
              <Button
                description="농지가치 확인하기"
                on={
                  props.address &&
                  Number(props.currentInfo.estimatedPrice) !== 0
                    ? true
                    : false
                }
                onClick={() => {
                  if (Number(props.currentInfo.estimatedPrice) !== 0) {
                    showFindlandReport();
                  }
                }}
              ></Button>
            )}
            {isOnAnnuityPage && !onReport2 && !priceDetail && (
              <Button
                description="농지연금 예상 수령액 확인하기"
                on={
                  props.address &&
                  Number(props.currentInfo.estimatedPrice) !== 0
                    ? true
                    : false
                }
                onClick={() => {
                  if (Number(props.currentInfo.estimatedPrice) !== 0) {
                    showAnnuityReport();
                  }
                }}
              />
            )}
            {!isOnPricePage && !isOnLandUsePage && renderInquiryButton()}
            {isOnPricePage && !onReport2 && !close && (
              <div className="w-100per" style={{ marginBottom: '16px' }}>
                {isLogin() ? (
                  <Button
                    description="가격 분석 보고서 보기"
                    on={
                      props.address &&
                      Number(props.currentInfo.estimatedPrice) !== 0
                        ? true
                        : false
                    }
                    onClick={() => {
                      if (Number(props.currentInfo.estimatedPrice) !== 0) {
                        checkReport('price');
                      }
                    }}
                  />
                ) : (
                  <div className="w-100per flex-column gap-16 mb-24">
                    <Button
                      description="가격 분석 보고서 보기"
                      on={
                        props.address &&
                        transNum(Number(props.currentInfo.estimatedPrice), true)
                          ? true
                          : false
                      }
                      onClick={async () => {
                        clickGoToDetail(locationHook.pathname.split('/')[1]);
                        try {
                          const areaListItem = {
                            area: props.query,
                            latLng: props.currentMarker.latLng,
                            info: props.currentInfo,
                          };
                          props.setAreaList([areaListItem]);
                          sessionStorage.setItem(
                            'areaList',
                            JSON.stringify([areaListItem]),
                          );
                          openLogin(currentPathname);
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                    />
                    {renderInquiryButton()}
                  </div>
                )}
              </div>
            )}
            {isOnLandUsePage && !onReport2 && !close && (
              <div className="w-100per" style={{ marginBottom: '16px' }}>
                {isLogin() ? (
                  <Button
                    description="토지 활용 보고서 보기"
                    on={
                      isAvaiableLandUse &&
                      props.address &&
                      Number(props.currentInfo.estimatedPrice) !== 0
                        ? true
                        : false
                    }
                    onClick={() => {
                      if (isAvaiableLandUse) {
                        PriceMobileNext();
                        getLandUseReportIdx();
                      }
                    }}
                  />
                ) : (
                  <div className="w-100per flex-column gap-16 mb-24">
                    <Button
                      description="토지 활용 보고서 보기"
                      on={
                        props.address &&
                        transNum(Number(props.currentInfo.estimatedPrice), true)
                          ? true
                          : false
                      }
                      onClick={async () => {
                        clickGoToDetail(locationHook.pathname.split('/')[1]);
                        try {
                          const areaListItem = {
                            area: props.query,
                            latLng: props.currentMarker.latLng,
                            info: props.currentInfo,
                          };
                          props.setAreaList([areaListItem]);
                          sessionStorage.setItem(
                            'areaList',
                            JSON.stringify([areaListItem]),
                          );
                          openLogin(currentPathname);
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Detail;
