import { TopNav2 } from '../../../component/mobile/Topnav';
import { useState, useEffect, useRef } from 'react';
import PurchaseType from './input/SelectBox';

import Button from '../../../component/mobile/Button';
import SelectBox from './input/SelectBox';
import HousePrice from './input/new/Price';
import HouseService from '../../../service/house/landApi';
import LandService from '../../../service/land/landApi';
import { useLocation, useNavigate } from 'react-router-dom';
import Pyeong from './input/new/Pyeong';
import { isMobile } from '../../../component/function/function';
import { useDispatch } from 'react-redux';
import { LoadingDot } from '../../../store/loadingSlice';
import { PointAlert2 } from '../../../component/common/pointAlert2';
import { min, set } from 'lodash';
import { PointColumn } from '../../../component/common/pointColumn';
import UserService from '../../../service/checkAccount/CheckAccount';
// import WarnPoint from '../../../component/common/warnPoint';
import useDidMountEffect from '../../../hooks/UseDidMountEffect';
import { type } from 'os';
import WarnPoint from '../../../component/common/warnPoint';
import ReportService from '../../../service/report/reportApi';

const LandValueReport = ({
  setReportIdx,
  setClose,
  estimatedHousingPrice,
  setSelectedDesignFloor,
  selectedDesignFloor,
  address,
}: any) => {
  const [point, setPoint] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [BtnOn, setBtnOn] = useState(false);
  const [onPointAlert, setOnPointAlert] = useState(false);
  const [selectedDesignFloorMo, setSelectedDesignFloorMo] = useState(1);

  const isHave = [
    { title: '네', type: 'Y' },
    { title: '아니오', type: 'N' },
  ];
  const isFarm = [
    { title: '농촌', type: 'F' },
    { title: '도시', type: 'C' },
  ];
  const isBuild = [
    { title: '신축', type: 'N' },
    { title: '리모델링', type: 'R' },
  ];
  const [type1, setType1] = useState('N');
  const [type2, setType2] = useState('N');

  const [type3, setType3] = useState('F');
  const [type4, setType4] = useState('N');
  const [type5, setType5] = useState('N');
  const [selectedDesignIdx, setSelectedDesignIdx] = useState(0);

  const [selectedPyeong, setSelectedPyeong] = useState(0);
  const [onDesignList, setOnDesignList] = useState(false);

  const [onResult, setOnResult] = useState(false);

  const locationHook = useLocation();
  const [query, setQuery] = useState('');
  const [landInfo, setLandInfo] = useState({
    area: 0,
    pnuCode: '',
    pyeong: 1,
    stdBuildingToLandRatio: 0,
    houseArea: 40.3,
    housePyeong: 12.2,
    stdFloorAreaRatio: 0,
    totalFloorAreaPyeong: 0,
  });

  const protoHouse = {
    authFile:
      'houseDesign/rural/농림-10-27-나-2/[2010] 농림-10-27-나-2(인허가용).zip',
    buildingArea: 27.4,
    buildingToLandRatio: 0.08,
    drawingFile: null,
    floor: 1,
    floorAreaRatio: 0.08,
    floorPlan: 'houseDesign/rural/농림-10-27-나-2/floorPlan.JPG',
    landPyeong: 316.1,
    mainImg: 'houseDesign/rural/농림-10-27-나-2/image1.jpg',
    readingFile:
      'houseDesign/rural/농림-10-27-나-2/[2010] 농림-10-27-나-2(열람용).zip',
    structure: 'E',
    subImg1: 'houseDesign/rural/농림-10-27-나-2/image2.jpg',
    subImg2: '',
    designName: '',
    designIdx: 0,
    totalArea: 78,
    stdBuildingToLandRatio: 0,
  };
  const [currentHouse, setCurrentHouse] = useState(protoHouse);
  useEffect(() => {
    if (
      locationHook.search.split('=')[1] &&
      !locationHook.search.includes('n_media')
    ) {
      setQuery(decodeURI(locationHook.search.split('=')[1]));
    } else {
      setQuery('');
    }
  }, [locationHook]);
  const [landPyeong, setLandPyeong] = useState(0);
  const [selectedStdBuildingToLandRatio, setSelectedStdBuildingToLandRatio] =
    useState(0);
  useEffect(() => {
    let q = query;

    const info = async (query: string) => {
      const type = '내 집 짓기';
      try {
        if (query) {
          dispatch(LoadingDot('Y'));
          const res2 = await LandService.infoPension(query);
          const res3 = await HouseService.info(query, type);

          setLandPyeong(res3.result.landPyeong);
          // setMaxPyeongValue(
          //   (res3.result.landPyeong * res3.result.stdBuildingToLandRatio) / 100
          // );

          if (
            (res3.result.landPyeong *
              res3.result.stdBuildingToLandRatio *
              currentHouse.floor) /
              100 <
            23.6
          ) {
            setPyeongValue(0);
          }
          setLandInfo({
            area: res2.result.area,
            pnuCode: res2.result.pnuCode,
            pyeong: res2.result.pyeong,
            housePyeong: res3.result.housePyeong,
            houseArea: res3.result.houseArea,
            stdBuildingToLandRatio: res3.result.stdBuildingToLandRatio,
            stdFloorAreaRatio: res3.result.stdFloorAreaRatio,
            totalFloorAreaPyeong: res3.result.totalFloorAreaPyeong,
          });
        }
        dispatch(LoadingDot('N'));
      } catch (err) {
        console.log(err);
        dispatch(LoadingDot('N'));
      }
    };
    info(q);
  }, [query]);

  const [houseSelected, setHouseSelected] = useState(false);
  const [pyeongValue, setPyeongValue] = useState(23.6);
  const [minPyeongValue, setMinPyeongValue] = useState(23.6);
  const [maxPyeongValue, setMaxPyeongValue] = useState(81);
  const CheckPoint = async () => {
    try {
      const result = await UserService.checkPoint();

      setPoint(result.data.data.freePoint + result.data.data.paidPoint);
    } catch (error) {
      console.log(error);
    }
  };

  const save = async () => {
    if (!houseSelected && selectCunsult === 'Y') {
      alert('설계안을 선택해주세요');
    } else {
      try {
        dispatch(LoadingDot('Y'));
        if (!isMobile(window.innerWidth)) {
          setClose(false);
        }
        const result = await HouseService.save(
          type5,
          landInfo.pnuCode,
          landInfo.area,
          // currentHouse.designIdx,
          selectCunsult === 'Y' && selectedDesignIdx,
          type1,
          type2,
          type1 === 'Y' ? null : type4,
          // type1 === 'Y' ? null : type3,
          type3 === 'C' ? 'N' : 'Y',
          pyeongValue,
          // currentHouse.floor,
          selectCunsult === 'Y' && isMobile(window.innerWidth)
            ? selectedDesignFloorMo
            : selectedDesignFloor,
          estimatedHousingPrice,
        );

        if (result.data.success) {
          if (isMobile(window.innerWidth)) {
            navigate(`/house/report/${result.data.reportIdx}`, {
              state: { selectedDesignFloor: selectedDesignFloorMo },
            });
          } else {
            setClose(true);
            setReportIdx(result.data.reportIdx);
          }
        }
        dispatch(LoadingDot('N'));
      } catch (err: any) {
        dispatch(LoadingDot('N'));
        console.log(err);
      }
    }
  };
  const [selectCunsult, setSelectCunsult] = useState('Y');
  // useEffect(() => {
  //   if (selectedDesignFloor === 2) {
  //     setMaxPyeongValue(
  //       Math.min(
  //         ((landPyeong * landInfo.stdBuildingToLandRatio) / 100) * 2,
  //         (landPyeong * landInfo.stdFloorAreaRatio) / 100
  //       )
  //     );
  //   } else {
  //     setMaxPyeongValue((landPyeong * landInfo.stdBuildingToLandRatio) / 100);
  //   }
  // }, [selectedDesignIdx]);
  useEffect(() => {
    CheckPoint();
  }, []);

  const checkReport = async () => {
    try {
      const result = await ReportService.check(
        'HH',
        isMobile(window.innerWidth) ? locationHook.state.address : address,
      );
      console.log('reuslt', result);

      if (result.isExist === false) {
        setOnPointAlert(true);
      } else {
        save();
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setPyeongValue(
      Math.round(Number(currentHouse.totalArea * 0.3025) * 10) / 10,
    );
    if (selectCunsult === 'N') {
      setPyeongValue(type5 === 'R' ? landInfo.totalFloorAreaPyeong : 23.6);
      // setMinPyeongValue(type5 === "R" ? landInfo.housePyeong : 23.6);
      if (type5 === 'R') {
        setMinPyeongValue(landInfo.totalFloorAreaPyeong);
        setMaxPyeongValue(landInfo.pyeong);
        setPyeongValue((landInfo.totalFloorAreaPyeong + landInfo.pyeong) / 2);
      } else if (type5 === 'N') {
        setMinPyeongValue(0);
        setMaxPyeongValue(landInfo.pyeong);
        setPyeongValue(landInfo.pyeong / 2);
      }
    } else {
      if (
        (isMobile(window.innerWidth)
          ? selectedDesignFloorMo
          : selectedDesignFloor) === 1
      ) {
        if (type5 === 'R') {
          setMinPyeongValue(landInfo.totalFloorAreaPyeong);
          setMaxPyeongValue(landInfo.totalFloorAreaPyeong);
        } else if (type5 === 'N') {
          setMinPyeongValue(selectedPyeong);
          setMaxPyeongValue(
            (landInfo.pyeong * currentHouse.stdBuildingToLandRatio) / 100,
          );
        }
      } else if (
        (isMobile(window.innerWidth)
          ? selectedDesignFloorMo
          : selectedDesignFloor) === 2
      ) {
        if (type5 === 'R') {
          setMinPyeongValue(landInfo.totalFloorAreaPyeong);
          setMaxPyeongValue(landInfo.totalFloorAreaPyeong * 2);
        } else if (type5 === 'N') {
          setMinPyeongValue(selectedPyeong);
          setMaxPyeongValue(
            ((landInfo.pyeong * currentHouse.stdBuildingToLandRatio) / 100) * 2,
          );
        }
      }
    }
  }, [selectCunsult, type5, selectedDesignIdx]);
  return (
    <div
      className={`flex-column w-100per bg-light-gray  browser-h-100per ${
        type1 ? `h-100per` : `h-100vh`
      }`}
    >
      {onPointAlert ? (
        point >= 2000 ? (
          <PointAlert2
            point={point}
            setOnPointAlert={setOnPointAlert}
            save2={save}
          />
        ) : (
          <WarnPoint setOnPointAlert={setOnPointAlert} point={point} />
        )
      ) : (
        ''
      )}
      <div className="flex-column bg-light-gray border-b-171E2610 h-100per padding-b-100 ">
        <TopNav2 text="집 설계 유형 입력" close back />
        <div className="flex-column w-100per h-100per gap-16 ">
          <div className="flex-column gap-6 padding-24-16-8-16 ">
            <div className="font-bold f-size-20 line-h-29 color-text-primary">
              내 조건 입력하기
            </div>
            <div className="flex-row space-between center-y tablet">
              <div className="flex-row gap-5 no-wrap center-y">
                <div className="font-medium f-size-11 line-h-12 color-text-secondary">
                  {query}
                </div>
              </div>
            </div>
          </div>

          <div className="flex-column padding-x-16 gap-16 ">
            {query && <PointColumn type="HH" address={query} />}
            <SelectBox
              title="1. 해당 주택 보유 여부"
              type={type1}
              setType={setType1}
              arr={isHave}
              on
            />
            {type1 === 'N' && (
              <SelectBox
                title="2. 거주 지역"
                type={type3}
                setType={setType3}
                arr={isFarm}
                on
              />
            )}
            {type1 === 'N' && (
              <SelectBox
                title="3. 유주택자 여부"
                type={type2}
                setType={setType2}
                arr={isHave}
                on
              />
            )}
            <SelectBox
              title="4. 귀농희망 여부"
              type={type4}
              setType={setType4}
              arr={isHave}
              on={type1 === 'N'}
            />
            <SelectBox
              title={type1 === 'Y' ? '2. 건축 방식' : '5. 건축 방식'}
              type={type5}
              setType={setType5}
              arr={isBuild}
              on={type1}
            />
            {type1 ? (
              <>
                <HousePrice
                  title={
                    (type1 === 'Y' ? '3.' : '6.') + ' 건축 설계안 선택 여부'
                  }
                  onDesignList={onDesignList}
                  setOnDesignList={setOnDesignList}
                  landInfo={landInfo}
                  currentHouse={currentHouse}
                  setCurrentHouse={setCurrentHouse}
                  setPyeongValue={setPyeongValue}
                  setMaxPyeongValue={setMaxPyeongValue}
                  setMinPyeongValue={setMinPyeongValue}
                  type5={type5}
                  setHouseSelected={setHouseSelected}
                  houseSelected={houseSelected}
                  selectCunsult={selectCunsult}
                  setSelectCunsult={setSelectCunsult}
                  setSelectedDesignFloor={
                    isMobile(window.innerWidth)
                      ? setSelectedDesignFloorMo
                      : setSelectedDesignFloor
                  }
                  setSelectedDesignIdx={setSelectedDesignIdx}
                  setSelectedPyeong={setSelectedPyeong}
                  setSelectedStdBuildingToLandRatio={
                    setSelectedStdBuildingToLandRatio
                  }
                />
                <Pyeong
                  title={type1 === 'Y' ? '4. 평형' : '7. 평형'}
                  selectedDesignFloor={
                    isMobile(window.innerWidth)
                      ? selectedDesignFloorMo
                      : selectedDesignFloor
                  }
                  pyeongValue={pyeongValue}
                  landPyeong={landPyeong}
                  setPyeongValue={setPyeongValue}
                  minPyeongValue={minPyeongValue}
                  maxPyeongValue={maxPyeongValue}
                  currentHouse={currentHouse}
                  landInfo={landInfo}
                  stdBuildingToLandRatio={landInfo.stdBuildingToLandRatio}
                  stdFloorAreaRatio={landInfo.stdFloorAreaRatio}
                  selectedDesignIdx={selectedDesignIdx}
                />
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 w-100per bg-FFFFFF browser-w-360">
        <div className="padding-16 ">
          <Button
            description="내 집 건축보고서 보기"
            on={
              type1 &&
              (type1 === 'Y' ? type1 : type1 && type2 && type3 && type4) &&
              (houseSelected || selectCunsult === 'N')
            }
            onClick={() => {
              if (
                type1 &&
                (type1 === 'Y' ? type1 : type1 && type2 && type3 && type4) &&
                (houseSelected || selectCunsult === 'N')
              ) {
                checkReport();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default LandValueReport;
