import logo from './../../asset/images/main/footerLogo.svg';
import instagram from './../../asset/images/main/instagram.svg';
import facebook from './../../asset/images/main/facebook.svg';
import youtube from './../../asset/images/main/youtube.svg';

import './../../asset/styles/SaleInfo.css';
import { Navigate, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import InquiryWrite from '../../pages/inquiry/InquiryWrite';
import { isMobile } from '../function/function';
const Banner = () => {
  const [inquiryPopUp, setInquiryPopUp] = useState(false);
  const navigate = useNavigate();
  const handleSendMail = (type: 'A' | 'B') => {
    if (type === 'A') {
      window.location.href = 'mailto:biz@transfarmer.co.kr';
    } else if (type === 'B') {
      window.location.href = 'mailto:biz@transfarmer.co.kr';
    }
  };

  return (
    <div className="mobile-footer-wrap">
      {inquiryPopUp && <InquiryWrite setOnInquiry={setInquiryPopUp} footer />}
      <div className="flex-column gap-20  borderbottom-1px-e8ebe6 padding-b-20">
        <div className="flex-row gap-10">
          <img src={logo} />
          <div
            className="font-bold"
            style={{
              color: '#a0a0a0',
            }}
          >
            트랜스파머
          </div>
        </div>
        <div className="footer-nav-text-box">
          <div className="flex-row gap-24 flex-wrap relative">
            <div
              className="m-b2-14-m color-dark-secondary"
              onClick={() => {
                navigate('/profile');
              }}
            >
              회사소개
            </div>
            <div
              className="m-b2-14-m color-dark-secondary"
              onClick={() => {
                navigate('/newsroom');
                // alert('준비중입니다.');
              }}
            >
              뉴스룸
            </div>
            <div
              className="absolute w-10 h-10 flex-center bg-increase border-radius-999"
              style={{ top: '0px', left: `110px` }}
            >
              <div className="font-bold f-size-8 color-FFFFFF">N</div>
            </div>
            <div
              className="m-b2-14-m color-dark-secondary"
              onClick={() =>
                // handleSendMail("A")

                isMobile(window.innerWidth)
                  ? navigate('/inquiry/write', { state: { footer: true } })
                  : setInquiryPopUp(true)
              }
            >
              고객문의
            </div>
            <div
              className="m-b2-14-m color-dark-secondary"
              onClick={() =>
                // handleSendMail("A")

                isMobile(window.innerWidth)
                  ? navigate('/inquiry/write')
                  : setInquiryPopUp(true)
              }
            >
              사업문의
            </div>
          </div>
          <div className="flex-row gap-24 flex-wrap">
            <div
              className="m-b2-14-m color-dark-secondary"
              onClick={() => {
                window.open(
                  'https://transfarmer.notion.site/20eba2a06fd246d1a1fe45b2e9d9d835?pvs=4',
                );
              }}
            >
              자주 묻는 질문
            </div>
            <div
              className="m-b2-14-m color-dark-secondary"
              onClick={() => {
                navigate('/terms/service');
              }}
            >
              이용약관
            </div>
            <div
              className="m-b2-14-b color-dark-secondary"
              onClick={() => {
                navigate('/terms/privacy_policy');
              }}
            >
              개인정보 처리방침
            </div>
          </div>
          <div
            className="location-policy-nav m-b2-14-b color-dark-secondary"
            onClick={() => {
              navigate('/terms/location');
            }}
          >
            위치기반서비스 이용약관
          </div>
        </div>
      </div>
      <div className="flex-column gap-8">
        <div className="font-regular f-size-12 line-h-20 color-text-tertiary">
          대표이사 : 김기현 &nbsp; | &nbsp; 사업자등록번호 : 507-87-02631 &nbsp;
          | &nbsp; 통신판매업신고번호:2024-서울서초-0689
        </div>
        <div className="font-regular f-size-12 line-h-12 color-text-tertiary">
          서울특별시 서초구 매헌로 24 NH농협 디지털혁신캠퍼스 5층 트랜스파머㈜
        </div>
        <div className="font-regular f-size-12 line-h-15 color-text-tertiary">
          트랜스파머에서 이용하는 유료 서비스 상품의 결제와 환불, 민원에 대한
          처리는 트랜스파머 주식회사에서 진행합니다.
        </div>
        <div className="font-regular f-size-12 line-h-20 color-text-tertiary">
          고객센터 : 02)555-2822 (평일 09:00~18:00) | E-mail :
          biz@transfarmer.co.kr
        </div>
        <div className="font-regular f-size-12 line-h-12 color-text-tertiary">
          COPYRIGHT ⓒ 2023 TransFarmer. ALL RIGHTS RESERVED.
        </div>
      </div>
      <div className="flex-row gap-8 center-y">
        <img
          src="/asset/images/icon/facebook.svg"
          alt=""
          onClick={() => {
            window.open(
              'https://ko-kr.facebook.com/people/%ED%8A%B8%EB%9E%9C%EC%8A%A4%ED%8C%8C%EB%A8%B8/61554542764224/',
            );
          }}
        />
        <img
          src="/asset/images/icon/blog.svg"
          alt=""
          onClick={() => {
            window.open('https://blog.naver.com/transfarmer2023');
          }}
        />
      </div>
    </div>
  );
};

export default Banner;
