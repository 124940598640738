import Request from '../../@api/request';

// import { request } from "../service"
// const baseURL = "http://localhost:3333/";
// const baseURL = "https://back.transfarmer.kr/";
const baseURL = `${process.env.REACT_APP_HOST}`;
export const request = new Request(baseURL);

export default class HouseService {
  static info = async (address: string, service: string) => {
    if (address) {
      const result = await request.get(
        `house?address=${address}&service=${service}`,
      );
      return result.data;
    }
  };

  static view = async (designIdx: string, pnuCode: string) => {
    const result = await request.get(
      'house/design?designIdx=' + designIdx + '&pnuCode=' + pnuCode,
    );
    return result.data;
  };

  static list = async (limit: number, offset: number, order: string) => {
    if (order === '') {
      const result = await request.get(
        'house/design/list?limit=' + limit + '&offset=' + offset,
      );
      return result.data;
    } else {
      const result = await request.get(
        'house/design/list?limit=' +
          limit +
          '&offset=' +
          offset +
          '&order=' +
          order,
      );
      return result.data;
    }
  };

  static download = async (link: string) => {
    const result = await request.get('download/' + link);
    return result;
  };

  static report = async (
    pnuCodes: any,
    designIdx: any,
    type: any,
    ownMoney: any,
    loanFund: any,
    constructionCost: any,
    pyeong: any,
  ) => {
    const data = {
      pnuCodes,
      designIdx,
      type,
      ownMoney,
      loanFund,
      constructionCost,
      pyeong,
    };
    const result = await request.post('land/report', data);
    return result;
  };

  static save = async (
    type: any,
    pnuCode: any,
    landArea: any,
    designIdx: any,
    ownHouseYN: any,
    haveHouseYN: any,
    returnToFarmYN: any,
    liveInFarmYN: any,
    pyeong: any,
    floor: any,
    estimatedHousingPrice: any,
  ) => {
    const data = {
      type,
      pnuCode,
      landArea,
      designIdx,
      ownHouseYN,
      haveHouseYN,
      returnToFarmYN,
      liveInFarmYN,
      pyeong,
      floor,
      estimatedHousingPrice,
    };
    const result = await request.post('house', data);
    return result;
  };

  static saveHouseLoan = async (
    type: any,
    pnuCode: any,
    loanOfHouseCost: any,
  ) => {
    const data = { type, pnuCode, loanOfHouseCost };
    const result = await request.post('house', data);
    return result;
  };

  static saveConstructionLoan = async (
    type: any,
    pnuCode: any,
    loanOfConstructionCost: any,
  ) => {
    const data = { type, pnuCode, loanOfConstructionCost };
    const result = await request.post('house', data);
    return result;
  };

  static load = async (reportIdx: string | number) => {
    const result = await request.get('house/report?reportIdx=' + reportIdx);
    return result.data;
  };
  static report2 = async (data: any) => {
    const result = await request.patch('house/report', data);
    return result.data;
  };
}
