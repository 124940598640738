import { RowSectionTitle } from './Row';
import { TextSectionTitle } from './Text';

export default class LandInfo {
  static BuildingInfo = ({
    data,
    RowTable,
    title,
  }: {
    data: any;
    RowTable: any;
    title: any;
  }) => {
    return (
      <div className="padding-b-24 browser-bg-FFFFFF">
        <RowSectionTitle>
          <TextSectionTitle>{title}</TextSectionTitle>
        </RowSectionTitle>
        <div className="flex-column">
          <div className="padding-24-16 gap-16 flex-column">
            <div className="flex-row gap-24">
              <RowTable
                title="연면적"
                value={
                  data && data.totalFloorArea
                    ? `${data.totalFloorArea} m²`
                    : '-m²'
                }
              />
              <RowTable
                title="건물층수"
                value={data && data.floor ? `${data.floor}층` : '-층'}
              />
            </div>
            <div className="flex-row gap-24">
              <RowTable
                title="건폐율"
                value={`${
                  Math.round(data && data.buildingToLandRatio * 10) / 10
                }%`}
              />
              <RowTable
                title="용적률"
                value={`${Math.round(data && data.floorAreaRatio * 10) / 10}%`}
              />
            </div>
            <div className="flex-row gap-24">
              <RowTable
                title="주용도"
                value={data && data.mainPurposeCodeName}
              />
            </div>
            <div className="flex-row gap-24">
              <RowTable
                title="건물구조"
                value={data && data.structureCodeName}
              />
            </div>
            <div className="flex-row gap-24">
              <RowTable
                title="노후년도"
                value={
                  data && data.year_int
                    ? `${data.year_int}년 (노후 ${
                        Number(new Date().getFullYear()) - Number(data.year_int)
                      }년)`
                    : '건물 정보가 없습니다.'
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
}
