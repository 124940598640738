import { styled } from 'styled-components';

export const SurveyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  gap: 50px;
  width: 90%;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: start;
  align-items: start;
  width: 100%;
`;
