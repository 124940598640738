import { useEffect, useState } from 'react';
import {
  RowTableGreenBold,
  RowSectionTitle,
  RowTableValueBold,
} from '../../../../../component/report/Row';
import { TextSectionTitle } from '../../../../../component/report/Text';
import DoughnutChart2 from '../../../../../component/mobile/DoughnutChart2';
import { transNum } from '../../../../../component/function/function';
import { BoxGreen1 } from '../../../../../component/report/Box';
import SignController from '../../../../../controller/sign/SignController';
import { useSelector } from 'react-redux';

interface data {
  SGA: number;
  avgFarmEquipmentCost: number;
  avgLaborCost: number;
  avgLandRentalCost: number;
  avgMaterialCost: number;
  avgOrchardCost: number;
  avgSGA: number;
  avgWaterHeatingCost: number;
  farmEquipmentCost: number;
  laborCost: number;
  landRentalCost: number;
  materialCost: number;
  orchardCost: number;
  waterHeatingCost: number;
}

const ReportDetail4 = ({ data }: { data: data }) => {
  const state = useSelector((state: any) => {
    return state.type;
  });
  const [name, setName] = useState('');
  const x =
    data.laborCost +
    data.materialCost +
    data.waterHeatingCost +
    data.farmEquipmentCost +
    data.SGA +
    data.landRentalCost +
    data.orchardCost;

  const y = data.laborCost + data.materialCost;
  const z =
    data.waterHeatingCost +
    data.farmEquipmentCost +
    data.SGA +
    data.landRentalCost +
    data.orchardCost;

  const costArr = [
    {
      color: '#FF497A',
      text: '인건비',
      rate: (data.laborCost * 100) / x,
      value: data.laborCost,
    },
    {
      color: '#F59F00',
      text: '재료비',
      rate: (data.materialCost * 100) / x,
      value: data.materialCost,
    },
    {
      color: '#1E9B6B',
      text: '수도 광열비',
      rate: (data.waterHeatingCost * 100) / x,
      value: data.waterHeatingCost,
    },
    {
      color: '#F885AD',
      text: '농구비',
      rate: (data.farmEquipmentCost * 100) / x,
      value: data.farmEquipmentCost,
    },
    {
      color: '#1D74F7',
      text: '기타 판매관리비',
      rate: (data.SGA * 100) / x,
      value: data.SGA,
    },
    {
      color: '#22B8CF',
      text: '농지 임차료',
      rate: (data.landRentalCost * 100) / x,
      value: data.landRentalCost,
    },
    {
      color: '#9775FA',
      text: '과수원 조성비',
      rate: (data.orchardCost * 100) / x,
      value: data.orchardCost,
    },
    // { color: "#F885AD", text: "대출 상환금액", rate: "", value: data. },
  ];

  if (!(costArr[5].value > 0)) {
    costArr.splice(5, 1);
  }
  if (!(costArr[5].value > 0)) {
    costArr.splice(5, 1);
  }

  const sortArr = costArr.sort((a, b) => b.value - a.value);

  useEffect(() => {
    SignController.getName(setName);
  }, []);

  const DataCell = () => (
    <div className="flex-row gap-16 end-x">
      <div className="flex-row center-y gap-8">
        <div
          style={{
            width: '8px',
            height: '8px',
            borderRadius: '100%',
            background: '#EAF8F2',
          }}
        ></div>
        <div className="font-medium f-size-12 line-h-14 color-text-tertiary">
          지역 평균
        </div>
      </div>
      <div className="flex-row center-y gap-8">
        <div
          style={{
            width: '8px',
            height: '8px',
            borderRadius: '100%',
            background: '#09CE7D',
          }}
        ></div>
        <div className="font-bold f-size-12 line-h-14 color-text-secondary">
          내 농장
        </div>
      </div>
    </div>
  );

  const GraphSet = ({ avg, pri }: { avg: number; pri: number }) => (
    <div className="padding-4-18 gap-16 flex-column">
      <DataCell />
      <div className="flex-column gap-4" style={{ paddingBottom: '38px' }}>
        <div
          className="w-100per border-radius-100 bg-primary-100 relative center-y padding-x-8"
          style={{
            height: '24px',
            width: pri > avg ? `${(avg * 212) / pri}px` : '212px',
          }}
        >
          <div className="font-bold f-size-12 line-h-14 color-171E2640">
            {`${transNum(avg)}원`}
          </div>
          <div
            className="absolute"
            style={{
              right: 0,
              top: 0,
              zIndex: 10,
              height: '52px',
              borderRight: '1px #5E7188 dashed',
            }}
          ></div>
        </div>
        <div
          className="w-100per border-radius-100 bg-primary-400 relative center-y padding-x-8"
          style={{
            height: '24px',
            width: pri < avg ? `${(pri * 212) / avg}px` : '212px',
          }}
        >
          <div
            className="absolute  bg-primary-500 center-y"
            style={{
              right: 0,
              borderTopRightRadius: '100px',
              borderBottomRightRadius: '100px',
              height: '24px',
              width: pri > avg ? `${212 - (avg * 212) / pri}px` : 0,
            }}
          ></div>
          <div
            className="absolute"
            style={{
              right: 0,
              bottom: 0,
              zIndex: 10,
              height: '52px',
              borderRight: '1px #5E7188 dashed',
            }}
          >
            <div
              className="padding-4-8 bg-blue-gray-700 border-radius-8 absolute"
              style={{ top: 0, left: '-24px' }}
            >
              <div className="font-medium f-size-14 line-h-20 color-FFFFFF">
                {Math.round((pri * 100) / avg)}%
              </div>
            </div>
          </div>
          <div className="font-bold f-size-12 line-h-14 color-text-tertiary">
            {`${transNum(pri)}원`}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="padding-b-24">
      <RowSectionTitle>
        <TextSectionTitle>4. 예상 비용</TextSectionTitle>
      </RowSectionTitle>
      <div className="padding-16-16-0">
        <RowTableGreenBold title="연간 운영비" value={`${transNum(x)}원`} />
      </div>
      <div className="padding-24-16 flex-row center-y space-between">
        <div className="w-50per center-y">
          <DoughnutChart2 list={costArr} />
        </div>
        <div className="flex-column gap-8" style={{ width: '144px' }}>
          {costArr.map((el) => (
            <div className="flex-row space-between">
              <div className="flex-row center-y gap-8">
                <div
                  style={{
                    width: '8px',
                    height: '8px',
                    borderRadius: '100%',
                    background: el.color,
                  }}
                ></div>
                <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                  {el.text}
                </div>
              </div>
              <div className="font-medium f-size-14 line-h-20 color-text-primary">
                {Math.round(el.rate * 10) / 10}%
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="padding-16">
        <RowTableValueBold title="1) 매출원가" value={`${transNum(y)}원`} />
      </div>
      <div className="padding-x-16">
        <div className="font-medium f-size-16 line-h-24 color-text-secondary">
          인건비
        </div>
      </div>
      <GraphSet avg={data.avgLaborCost} pri={data.laborCost} />
      <div className="padding-x-16">
        <div className="font-medium f-size-16 line-h-24 color-text-secondary">
          재료비
        </div>
      </div>
      <GraphSet avg={data.avgMaterialCost} pri={data.materialCost} />

      <div className="padding-16">
        <RowTableValueBold title="2) 판매관리비" value={`${transNum(z)}원`} />
      </div>
      <div className="padding-x-16">
        <div className="font-medium f-size-16 line-h-24 color-text-secondary">
          수도 광열비
        </div>
      </div>
      <GraphSet avg={data.avgWaterHeatingCost} pri={data.waterHeatingCost} />
      <div className="padding-x-16">
        <div className="font-medium f-size-16 line-h-24 color-text-secondary">
          농구비
        </div>
      </div>
      <GraphSet avg={data.avgFarmEquipmentCost} pri={data.farmEquipmentCost} />
      <div className="padding-x-16">
        <div className="font-medium f-size-16 line-h-24 color-text-secondary">
          기타 판매관리비
        </div>
      </div>
      <GraphSet avg={data.avgSGA} pri={data.SGA} />

      {data.landRentalCost !== 0 ? (
        <div className="flex-column">
          <div className="padding-x-16">
            <div className="font-medium f-size-16 line-h-24 color-text-secondary">
              농지 임차료
            </div>
          </div>
          <GraphSet avg={data.avgLandRentalCost} pri={data.landRentalCost} />
        </div>
      ) : (
        ''
      )}
      {data.orchardCost !== 0 ? (
        <div className="flex-column">
          <div className="padding-x-16">
            <div className="font-medium f-size-16 line-h-24 color-text-secondary">
              과수원 조성비
            </div>
          </div>
          <GraphSet avg={data.avgOrchardCost} pri={data.orchardCost} />
        </div>
      ) : (
        ''
      )}
      {/* <div className="padding-16">
        <RowTableValueBold title="3) 금융비" value="00만원" />
      </div> */}
      <div className="padding-8-16 gap-10">
        <BoxGreen1>
          <div className="font-bold f-size-14 line-h-20 color-text-secondary">
            {name}님 농장의 가장 큰 지출 항목은{' '}
            <span className="color-primary-500">
              {sortArr[0].text}({Math.round(sortArr[0].rate)}%)
            </span>
            이며, 그 다음은 {sortArr[1].text}({Math.round(sortArr[1].rate)}%),
            {sortArr[2].text}({Math.round(sortArr[2].rate)}%), {sortArr[3].text}
            ({Math.round(sortArr[3].rate)}%) 순으로 나타납니다. 지역 평균보다
            높은 항목은 관리가 필요합니다.
          </div>
        </BoxGreen1>
      </div>
    </div>
  );
};

export default ReportDetail4;
