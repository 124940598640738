import { useEffect, useState } from 'react';
import { GuideData } from './component/GuideBannerData';
import { useLocation, useNavigate } from 'react-router-dom';

const GuideBanner = ({ temp, setOnBanner }: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentGuide = GuideData.find(
    (item) => item.path === location.pathname,
  );

  const handleNavigate = () => {
    if (currentGuide) {
      navigate(currentGuide.promotionPath);
    }
  };
  const [isBannerClosed, setIsBannerClosed] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem('isBannerClosed') === 'true') {
      setIsBannerClosed(true);
    } else {
      setIsBannerClosed(false);
    }
  }, [temp]);

  return (
    <div
      className="padding-16 flex w-100per bg-transparent z-999 fixed"
      style={{
        top: isBannerClosed ? '50px' : '100px',
      }}
    >
      <div className="flex-row w-100per space-between bg-FFFFFF padding-12-16 border-radius-8 border-171E26">
        <div className="flex-row gap-8 center-y" onClick={handleNavigate}>
          <img src={currentGuide && currentGuide.mobileImageSrc} />
          <div className="flex-column">
            <div className="m-c2-11-r color-text-tertiary">
              트랜스파머가 처음이신가요?
            </div>
            <div className="m-b2-14-b color-dark-primary">
              {currentGuide && currentGuide.buttonText}
            </div>
          </div>
        </div>
        <img
          src="/asset/images/close/CloseBlack24.svg"
          onClick={() => {
            setOnBanner(false);
          }}
        />
      </div>
    </div>
  );
};

export default GuideBanner;
