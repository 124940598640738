import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import { useEffect } from 'react';
import { isMobile } from '../function/function';
const DoughnutChart4 = ({ list }: any) => {
  const options = {
    responsive: false,

    maintainAspectRatio: false, // Disable default aspect ratio

    devicePixelRatio: 3,
  };

  const colorArray = [
    '#0CA96D',
    '#F8B566',
    '#5377A1',
    '#9b1010',
    '#ca29c2',
    '#c8dd08',
    '#1a1a1d',
  ];

  const expData = {
    datasets: [
      {
        labels: list.map((element: any) => element.text),
        data: list && list.map((element: any) => element.rate),
        borderWidth: 0,
        hoverBorderWidth: 3,
        backgroundColor: list && list.map((element: any) => element.color),
        fill: true,
      },
    ],
  };

  return (
    <div
      style={{
        display: 'relative',
        flexDirection: 'column',
        gap: '10px',
        width: isMobile(window.innerWidth) ? 'fit-content' : 'fit-content',
        alignItems: 'center',
      }}
    >
      <Doughnut
        data={expData}
        options={options}
        height="100vh"
        width="100vh"
        style={{
          width: '150px',
          height: '150px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      />
    </div>
  );
};

export default DoughnutChart4;
