import { RowSectionTitle } from '../../../../component/report/Row';
import { TextSectionTitle } from '../../../../component/report/Text';
import { transNum } from '../../../../component/function/function';
import { BoxCard1 } from '../../../../component/report/Box';
interface data {
  perMeterEstimatedPrice: number;
  perPyeongEstimatedPrice: number;
  estimatedPrice: number;
  useStatus: string;
  height: string;
  shape: string;
  roadSurface: string;
  landTradePrice: number | null;
  landTradeDate: object | null;
  officialPriceList: object;
  difference: number;
  surroundings: object;
  surroundingsAvgPrice: number;
}

interface data2 {
  address: string;
  cropName: string;
  crops: number;
  facility: string;
  farmArea: number;
  quantity: number;
  type: string;
  landArea: number;
}

const Summary1 = ({ data, data2 }: { data: data; data2: data2 }) => {
  return (
    <div>
      <RowSectionTitle>
        <TextSectionTitle>농지 가격</TextSectionTitle>
      </RowSectionTitle>
      <div className="padding-0-16-24 gap-8">
        <div className="flex-row w-100per">
          <BoxCard1
            title="농지 추정가"
            value={
              <div className="flex-coloumn">
                <div className="font-bold f-size-18 line-h-26 color-text-secondary">
                  {transNum(data.estimatedPrice, true) + '원'}
                </div>
                <div className="font-medium f-size-12 line-h-14 color-text-tertiary">
                  {transNum(
                    data.estimatedPrice / (data2.landArea * 0.3025),
                    true,
                  ) + '원/평'}
                </div>
              </div>
            }
          />
          <BoxCard1
            title="실거래가"
            value={
              data.landTradePrice !== null ? (
                transNum(data.landTradePrice, true) + '원'
              ) : (
                <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                  최근 5년 내 <br /> 거래 이력이 없습니다.
                </div>
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Summary1;
