import { useSelector } from 'react-redux';
import loadingImg from '../../asset/images/spinner/loading.svg';
const Loading = () => {
  const state = useSelector((state: any) => {
    return state.loading;
  });

  const LoadingBase = ({ width, save }: { width: string; save?: boolean }) => (
    <div
      className="fixed z-1000 flex-column flex-center gap-16 padding-24 "
      style={
        window.innerWidth < 1024
          ? {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background:
                state.loading === 'W' ||
                state.loading === 'A' ||
                state.loading === 'S'
                  ? '#fff'
                  : '#00000075',
            }
          : {
              top: state.loading === 'R' ? '126px' : 0,
              bottom: 0,
              left:
                state.loading === 'W2'
                  ? 0
                  : state.loading === 'W' || state.loading === 'S'
                    ? '449px'
                    : state.loading === 'R'
                      ? '778px'
                      : '74px',
              width: state.loading === 'R' ? '365px' : width,
              height: state.loading === 'R' ? '702px' : '',
              background:
                state.loading === 'W' ||
                state.loading === 'A' ||
                state.loading === 'S'
                  ? '#fff'
                  : '#00000075',
            }
      }
    >
      {(state.loading === 'W' ||
        state.loading === 'A' ||
        state.loading === 'S') && (
        <div className="font-bold f-size-16">
          {save ? '보고서 저장 중입니다' : 'AI 분석 중입니다'}
        </div>
      )}
      <div className="flex-row gap-10 spinner-container">
        <img src={loadingImg} className="spinner-dot" />
        <img src={loadingImg} className="spinner-dot" />
        <img src={loadingImg} className="spinner-dot" />
        <img src={loadingImg} className="spinner-dot" />
      </div>
    </div>
  );

  return state.loading === 'Y' ||
    state.loading === 'A' ||
    state.loading === 'W' ? (
    <LoadingBase width="375px" />
  ) : state.loading === 'W2' ? (
    <LoadingBase width="100%" />
  ) : state.loading === 'R' ? (
    <LoadingBase width="720px" />
  ) : state.loading === 'S' ? (
    <LoadingBase width="375px" save />
  ) : (
    <></>
  );
};

export default Loading;
