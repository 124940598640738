import Request from '../../@api/request';
interface commentData {
  content: string;
  articleIdx: string | undefined;
  parentIdx?: string;
}

interface inquiryData {
  fileImg?: [];
  type: string;
  title: string;
  content: string;
  email: string;
}

interface inquiryDetailType {
  isAdmin?: string;
}

interface ModifyData {
  board: string;
  title: string;
  content: string;
  isAdmin?: string;
}
interface ModifyComment {
  content: string;
  isAdmin?: string;
}
interface LikeData {
  type: string;
}
interface ContentData {
  content?: string;
  type: string;
}
interface WriteData {
  board: number;
  title: string;
  content: string;
}

// const baseURL = "http://localhost:3333/";
// const baseURL = "https://back.transfarmer.kr/";
const baseURL = `${process.env.REACT_APP_HOST}`;
export const request = new Request(baseURL);

export default class BoardService {
  static GetNoticeList = async (offset: number, limit: number) => {
    const result = await request.get(
      `boards/article/list?board=1&offset=${offset}&limit=${limit}`,
    );
    return result.data;
  };
  static GetNoticeDetail = async (articleIdx: string) => {
    const result = await request.get(`boards/article/${articleIdx}`);
    return result;
  };
  static GetCommunity = async (type: string, limit: number, offset: number) => {
    return await request.get(
      `boards?type=${type}&limit=${limit}&offset=${offset}`,
    );
  };
  static GetInquiryList = async (
    type: string,
    limit?: number,
    offset?: number,
  ) => {
    return await request.get(
      `users/mypage/inquiry/list?type=${type}&limit=${limit}&offset=${offset}`,
    );
  };
  static WriteInuqiry = async (formData: any) => {
    const result = await request.post('inquiry/base', formData);

    return result;
  };
  static getInquiryDetail = async (
    inquiryIdx: string,
    formData: inquiryDetailType,
  ) => {
    const result = await request.post(`inquiry/base/${inquiryIdx}`, formData);
    return result;
  };
  static GetBoardList = async (
    limit: number,
    offset: number,
    board: number,
  ) => {
    const data = await request.get(
      `boards/article/list?board=${board}&offset=${offset}&limit=${limit}`,
    );
    return data.data;
  };
  static GetBoardDetail = async (articleIdx: number) => {
    const data = await request.get(`boards/article/${articleIdx}`);
    return data.data;
  };

  static WriteBoard = async (data: WriteData) => {
    return await request.post('boards/article', data);
  };

  static GetComment = async (
    key: string,
    value: string | undefined,
    offset: number,
    limit: number,
  ) => {
    const data = await request.get(
      `boards/comment/list?key=${key}&value=${value}&offset=${offset}&limit=${limit}`,
    );
    return data.data;
  };
  static GetOneComment = async (commentIdx: string | undefined) => {
    const data = await request.get(`/boards/comment/${commentIdx}`);
    return data.data;
  };

  static WriteComment = async (data: commentData) => {
    const result = await request.post('/boards/comment', data);
    return result.data;
  };
  static GetInquiry = async (
    individual: string,
    offset: number,
    limit: number,
  ) => {
    const result = await request.get(
      `/inquiry/base/list?individual=${individual}&offset=0&limit=10`,
    );
    return result.data;
  };
  static ModifyBoard = async (articleIdx: string, data: ModifyData) => {
    const result = await request.put(
      `/boards/article/${articleIdx}/update`,
      data,
    );
    return result.data;
  };
  static ModifyComment = async (commentIdx: string, data: ModifyComment) => {
    const result = await request.put(
      `/boards/comment/${commentIdx}/update`,
      data,
    );
    return result.data;
  };
  static DeleteBoard = async (articleIdx: string, isAdmin?: string) => {
    const result = await request.put(
      `/boards/article/${articleIdx}/delete`,
      isAdmin,
    );
    return result.data;
  };
  static DeleteComment = async (commentIdx: string, isAdmin?: string) => {
    const result = await request.put(
      `/boards/comment/${commentIdx}/delete`,
      isAdmin,
    );
    return result.data;
  };
  static Like = async (idx: string, data: LikeData) => {
    const result = await request.post(`/boards/like/${idx}`, data);
    return result.data;
  };
  static Dislike = async (idx: string, data: LikeData) => {
    const result = await request.delete(`boards/dislike/${idx}`, {
      data: { ...data },
    });
    return result.data;
  };
  static Report = async (idx: string, data: ContentData) => {
    const result = await request.post(`/boards/declare/${idx}`, data);
    return result.data;
  };
}
