import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReportService from '../../service/report/reportApi';
import { set } from 'lodash';

export const PointColumn = ({ type, address }: any) => {
  const [pointTooltip, setPointTooltip] = useState(true);
  const [date, setDate] = useState('');
  const navigate = useNavigate();
  const [existReport, setExistReport] = useState(false);
  const location = useLocation();
  const checkReport = async () => {
    try {
      const result = await ReportService.check(type, address);
      console.log(result);
      setExistReport(result.isExist);
      setDate(result.expriation);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    checkReport();
  }, []);
  return (
    <div className="flex-row w-100per">
      {existReport ? (
        <div className="flex-row space-between padding-16 report_result_box bg-white bg-FFFFFF relative w-100per">
          <div className="flex-row gap-4 center-y">
            <img className="w-20" src="/asset/images/check/Check24Green2.svg" />
            <div className="m-b2-14-m color-dark-secondary">
              <span className="color-primary-600">{date} 까지 무료 재분석</span>
              할 수 있어요
            </div>
            <img className="w-20" src="/asset/images/warn/Warn20.svg" />
          </div>
        </div>
      ) : (
        <div className="flex-row space-between padding-16 report_result_box bg-white bg-FFFFFF relative w-100per">
          <div className="flex-row gap-4">
            <div className="m-b2-14-b color-dark-secondary">
              서비스 이용금액
            </div>
            <div className="m-b2-14-b" style={{ color: '#09CE7D' }}>
              2,000P
            </div>
            <img
              src="/asset/images/icon/info.svg"
              className="pointer"
              onClick={() => {
                setPointTooltip(true);
              }}
            />
          </div>
          <div
            className="underline font-bold f-size-14 line-h-20 color-dark-secondary pointer"
            onClick={() => {
              navigate('/point/recharge', {
                state: { search: true },
              });
            }}
          >
            충전하기
          </div>
          {pointTooltip && (
            <div
              className="flex-row center-y speech-bubble5 absolute m-c1-12-b color-FFFFFF"
              style={{
                backgroundColor: '#3D5066',

                borderRadius: '8px',
                color: 'white',
                padding: '8px',
                right: '0',
                bottom: '45px',
              }}
            >
              입력 후{'  '}
              <span style={{ color: '#09CE7D', marginLeft: '3px' }}>
                {' '}
                보고서 확인 시 포인트가 필요
              </span>
              해요
              <img
                className="ml-4 pointer"
                src="/asset/images/close/close_point_tooltip.svg"
                onClick={() => {
                  setPointTooltip(false);
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
